import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { updateStatus } from '../utils/updateStatus';
import { generateDocuments } from '../utils/generateDocuments';

/**
 * Component for displaying extra filters in the MailSender show view.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.dispatchAPI - Function for dispatching API requests.
 * @param {Function} props.setRefreshData - Function to set the refresh data state.
 * @param {boolean} props.refreshData - Whether data should be refreshed.
 * @param {Function} props.message - Function for displaying messages.
 * @returns {JSX.Element} React element representing the component.
 */

export const ShowExtraFilters = ({
  dispatchAPI,
  setRefreshData,
  refreshData,
  message,
  mailSender,
  setLoading
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type, id, status, sendingType } = useParams();

  return (
    <Row gutter={16}>
      {status !== 'SENT' && sendingType !== 'EMAIL' ? (
        <Col>
          <Button
            onClick={() =>
              updateStatus({
                dispatchAPI,
                id,
                setRefreshData,
                refreshData,
                message,
                navigate,
                sendingType,
                type,
                mailSender
              })
            }
          >
            {t('mail-sender.buttons.sent')}
          </Button>
        </Col>
      ) : null}
      {sendingType !== 'EMAIL' ? (
        <Col>
          <Button
            onClick={() => {
              generateDocuments(
                mailSender,
                dispatchAPI,
                message,
                t,
                setLoading
              );
            }}
          >
            {t('buttons.download')}
          </Button>
        </Col>
      ) : null}
      {status !== 'SENT' ? (
        <Col>
          <Button
            onClick={() =>
              navigate(`/mail-sender/edit/${type}/${id}/${sendingType}`)
            }
          >
            {t('buttons.edit')}
          </Button>
        </Col>
      ) : null}
    </Row>
  );
};

ShowExtraFilters.propTypes = {
  dispatchAPI: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  message: PropTypes.func.isRequired,
  mailSender: PropTypes.shape({}).isRequired,
  setLoading: PropTypes.func
};

ShowExtraFilters.defaultProps = {
  setLoading: () => {}
};
