/**
 * Configuration function for monthly sales chart.
 * @param {Object} params - The parameters for the monthlySalesChartConfig function.
 * @param {Array} params.monthlySalesChartData - Data for the monthly sales chart.
 * @returns {Object} - Configuration object for the monthly sales chart.
 */
export const monthlySalesChartConfig = ({ monthlySalesChartData }) => ({
  data: monthlySalesChartData.flat(2),
  xField: 'month',
  yField: 'sales',
  seriesField: 'type',
  color: ['#094452', '#FF483C', '#7EDB50'],
  isGroup: true,
  tooltip: {
    /**
     * Formatter function for the tooltip.
     * @type {Function}
     * @param {Object} item - Data item for the tooltip.
     * @returns {Object} - Formatted tooltip content.
     */
    formatter: (item) => ({
      name: item.type,
      value: `${item.sales.toFixed(2)} €`
    })
  },
  legend: {
    position: 'bottom',
    marker: {
      symbol: 'circle'
    },
    itemName: {
      style: {
        fontSize: 15,
        fontWeight: 400
      }
    }
  }
});
