import { Route, Routes } from 'react-router-dom';
import { CustomerInvoiceRouter } from './customers/CustomerInvoiceRouter';
import { InternInvoiceRouter } from './intern/InternInvoiceRouter';
import { Exception } from '../../components';

/**
 * Router component for handling different types of invoices.
 *
 * @component
 *
 * @returns {ReactElement} The rendered InvoiceRouter component.
 */
export const InvoiceRouter = () => (
  <Routes>
    <Route path="/customers/*" element={<CustomerInvoiceRouter />} />
    <Route path="/intern/*" element={<InternInvoiceRouter />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
