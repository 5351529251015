export const routes = {
  DASHBOARD: '/',
  PET_SITTING_ADDRESS: '/petsitting-address',
  ANIMALS: '/my-pets',
  SUPERVISIONS: '/supervisions',
  VETERINARIAN_GROOMER: '/veterinarian-groomer',
  SUBSCRIPTION: '/subscription',
  DOCUMENTS: '/documents',
  VIEW_ADS: '/view-ads'
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  CHANGE_ROLE: '/change-role',
  PASSWORD_CREATION: '/post-pwd'
};
