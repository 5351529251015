/**
 * Function to generate configuration for a registrations chart using ant-design/charts.
 *
 * @function
 * @param {Array} data - The chart data.
 * @returns {Object} - Configuration object for the registrations chart.
 */
export const registrationConfig = (data) => ({
  data,
  xField: 'month',
  yField: 'sales',
  seriesField: 'type',
  color: ['#90C4D9', '#F8B238'],
  isGroup: true,
  tooltip: {
    formatter: (item) => ({ name: item.type, value: `${item.sales} ` })
  },
  legend: {
    position: 'bottom',
    marker: {
      symbol: 'circle'
    },
    itemName: {
      style: {
        fontSize: 15,
        fontWeight: 400
      }
    }
  }
});
