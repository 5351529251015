export const onFinishSummaryForm = async ({
  form,
  setSummaryData,
  setCurrent,
  setFormSubmitted,
  Message,
  t
}) => {
  try {
    const values = await form.validateFields();
    setSummaryData(values);
    setCurrent(3);
    setFormSubmitted(true);
    return null;
  } catch (errorInfo) {
    return Message.error(t('supervisions.messages.required_fields'));
  }
};
