import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col, Button, Spin } from 'antd';
import { generalPetsittingAddressInfo } from '../../../../../components/PetsittingAddress/listContent/petsittingAddressInfo';
import { DescriptionList } from '../../../../../components';
import PetsittingAddressInfoModal from '../modals/PetsittingAddressModal';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * PetsittingAddressInfoCards is a component that displays information about a pet-sitting address,
 * including a carousel of photos and a description list with additional details.
 * It also manages a modal which can be opened to view more details.
 *
 * @component
 * @param {Object} props - The properties passed down from the parent component.
 * @param {Function} props.t - A function to handle the translation of strings.
 * @param {boolean} props.isLoading - A boolean flag to indicate whether data is currently being loaded.
 * @param {Object} props.supervision - An object containing supervision details, including information about the pet-sitting address.
 *
 * @returns {React.Element} JSX representation of the component.
 */
const PetsittingaddressInfoCards = ({ t, isLoading, supervision }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { address: { petsitting_address } = {} } = supervision || {};

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[12, 12]}>
        <Col xs={24} xxl={24}>
          <Card
            title={t('owners.show.petsitting_address_info')}
            extra={
              <Button onClick={() => setIsModalOpen(true)} size="small">
                {t('owners.buttons.show_more')}
              </Button>
            }
          >
            <Row gutter={[24, 24]}>
              <Col xs={24} md={8}>
                <Carousel imagesIds={petsitting_address?.dwelling_photos} />
              </Col>
              <Col xs={24} md={16}>
                <DescriptionList
                  data={generalPetsittingAddressInfo(petsitting_address || {})}
                  translate
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <PetsittingAddressInfoModal
        address={supervision?.address?.petsitting_address}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        t={t}
        fromSupervision
      />
    </Spin>
  );
};

export default PetsittingaddressInfoCards;

PetsittingaddressInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  supervision: PropTypes.shape({
    address: PropTypes.shape({
      petsitting_address: PropTypes.shape({
        dwelling_photos: PropTypes.arrayOf(PropTypes.shape({}))
      })
    })
  }).isRequired
};
