import { Button, Collapse, Divider, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useDetailsCollapse } from './utils/useDetailsCollapse';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';

export const ShowMyAnimals = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, dispatchAPI } = useAuthContext();
  const [owner, setOwner] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { petCollapse } = useDetailsCollapse(
    owner?.animals || [],
    t,
    setRefresh
  );
  const { message } = useErrorMessage();

  const getOwner = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/owners?email=${user?.email}&populate=animals,animals.animal_photo`
      });
      const animalPhotos = [];
      if (data[0].animals.length) {
        const animalPhotosPromises = data[0].animals.map(
          async (animal, index) => {
            const animalPhotoProcessPromises = animal.animal_photo.map(
              (photo) => createFileFromJSON(photo, dispatchAPI, message)
            );
            animalPhotos[index] = await Promise.all(animalPhotoProcessPromises);
          }
        );

        await Promise.all(animalPhotosPromises);
      }

      const updatedData = {
        ...data[0],
        animals: data[0].animals
          .filter((el) => !el.is_archived)
          .map((animal, index) => ({
            ...animal,
            animal_photo: animalPhotos[index]
          }))
      };

      setOwner(updatedData);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getOwner();
    })();
  }, [refresh]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        title={t('owners.users_shows.title.my_pets')}
        extra={
          <Button
            type="primary"
            onClick={() => navigate(`/my-pets/create/${owner?._id}`)}
          >
            <PlusOutlined />
            {t('owners.users_shows.buttons.add_animal')}
          </Button>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            size="large"
            items={petCollapse}
            className="pet-collapse"
          />
        </Skeleton>
        <Divider />
      </ContentCustom>
    </>
  );
};
