import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { ListGeneralCondition } from './general-condition/ListGeneralCondition';
import { ListSubscriptionPrice } from './subscription-price/ListSubscriptionPrice';

const { Panel } = Collapse;

export const Settings = () => {
  const { t } = useTranslation();

  const panels = [
    {
      title: t('settings.panels.titles.general_condition'),
      element: <ListGeneralCondition />
    },
    {
      title: t('settings.panels.titles.subscription'),
      element: <ListSubscriptionPrice />
    }
  ];

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom>
        <Collapse>
          {panels.map((panel) => (
            <Panel key={panel.title} header={panel.title}>
              {panel.element}
            </Panel>
          ))}
        </Collapse>
      </ContentCustom>
    </>
  );
};
