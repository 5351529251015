import { createContext, useContext, useReducer, useState } from 'react';
import { supervisionReducer, initialState } from './reducer';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../AuthContext';

const SupervisionContext = createContext();

/**
 * Provides the context for supervision-related data and actions.
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The context provider component.
 */
export const SupervisionContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(supervisionReducer, initialState);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [selectedAnouncePhotos, setSelectedAnouncePhotos] = useState([]);

  /**
   * Retrieves the supervision data for a given ID.
   * @async
   * @function
   * @param {string} id - The ID of the supervision.
   * @returns {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getSupervision = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/supervisions/form/${id}`
      });

      dispatch({ type: 'SET_SUPERVISION', payload: data });
      setSelectedAnouncePhotos(data.summary.anounce_photos);
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  /**
   * Retrieves the announcement data for a given ID.
   * @async
   * @function
   * @param {string} id - The ID of the announcement.
   * @returns {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getAnounce = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/anounces/form?supervision=${id}&populate=supervision,supervision.summary.anounce_photos,supervision.address.petsitting_address`
      });
      if (data) {
        dispatch({ type: 'SET_ANOUNCE', payload: data });
      }
    } catch (error) {
      message(error);
    }
  };

  return (
    <SupervisionContext.Provider
      value={{
        state,
        dispatch,
        getSupervision,
        isLoading,
        setIsLoading,
        getAnounce,
        refreshData,
        setRefreshData,
        selectedAnouncePhotos,
        setSelectedAnouncePhotos,
        setStatus: (status) =>
          dispatch({ type: 'SET_STATUS', payload: status }),
        setCurrent: (current) =>
          dispatch({ type: 'SET_CURRENT', payload: current }),
        setAnounceModalVisible: (visible) =>
          dispatch({ type: 'SET_ANOUNCE_MODAL_VISIBLE', payload: visible }),
        setCancelSupervisionModalVisible: (visible) =>
          dispatch({
            type: 'SET_CANCEL_SUPERVISION_MODAL_VISIBLE',
            payload: visible
          }),
        setInterruptSupervisionModalVisible: (visible) =>
          dispatch({
            type: 'SET_INTERRUPT_SUPERVISION_MODAL_VISIBLE',
            payload: visible
          }),
        setIsEmailModalOpen: (visible) =>
          dispatch({ type: 'SET_IS_EMAIL_MODAL_OPEN', payload: visible })
      }}
    >
      {children}
    </SupervisionContext.Provider>
  );
};

export const useSupervisionContext = () => useContext(SupervisionContext);
