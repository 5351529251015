export const useGroomerInfos = (groomer) => {
  const { address, name } = groomer || {};
  const { postal_code, city, street, number, country } = address || {};

  const groomerInfos = [
    {
      label: 'owners.show.veterinarian_groomer.name',
      content: name || '-',
      span: 1
    },
    {
      label: 'owners.show.veterinarian_groomer.postal_address',
      content:
        `${number || ''} ${street || ''} ${postal_code || ''} ${city || ''}` ||
        '-',
      span: 2
    },
    {
      label: 'owners.show.veterinarian_groomer.country',
      content: country || '-',
      span: 1
    },
    {
      label: 'owners.show.veterinarian_groomer.phone_number',
      content: groomer?.phone_number?.number || '-',
      span: 2
    },
    {
      label: 'owners.show.veterinarian_groomer.send_ads',
      content: groomer.send_ads || '-',
      span: 1
    }
  ];

  return { groomerInfos };
};
