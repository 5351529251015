import { createFilter } from '../../../utils/columnFilters/createFilter';

export const postalCodeFilter = (searchInput, t) =>
  createFilter({
    dataIndex: 'address',
    placeHolder: 'owners.show.place_holders.search_by_postal_code',
    filterFunction: (value, record, dataIndex) =>
      record?.[dataIndex]?.postal_code.toString().toLowerCase().includes(value),
    searchInput,
    t
  });
