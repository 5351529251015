import { Badge, Dropdown, Row, Col, Avatar } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { handleNotifMenuItemLink } from './handleNotificationMenuLink';
import { useDeleteNotification } from '../../utils/deleteNotification';

export const Notifications = ({
  notifications,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const { deleteNotification } = useDeleteNotification({
    dispatchAPI,
    message,
    setForceRefresh,
    forceRefresh
  });

  const consultedNotif = (notification) =>
    !notification.consulted.filter((id) => id === user._id).length;

  const refExist = notifications.filter((notif) => notif.item.type !== null);

  const hasUnreadNotification = refExist.some(
    (notification) =>
      consultedNotif(notification) && !notification.consulted.includes(user._id)
  );

  const items = {
    items: [
      ...(notifications || []).map(
        (notification) =>
          consultedNotif(notification) && {
            key: notification?._id,
            label: (
              <Row>
                <Col span={24}>
                  {handleNotifMenuItemLink(
                    notification?._id,
                    notification?.item,
                    deleteNotification,
                    t,
                    notification?.created_at,
                    notification?.consulted
                  )}
                </Col>
              </Row>
            )
          }
      )
    ]
  };

  const numberOfNotifNotConsulted = items.items.filter(
    (item) => item !== false
  );

  return hasUnreadNotification ? (
    <Dropdown trigger={['click']} menu={items}>
      <Badge
        count={numberOfNotifNotConsulted.length}
        status="warning"
        size="small"
        className="notification-badge"
      >
        <Avatar icon={<BellOutlined />} />
      </Badge>
    </Dropdown>
  ) : null;
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      item: PropTypes.shape({
        type: PropTypes.shape({
          _id: PropTypes.string
        })
      })
    })
  ),
  forceRefresh: PropTypes.bool,
  setForceRefresh: PropTypes.func
};

Notifications.defaultProps = {
  notifications: null,
  forceRefresh: false,
  setForceRefresh: null
};
