export const setRatingShowInfo = ({
  setSupervisionRating,
  reviewData,
  reviewActiveKey,
  form,
  questionnaireType
}) => {
  setSupervisionRating(reviewData.supervision);
  let receiverIds = [];

  if (reviewActiveKey === 'received_reviews') {
    receiverIds = reviewData.receiver.id.map((pensioner) => pensioner._id);
  } else {
    receiverIds = reviewData.supervision.main_informations.owner._id;
  }
  form.setFieldsValue({
    ...reviewData,
    questionnaire_type: questionnaireType,
    author: { id: reviewData.author.id._id },
    receiver: { id: receiverIds }
  });
};
