export const updateSupervisionStatus = async ({
  nextStatus,
  dispatchAPI,
  id,
  setRefreshData,
  refreshData,
  message
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `supervisions/${id}/status`,
      body: { status: nextStatus }
    });
    setRefreshData(!refreshData);
  } catch (e) {
    message(e.response.status);
  }
};
