import { processedTemplateContent } from '../../../../components/Mailing/utils/processedTemplateContent';
import { sendMail } from '../../../../components/Mailing/utils/sendMail';

/**
 * Confirms the pensioner for a supervision.
 * @async
 * @function
 *
 * @param {Object} options - The options for confirming the pensioner.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {Object} options.supervision - The supervision object.
 * @param {Function} options.setRefreshData - The function to set the refresh data flag.
 * @param {Function} options.message - The function to display a message.
 * @param {boolean} options.refreshData - The flag to refresh the data.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.setOpenModal - The function to set the open modal flag.
 * @returns {Promise<void>} - A promise that resolves when the pensioner is confirmed.
 */
export const confirmPensioner = async ({
  dispatchAPI,
  supervision,
  setRefreshData,
  message,
  refreshData,
  t,
  setOpenModal
}) => {
  setOpenModal(true);
  try {
    await dispatchAPI('PATCH', {
      url: `supervisions/${supervision._id}/status`,
      body: { status: 'BOOKED' }
    });

    const customers = [];
    customers.push({
      id: supervision.main_informations.owner.user._id,
      template_type: 'OWNER_SUPERVISION_VALIDATION'
    });

    supervision.pensioners.forEach((pensioner) => {
      customers.push({
        id: pensioner.user._id,
        template_type: 'PENSIONER_SUPERVISION_VALIDATION'
      });
    });

    const mailPromise = customers.map(async (customer) => {
      const { data } = await dispatchAPI('GET', {
        url: `templates-mail/type/${customer.template_type}`
      });

      const content = await processedTemplateContent({
        templateType: customer.template_type,
        templateContent: data[0]?.template_content,
        collectionData: supervision,
        dispatchAPI,
        t
      });

      const newValue = {
        ...data[0],
        recipients: customer.id
      };

      await sendMail({
        value: newValue,
        dispatchAPI,
        message,
        templateType: customer.template_type,
        setIsSubmitting: null,
        editorState: content,
        setIsModalOpen: null,
        documentType: null,
        recordID: null,
        filterID: null,
        filesList: [],
        t
      });
    });

    await Promise.all(mailPromise);

    supervision.pensioners.map(async (pensionerData) => {
      await dispatchAPI('PATCH', {
        url: `pensioners/${pensionerData._id}`,
        body: { is_in_supervision: true }
      });
    });

    await dispatchAPI('POST', {
      url: `/customerinvoices/form/${supervision._id}`
    });

    setRefreshData(!refreshData);
    setOpenModal(false);
  } catch (error) {
    setOpenModal(false);
    message(error);
  }
};
