export const pensionerListContent = (
  { address, phone_number, email, is_animals, is_smoker },
  t
) => [
  {
    label: t('profile.form.address'),
    content: address
      ? `${address?.number || ''}
                ${address?.street || ''},
                ${address?.additional || ''}
                ${address?.postal_code || ''}, ${address?.city || ''}, ${
          address?.country || ''
        }`
      : '-'
  },
  {
    label: t('profile.personal_info.country'),
    content: address?.country || '-'
  },
  {
    label: t('profile.personal_info.phone_number'),
    content: phone_number ? phone_number?.number : '-'
  },
  {
    label: t('profile.personal_info.email'),
    content: email || '-'
  },
  {
    label: t('profile.personal_info.have_animals'),
    content: is_animals
      ? t('profile.personal_info.yes')
      : t('profile.personal_info.no') || '-'
  },
  {
    label: t('profile.personal_info.is_smoker'),
    content: is_smoker
      ? t('profile.personal_info.yes')
      : t('profile.personal_info.no') || '-'
  }
];
