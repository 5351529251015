import PropTypes from 'prop-types';
import { Card, Spin, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * Renders a card component displaying the history of a supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object containing the history information.
 * @param {boolean} props.isLoading - A flag indicating whether the data is currently being loaded.
 * @returns {JSX.Element} The rendered HistoryCard component.
 */
export const HistoryCard = ({ supervision, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card title={t('owners.show.history_info')}>
        <Timeline
          style={{
            maxHeight: '400px',
            overflowY: 'scroll',
            width: '100%'
          }}
        >
          {(supervision?.history || []).map((event) => {
            const formattedDate = dayjs(event.date).format(
              'DD/MM/YYYY à HH:mm'
            );

            return (
              <Timeline.Item
                style={{
                  marginTop: '10px'
                }}
                key={`${event.type}-${formattedDate}`}
              >
                <Text>{t(`history.${event.type}`)}</Text>
                <br />
                <Text type="secondary" italic style={{ color: 'grey' }}>
                  {event.by
                    ? t('history.item', {
                        first_name: event?.by?.first_name,
                        last_name: event?.by?.last_name,
                        date: formattedDate
                      })
                    : t('history.automatic_action', { date: formattedDate })}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Card>
    </Spin>
  );
};

HistoryCard.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  supervision: PropTypes.shape({
    history: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
