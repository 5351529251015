import { Col, List, Typography, Row, Image, Carousel } from 'antd';
import {
  ExtraCollapseEditButton,
  ExtraCollapseDeleteButton
} from './ExtraCollapseButtons';

const { Text } = Typography;

export const useDetailsCollapse = (animals, t, setRefresh) => {
  const dogAndCatData = (animal) => [
    {
      label: t('animals.form.age'),
      value: animal.age
    },
    {
      label: t('animals.form.breed'),
      value: animal.breed
    },
    {
      label: t('animals.form.sex'),
      value: animal.sex
    },
    {
      label: t('animals.users_show.tattoo_number'),
      value: animal.tattoo_number
    },
    ...(animal.type === 'DOG'
      ? [
          {
            label: t('animals.users_show.walk_number'),
            value: animal.walk_outside_property_number
          },
          {
            label: t('animals.users_show.walk_frequency'),
            value: animal.walk_frequency
          },
          {
            label: t('animals.users_show.walk_duration'),
            value: animal.walk_duration
          }
        ]
      : []),
    {
      label: t('animals.form.is_animal_leave_property'),
      value: animal.is_animal_leave_property
        ? t('animals.users_show.yes')
        : t('animals.users_show.no')
    },
    {
      label: t('animals.form.is_runaway'),
      value: animal.is_runaway
        ? t('animals.users_show.yes')
        : t('animals.users_show.no')
    },
    {
      label: t('animals.form.in_case_of_absence'),
      value: animal.in_case_of_absence
    },
    {
      label: t('animals.form.where_it_sleep'),
      value: animal.where_it_sleep
    },
    {
      label: t('animals.form.alimentation_type_and_frequency'),
      value: animal.alimentation_type_and_frequency
    },
    {
      label: t('animals.form.diet'),
      value: animal.diet
    },
    {
      label: t('animals.form.treatment'),
      value: animal.treatment
    },
    {
      label: t('animals.form.brushing'),
      value: animal.brushing
    },
    {
      label: t('animals.form.other_animals_presence'),
      value: animal.other_animals_presence
        ? t('animals.users_show.yes')
        : t('animals.users_show.no')
    },
    {
      label: t('animals.form.additional_informations'),
      value: animal.additional_informations
    }
  ];

  const otherAnimalsData = (animal) => [
    {
      label: t('animals.form.number'),
      value: animal.number
    },
    {
      label: t('animals.form.breed'),
      value: animal.breed
    },
    ...(animal.type === 'FARM'
      ? [
          {
            label: t('animals.form.is_in_enclosure'),
            value: animal.is_in_enclosure
          },
          {
            label: t('animals.form.enclosure_cleaning'),
            value: animal.box_cleaning
          }
        ]
      : []),
    ...(animal.type === 'BIRD'
      ? [
          {
            label: t('animals.form.cage_cleaning'),
            value: animal.cage_cleaning
          }
        ]
      : []),
    ...(animal.type === 'EQUINE'
      ? [
          {
            label: t('animals.form.is_in_enclosure'),
            value: animal.is_in_enclosure
          },
          {
            label: t('animals.form.box_cleaning'),
            value: animal.box_cleaning
          }
        ]
      : []),
    {
      label: t('animals.form.alimentation_type_and_frequency'),
      value: animal.alimentation_type_and_frequency
    },
    {
      label: t('animals.form.treatment'),
      value: animal.treatment
    },
    {
      label: t('animals.form.other_animals_presence'),
      value: animal.other_animals_presence
        ? t('animals.users_show.yes')
        : t('animals.users_show.no')
    },
    {
      label: t('animals.form.additional_informations'),
      value: animal.additional_informations
    }
  ];

  const dataSourceByAnimalType = (animal) => {
    switch (animal.type) {
      case 'DOG':
        return dogAndCatData(animal);
      case 'CAT':
        return dogAndCatData(animal);
      case 'BIRD':
        return otherAnimalsData(animal);
      case 'EQUINE':
        return otherAnimalsData(animal);
      case 'FARM':
        return otherAnimalsData(animal);
      default:
        return otherAnimalsData(animal);
    }
  };

  const petCollapse = animals
    .filter((animal) => animal.is_archived === false)
    .map((animal, index) => ({
      key: index + 1,
      label: (
        <>
          <Text strong>{animal.name} </Text>
          <Text>
            Type d&apos;animal :{' '}
            <strong>{t(`animals.tags.${animal.type}`)}</strong>
          </Text>
        </>
      ),
      children: (
        <Row>
          <Col span={8}>
            <Carousel autoplay>
              {animal?.animal_photo.map((photo) => (
                <Image key={photo._id} width={200} src={photo.url} />
              ))}
            </Carousel>
          </Col>
          <Col span={16}>
            <List
              itemLayout="vertical"
              dataSource={dataSourceByAnimalType(animal)}
              renderItem={(item) => (
                <List.Item>
                  <Text>{item.label} : </Text>
                  <Text strong>{item.value}</Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      ),
      extra: (
        <>
          <ExtraCollapseEditButton animal={animal} t={t} />
          <ExtraCollapseDeleteButton
            animal={animal}
            t={t}
            setRefresh={setRefresh}
          />
        </>
      )
    }));

  return { petCollapse };
};
