/**
 * Sets parameters for creating or updating a mail sender record based on the type.
 *
 * @function
 * @param {Object} params - The parameters for setting create/update parameters.
 * @param {string} params.type - The type of mail sender record.
 * @param {Array} params.pensionerColumns - Columns specific to pensioners.
 * @param {string} params.sendingType - The type of sending (e.g., 'EMAIL', 'POST').
 * @param {Array} params.ownerColumns - Columns specific to owners.
 * @param {Array} params.veterinarianAndGroomerColumns - Columns specific to veterinarians and groomers.
 * @returns {Object|string} - Returns an object with parameters or a string indicating 'other'.
 */

export const setCreateUpdateParameters = ({
  type,
  pensionerColumns,
  sendingType,
  ownerColumns,
  veterinarianAndGroomerColumns
}) => {
  switch (type) {
    case 'ANOUNCEMENT':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}&is_newsletters=${true}&is_in_supervision=${false}`
            : `internet_access=${true}&is_newsletters=${true}&is_in_supervision=${false}`
      };
    case 'PENSIONER_WISH':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    case 'OWNER_WISH':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    case 'OWNER_EXPLANATORY_DOCUMENT':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    case 'PENSIONER_EXPLANATORY_DOCUMENT':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: ownerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    case 'GROOMER_VETERINARIAN_ADVERT':
      return {
        resourceName: 'veterinarians/groomer_and_veterinarian',
        resourceModelName: 'Veterinarian',
        columns: veterinarianAndGroomerColumns
      };
    case 'OTHER_PENSIONER':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    case 'OTHER_OWNER':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        extraQuery:
          sendingType === 'POST'
            ? `internet_access=${false}`
            : `internet_access=${true}`
      };
    default:
      return 'pensioners';
  }
};
