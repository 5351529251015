import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Col, Avatar, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useListContent } from './listContent';
import {
  ContentCustom,
  PageHeaderCustom,
  DescriptionList
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

export const ShowUser = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState();
  const listContent = useListContent(t, user);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users/${id}`
      });
      setUser(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const deleteUser = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${id}`,
        body: { is_archived: true }
      });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('users.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.USERS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteUser}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.archive')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Card
          title={t('users.show.card_title')}
          bordered={false}
          style={{ width: '100%' }}
          className="form-card"
        >
          <Row gutter={[0, 24]}>
            <Col xs={24} md={8} lg={6} xl={4} xxl={2}>
              <Skeleton
                loading={isLoading}
                title={0}
                paragraph={0}
                avatar={{ size: 80 }}
                active
              >
                <Avatar size={80} src={user && user.photo ? user.photo : ``} />
              </Skeleton>
            </Col>
            <Col xs={24} md={16} lg={18} xl={20} xxl={22}>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList data={listContent} translate />
              </Skeleton>
            </Col>
          </Row>
        </Card>
      </ContentCustom>
    </>
  );
};
