import React from 'react';
import { Typography, Row, Col, Divider } from 'antd';

const { Text, Title } = Typography;

/**
 * An array of necessary documents.
 * @type {string[]}
 */
const necessaryDocuments = [
  'criminal_record_extract',
  'identification_photocopy',
  'identification_photo',
  'insurance_certificate',
  'residence_proof',
  'early_retirement_certificate'
];

/**
 * Generates a list of missing documents.
 * @param {Object} missingDocumentsInfo - Information about missing documents.
 * @param {Function} t - Translation function.
 * @returns {React.ReactNode[]} - Array of React nodes representing missing documents.
 */
const generateMissingDocumentsList = (missingDocumentsInfo, t) => {
  const isAllDocumentsMissing = Object.keys(missingDocumentsInfo).length === 0;
  const documentsToCheck = isAllDocumentsMissing
    ? necessaryDocuments
    : Object.keys(missingDocumentsInfo);

  return documentsToCheck
    .map((document) => {
      if (isAllDocumentsMissing || !missingDocumentsInfo[document]) {
        return <Text>{t(`dashboard.messages.content.${document}`)}</Text>;
      }
      return null;
    })
    .filter((doc) => doc !== null);
};

/**
 * Adds a message to the messages array based on the given condition.
 * @param {Object[]} messages - Array of messages to be displayed.
 * @param {boolean} condition - Condition to check.
 * @param {string} titleKey - Key for translating the title.
 * @param {Object} missingDocumentsInfo - Information about missing documents.
 * @param {Function} t - Translation function.
 * @returns {void}
 */
const addMessage = ({
  messages,
  condition,
  titleKey,
  missingDocumentsInfo,
  t
}) => {
  if (condition) {
    const missingDocuments = generateMissingDocumentsList(
      missingDocumentsInfo,
      t
    );

    messages.push({
      title: missingDocuments.length ? (
        <Title level={5}>{t(`dashboard.messages.titles.${titleKey}`)}</Title>
      ) : null,
      content: titleKey === 'subscription' ? null : missingDocuments
    });
  }
};

/**
 * Component to set announcement messages based on missing conditions.
 * @param {Object} props - Component properties.
 * @param {Object} props.missingConditionInfo - Information about missing conditions.
 * @param {Object} props.pensionerInfo - Information about the pensioner.
 * @param {Function} props.t - Translation function.
 * @returns {React.ReactNode} - React node containing announcement messages.
 */
export const SetAnounceMessages = ({
  missingConditionInfo,
  pensionerInfo,
  t
}) => {
  const messages = [];

  // Check subscription condition
  addMessage({
    messages,
    condition: missingConditionInfo.status !== ('ACTIVE' || 'FREE'),
    titleKey: 'subscription',
    missingDocumentsInfo: {},
    t
  });

  // Check principal profile documents condition
  addMessage({
    messages,
    condition: true,
    titleKey: 'principal_profile_documents',
    missingDocumentsInfo:
      missingConditionInfo.principalProfileNecessaryDocuments,
    t
  });

  // Check secondary profile documents condition
  addMessage({
    messages,
    condition: Object.keys(pensionerInfo.secondary_profile).length > 3,
    titleKey: 'secondary_profile_documents',
    missingDocumentsInfo:
      missingConditionInfo.secondaryProfileNecessaryDocuments,
    t
  });

  // Render messages
  return messages.map(({ title, content }) =>
    title || content?.length ? (
      <>
        <Divider />
        <Row>
          <Col>{title}</Col>
        </Row>
        <Row>
          {content?.length
            ? content.map((docType) => <Col span={24}>{docType}</Col>)
            : null}
        </Row>
        <Divider />
      </>
    ) : null
  );
};
