import PropTypes from 'prop-types';
import { Row, Card, Col, Tabs } from 'antd';
import ListInvoices from '../ListInvoices';
import ListSupervisions from '../ListSupervisions';

/**
 * Displays information cards for supervisions and invoices within a tabbed interface.
 *
 * @component
 * @example
 * const ExampleComponent = () => {
 *   return <SupervisionAndInvoiceInfoCards t={translateFunction} pensioner={pensionerData} />;
 * };
 *
 * @param {Object} props - The component props.
 * @param {function} props.t - The translation function for internationalization.
 * @param {Object} props.pensioner - The pensioner data.
 * @returns {JSX.Element} - The rendered component.
 */
const SupervisionAndInvoiceInfoCards = ({ t, pensioner }) => {
  /**
   * Tab items for supervisions and invoices.
   * @type {Object[]}
   * @property {string} key - The unique key for the tab.
   * @property {string} label - The display label for the tab.
   * @property {JSX.Element} children - The content to be displayed under the tab.
   */
  const items = [
    {
      key: 'supervision',
      label: t('supervision_and_invoice.tabs.supervisions'),
      children: <ListSupervisions pensioner={pensioner} />
    },
    {
      key: 'invoice',
      label: t('supervision_and_invoice.tabs.invoices'),
      children: <ListInvoices pensioner={pensioner} />
    }
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xxl={24}>
        <Card className="card-with-tabs">
          <Tabs defaultActiveKey="supervision" items={items} />
        </Card>
      </Col>
    </Row>
  );
};

export default SupervisionAndInvoiceInfoCards;

SupervisionAndInvoiceInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  pensioner: PropTypes.shape({}).isRequired
};
