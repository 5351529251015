import { message as antdMessage } from 'antd';
import { patchSupervisionStatus } from './patchSupervisionStatus';
import { profileLockMessage } from '../../utils/profileLockMessage';
import { editingLocked } from '../../../../utils/editingLocked';

const editingCheck = ({ editSupervisionLock, t, id, navigate }) => {
  if (editSupervisionLock) {
    profileLockMessage(t, 'supervision');
  } else {
    navigate(`/supervisions/edit/${id}`);
  }
};

export const buttonActions = ({
  navigate,
  supervision,
  dispatchAPI,
  message,
  setSupervision,
  setIsLoading,
  id,
  setOpen,
  t,
  editSupervisionLock
}) => ({
  WAITING: () =>
    editingCheck({
      editSupervisionLock: editingLocked(editSupervisionLock, id),
      t,
      id,
      navigate
    }),
  REQUEST: () =>
    editingCheck({
      editSupervisionLock: editingLocked(editSupervisionLock, id),
      t,
      id,
      navigate
    }),
  BOOKED: () =>
    antdMessage.warning(t('supervisions.users_shows.messages.no_edit')),
  PUBLISHED: () =>
    antdMessage.warning(t('supervisions.users_shows.messages.no_edit')),
  IN_PROGRESS: () =>
    antdMessage.warning(t('supervisions.users_shows.messages.no_edit')),
  WAITING_BOOKING: () =>
    patchSupervisionStatus({
      status: { status: 'BOOKED' },
      pensioners: supervision.pensioners,
      dispatchAPI,
      supervision,
      message,
      setSupervision,
      setIsLoading,
      id,
      t
    }),
  FINISHED: () => setOpen(true),
  default: null
});
