import { Card, Col, List, Skeleton, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CheckSquareOutlined, CloseOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ListDocument } from './ListDocument';
import { useCallsAPI } from '../utils/callAPI';
import { getSubscription } from '../../pensioners/utils/getSubscription';
import { useListContent } from './listContent';

const { Text, Title } = Typography;

/**
 * Renders the component for displaying the user's subscription information.
 * @component
 * @returns {JSX.Element} The JSX element representing the ShowMySubscription component.
 */
export const ShowMySubscription = () => {
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pensioner, setPensioner] = useState({
    _id: '',
    status: '',
    user: '',
    name: ''
  });
  const [invoice, setInvoice] = useState([]);

  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { listContent } = useListContent(subscription);

  const { getProfile } = useCallsAPI({
    setIsLoading,
    setFunction: (data) =>
      setPensioner({
        _id: data[0]._id,
        status: data[0].status,
        user: data[0].user,
        name: `${data[0].first_name} ${data[0].last_name}`
      })
  });

  useEffect(async () => {
    await getProfile();
  }, []);

  const getInvoice = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerinvoices?customer=${pensioner?.user}`
      });
      setInvoice(data[data.length - 1]);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    (async () => {
      if (pensioner._id) {
        Promise.all([
          getSubscription({ dispatchAPI, pensioner, setSubscription, message }),
          getInvoice()
        ]);
        await getInvoice();
      }
    })();
  }, [pensioner]);

  return (
    <>
      <PageHeaderCustom
        title={t('dashboard.cards_titles.subscription')}
        withoutBreadCrumb
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <Col>
            <Card
              // style={{ backgroundColor: 'red' }}
              className="my-subscription-card"
              title={
                pensioner.status === 'ACTIVE' ? (
                  <Title level={4}>
                    <Space>
                      {t('dashboard.show.subscription.sub_title')}
                      <CheckSquareOutlined
                        style={{ color: 'var(--addColor)' }}
                      />
                    </Space>
                  </Title>
                ) : (
                  <Title level={4}>
                    <Space>
                      {t('dashboard.show.subscription.no_sub_title')}
                      <CloseOutlined style={{ color: 'var(--errorColor)' }} />
                    </Space>
                  </Title>
                )
              }
            >
              <p>
                {t('dashboard.show.subscription.text_sub_infos', {
                  price: subscription?.total_ttc
                })}
              </p>
              <p>
                {t('dashboard.show.subscription.text_sub_infos_payment', {
                  price: subscription?.total_ttc
                })}
              </p>
              {pensioner.status === 'ACTIVE' && (
                <List
                  itemLayout="vertical"
                  dataSource={listContent}
                  renderItem={(item) => (
                    <List.Item>
                      <Text>{item.label} : </Text>
                      <Text strong>{item.content}</Text>
                    </List.Item>
                  )}
                />
              )}
              {Object.keys(subscription).length && pensioner?.name && (
                <ListDocument
                  invoice={{
                    invoiceId: invoice?._id,
                    reference: invoice?.reference
                  }}
                  isLoading={isLoading}
                  pensioner={[pensioner]}
                />
              )}
            </Card>
          </Col>
        </Skeleton>
      </ContentCustom>
    </>
  );
};
