import moment from 'moment';

const pensionerInfo = (data, t) => {
  const {
    civility,
    first_name,
    last_name,
    birth_date,
    profession,
    principal_language
  } = data;

  return [
    {
      label: 'pensioners.form.civility',
      span: 1,
      content: (civility && t(`pensioners.tags.${civility}`)) || '-'
    },
    {
      label: 'pensioners.form.first_name',
      span: 1,
      content: (first_name && first_name) || '-'
    },
    {
      label: 'pensioners.form.last_name',
      span: 1,
      content: (last_name && last_name) || '-'
    },
    {
      label: 'pensioners.form.birth_date',
      span: 1,
      content: (birth_date && moment(birth_date).format('DD-MM-YYYY')) || '-'
    },
    {
      label: 'pensioners.form.profession',
      span: 1,
      content: (profession && profession) || '-'
    },
    {
      label: 'pensioners.form.principal_language',
      span: 1,
      content: (principal_language && principal_language) || '-'
    }
  ];
};

export { pensionerInfo };
