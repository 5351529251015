import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Avatar } from 'antd';
import { pensionerInfo } from '../listContent/pensionerInfo';
import { DescriptionList } from '../../../../../components';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * Renders a set of cards containing information about a pensioner.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {boolean} props.isLoading - Indicates whether the data is currently loading.
 * @param {Object} props.pensioner - The pensioner object containing the information.
 * @param {Function} props.getColWidth - The function to calculate the column width.
 * @returns {JSX.Element} The rendered component.
 */
const PensionerInfoCards = ({ t, isLoading, pensioner, getColWidth }) => {
  const { token } = useAuthContext();
  const [pensioners, setPensioners] = useState([]);
  const [pensionerCount, setPensionerCount] = useState(1);

  const setPensionersInfo = () => {
    let pensionerData = [];
    if (Object.keys(pensioner).length) {
      if (Object.keys(pensioner.secondary_profile).length > 3) {
        const primaryObj = { ...pensioner };
        delete primaryObj.secondary_profile;

        const secondaryObj = { ...pensioner?.secondary_profile };

        pensionerData = [primaryObj, secondaryObj];
      } else {
        pensionerData = [pensioner];
      }

      setPensioners(pensionerData);
      setPensionerCount(pensionerData.length);
    }
  };

  useEffect(() => {
    setPensionersInfo();
  }, [pensioner]);

  const photoDocumentType = [
    'identification_photo',
    'secondary_profile_identification_photo'
  ];
  const photo = pensioners?.flatMap((profile) =>
    profile?.documents?.filter((doc) => photoDocumentType.includes(doc.type))
  );

  return (
    <Row gutter={[12, 12]}>
      {pensioners.map((p, index) => (
        <Col
          xs={24}
          xxl={getColWidth(pensionerCount)}
          // eslint-disable-next-line react/no-array-index-key
          key={p}
        >
          <Card
            title={t('pensioners.show.pensioner_info', {
              pensioner_number: index + 1
            })}
          >
            <Row gutter={[0, 24]}>
              <Col xs={24} md={8}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  {photo[index]?._id?._id && (
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL}/owners/get-url/${photo[index]?._id?._id}/${token}`}
                      size={160}
                    />
                  )}
                </Skeleton>
              </Col>
              <Col xs={24} md={16}>
                <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                  <DescriptionList data={pensionerInfo(p || {}, t)} translate />
                </Skeleton>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default PensionerInfoCards;

PensionerInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({
    secondary_profile: PropTypes.shape({}),
    photo: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  getColWidth: PropTypes.func.isRequired
};
