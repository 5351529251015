export const useDocumentsManagement = (supervision, t) => {
  const contractsConditions = () => {
    if (supervision?.pensioners?.length === 1) {
      return [
        {
          label: t('supervisions.show.documents.PENSIONER_CONTRACT_1', {
            reference: supervision.pensioners?.[0]?.reference
          }),
          value: 'PENSIONER_CONTRACT_1'
        }
      ];
    }
    if (supervision?.pensioners?.length > 1) {
      return [
        {
          label: t('supervisions.show.documents.PENSIONER_CONTRACT_1', {
            reference: supervision.pensioners?.[0]?.reference
          }),
          value: 'PENSIONER_CONTRACT_1'
        },
        {
          label: t('supervisions.show.documents.PENSIONER_CONTRACT_2', {
            reference: supervision.pensioners?.[1]?.reference
          }),
          value: 'PENSIONER_CONTRACT_2'
        }
      ];
    }
    return [];
  };

  const PensionersRatingsSheetConditions = () => {
    if (supervision?.pensioners?.length && supervision?.status === 'FINISHED') {
      return [
        {
          label: t('supervisions.show.documents.PENSIONER_RATING_SHEET'),
          value: 'PENSIONER_RATING_SHEET'
        }
      ];
    }
    if (
      supervision?.pensioners?.length > 1 &&
      supervision?.status === 'FINISHED'
    ) {
      return [
        {
          label: t('supervisions.show.documents.PENSIONER_RATING_SHEET_1'),
          value: 'PENSIONER_RATING_SHEET_1'
        },
        {
          label: t('supervisions.show.documents.PENSIONER_RATING_SHEET_2'),
          value: 'PENSIONER_RATING_SHEET_2'
        }
      ];
    }
    return [];
  };

  const selectOptions = [
    ...contractsConditions(),
    {
      label: t('supervisions.show.documents.OWNER_CONTRACT'),
      value: 'OWNER_CONTRACT__'
    },
    ...PensionersRatingsSheetConditions(),
    ...(supervision?.status === 'FINISHED'
      ? [
          {
            label: t('supervisions.show.documents.OWNER_RATING_SHEET'),
            value: 'OWNER_RATING_SHEET__'
          }
        ]
      : []),
    {
      label: t('supervisions.show.documents.OTHER_DOCUMENT'),
      value: 'OTHER_DOCUMENT__'
    }
  ];

  return { selectOptions };
};
