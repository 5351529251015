import { useState, useCallback, useEffect } from 'react';
import { Form, Space, Input, InputNumber, Button, Select, Tag } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { deleteWordingField } from '../utils/deleteWordingField';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook for handling credit note fields in a form.
 *
 * @param {Object} options - Options for the hook.
 * @param {Array} options.wordings - List of wordings.
 * @param {Function} options.t - Translation function.
 * @param {Function} options.setWordingTotalHT - Setter for total HT of wordings.
 * @param {Function} options.setWordingTotalTVA - Setter for total TVA of wordings.
 * @param {Function} options.setWordings - Setter for wordings.
 * @param {Object} options.invoice - Invoice object.
 * @param {Function} options.setWordingTitle - Setter for wording title.
 * @param {Object} options.wordingTitle - Wording title object.
 * @param {Object} options.wordingsIds - Wording IDs object.
 * @param {Function} options.setWordingsIds - Setter for wording IDs.
 * @param {Function} options.setUpdatedWordingType - Setter for updated wording type.
 * @param {Function} options.setWordingTotalTTC - Setter for total TTC of wordings.
 * @returns {Object} - Object containing credit note fields.
 */
export const useCreditNoteFields = ({
  wordings,
  t,
  setWordingTotalHT,
  setWordingTotalTVA,
  setWordings,
  invoice,
  setWordingTitle,
  wordingTitle,
  wordingsIds,
  setWordingsIds,
  setUpdatedWordingType,
  setWordingTotalTTC
}) => {
  const [enums, setEnums] = useState([]);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const addWordingField = () => {
    const newWording = { id: Date.now().toString(), value: '' };
    setWordings([...wordings, newWording]);
  };

  const creditNoteFields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} disabled>
          {(enums?.type || []).map((type) => (
            <Option key={type} value={type}>
              <Tag>{t(`customerinvoices.tags.${type}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      noLabel: true,
      name: ['wordings'],
      input: (
        <>
          {wordings.map((wording) => (
            <Space key={wording.id}>
              <Form.Item
                name={['wordings', wording.id]}
                noStyle
                rules={[{ required: true }]}
              >
                {t('interninvoices.form.wordings.title')}
                <Form.Item name={['wordings', wording.id, 'title']} noStyle>
                  <Select
                    loading={isFieldsLoading}
                    onChange={(el, option) => {
                      setWordingTitle((prevTotalHT) => ({
                        ...prevTotalHT,
                        [wording.id]: el
                      }));
                      setWordingsIds((prevWordingId) => ({
                        ...prevWordingId,
                        [wording.id]: option.wording._id
                      }));
                    }}
                  >
                    {(
                      invoice?.wordings
                        .filter(
                          (el) =>
                            !Object.values(wordingTitle).includes(el.title)
                        )
                        .filter((el) => !el.credit_note) || []
                    ).map((element) => (
                      <Option
                        key={element.title}
                        value={element.title}
                        wording={element}
                      >
                        {element.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {t('interninvoices.form.wordings.total_ht')}
                <Form.Item name={['wordings', wording.id, 'total_ht']} noStyle>
                  <InputNumber
                    onChange={(e) => {
                      setUpdatedWordingType('HT');
                      setWordingTotalHT((prevTotalHT) => ({
                        ...prevTotalHT,
                        [wording.id]: e
                      }));
                    }}
                  />
                </Form.Item>

                {t('interninvoices.form.wordings.tva')}
                <Form.Item name={['wordings', wording.id, 'tva']} noStyle>
                  <InputNumber
                    onChange={(e) =>
                      setWordingTotalTVA((prevTotalTVA) => ({
                        ...prevTotalTVA,
                        [wording.id]: e
                      }))
                    }
                  />
                </Form.Item>

                {t('interninvoices.form.wordings.total_ttc')}
                <Form.Item name={['wordings', wording.id, 'total_ttc']} noStyle>
                  <InputNumber
                    onChange={(e) => {
                      setUpdatedWordingType('TTC');
                      setWordingTotalTTC((prevTTC) => ({
                        ...prevTTC,
                        [wording.id]: e
                      }));
                    }}
                  />
                </Form.Item>
              </Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  deleteWordingField({
                    id: wording.id,
                    setWordingTitle,
                    setWordings,
                    wordingTitle,
                    wordings,
                    wordingsIds
                  })
                }
                icon={<MinusOutlined />}
              >
                {t('buttons.delete')}
              </Button>
            </Space>
          ))}
          <Button
            type="dashed"
            onClick={addWordingField}
            icon={<PlusOutlined />}
          >
            {t('interninvoices.form.wordings_add_button')}
          </Button>
        </>
      )
    },
    {
      name: ['means_of_reimbursement']
    },
    {
      name: ['comments'],
      input: <TextArea />
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { creditNoteFields };
};
