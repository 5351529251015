import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';

/**
 * `ListTemplateMails` is a component for listing and managing mailing templates.
 *
 * @component
 * @returns {JSX.Element} The JSX element for listing and managing mailing templates.
 */

export const ListTemplateMails = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('templates_mails.template_name'),
      key: 'template_name',
      dataIndex: 'template_name',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="templates-mails"
      tradKey="templates_mails"
      columns={columns}
      isArchived
    />
  );
};
