import { Modal } from 'antd';
import { deleteFile } from './deleteFile';
import { onPreview } from '../../../../utils/filesManagement/onPreview';
import { fileToBase64 } from '../../../../utils/filesManagement/fileToBase64';
import { fileExtensionManage } from '../../../../utils/filesManagement/fileExtensionManage';
import { compressFile } from '../../../../utils/filesManagement/compressFile';

/**
 * Generates properties for the Dragger component from the 'antd' library. It configures
 * the behavior of file uploads, including file preview, removal, and the upload process
 * itself (which includes a file type check and a file conversion to base64).
 *
 * @function
 * @param {function} t - The translation function from 'react-i18next'.
 * @param {function} dispatchAPI - The function to dispatch API calls.
 * @param {function} message - The function to display messages.
 * @param {object} fileList - An object representing the list of files.
 * @param {function} setFileList - The function to update the file list state.
 * @param {string} name - The name attribute of the input.
 * @returns {object} The properties for the Dragger component, configuring file upload behavior.
 *
 * @example
 * const draggerProperties = draggerProps(t, dispatchAPI, message, fileList, setFileList, 'exampleName');
 */
export const draggerProps = (
  t,
  dispatchAPI,
  message,
  fileList,
  setFileList,
  name
) => ({
  listType: 'picture-card',
  onRemove: (file) => {
    Modal.confirm({
      title: t('files.modal.confirm_delete_title'),
      content: t('files.modal.confirm_delete_content'),
      okText: t('files.modal.ok'),
      cancelText: t('files.modal.cancel'),
      okType: 'warning',
      onOk: () => {
        const currentFileList = fileList[name] || [];
        const newFileList = currentFileList.filter((f) => f !== file);
        setFileList({ ...fileList, [name]: newFileList });
        if (file._id) {
          deleteFile(file._id, dispatchAPI, message);
        }
      }
    });
  },
  onPreview: (file) => onPreview(file),
  beforeUpload: async (file) => {
    const newFile = await compressFile(file);
    const fileExtension = file.name.split('.').pop();
    if (!fileExtensionManage(fileExtension)) {
      message(t('errors.message.invalid_file_type'));
      return false;
    }

    try {
      const base64 = await fileToBase64(newFile);
      setFileList((prevList) => {
        const newFileList = [
          ...(prevList[name] || []),
          { file: newFile, url: base64, animalIndex: name }
        ];
        return { ...prevList, [name]: newFileList };
      });
    } catch (error) {
      message('Error converting file to base64');
    }

    return false;
  },
  fileList: fileList[name] || []
});
