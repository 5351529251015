import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * Returns a radio group component for reviews.
 * @function
 *
 * @returns {JSX.Element} The radio group component.
 */
export const getRadioGroup = () => {
  const { t } = useTranslation();
  const reviewEnums = [
    t('owners.users_shows.review_drawer.form.answers.excellent'),
    t('owners.users_shows.review_drawer.form.answers.good'),
    t('owners.users_shows.review_drawer.form.answers.fair'),
    t('owners.users_shows.review_drawer.form.answers.bad')
  ];

  return (
    <Radio.Group>
      {reviewEnums.map((value) => (
        <Radio key={value} value={value}>
          {value}
        </Radio>
      ))}
    </Radio.Group>
  );
};
