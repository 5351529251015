/**
 * Deletes a wording field by ID, updating relevant state variables.
 *
 * @param {Object} options - Options for the function.
 * @param {string} options.id - ID of the wording field to delete.
 * @param {Function} options.setWordingTitle - Setter for wording title.
 * @param {Function} options.setWordings - Setter for wordings.
 * @param {Object} options.wordingTitle - Wording title object.
 * @param {Array} options.wordings - List of wordings.
 * @param {Object} options.wordingsIds - Wording IDs object.
 * @returns {void}
 */
export const deleteWordingField = ({
  id,
  setWordingTitle,
  setWordings,
  wordingTitle,
  wordings,
  wordingsIds
}) => {
  const updatedWordings = wordings.filter((element) => element.id !== id);
  setWordings(updatedWordings);
  const updatedTitle = { ...wordingTitle };
  delete updatedTitle[id];
  setWordingTitle(updatedTitle);
  const updatedWordingsIds = { ...wordingsIds };
  delete updatedWordingsIds[id];
  setWordingTitle(updatedWordingsIds);
};
