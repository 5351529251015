import React from 'react';
import dayjs from 'dayjs';

export const useDateManagement = ({ startDate, endDate }) => {
  const startDateRef = startDate;
  const endDateRef = endDate;

  const dateChangeAction = {
    startDate: (date) => {
      startDateRef.current = date;
    },
    endDate: (date) => {
      endDateRef.current = date;
    }
  };

  const disabledDateAction = {
    startDate: (current) => {
      const currentDate = dayjs().endOf('day');

      if (endDateRef.current) {
        return (
          current &&
          (current < currentDate ||
            current > dayjs(endDateRef.current).endOf('day').subtract(1, 'd'))
        );
      }

      return current && current < currentDate;
    },
    endDate: (current) => {
      const currentDate = dayjs().endOf('day').add(1, 'd');

      if (startDateRef.current) {
        return (
          current &&
          (current < currentDate ||
            current < dayjs(startDateRef.current).endOf('day'))
        );
      }
      return current && current < currentDate;
    }
  };

  const cellRenderer = (current, dateType) => {
    const isDisabled = disabledDateAction[dateType](current);
    return isDisabled ? (
      <div style={{ background: 'rgba(0, 0, 0, 0.04)' }}>{current.date()}</div>
    ) : (
      current.date()
    );
  };

  return {
    dateChangeAction,
    disabledDateAction,
    cellRenderer
  };
};
