export const getVeterinarian = async ({
  dispatchAPI,
  owner,
  setVeterinarian,
  setGroomer,
  message
}) => {
  try {
    const { data: veterinarianData } = await dispatchAPI('GET', {
      url: `/veterinarians/details/${owner?._id}`
    });
    if (veterinarianData) setVeterinarian(veterinarianData[0]);

    const { data: groomerData } = await dispatchAPI('GET', {
      url: `/groomers/details/${owner?._id}`
    });
    if (groomerData) setGroomer(groomerData[0]);
  } catch (error) {
    message(error);
  }
};
