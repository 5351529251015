import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { MonthlySalesChart } from './cards/MonthlySalesChart';
import { InternalExpencesChart } from './cards/InternalExpencesChart';
import { setCustomerInvoicesData } from '../../utils/setDataChart/customerInvoicesChart';

/**
 * ShowFinances component for displaying financial charts and data.
 * @component
 * @param {Object} props - The properties of the ShowFinances component.
 * @param {Object} props.customerInvoices - Object containing customer invoices data.
 * @param {Object} props.internInvoices - Object containing internal invoices data.
 * @param {Function} props.setFinanceDate - Function to set the finance date.
 * @returns {JSX.Element} - JSX element representing the ShowFinances component.
 */
export const ShowFinances = ({
  customerInvoices,
  internInvoices,
  setFinanceDate
}) => {
  const [calculatedCustomerInvoices, setCalculatedCustomerInvoices] = useState(
    {}
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (customerInvoices)
      setCustomerInvoicesData({
        setCalculatedCustomerInvoices,
        customerInvoices
      });
  }, [customerInvoices]);

  const handleChange = (date) => {
    if (date) {
      setFinanceDate(date);
    } else {
      setFinanceDate(dayjs().format('YYYY-MM-DD'));
    }
  };

  return (
    <>
      <DatePicker
        picker="year"
        onChange={handleChange}
        style={{ marginBottom: '20px' }}
      />
      <MonthlySalesChart
        t={t}
        customerInvoices={
          calculatedCustomerInvoices && calculatedCustomerInvoices
        }
      />
      <InternalExpencesChart t={t} internInvoices={internInvoices} />
    </>
  );
};

ShowFinances.propTypes = {
  customerInvoices: PropTypes.shape({}).isRequired,
  internInvoices: PropTypes.shape({}).isRequired,
  setFinanceDate: PropTypes.func.isRequired
};
