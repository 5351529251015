export const ownerListContent = (owner, t, supervision) => {
  const {
    civility,
    last_name,
    first_name,
    phone_number: { number: phone_number } = {},
    cell_phone_number: { number: cell_phone_number } = {},
    email,
    first_profession
  } = owner || {};

  const arayStatus = ['REQUEST', 'WAITING', 'WAITING_BOOKING', 'FINISHED'];

  return [
    {
      label: t('owners.form.civility'),
      content: civility ? t(`users.tags.${civility}`) : '-',
      span: 3
    },
    {
      label: t('owners.form.first_name'),
      content: first_name || '-',
      span: 3
    },
    {
      label: t('owners.form.last_name'),
      content: last_name || '-',
      span: 3
    },
    ...(!arayStatus.includes(supervision?.status)
      ? [
          {
            label: t('owners.form.phone_number.number'),
            content: phone_number || '-',
            span: 3
          },
          {
            label: t('owners.form.cell_phone_number.number'),
            content: cell_phone_number || '-',
            span: 3
          },
          {
            label: t('owners.form.email'),
            content: email || '-',
            span: 3
          },
          {
            label: t('owners.form.first_profession'),
            content: first_profession || '-',
            span: 3
          }
        ]
      : [])
  ];
};
