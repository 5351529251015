import { Input, Form, Select, Tag } from 'antd';
import { useCountryArray } from '../../../../utils/countryArray';

const { Option } = Select;

export const useVeterinariansFields = () => {
  const countries = useCountryArray();
  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const veterinarianFields = [
    {
      name: ['name']
    },
    {
      name: ['address', 'number'],
      input: <Input type="number" />
    },
    {
      name: ['address', 'street']
    },
    {
      name: ['address', 'city']
    },
    {
      name: ['address', 'postal_code'],
      input: <Input type="number" />
    },
    {
      name: ['address', 'country'],
      input: (
        <Select showSearch filterOption={filterOption}>
          {(countries || []).map((country) => (
            <Option key={country} value={country}>
              <Tag>{country}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return { veterinarianFields };
};
