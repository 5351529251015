import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns/mailSenderColumns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { CreateModal } from './CreateModal';

/**
 * Component for managing and displaying a list of mail senders.
 * Provides functionality for filtering, creating, and performing actions on mail senders.
 *
 * @component
 * @returns {JSX.Element} React element representing the component.
 */

export const MailSenderList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [sendingType, setSendingType] = useState('POST');
  const [refreshData, setRefreshData] = useState(false);
  const columns = useColumns({
    enums,
    sendingType,
    setRefreshData,
    refreshData
  });

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/mail-sender/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <>
      <ListResource
        resourceName="mail-sender"
        columns={columns}
        resourceModelName="MailSender"
        customActionColumn
        forceRefresh={refreshData}
        populate="created_by"
        extraQuery={`type=${sendingType}`}
        withArchiveButton
        withCreateButton={false}
        withCustomCreateButton
        onDoubleClickAction={{
          action: (record) =>
            navigate(
              `show/${record.document_type}/${record?._id}/${record?.status}/${sendingType}`
            )
        }}
        customCreateButtonAction={() => {
          setIsCreateModalOpen(true);
        }}
        extraFilters={
          <Flex gap="small" align="center">
            {`${t('mail-sender.post-sending')}`}
            <Switch
              onChange={(e) =>
                e ? setSendingType('EMAIL') : setSendingType('POST')
              }
            />
            {`${t('mail-sender.mail-sending')}`}
          </Flex>
        }
      />
      <CreateModal
        isCreateModalOpen={isCreateModalOpen}
        setIsCreateModalOpen={setIsCreateModalOpen}
        enums={enums}
        sendingType={sendingType}
      />
    </>
  );
};
