import PropTypes from 'prop-types';
import { useState } from 'react';
import { Datatable, ContentCustom } from '../../components';
import { useColumns } from './columns';

/**
 * `TemplateDocsDatatable` is a component that renders a datatable for displaying
 * template documents. It leverages the `Datatable` component and sets up specific
 * columns and queries based on the `useCaseParams` prop.
 *
 * @component
 * @param {Object} props - The properties that define the component.
 * @param {string} props.useCaseParams - A string parameter used to construct a query to fetch data for the datatable.
 *
 * @returns {React.Element} A `Datatable` component populated with columns and data based on the `useCaseParams`.
 *
 * @example
 *
 * return (
 *   <TemplateDocsDatatable useCaseParams="someUseCaseParam" />
 * );
 */
export const TemplateDocsDatatable = ({ useCaseParams }) => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = useColumns(forceRefresh, setForceRefresh);

  return (
    <ContentCustom>
      <Datatable
        columns={columns}
        resourceName="template-documents"
        showAction={{
          pathname: (record) => `/template-docs/show/${record._id}`
        }}
        editAction={{
          pathname: (record) => `/template-docs/edit/${record._id}`
        }}
        forceRefresh={forceRefresh}
        extraQuery={`use_case=${useCaseParams}`}
        populate="author"
        scroll={{ x: 900 }}
        onDoubleClickAction={null}
      />
    </ContentCustom>
  );
};

TemplateDocsDatatable.propTypes = {
  useCaseParams: PropTypes.string.isRequired
};
