export const changeForm = async ({
  nextStep,
  form,
  setCurrent,
  Message,
  t,
  current,
  setInformationData,
  setSupervisionAddressData,
  setSummaryData
}) => {
  const validateFields = async (setState) => {
    try {
      const values = await form.validateFields();
      setState(values);
      setCurrent(nextStep);
      return null;
    } catch (errorInfo) {
      return Message.error(t('supervisions.messages.required_fields'));
    }
  };

  switch (current) {
    case 0:
      await validateFields(setInformationData);
      break;
    case 1:
      await validateFields(setSupervisionAddressData);
      break;
    case 2:
      await validateFields(setSummaryData);
      break;
    default:
      return null;
  }

  return null;
};
