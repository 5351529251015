export const getInvoices = async ({
  dispatchAPI,
  user,
  setInvoices,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `customerinvoices?customer=${user._id}`
    });
    const updatedInvoices = [];

    if (data.length) {
      data.map((invoice) =>
        updatedInvoices.push({
          ...invoice,
          document_type:
            invoice.type === 'SUBSCRIPTION'
              ? 'SUBSCRIPTION-INVOICE'
              : 'OWNER-INVOICE'
        })
      );
    }
    setInvoices(updatedInvoices);
  } catch (e) {
    message(e);
  }
};
