import {
  Card,
  Upload,
  Button,
  Row,
  Col,
  Spin,
  List,
  Skeleton,
  notification,
  Space
} from 'antd';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InboxOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';
import { useDocumentsManagement } from '../../utils/documentsCard/documentsManagement';
import { translateDocumentsType } from '../../utils/documentsCard/translateDocumentsType';
import { useHandleSubmitDocuments } from '../../utils/documentsCard/handleSubmitDocuments';
import { draggerProps } from '../../utils/documentsCard/draggerProps';
import { useViewDocument } from '../../../../../utils/filesManagement/viewDocument';
import { LoadingModal } from '../../../../../components/LoadingModal/LoadingModal';

const { Dragger } = Upload;

/**
 * Component for displaying document cards.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Object} props.supervision - The supervision object.
 * @param {Function} props.refreshData - The function to refresh data.
 * @param {Function} props.setRefreshData - The function to set refresh data.
 * @param {Function} props.setIsLoading - The function to set loading state.
 * @param {boolean} props.isLoading - The loading state.
 * @returns {JSX.Element} The DocumentCard component.
 */
export const DocumentCard = ({
  t,
  supervision,
  refreshData,
  setRefreshData,
  setIsLoading,
  isLoading
}) => {
  const [filesList, setFilesList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { viewDocument } = useViewDocument(setDownloading);
  const { downloadDocument } = useDownloadDocument();
  const { selectOptions } = useDocumentsManagement(supervision, t);
  const { handleSubmitDocuments } = useHandleSubmitDocuments({
    setFilesList,
    setIsLoading,
    filesList,
    notification,
    supervision,
    setRefreshData,
    refreshData,
    t
  });

  useEffect(() => {
    if (refresh) setRefresh(false);
  }, [refresh]);

  return (
    <>
      <Card
        title={t('supervisions.show.documents.title')}
        className="file-manager"
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Spin spinning={isLoading}>
              <List
                className="demo-loadmore-list fileList"
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={supervision?.documents}
                renderItem={(item) => (
                  <List.Item
                    className="fileList-item"
                    actions={[
                      <Button
                        type="link"
                        onClick={async () => {
                          await viewDocument(item?._id);
                        }}
                      >
                        <EyeOutlined />
                      </Button>,
                      <Button
                        type="link"
                        onClick={() =>
                          downloadDocument({
                            _id: item._id,
                            metadata: item.metadata,
                            contentType: item.contentType
                          })
                        }
                      >
                        <DownloadOutlined />
                      </Button>
                    ]}
                  >
                    <Skeleton
                      avatar
                      title={false}
                      loading={item.loading}
                      active
                    >
                      <List.Item.Meta
                        avatar={<CheckCircleOutlined />}
                        description={
                          <Space>
                            <p>{item._id.metadata.originalName}</p>
                            <p>
                              {
                                translateDocumentsType(t, supervision)[
                                  item.type
                                ]
                              }
                            </p>
                          </Space>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Spin>
          </Col>
          <Col span={12}>
            <Dragger
              {...draggerProps({ setFilesList, filesList, selectOptions })}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </Dragger>
            <Button onClick={() => handleSubmitDocuments()}>
              {t('buttons.save')}
            </Button>
          </Col>
        </Row>
      </Card>
      <LoadingModal
        openModal={downloading}
        loadingText="files.modal.preparing_document"
      />
    </>
  );
};

DocumentCard.propTypes = {
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      owner: PropTypes.string
    }),
    documents: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.string,
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        reference: PropTypes.string
      })
    ),
    _id: PropTypes.string
  }).isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};
