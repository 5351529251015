const extraInfo = (data, t) => {
  const {
    means_of_transport,
    disponibility,
    city_to_discover,
    usage_of_gps,
    urgency,
    is_children,
    children_place,
    is_grand_children,
    grand_children_place,
    how_did_you_know_us
  } = data;

  return [
    {
      label: 'pensioners.form.means_of_transport',
      span: 1,
      content:
        means_of_transport
          ?.map((type) => t(`profile.personal_info.means_of_transport.${type}`))
          .join(', ') || '-'
    },
    {
      label: 'pensioners.form.disponibility',
      span: 2,
      content: (disponibility && disponibility) || '-'
    },
    {
      label: 'pensioners.form.city_to_discover',
      span: 3,
      content: (city_to_discover && city_to_discover) || '-'
    },
    {
      label: 'pensioners.form.usage_of_gps',
      span: 4,
      content: usage_of_gps ? t(`pensioners.form.yes`) : t(`pensioners.form.no`)
    },
    {
      label: 'pensioners.form.urgency.name',
      span: 1,
      content: urgency?.name ? `${urgency?.name}` : '-'
    },
    {
      label: 'pensioners.form.urgency.phone_number',
      span: 2,
      content: (urgency?.phone_number && urgency?.phone_number?.number) || '-'
    },
    {
      label: 'pensioners.form.is_children',
      span: 4,
      content: is_children ? t(`pensioners.form.yes`) : t(`pensioners.form.no`)
    },
    {
      label: 'pensioners.form.children_place',
      span: 1,
      content: children_place && children_place
    },
    {
      label: 'pensioners.form.is_grand_children',
      span: 2,
      content: is_grand_children
        ? t(`pensioners.form.yes`)
        : t(`pensioners.form.no`)
    },
    {
      hidden: true,
      label: 'pensioners.form.grand_children_place',
      span: 3,
      content: grand_children_place && grand_children_place
    },
    {
      label: 'pensioners.form.how_did_you_know_us',
      span: 4,
      content: (how_did_you_know_us && how_did_you_know_us) || '-'
    }
  ];
};

export { extraInfo };
