import { Select, Input, Form, Space, DatePicker, Upload, Tag } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useCountryArray } from '../../../../utils/countryArray';
import { compressFile } from '../../../../utils/filesManagement/compressFile';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Custom hook for generating main fields used in profile creation/update form.
 * @param {Object} params - Parameters for the hook.
 * @param {function} params.setDocumentsFileList - Function to set the documents file list.
 * @param {Object} params.documentsFileList - Current documents file list.
 * @returns {Object} - Object containing mainFields array.
 */
export const useFields = ({
  setDocumentsFileList,
  documentsFileList,
  setRemovedFiles
}) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();

  // Custom hook for fetching an array of countries
  const countries = useCountryArray();

  /**
   * Function to filter options based on input.
   * @param {string} input - The input string to filter options.
   * @param {Object} option - The option object to filter.
   * @returns {boolean} - Whether the option should be displayed.
   */
  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  /**
   * Function to set documents dragger properties.
   * @param {string} name - The name of the document.
   * @returns {Object} - Object containing properties for the documents Dragger component.
   */
  const documentsDraggerProps = (name) => ({
    onRemove: () => {
      const updatedFileList = { ...documentsFileList };
      setRemovedFiles((prevRemovedFiles) => [
        ...prevRemovedFiles,
        updatedFileList[name][0].id
      ]);
      delete updatedFileList[name];
      setDocumentsFileList(updatedFileList);
    },
    beforeUpload: async (file) => {
      if (name === 'identification_photo') {
        const newFile = await compressFile(file);
        setDocumentsFileList((prevFileList) => ({
          ...prevFileList,
          [name]: [newFile]
        }));

        return false;
      }
      setDocumentsFileList((prevFileList) => ({
        ...prevFileList,
        [name]: [file]
      }));

      return false;
    },
    fileList: documentsFileList[name]
  });

  /**
   * Main fields array for the profile form.
   */
  const mainFields = [
    {
      name: ['civility'],
      rules: [{ required: true }],
      input: (
        <Select
          options={[
            { label: t('users.tags.MR'), value: 'MR' },
            { label: t('users.tags.MRS'), value: 'MRS' },
            { label: t('users.tags.MR_&_MRS'), value: 'MR_&_MRS' },
            { label: t('users.tags.MR_&_MR'), value: 'MR_&_MR' },
            { label: t('users.tags.MRS_&_MRS'), value: 'MRS_&_MRS' }
          ]}
        />
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },
    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    ...(user?.role === 'users:PENSIONER'
      ? [
          {
            name: ['birth_date'],
            rules: [{ required: true }],
            input: <DatePicker format="DD/MM/YYYY" />
          }
        ]
      : []),
    {
      name: ['cell_phone_number'],
      label: 'cell_phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['cell_phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['cell_phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    ...(user?.role === 'users:OWNER'
      ? [
          {
            name: ['phone_number'],
            label: 'phone_number.number',
            input: (
              <Input.Group compact>
                <Form.Item
                  noStyle
                  name={['phone_number', 'country_code']}
                  initialValue="+33"
                >
                  <Select style={{ width: 70 }}>
                    <Option value="+33">+33</Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle name={['phone_number', 'number']}>
                  <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
                </Form.Item>
              </Input.Group>
            )
          },
          {
            name: ['email'],
            rules: [{ required: true }]
          }
        ]
      : []),
    ...(user?.role === 'users:OWNER'
      ? [
          {
            name: ['first_profession']
          },
          {
            name: ['second_profession']
          },
          {
            name: ['address', 'number']
          },
          {
            name: ['address', 'street']
          },
          {
            label: ['city'],
            name: ['address', 'city']
          },
          {
            label: ['postal_code'],
            name: ['address', 'postal_code'],
            input: <Input type="number" />
          },
          {
            name: ['address', 'country'],
            input: (
              <Select showSearch filterOption={filterOption}>
                {(countries || []).map((country) => (
                  <Option key={country} value={country}>
                    <Tag>{country}</Tag>
                  </Option>
                ))}
              </Select>
            )
          }
        ]
      : [
          {
            name: ['profession']
          },
          {
            name: ['principal_language']
          },
          {
            label: 'criminal_record_extract',
            input: (
              <Dragger {...documentsDraggerProps('criminal_record_extract')}>
                <Space>
                  <UploadOutlined />
                  {t('users.form.upload_file')}
                </Space>
              </Dragger>
            )
          },
          {
            label: 'identification_photocopy',
            input: (
              <Dragger {...documentsDraggerProps('identification_photocopy')}>
                <Space>
                  <UploadOutlined />
                  {t('users.form.upload_file')}
                </Space>
              </Dragger>
            )
          },
          {
            label: 'identification_photo',
            input: (
              <Dragger {...documentsDraggerProps('identification_photo')}>
                <Space>
                  <UploadOutlined />
                  {t('users.form.upload_file')}
                </Space>
              </Dragger>
            )
          }
        ])
  ];

  return {
    mainFields
  };
};
