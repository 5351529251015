import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Form, Dropdown, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  WarningOutlined,
  ContainerOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import DetailsCards from './detailsCards/detailsCards';
import { ReviewDrawer } from '../../../components/Review/ReviewDrawer';
import { deleteOwner } from './utils/deleteOwner';
import { MailingModal } from '../../../components/Mailing/MailingModal';
import { ExplanatoryDocumentMenu } from './utils/ExplanatoryDocumentMenu';
import { useSocketContext } from '../../../contexts/SocketContext';
import { editingLocked } from '../../../utils/editingLocked';
import { useOwnerContext } from '../../../contexts/OwnerContext/OwnerContext';

/**
 * The `ShowOwner` component displays detailed information about a specific owner.
 * The component fetches the owner data based on the ID retrieved from the URL parameters
 * and processes dwelling and animal photos before displaying the details in nested components.
 * It also allows users to edit or delete the owner information with respective buttons and actions.
 *
 * It uses several hooks and contexts:
 * - `useParams` to get the ID parameter from the URL.
 * - `useNavigate` to navigate to different paths programmatically.
 * - `useTranslation` to facilitate the translation of texts.
 * - `useAuthContext` to access the `dispatchAPI` method for making API requests.
 * - `useErrorMessage` to handle error messages.
 * - `useCallback` and `useEffect` to manage fetching the owner data and other side effects.
 *
 * @component
 *
 * @example
 * <ShowOwner />
 *
 * @returns {React.Element} A React component that displays the detailed information about an owner and allows editing or deleting the owner information.
 */
export const ShowOwner = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { profileLock } = useSocketContext();

  const [reviewDrawerOpen, setReviewDrawerOpen] = useState(false);
  const [questionnaireType, setQuestionnaireType] = useState('users:OWNER');
  const [supervisionRating, setSupervisionRating] = useState({});
  const [showReviewDrawer, setShowReviewDrawer] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [templateType, setTemplateType] = useState('SEND_MAIL');
  const [documentType, setDocumentType] = useState(null);
  const [reviewData, setReviewData] = useState({});
  const [editReviewDrawer, setEditReviewDrawer] = useState(false);

  const { owner, getOwner, supervisions, getSupervisions, isLoading } =
    useOwnerContext();

  useEffect(() => {
    (async () => {
      await Promise.all([getOwner(id), getSupervisions(id)]);
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        title={
          Object.keys(owner).length
            ? `${t(owner.civility && `owners.tags.${owner.civility}`)} ${
                owner.last_name
              } ${owner.first_name} n°${owner.reference}`
            : null
        }
        extra={
          <>
            <Button
              type="primary"
              onClick={() => {
                setIsEmailModalOpen(true);
                setTemplateType('SEND_MAIL');
                setDocumentType(null);
              }}
            >
              {t('buttons.send_mail')}
            </Button>
            <Dropdown
              overlay={
                <ExplanatoryDocumentMenu
                  owner={owner}
                  setIsEmailModalOpen={setIsEmailModalOpen}
                  setTemplateType={setTemplateType}
                  setDocumentType={setDocumentType}
                />
              }
            >
              <Button type="primary">
                {t('owners.show.buttons.explanatory_document')}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Link
              to={{
                pathname: `${routes.SUPERVISIONS}/create`,
                search: `?id=${id}`
              }}
            >
              <Button type="primary">
                <PlusOutlined />
                {`${t('owners.buttons.create_supervision')} `}
              </Button>
            </Link>
            <Link
              to={{
                pathname: !editingLocked(profileLock, id)
                  ? `${routes.OWNERS}/edit/${id}`
                  : ''
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  editingLocked(profileLock, id) &&
                  notification.warning({
                    message: t('pensioners.profile_lock'),
                    duration: false
                  })
                }
              >
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() =>
                deleteOwner({ dispatchAPI, navigate, id, message })
              }
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.archive')} `}
                <ContainerOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <DetailsCards
          owner={owner}
          isLoading={isLoading}
          setReviewDrawerOpen={setReviewDrawerOpen}
          setQuestionnaireType={setQuestionnaireType}
          form={form}
          questionnaireType={questionnaireType}
          setSupervisionRating={setSupervisionRating}
          setShowReviewDrawer={setShowReviewDrawer}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          setReviewData={setReviewData}
          reviewData={reviewData}
          setEditReviewDrawer={setEditReviewDrawer}
        />
      </ContentCustom>

      <ReviewDrawer
        setOpen={setReviewDrawerOpen}
        open={reviewDrawerOpen}
        t={t}
        questionnaireType={questionnaireType}
        customInstanceForm={form}
        supervision={supervisionRating}
        showReviewDrawer={showReviewDrawer}
        supervisions={supervisions}
        setSupervision={setSupervisionRating}
        owner={owner}
        setForceRefresh={setRefreshData}
        forceRefresh={refreshData}
        reviewData={reviewData}
        editReviewDrawer={editReviewDrawer}
      />

      {isEmailModalOpen && (
        <MailingModal
          isModalOpen={isEmailModalOpen}
          setIsModalOpen={setIsEmailModalOpen}
          recipients={[{ ...owner, customer_type: 'OWNER' }]}
          recipient={owner.user._id}
          customFormInstance={form}
          templateType={templateType}
          documentType={documentType}
          collection="owners"
        />
      )}
    </>
  );
};
