import { Route, Routes } from 'react-router-dom';
import { ShowProfile } from './showProfile/ShowProfile';
import UpdatePwd from './UpdatePwd';
import { CreateUpdateProfile } from './CreateUpdateProfile';

export const ProfileRouter = () => (
  <Routes>
    <Route path="/edit/:id" element={<CreateUpdateProfile purpose="edit" />} />
    <Route path="/change-pwd" element={<UpdatePwd />} />
    <Route path="/:id" element={<ShowProfile />} />
  </Routes>
);
