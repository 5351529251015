import React from 'react';
import { Input, Form } from 'antd';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * `GroomerFields` is a component that renders a group of form fields for grooming information.
 * It utilizes the `useFields` hook to get the fields' configuration and
 * `useGenerateFormItem` to generate form items dynamically based on the field configurations.
 *
 * @component
 * @returns {React.Element} - A group of form fields encapsulated within an Input.Group and Form.Item component.
 */
const GroomerFields = () => {
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();

  return (
    <Input.Group name={['groomer']}>
      <Form.Item noStyle name={['groomer']}>
        {fields.map((field) => generateFields('groomers', field))}
      </Form.Item>
    </Input.Group>
  );
};

export default GroomerFields;
