import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Button, Typography } from 'antd';
import { BellOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { subscriptionInfo } from '../listContent/subscriptionInfo';
import { DescriptionList } from '../../../../../components';

const { Text } = Typography;

const SubscriptionInfoCards = ({
  isLoading,
  subscription,
  setSubscriptionDrawerOpen,
  subscriptionInvoice,
  setSubscriptonInfoModalOpen,
  pensioner,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType
}) => {
  const { t } = useTranslation();

  return (
    <Card
      title={t('pensioners.show.subscription_info')}
      className="subscription-info-card"
      extra={
        <Row>
          {Object.keys(subscription).length ? (
            <Col>
              <Button
                type="link"
                style={{ color: 'var(--errorColor)' }}
                onClick={() => {
                  setIsEmailModalOpen(true);
                  setTemplateType('SEND_SUBSCRIPTION_REMINDER');
                  setDocumentType('SUBSCRIPTION-INVOICE');
                }}
              >
                <BellOutlined />
              </Button>
            </Col>
          ) : null}
          <Col>
            <Button
              type="link"
              onClick={() =>
                ['PAID', 'FREE'].includes(subscriptionInvoice.status) ||
                Object.keys(subscription).length === 0
                  ? setSubscriptionDrawerOpen(true)
                  : setSubscriptonInfoModalOpen(true)
              }
            >
              <EditOutlined />
            </Button>
          </Col>
        </Row>
      }
    >
      <Row gutter={[0, 24]}>
        <Col xs={24} xxl={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            {['INACTIVE', 'OUTDATED'].includes(pensioner.status) &&
            Object.keys(subscription).length ? (
              <Text>{t('subscriptions.messages.waiting_payment')}</Text>
            ) : null}
            {['ACTIVE', 'FREE'].includes(pensioner.status) &&
            Object.keys(subscription).length &&
            ['PAID', 'FREE'].includes(subscriptionInvoice.status) ? (
              <DescriptionList
                data={subscriptionInfo(
                  subscription || {},
                  subscriptionInvoice?.total_ttc || t('subscriptions.form.free')
                )}
                translate
              />
            ) : null}
            {Object.keys(subscription).length === 0 ? (
              <Text>{t('subscriptions.messages.no_subscription')}</Text>
            ) : null}
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

export default SubscriptionInfoCards;

SubscriptionInfoCards.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  subscription: PropTypes.shape({}).isRequired,
  setSubscriptionDrawerOpen: PropTypes.func.isRequired,
  subscriptionInvoice: PropTypes.shape({
    total_ttc: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  setSubscriptonInfoModalOpen: PropTypes.func.isRequired,
  pensioner: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  setTemplateType: PropTypes.func.isRequired,
  setDocumentType: PropTypes.func.isRequired
};
