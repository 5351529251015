import { Card, Col, Row, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { supervisionInfos, urgencyInfos } from '../listContent/supervisionInfo';

const { Title } = Typography;

/**
 * A component that displays the supervision information card.
 * @param {Object} supervision - The supervision object.
 * @param {boolean} isLoading - A flag indicating whether the supervision is currently being loaded.
 * @returns {JSX.Element} The rendered component.
 */
export const SupervisionInfoCard = ({ supervision, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card title={t('supervisions.show.supervision_infos')}>
        <Row>
          <Col span={24}>
            <DescriptionList data={supervisionInfos(supervision)} translate />
          </Col>
          <Col span={24}>
            <Title style={{ whiteSpace: 'wrap' }} level={6}>
              {t('supervisions.show.urgency.title')}
            </Title>
            <DescriptionList data={urgencyInfos(supervision)} translate />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

SupervisionInfoCard.propTypes = {
  supervision: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired
};
