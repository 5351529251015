import { Input } from 'antd';

const { TextArea } = Input;

export const useLastStepFields = ({ showReviewDrawer }) => {
  const lastStepFields = [
    {
      name: ['comment_or_suggestion'],
      label: 'comment_or_suggestion',
      input: <TextArea disabled={showReviewDrawer} />
    }
  ];

  return { lastStepFields };
};
