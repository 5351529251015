import moment from 'moment';

export const pensionersInfo = (pensioner, t) => {
  const {
    address,
    cell_phone_number,
    phone_number,
    email,
    civility,
    first_name,
    last_name,
    birth_date,
    secondary_profile
  } = pensioner || {};
  const { number, city, postal_code, street, country } = address || {};
  const {
    birth_date: secondaryProfileBirthDate,
    first_name: secondaryProfileFirstName,
    last_name: secondaryProfileLastName,
    civility: secondaryProfileCivility
  } = secondary_profile || {};

  const contactInfo = [
    {
      label: 'supervisions.form.pensioner.address.title',
      span: 24,
      content:
        (address &&
          `${number || ''} ${street || ''}, ${city || ''}, ${
            postal_code || ''
          }`) ||
        '-'
    },
    {
      label: 'supervisions.form.pensioner.address.country',
      span: 12,
      content: (address && country) || '-'
    },
    {
      label: 'supervisions.form.owner.cell_phone_number',
      span: 12,
      content: (cell_phone_number && cell_phone_number.number) || '-'
    },
    {
      label: 'supervisions.form.owner.phone_number',
      span: 12,
      content: (phone_number && phone_number.number) || '-'
    },
    {
      label: 'supervisions.form.owner.email',
      span: 12,
      content: (email && email) || '-'
    }
  ];

  const principalInfo = [
    {
      label: 'supervisions.form.pensioner.last_name',
      span: 24,
      content:
        (civility &&
          `${t(
            `supervisions.form.civility.${civility}`
          )} ${last_name} ${first_name}`) ||
        '-'
    },
    {
      label: 'supervisions.form.pensioner.birth_date',
      span: 24,
      content: (birth_date && moment(birth_date).format('DD/MM/YYYY')) || '-'
    }
  ];

  const secondaryProfileInfo = [
    {
      label: 'supervisions.form.pensioner.last_name',
      span: 24,
      content:
        (secondaryProfileCivility &&
          `${t(
            `supervisions.form.civility.${secondaryProfileCivility}`
          )} ${secondaryProfileLastName} ${secondaryProfileFirstName}`) ||
        '-'
    },
    {
      label: 'supervisions.form.pensioner.birth_date',
      span: 24,
      content:
        (secondaryProfileBirthDate &&
          moment(secondaryProfileBirthDate).format('DD/MM/YYYY')) ||
        '-'
    }
  ];

  return { contactInfo, principalInfo, secondaryProfileInfo };
};
