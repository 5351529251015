import dayjs from 'dayjs';
import { routes } from '../../utils/constants/adminRoutes';
import { NotificationsDetails } from './NotificationsDetails';

/**
 * Handles the rendering of notification menu item links based on the provided parameters.
 * @function
 *
 * @param {string} notifID - The ID of the notification.
 * @param {object} item - The notification item object.
 * @param {function} deleteNotification - The function to delete the notification.
 * @param {object} t - The translation object.
 * @param {string} createdAt - The creation date of the notification.
 * @param {boolean} consulted - Indicates whether the notification has been consulted.
 * @returns {JSX.Element} - The rendered notification link component.
 */
export const handleNotifMenuItemLink = (
  notifID,
  item,
  deleteNotification,
  t,
  createdAt,
  consulted
) => {
  const {
    type: {
      _id,
      reference,
      supervision,
      main_informations: { start_date } = {}
    } = {}
  } = item || {};

  const action = item?.action;
  const ref = item?.ref;

  const displayNotificationLink = () => {
    switch (true) {
      case ref === 'Supervision' && action === 'create':
        return (
          <NotificationsDetails
            content={t('notifications.new_supervision')}
            pathname={`${routes.SUPERVISIONS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Supervision' && action === 'owner_accept_pensioner':
        return (
          <NotificationsDetails
            content={t('notifications.accept_pensioners', { reference })}
            pathname={`${routes.SUPERVISIONS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Supervision' && action === 'tomorrow_supervision':
        return (
          <NotificationsDetails
            content={t('notifications.tomorrow_supervision', {
              reference,
              start_date: dayjs(start_date).format('DD/MM/YYYY')
            })}
            pathname={`${routes.SUPERVISIONS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Supervision' && action === 'supervision_finished':
        return (
          <NotificationsDetails
            content={t('notifications.supervision_finished', {
              reference
            })}
            pathname={`${routes.SUPERVISIONS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Rating' && action === 'create':
        return (
          <NotificationsDetails
            content={t('notifications.new_review')}
            pathname={`${routes.SUPERVISIONS}/show/${supervision}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Owner' && action === 'create':
        return (
          <NotificationsDetails
            content={t('notifications.new_owner')}
            pathname={`${routes.OWNERS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Pensioner' && action === 'create':
        return (
          <NotificationsDetails
            content={t('notifications.new_pensioner')}
            pathname={`${routes.PENSIONERS}/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Reservation' && action === 'create':
        return (
          <NotificationsDetails
            content={t('notifications.new_reservation')}
            pathname={`${routes.SUPERVISIONS}/show/${supervision}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      case ref === 'Customerinvoice' && action === 'unpaid_owners_invoices':
        return (
          <NotificationsDetails
            content={t('notifications.unpaid_owners_invoices', { reference })}
            pathname={`${routes.INVOICES}/customers/show/${_id}`}
            notifID={notifID}
            createdAt={createdAt}
            deleteNotification={deleteNotification}
            consulted={consulted}
          />
        );
      default:
        return t('notifications.form.unknown-type');
    }
  };

  return displayNotificationLink();
};
