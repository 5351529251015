import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExtraFilters } from '../utils/ExtraFilters';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useFormatter } from '../../../utils/formatter';

/**
 * Composant pour afficher une liste de factures internes.
 * @component
 * @returns {JSX.Element} Le composant de liste de factures internes.
 */

export const ListInternInvoices = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { formattedData } = useFormatter();
  const [dateFilter, setDateFilter] = useState(null);
  const [enums, setEnums] = useState({});
  const [status, setStatus] = useState('');
  const [isFieldLoading, setIsFieldLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const { columns, headers } = useColumns(setRefreshData);

  useEffect(() => {
    if (refreshData) setRefreshData(false);
  }, [refreshData]);

  const setExtraQuery = () => {
    let extraQuery = '';
    if (status) {
      extraQuery = `${extraQuery ? `${extraQuery}&` : ''}status=${status}`;
    }
    if (dateFilter) {
      extraQuery = `${extraQuery ? `${extraQuery}&` : ''}due_date>=${dayjs(
        dateFilter
      ).format('YYYY-MM-DD')}&due_date<=${dayjs(dateFilter)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')}`;
    }

    return extraQuery;
  };

  const getEnums = async () => {
    try {
      setIsFieldLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      setEnums(data);
      setIsFieldLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    setExtraQuery();
  }, [dateFilter, status]);

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return (
    <ListResource
      resourceName="interninvoices"
      columns={columns}
      headers={headers}
      resourceModelName="Interninvoice"
      forceRefresh={refreshData}
      extraQuery={setExtraQuery()}
      formatter={formattedData}
      withArchiveButton
      withoutImportButton
      extraFilters={
        <ExtraFilters
          setDateFilter={setDateFilter}
          setStatus={setStatus}
          enums={enums}
          isFieldLoading={isFieldLoading}
          invoiceType="intern"
        />
      }
      withoutBreadCrumb
      customActionColumn
    />
  );
};
