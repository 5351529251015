import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from './errorMessage';

export const useUnlockEditProfile = (id) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const unlockEditProfile = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/editing-rights/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  return { unlockEditProfile };
};
