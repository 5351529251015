/**
 * A card component for displaying a comment.
 * @component
 *
 * @param {Object} props - The component's props.
 * @param {string} props.comment - The comment to display.
 * @param {boolean} props.isLoading - Indicates if the data is currently loading.
 * @returns {JSX.Element} The CommentCard component.
 */
import { Card, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DescriptionList } from '../../../../../components';
import { commentInfo } from '../listContent/commentInfo';

export const CommentCard = ({ comment, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card title={t('supervisions.show.comment')}>
        <Row>
          <Col span={24}>
            <DescriptionList data={commentInfo(comment, t)} translate />
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};
