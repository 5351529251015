import { message as antdMessage } from 'antd';
import dayjs from 'dayjs';

export const handleSubmitSubscriptionDrawer = async ({
  values,
  pensioner,
  dispatchAPI,
  setRefreshData,
  setSubscriptionDrawerOpen,
  refreshData,
  message,
  t
}) => {
  const body = {
    ...values,
    pensioner: pensioner._id,
    end_date: dayjs(values.start_date).add(1, 'year')
  };

  try {
    await dispatchAPI('POST', {
      url: '/subscriptions',
      body
    });

    setRefreshData(!refreshData);
    setSubscriptionDrawerOpen(false);
    antdMessage.success(t('subscriptions.messages.invoice'));
  } catch (e) {
    message(e);
  }
};
