import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Flex } from 'antd';
import { useReservationInfoFields } from './reservationFields';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import { confirmReservation } from './utils/confirmReservation';

/**
 * Composant de modal pour la confirmation d'une réservation.
 * @component
 * @param {Object} props - Les propriétés du composant.
 * @param {boolean} props.isModalOpen - Indique si la modal est ouverte.
 * @param {Function} props.setIsModalOpen - Fonction pour définir l'état de la modal.
 * @param {Function} props.t - Fonction de traduction.
 * @param {Object} props.supervision - Informations sur la supervision.
 * @param {string} props.supervision._id - L'identifiant de la supervision.
 * @param {string} props.supervision.reference - La référence de la supervision.
 * @param {Object} props.supervision.main_informations - Informations principales de la supervision.
 * @param {Object} props.supervision.main_informations.owner - Propriétaire de la supervision.
 * @param {string} props.supervision.main_informations.owner.last_name - Nom du propriétaire.
 * @param {Array} props.reservations - Liste des réservations.
 * @param {string} props.pensionerId - L'identifiant du pensionnaire.
 * @param {string} props.reservationId - L'identifiant de la réservation.
 * @param {Function} props.message - Fonction pour afficher un message.
 * @param {Function} props.dispatchAPI - Fonction pour effectuer une requête API.
 * @param {boolean} props.refreshData - Indique s'il faut rafraîchir les données.
 * @param {Function} props.setRefreshData - Fonction pour définir l'état de rafraîchissement des données.
 * @param {Function} props.navigate - Fonction pour la navigation.
 * @returns {JSX.Element} Le composant de modal de confirmation de réservation.
 */
const ReservationModal = ({
  isModalOpen,
  setIsModalOpen,
  t,
  supervision,
  reservations,
  pensionerId,
  reservationId,
  message,
  dispatchAPI,
  refreshData,
  setRefreshData,
  navigate
}) => {
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredReservations, setFilteredReservations] = useState([]);

  const { reservationFields } = useReservationInfoFields({
    filteredReservations,
    setSelectedReservations,
    pensionerId
  });

  const filterReservations = () => {
    const filteredReservationsData = reservations.filter((reservation) => {
      if (reservation.supervision._id === supervision._id) {
        if (reservation.pensioner._id === pensionerId) {
          return reservation._id === reservationId;
        }
        return true;
      }
      return false;
    });

    setFilteredReservations(filteredReservationsData);
  };

  useEffect(() => {
    filterReservations();
  }, [pensionerId, reservationId]);

  useEffect(() => {
    if (Object.keys(supervision).length) {
      form.setFieldsValue({
        supervision: supervision.reference,
        owner: supervision.main_informations.owner.last_name,
        pensioners: [pensionerId]
      });
    }
  }, [isModalOpen]);

  return (
    <Modal
      title={t('reservations.form.modal.confirm_supervision')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={
        <Flex gap="small" justify="end">
          <Button onClick={() => setIsModalOpen(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button
            loading={isLoading}
            onClick={() =>
              confirmReservation({
                reservationId,
                supervisionId: supervision?._id,
                pensionerId,
                dispatchAPI,
                message,
                refreshData,
                setRefreshData,
                form,
                setIsModalOpen,
                selectedReservations,
                navigate,
                setIsLoading
              })
            }
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      }
    >
      <Form form={form}>
        {reservationFields &&
          reservationFields.map((field) =>
            generateFields('reservations', field)
          )}
      </Form>
    </Modal>
  );
};

export default ReservationModal;

ReservationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    reference: PropTypes.string,
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        last_name: PropTypes.string
      })
    })
  }).isRequired,
  reservations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pensionerId: PropTypes.string,
  reservationId: PropTypes.string,
  message: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
};

ReservationModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null,
  pensionerId: null,
  reservationId: null
};
