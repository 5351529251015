import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { extraInfo } from '../listContent/extraInfo';
import { DescriptionList } from '../../../../../components';

const ExtraInfoCards = ({ t, isLoading, pensioner }) => (
  <Card title={t('pensioners.show.extra_info')} className="form-card">
    <Row gutter={[0, 24]}>
      <Col xs={24} xxl={24}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            skipEmpty
            data={extraInfo(pensioner || {}, t)}
            translate
          />
        </Skeleton>
      </Col>
    </Row>
  </Card>
);

export default ExtraInfoCards;

ExtraInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({}).isRequired
};
