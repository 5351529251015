import { Input, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * A React component function that generates an array of field configurations
 * for room and laundry information in a form. The visibility of certain fields
 * is determined based on the type of dwelling passed as a parameter. It utilizes
 * the `useTranslation` hook from `react-i18next` to provide translations for the
 * labels of several checkbox inputs.
 *
 * @param {string} typeOfDwelling - The type of dwelling to determine the visibility
 * of certain fields. Can be 'APPARTMENT' or other values to affect the hidden property
 * of certain fields.
 *
 * @returns {Object} An object containing the following properties:
 * - `roomAndLaundryInformationsFields`: An array of objects, each representing
 * a field configuration for inputs capturing various room and laundry details.
 * The configurations include properties such as `label`, `hidden`, `noLabel`, `name`,
 * `input`, and `valuePropName`.
 *
 * @function
 * @example
 *
 * import RoomAndLaundryInformations from './RoomAndLaundryInformations';
 *
 * const { roomAndLaundryInformationsFields } = RoomAndLaundryInformations('APPARTMENT');
 *
 * // Now, roomAndLaundryInformationsFields can be used to generate a form or part of a form
 */
const RoomAndLaundryInformations = (typeOfDwelling) => {
  const { t } = useTranslation();

  const roomAndLaundryInformationsFields = [
    {
      label:
        'type_of_storage_for_pensioner.room_and_laundry.cleaner_during_supervision',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'cleaner_during_supervision'
      ],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      label:
        'type_of_storage_for_pensioner.room_and_laundry.room_in_ground_floor',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'room_in_ground_floor'
      ],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      label:
        'type_of_storage_for_pensioner.room_and_laundry.rooms_number_for_pensioner',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'rooms_number_for_pensioner'
      ],
      input: <Input />
    },
    {
      label:
        'type_of_storage_for_pensioner.room_and_laundry.air_conditioned_room',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'air_conditioned_room'
      ],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      hidden: typeOfDwelling === 'APPARTMENT',
      label: 'type_of_storage_for_pensioner.room_and_laundry.bed',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'bed'
      ],
      input: <Input />
    },
    {
      label: 'type_of_storage_for_pensioner.room_and_laundry.tidying_type',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'tidying_type'
      ]
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'sheet'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.sheet'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'household_linen'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.household_linen'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'washer'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.washer'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'dryer'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.dryer'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'room_and_laundry',
        'iron'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.room_and_laundry.iron'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    roomAndLaundryInformationsFields
  };
};

export default RoomAndLaundryInformations;
