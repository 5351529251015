import { Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PensionerStatus } from '../../../utils/constants/tagColors';

/**
 * Custom column configurations for different types of MailSender entries (pensioners, owners, and veterinarians/groomers).
 *
 * @function
 * @param {Object} props - The component props.
 * @param {Object} props.enums - An object containing enumerated values.
 * @returns {Object} An object containing column configurations for pensioners, owners, and veterinarians/groomers.
 */

export const useColumns = ({ enums, type }) => {
  const { t } = useTranslation();

  const pensionerColumns = [
    {
      title: t('mail-sender.form.pensioners.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('mail-sender.form.pensioners.name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    ...(type === 'ANOUNCEMENT'
      ? [
          {
            title: t('mail-sender.form.pensioners.sending_date'),
            key: 'anouncement_mail_sending_date',
            dataIndex: 'anouncement_mail_sending_date',
            render: (anouncement_mail_sending_date) =>
              anouncement_mail_sending_date &&
              dayjs(anouncement_mail_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    ...(type === 'PENSIONER_WISH'
      ? [
          {
            title: t('mail-sender.form.pensioners.sending_date'),
            key: 'wish_mail_sending_date',
            dataIndex: 'wish_mail_sending_date',
            render: (wish_mail_sending_date) =>
              wish_mail_sending_date &&
              dayjs(wish_mail_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    ...(type === 'OTHER_PENSIONER'
      ? [
          {
            title: t('mail-sender.form.pensioners.sending_date'),
            key: 'other_mail_sending_date',
            dataIndex: 'other_mail_sending_date',
            render: (other_mail_sending_date) =>
              other_mail_sending_date &&
              dayjs(other_mail_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    ...(type === 'PENSIONER_EXPLANATORY_DOCUMENT'
      ? [
          {
            title: t('mail-sender.form.owners.sending_date'),
            key: 'explanatory_document_sending_date',
            dataIndex: 'explanatory_document_sending_date',
            render: (explanatory_document_sending_date) =>
              explanatory_document_sending_date &&
              dayjs(explanatory_document_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    {
      title: t('mail-sender.form.pensioners.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.city && address?.city
    },
    {
      title: t('mail-sender.form.pensioners.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={PensionerStatus[status]}>
            {t(`mail-sender.form.pensioners.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`mail-sender.form.pensioners.tags.${r}`),
        value: r
      }))
    }
  ];

  const ownerColumns = [
    {
      title: t('mail-sender.form.owners.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('mail-sender.form.owners.name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    ...(type === 'OWNER_WISH'
      ? [
          {
            title: t('mail-sender.form.owners.sending_date'),
            key: 'owner_wish_sending_date',
            dataIndex: 'owner_wish_sending_date',
            render: (owner_wish_sending_date) =>
              owner_wish_sending_date &&
              dayjs(owner_wish_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    ...(type === 'OTHER_OWNER'
      ? [
          {
            title: t('mail-sender.form.owners.sending_date'),
            key: 'other_mail_sending_date',
            dataIndex: 'other_mail_sending_date',
            render: (other_mail_sending_date) =>
              other_mail_sending_date &&
              dayjs(other_mail_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    ...(type === 'OWNER_EXPLANATORY_DOCUMENT'
      ? [
          {
            title: t('mail-sender.form.owners.sending_date'),
            key: 'explanatory_document_sending_date',
            dataIndex: 'explanatory_document_sending_date',
            render: (explanatory_document_sending_date) =>
              explanatory_document_sending_date &&
              dayjs(explanatory_document_sending_date).format('DD/MM/YYYY'),
            sorter: true
          }
        ]
      : []),
    {
      title: t('mail-sender.form.owners.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.city && address?.city
    },
    {
      title: t('mail-sender.form.owners.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) =>
        status ? (
          <Tag color={PensionerStatus[status]}>
            {t(`mail-sender.form.owners.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`mail-sender.form.owners.tags.${r}`),
        value: r
      }))
    }
  ];

  const veterinarianAndGroomerColumns = [
    {
      title: t('mail-sender.form.veterinarian_and_groomer.type'),
      key: 'type',
      dataIndex: 'type',
      render: (el) =>
        el && t(`mail-sender.form.veterinarian_and_groomer.types.${el}`),
      filters: [
        {
          text: t(
            `mail-sender.form.veterinarian_and_groomer.types.veterinarian`
          ),
          value: 'veterinarian'
        },
        {
          text: t(`mail-sender.form.veterinarian_and_groomer.types.groomer`),
          value: 'groomer'
        }
      ],
      onFilter: (value, record) => record.type === value
    },
    {
      title: t('mail-sender.form.veterinarian_and_groomer.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('mail-sender.form.veterinarian_and_groomer.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.city && address?.city,
      sorter: true
    },
    {
      title: t('mail-sender.form.veterinarian_and_groomer.reference'),
      key: 'owner',
      dataIndex: 'owner',
      render: (owner) => owner?.reference && owner?.reference,
      sorter: true
    },
    {
      title: t('mail-sender.form.veterinarian_and_groomer.sending_date'),
      key: 'advert_sending_date',
      dataIndex: 'advert_sending_date',
      render: (advert_sending_date) =>
        advert_sending_date && dayjs(advert_sending_date).format('DD/MM/YYYY'),
      sorter: true
    }
  ];

  return { pensionerColumns, ownerColumns, veterinarianAndGroomerColumns };
};
