import moment from 'moment';
import { Tag } from 'antd';
import { InvoiceStatus } from '../../../../../utils/constants/tagColors';

/**
 * Fonction pour générer les informations principales d'une facture.
 * @param {Object} data - Les données de la facture.
 * @param {Function} t - Fonction de traduction.
 * @returns {Object} Les informations principales de la facture.
 */

const principalInfo = (data, t) => {
  const {
    type,
    customer,
    supervision,
    payment_method,
    cheque_number,
    invoicing_address,
    city,
    postal_code,
    emission_date,
    due_date,
    total_ht,
    total_reduction_ttc,
    total_ttc,
    payment_amount,
    emitter,
    recipient_name,
    wording,
    associated_invoice,
    payment_date,
    franc
  } = data;

  const unit = franc ? 'Fr' : '€';

  const customerInfo = [
    {
      label: 'customerinvoices.form.type',
      span: 1,
      content:
        (type && (
          <Tag color={InvoiceStatus[type]}>
            {t(`customerinvoices.tags.${type}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'customerinvoices.form.customer',
      span: 1,
      content: (customer && customer.last_name) || '-'
    },
    ...(type === 'CREDIT_NOTE'
      ? [
          {
            label: 'customerinvoices.form.associated_invoice',
            span: 1,
            content: (associated_invoice && associated_invoice.reference) || '-'
          }
        ]
      : [
          {
            label: 'customerinvoices.form.supervision',
            span: 1,
            content: (supervision && supervision.reference) || '-'
          }
        ]),

    {
      label: 'customerinvoices.form.payment_method',
      span: 1,
      content:
        (payment_method &&
          t(`customerinvoices.tags.${payment_method}`, {
            number: cheque_number || ''
          })) ||
        '-'
    },
    ...(type !== 'CREDIT_NOTE'
      ? [
          {
            label: 'customerinvoices.form.invoicing_address',
            span: 1,
            content:
              (invoicing_address &&
                `${invoicing_address}, ${city}, ${postal_code}`) ||
              '-'
          }
        ]
      : [
          {
            label: 'customerinvoices.form.invoicing_address',
            span: 1,
            content:
              (associated_invoice &&
                `${associated_invoice.invoicing_address}, ${associated_invoice.city}, ${associated_invoice.postal_code}`) ||
              '-'
          }
        ]),
    {
      label: 'customerinvoices.form.emission_date',
      span: 1,
      content:
        (emission_date && moment(emission_date).format('DD/MM/YYYY')) || '-'
    },
    ...(type !== 'CREDIT_NOTE'
      ? [
          {
            label: 'customerinvoices.form.due_date',
            span: 1,
            content: (due_date && moment(due_date).format('DD/MM/YYYY')) || '-'
          }
        ]
      : []),
    {
      label: 'customerinvoices.form.total_ht',
      span: 1,
      content:
        (total_ht &&
          (type === 'CREDIT_NOTE'
            ? `- ${total_ht} ${unit}`
            : `${total_ht} ${unit}`)) ||
        '-'
    },
    ...(type !== 'CREDIT_NOTE'
      ? [
          {
            label: 'customerinvoices.form.total_reduction_ttc',
            span: 1,
            content:
              (total_reduction_ttc && `${total_reduction_ttc} ${unit}`) || '-'
          }
        ]
      : []),
    {
      label: 'customerinvoices.form.total_ttc',
      span: 1,
      content:
        (total_ttc &&
          (type === 'CREDIT_NOTE'
            ? `- ${total_ttc} ${unit}`
            : `${total_ttc} ${unit}`)) ||
        '0 €'
    },
    {
      label: 'customerinvoices.form.payment_amount',
      span: 1,
      content:
        (payment_amount &&
          (type === 'CREDIT_NOTE'
            ? `- ${payment_amount} ${unit}`
            : `${payment_amount} ${unit}`)) ||
        '-'
    },
    {
      label: 'customerinvoices.form.payment_date',
      span: 1,
      content:
        (payment_date && moment(payment_date).format('DD/MM/YYYY')) || '-'
    }
  ];

  const internInfo = [
    {
      label: 'interninvoices.form.emitter',
      span: 1,
      content: (emitter && emitter) || '-'
    },
    {
      label: 'interninvoices.form.payment_method',
      span: 1,
      content: (payment_method && payment_method) || '-'
    },
    {
      label: 'interninvoices.form.recipient_name',
      span: 1,
      content: (recipient_name && recipient_name) || '-'
    },
    {
      label: 'interninvoices.form.invoicing_address',
      span: 1,
      content:
        (invoicing_address &&
          `${invoicing_address}, ${city}, ${postal_code}`) ||
        '-'
    },
    {
      label: 'interninvoices.form.emission_date',
      span: 1,
      content:
        (emission_date && moment(emission_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'interninvoices.form.wording',
      span: 1,
      content: (wording && wording) || '-'
    },
    {
      label: 'interninvoices.form.due_date',
      span: 1,
      content: (due_date && moment(due_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'interninvoices.form.total_ht',
      span: 1,
      content: (total_ht && `${total_ht} ${unit}`) || '-'
    },
    {
      label: 'interninvoices.form.total_ttc',
      span: 1,
      content: (total_ttc && `${total_ttc} ${unit}`) || '-'
    },
    {
      label: 'customerinvoices.form.payment_date',
      span: 1,
      content:
        (payment_date && moment(payment_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'customerinvoices.form.payment_amount',
      span: 1,
      content: (payment_amount && `${payment_amount} ${unit}`) || '-'
    }
  ];

  return { customerInfo, internInfo };
};

export { principalInfo };
