import { Tag, Divider, Button, Popconfirm } from 'antd';
import {
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  ContainerOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { mailSenderStatus } from '../../../utils/constants/tagColors';
import { updateStatus } from '../utils/updateStatus';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { deleteMailSender } from '../utils/deleteMailSender';
import { generateDocuments } from '../utils/generateDocuments';
import { dateFilterColumns } from '../../reservation/utils/dateFilterColumns';

/**
 * Custom column configuration for the MailSender list.
 *
 * @function
 * @param {Object} props - The component props.
 * @param {Object} props.enums - An object containing enumerated values.
 * @param {string} props.sendingType - The sending type of the MailSender.
 * @param {function} props.setRefreshData - Function to set the data refresh flag.
 * @param {boolean} props.refreshData - Flag indicating whether to refresh data.
 * @returns {Array} Array of column configurations for the MailSender list.
 */

export const useColumns = ({
  enums,
  sendingType,
  setRefreshData,
  refreshData
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const creationDate = useRef(null);
  const sendingDate = useRef(null);
  const previsionalSendingDate = useRef(null);

  return [
    {
      title: t('mail-sender.form.creation_date'),
      key: 'creation_date',
      dataIndex: 'creation_date',
      render: (creation_date) =>
        creation_date && dayjs(creation_date).format('DD/MM/YYYY'),
      sorter: true,
      defaultSortOrder: 'descend',
      ...dateFilterColumns(creationDate, t)
    },
    {
      title: t('mail-sender.form.document_type'),
      key: 'document_type',
      dataIndex: ['document_type'],
      render: (type) => t(`mail-sender.tags.${type}`),
      sorter: true,
      filters: enums?.document_type?.map((r) => ({
        text: t(`mail-sender.tags.${r}`),
        value: r
      }))
    },
    {
      title: t(`mail-sender.form.${sendingType.toLowerCase()}_number`),
      key: 'mail_number',
      dataIndex: 'mail_number',
      sorter: true
    },
    {
      title: t('mail-sender.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={mailSenderStatus[status]}>
            {t(`mail-sender.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`mail-sender.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('mail-sender.form.created_by'),
      key: 'created_by',
      dataIndex: 'created_by',
      render: (created_by) =>
        `${created_by?.last_name || ''} ${created_by?.first_name || ''}`,
      sorter: true
    },
    ...(sendingType === 'POST'
      ? [
          {
            title: t('mail-sender.form.sending_date'),
            key: 'sending_date',
            dataIndex: 'sending_date',
            render: (sending_date) =>
              sending_date && dayjs(sending_date).format('DD/MM/YYYY'),
            sorter: true,
            ...dateFilterColumns(sendingDate, t)
          }
        ]
      : [
          {
            title: t('mail-sender.form.previsional_sending_date'),
            key: 'previsional_sending_date',
            dataIndex: 'previsional_sending_date',
            render: (previsional_sending_date) =>
              previsional_sending_date &&
              dayjs(previsional_sending_date).format('DD/MM/YYYY'),
            sorter: true,
            ...dateFilterColumns(previsionalSendingDate, t)
          }
        ]),
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          {record.status === 'NOT_SENT' && record.type === 'POST' ? (
            <>
              <Button
                type="link"
                onClick={() =>
                  updateStatus({
                    dispatchAPI,
                    id: record._id,
                    setRefreshData,
                    refreshData,
                    message
                  })
                }
                style={{ padding: 0 }}
              >
                <CheckOutlined style={{ fontSize: 18 }} />
              </Button>
              <Divider type="vertical" />
            </>
          ) : null}
          <Link
            to={{
              pathname: `show/${record.document_type}/${record?._id}/${record?.status}/${sendingType}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {record.status === 'NOT_SENT' ? (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `edit/${record.document_type}/${record?._id}/${sendingType}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
            </>
          ) : null}

          <Divider type="vertical" />

          <Button
            type="link"
            onClick={() => generateDocuments(record, dispatchAPI, message, t)}
            style={{ padding: 0 }}
          >
            <DownloadOutlined />
          </Button>

          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.archive.title')}
            okText={t('datatable.column.action.archive.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.archive.cancel')}
            onConfirm={() =>
              deleteMailSender({
                id: record._id,
                dispatchAPI,
                message,
                refreshData,
                setRefreshData
              })
            }
            icon={<WarningOutlined />}
          >
            <ContainerOutlined
              style={{ color: '#b51010', fontSize: 18 }}
              type="delete"
            />
          </Popconfirm>
        </>
      )
    }
  ];
};
