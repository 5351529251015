import dayjs from 'dayjs';

const subscriptionInfo = (data, totalTTC) => [
  {
    label: 'pensioners.form.subscription.start_date',
    span: 1,
    content: (data && dayjs(data.start_date).format('DD/MM/YYYY')) || '-'
  },
  {
    label: 'pensioners.form.subscription.end_date',
    span: 2,
    content: (data && dayjs(data.end_date).format('DD/MM/YYYY')) || '-'
  },
  {
    label: 'pensioners.form.subscription.total_ttc',
    span: 3,
    content: totalTTC
  }
];

export { subscriptionInfo };
