import React, { useState, useEffect } from 'react';
import { Modal, Select, Flex, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Component for displaying a modal for creating a mail sender.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isCreateModalOpen - Whether the create modal is open.
 * @param {Function} props.setIsCreateModalOpen - Function to set the modal open state.
 * @param {Object} props.enums - Enumerations used in the component.
 * @param {string} props.sendingType - The type of sending (e.g., POST or EMAIL).
 * @returns {JSX.Element} React element representing the component.
 */

const { Option } = Select;

export const CreateModal = ({
  isCreateModalOpen,
  setIsCreateModalOpen,
  enums,
  sendingType
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [documentType, setDocumentType] = useState('ANOUNCEMENT');

  const setEnums = () => {
    if (sendingType === 'EMAIL') {
      return enums?.document_type.filter(
        (type) =>
          ![
            'GROOMER_VETERINARIAN_ADVERT',
            'OWNER_EXPLANATORY_DOCUMENT',
            'PENSIONER_EXPLANATORY_DOCUMENT',
            'OTHER_PENSIONER',
            'OTHER_OWNER'
          ].includes(type)
      );
    }
    return enums?.document_type;
  };

  useEffect(() => {}, [sendingType]);

  return (
    <Modal
      title={t(`mail-sender.modal.title`)}
      open={isCreateModalOpen}
      onOk={() => setIsCreateModalOpen(false)}
      onCancel={() => setIsCreateModalOpen(false)}
      footer={
        <Flex gap="small" justify="end">
          <Button
            onClick={() => setIsCreateModalOpen(false)}
            type="link"
            danger
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              navigate(`/mail-sender/create/${documentType}/${sendingType}`)
            }
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      }
    >
      <Select
        onChange={(e) => setDocumentType(e)}
        style={{ width: '200px' }}
        defaultValue="ANOUNCEMENT"
      >
        {Object.keys(enums).length &&
          setEnums().map((type) => (
            <Option value={type} key={type}>
              {t(`mail-sender.tags.${type}`)}
            </Option>
          ))}
      </Select>
    </Modal>
  );
};

CreateModal.propTypes = {
  isCreateModalOpen: PropTypes.bool,
  setIsCreateModalOpen: PropTypes.func,
  enums: PropTypes.shape({
    document_type: PropTypes.arrayOf(PropTypes.string)
  }),
  sendingType: PropTypes.string.isRequired
};

CreateModal.defaultProps = {
  isCreateModalOpen: false,
  setIsCreateModalOpen: () => {},
  enums: {
    document_type: []
  }
};
