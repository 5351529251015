import { Route, Routes } from 'react-router-dom';
import { ListCustomerInvoices } from './ListCustomerInvoices';
import { CreateUpdateCustomerInvoice } from './CreateUpdateCustomerInvoice';
import { ShowCustomerInvoice } from './ShowCustomerInvoice';
import { Exception } from '../../../components';
import { InvoicesContextProvider } from '../../../contexts/InvoicesContext/InvoicesContext';

/**
 * Router component for managing customer invoices with create, edit, show, and list views.
 *
 * @component
 * @returns {JSX.Element} - The rendered CustomerInvoiceRouter component.
 */
export const CustomerInvoiceRouter = () => (
  <InvoicesContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateCustomerInvoice purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateCustomerInvoice purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowCustomerInvoice />} />
      <Route index element={<ListCustomerInvoices />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </InvoicesContextProvider>
);
