import dayjs from 'dayjs';
import { monthArray } from '../monthsArray';

/**
 * Function to calculate and set data for a registrations chart.
 *
 * @function
 * @param {Object} options - Options for generating chart data.
 * @param {Object} options.registrations - Registrations data.
 * @param {function} options.setItems - Function to set chart data items.
 * @param {function} options.t - Translation function for localization.
 * @returns {void}
 */
export const setRegistrationsChart = ({ registrations, setItems, t }) => {
  const calculatedData = {};

  Object.entries(registrations).forEach(([key, users]) => {
    users.forEach((registration) => {
      const monthKey = dayjs(registration.created_at).format('MMMM');

      calculatedData[key] = calculatedData[key] || {};
      calculatedData[key][monthKey] =
        (calculatedData[key]?.[monthKey] || 0) + 1;
    });
  });

  monthArray(t).map((month) =>
    setItems((prev) => [
      ...prev,
      {
        month,
        owners: calculatedData.owners?.[month.toLowerCase()] || 0,
        pensioners: calculatedData.pensioners?.[month.toLowerCase()] || 0
      }
    ])
  );
};
