import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Button, message as antdMessage } from 'antd';
import { useAuthContext } from '../contexts/AuthContext';
import { useCallsAPI } from '../routes/usersShows/utils/callAPI';

export const useCreateSecondaryProfile = (secondProfile) => {
  const [formData, setFormData] = useState({});
  const [profile, setProfile] = useState(null);

  const { t } = useTranslation();
  const { user, dispatchAPI, getUserProfiles } = useAuthContext();
  const { getProfile } = useCallsAPI({
    setFunction: (data) => setProfile(data[0])
  });
  const roleTranslation = {
    owners: t('login.pensioner'),
    pensioners: t('login.owner')
  };

  const setSecondaryProfileData = () => {
    const {
      _id,
      reference,
      documents,
      history,
      status,
      is_archived,
      keywords,
      __v,
      ...rest
    } = profile;
    const newFormData = {};
    Object.keys(rest).forEach((key) => {
      newFormData[key] = rest[key];
    });
    setFormData(newFormData);
  };

  useEffect(async () => {
    await getProfile();
  }, []);

  useEffect(() => {
    if (profile) setSecondaryProfileData();
  }, [profile]);

  const createSecondaryProfile = async () => {
    try {
      if (formData) {
        await dispatchAPI('POST', {
          url: `${secondProfile}/create_profile/${user._id}`,
          body: formData
        });
      }
      antdMessage.success(
        t('settings.messages.profile_created', {
          customer: secondProfile === 'owners' ? 'propriétaire' : 'retraité'
        })
      );
      await getUserProfiles();
    } catch (error) {
      notification.warning({
        message: t('login.archived_account', {
          role: roleTranslation[secondProfile]
        }),
        key: 'archived_account',
        duration: null,
        btn: (
          <Button onClick={() => notification.destroy('archived_account')}>
            Ok
          </Button>
        )
      });
    }
  };

  return { createSecondaryProfile };
};
