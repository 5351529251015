import { Route, Routes } from 'react-router-dom';
import { ListReservations } from './ListReservations';
import { CreateUpdateReservation } from './CreateUpdateReservation';
import { Exception } from '../../components';

export const ReservationRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateReservation purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateReservation purpose="edit" />}
    />
    <Route index element={<ListReservations />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
