import { Card, Col } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@ant-design/charts';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { internExpencesSetData } from '../../../utils/setDataChart/internalExpencesChart';
import { internExpencesConfig } from '../../../utils/setChartConfig/internExpencesConfig';

/**
 * InternalExpencesChart component for displaying internal expenses data.
 * @component
 * @param {Object} props - The properties of the InternalExpencesChart component.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Object} props.internInvoices - Object containing internal invoices data.
 * @returns {JSX.Element} - JSX element representing the InternalExpencesChart component.
 */
export const InternalExpencesChart = ({ t, internInvoices }) => {
  const [items, setItems] = useState([]);

  // Effect hook for setting chart data based on internal expenses invoices.
  useEffect(() => {
    if (internInvoices) {
      setItems([]);
      internExpencesSetData({ internInvoices, setItems, t });
    }
  }, [internInvoices]);

  // Transform chart data for rendering.
  const data = items.reduce((acc, item) => {
    acc.push({
      month: item.month,
      sales: item.salesNMinus1,
      type: 'N-1'
    });

    acc.push({
      month: item.month,
      sales: item.salesN,
      type: 'N'
    });

    return acc;
  }, []);

  return (
    <Col span={24} style={{ marginTop: '20px' }}>
      <Card
        title={t('dashboard.tabs.finances.internal_expences')}
        className="dashboard-card"
      >
        <Column {...internExpencesConfig(data)} />
      </Card>
    </Col>
  );
};

InternalExpencesChart.propTypes = {
  t: PropTypes.func.isRequired,
  internInvoices: PropTypes.shape({}).isRequired
};
