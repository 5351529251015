import { Route, Routes } from 'react-router-dom';
import { CreateUpdateAnimals } from './CreateUpdateAnimals';
import { ShowMyAnimals } from './ShowMyAnimals';

export const AnimalsRouter = () => (
  <Routes>
    <Route
      path="/create/:id"
      element={<CreateUpdateAnimals purpose="create" />}
    />
    <Route
      path="/:id/edit/:petId"
      element={<CreateUpdateAnimals purpose="edit" />}
    />
    <Route path="/:id" element={<ShowMyAnimals />} />
  </Routes>
);
