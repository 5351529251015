export const setMissingSupervisionsMessage = ({ user, tabsKey, t }) => {
  switch (user.role) {
    case 'users:PENSIONER':
      return t(`supervisions.messages.no_supervision.${tabsKey}`);
    case 'users:OWNER':
      return t('supervisions.messages.no_supervision.CREATED');
    default:
      return t('supervisions.messages.no_supervision.BOOKED');
  }
};
