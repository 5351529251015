import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal } from 'antd';
import { detailPetsittingAddressInfo } from '../../../../../components/PetsittingAddress/listContent/petsittingAddressInfo';
import { DescriptionList } from '../../../../../components';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * A modal component for displaying pet sitting address information.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.address - The address data including dwelling photos.
 * @param {boolean} props.isModalOpen - A boolean indicating whether the modal is open.
 * @param {Function} props.setIsModalOpen - A function to set the modal's open state.
 * @param {Function} props.t - A translation function for localization.
 * @returns {JSX.Element} The rendered modal component.
 */
const PetsittingAddressInfoModal = ({
  address,
  isModalOpen,
  setIsModalOpen,
  t
}) => {
  const descriptionTitle = [
    'details',
    'general_informations',
    'room_and_laundry',
    'bathroom',
    'kitchen',
    'outside',
    'parking_and_installation',
    'equipment'
  ];
  return (
    <Modal
      title={t('owners.show.petsitting_address_info')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      width={800}
    >
      <Col xs={24} style={{ marginBottom: 16 }}>
        <Carousel imagesIds={address?.dwelling_photos} />
      </Col>
      {descriptionTitle.map((title) => (
        <Col xs={24}>
          <DescriptionList
            title={
              title === 'details'
                ? null
                : t(`petsittingaddress.card_title.${title}`)
            }
            data={detailPetsittingAddressInfo(address || {}, title, t)}
            translate
            skipEmpty
          />
        </Col>
      ))}
    </Modal>
  );
};

export default PetsittingAddressInfoModal;

PetsittingAddressInfoModal.propTypes = {
  address: PropTypes.shape({
    dwelling_photos: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  t: PropTypes.func.isRequired
};

PetsittingAddressInfoModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null
};
