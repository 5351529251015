/**
 * Returns an array of objects containing general information about an old animal.
 *
 * @hook
 * @param {object} data - The data object containing the animal information.
 * @returns {Array} An array of objects with label, span, and content properties.
 */
export const generalOldAnimalInfo = (data) => {
  const {
    dog,
    cat,
    other,
    other_animals_presence,
    treatment,
    is_animal_leave_property,
    is_in_enclosure,
    walk_outside_property_number,
    walk_frequency,
    walk_duration,
    is_runaway
  } = data;

  return [
    {
      label: 'animals.form.dog',
      span: 1,
      content: dog || '-'
    },
    {
      label: 'animals.form.cat',
      span: 1,
      content: cat || '-'
    },
    {
      label: 'animals.form.other',
      span: 1,
      content: other || '-'
    },
    {
      label: 'animals.form.other_animals_presence',
      span: 1,
      content: other_animals_presence || '-'
    },
    {
      label: 'animals.form.treatment',
      span: 1,
      content: treatment || '-'
    },
    {
      label: 'animals.form.is_animal_leave_property',
      span: 1,
      content: is_animal_leave_property || '-'
    },
    {
      label: 'animals.form.is_in_enclosure',
      span: 1,
      content: is_in_enclosure || '-'
    },
    {
      label: 'animals.form.walk_outside_property_number',
      span: 1,
      content: walk_outside_property_number || '-'
    },
    {
      label: 'animals.form.walk_frequency',
      span: 1,
      content: walk_frequency || '-'
    },
    {
      label: 'animals.form.walk_duration',
      span: 1,
      content: walk_duration || '-'
    },
    {
      label: 'animals.form.is_runaway',
      span: 1,
      content: (is_runaway ? 'Oui' : 'Non') || '-'
    }
  ];
};
