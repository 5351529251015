import { calculateAge } from '../utils/calculateAge';

export const personnalListContent = (pensioner, t, supervision) => {
  const {
    birth_date,
    profession,
    principal_language,
    phone_number,
    cell_phone_number
  } = pensioner || {};

  const sameContent = [
    {
      label: t('owners.users_shows.pensioners_age'),
      content: calculateAge(birth_date) || '-',
      span: 1
    },
    {
      label: t('owners.users_shows.previous_profession'),
      content: profession || '-'
    },
    {
      label: t('owners.users_shows.language'),
      content: principal_language || '-'
    },
    ...(supervision?.status !== 'FINISHED'
      ? [
          {
            label: t('owners.users_shows.phone_number'),
            content: phone_number ? phone_number?.number : '-'
          },
          {
            label: t('owners.users_shows.cell_phone_number'),
            content: cell_phone_number ? cell_phone_number?.number : '-'
          }
        ]
      : [])
  ];

  return sameContent;
};
