import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Divider } from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { setRatingShowInfo } from '../utils/setRatingShowInfo';

const ListRatings = ({
  reviewActiveKey,
  owner,
  setReviewDrawerOpen,
  form,
  questionnaireType,
  setSupervisionRating,
  setShowReviewDrawer,
  setRefreshData,
  refreshData,
  setReviewData,
  reviewData,
  setEditReviewDrawer
}) => {
  const { t } = useTranslation();

  const setExtraQuery = () => {
    if (reviewActiveKey === 'received_reviews') {
      return `received_reviews=${owner?.user?._id}`;
    }
    return `author.id=${owner?.user?._id}`;
  };

  useEffect(() => {
    setExtraQuery();
    setRefreshData(!refreshData);
  }, [reviewActiveKey]);

  const setDataForCreateForm = () => {
    form.resetFields();
    setShowReviewDrawer(false);
    form.setFieldsValue({
      questionnaire_type: 'users:OWNER',
      author: { id: owner._id }
    });
  };

  useEffect(() => {
    if (Object.keys(reviewData).length) {
      setRatingShowInfo({
        setSupervisionRating,
        reviewData,
        reviewActiveKey,
        form,
        questionnaireType
      });
    }
  }, [reviewData]);

  const columns = [
    ...(reviewActiveKey === 'received_reviews'
      ? [
          {
            title: t('ratings.form.rating_reference'),
            key: 'author.id.reference',
            dataIndex: 'author',
            render: (author) => (author.id && author?.id?.reference) || '-'
          },
          {
            title: t('ratings.form.left_by'),
            key: 'author.id.name',
            dataIndex: 'author',
            render: (author) =>
              author.id
                ? `${author?.id?.last_name} ${author?.id?.first_name}`
                : '-'
          }
        ]
      : [
          {
            title: t('ratings.form.rating_reference'),
            key: 'receiver.id.reference',
            dataIndex: 'receiver',
            render: (receiver) => {
              if (receiver && receiver.id.length) {
                return receiver.id.map((user) => user?.reference).join(', ');
              }
              return '-';
            }
          },
          {
            title: t('ratings.form.received_by'),
            key: 'receiver.id.name',
            dataIndex: 'receiver',
            render: (receiver) => {
              if (receiver && receiver.id.length) {
                return receiver.id
                  .map(
                    (user) =>
                      `${
                        user?.civility
                          ? t(`pensioners.tags.${user?.civility}`)
                          : ''
                      } ${user?.last_name || ''} ${user?.first_name || ''}`
                  )
                  .join(', ');
              }
              return '-';
            }
          }
        ]),
    {
      title: t('ratings.form.supervision_reference'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (supervision) => supervision?.reference || '-'
    },
    {
      title: t('ratings.form.supervision_city'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (supervision) =>
        (supervision?.address?.petsitting_address?.address?.city &&
          supervision?.address?.petsitting_address?.address?.city) ||
        '-'
    },
    {
      title: t('ratings.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('ratings.form.rating'),
      key: 'rating',
      dataIndex: 'rating',
      render: (rating) => rating || '-'
    },
    {
      key: 'action',
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setEditReviewDrawer(false);
              setShowReviewDrawer(true);
              setReviewData(record);
              setReviewDrawerOpen(true);
            }}
          >
            <EyeOutlined />
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => {
              setShowReviewDrawer(false);
              setEditReviewDrawer(true);
              setReviewData(record);
              setReviewDrawerOpen(true);
            }}
          >
            <EditOutlined />
          </Button>
        </>
      )
    }
  ];

  return (
    <ListResource
      forceRefresh={refreshData}
      resourceName="ratings"
      columns={columns}
      extraQuery={setExtraQuery()}
      customActionColumn
      withPageHeader={false}
      onDoubleClickAction={{
        action: (record) => {
          setEditReviewDrawer(false);
          setShowReviewDrawer(true);
          setReviewData(record);
          setReviewDrawerOpen(true);
        }
      }}
      withCreateButton={false}
      withCustomCreateButton={reviewActiveKey === 'left_reviews'}
      customCreateButtonAction={() => {
        setShowReviewDrawer(false);
        setEditReviewDrawer(false);
        setReviewDrawerOpen(true);
        setDataForCreateForm();
      }}
    />
  );
};

export default ListRatings;

ListRatings.propTypes = {
  reviewActiveKey: PropTypes.string.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string,
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  setReviewDrawerOpen: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func,
    resetFields: PropTypes.func
  }).isRequired,
  questionnaireType: PropTypes.string.isRequired,
  setSupervisionRating: PropTypes.func.isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({}),
  setEditReviewDrawer: PropTypes.func.isRequired
};

ListRatings.defaultProps = {
  reviewData: {}
};
