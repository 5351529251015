import { useEffect, useState } from 'react';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { headers } from './headers';

export const ListUsers = () => {
  const [forceRefresh, setForceRefresh] = useState(false);

  const columns = useColumns(setForceRefresh);

  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      resourceModelName="User"
      withArchiveButton
      extraQuery="role=admins:ADMIN,developers:DEVELOPER"
      withUploadButton={false}
      customActionColumn
      forceRefresh={forceRefresh}
      backIcon={false}
    />
  );
};
