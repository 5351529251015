import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Collapse,
  Typography,
  Divider,
  Skeleton,
  Button
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import {
  phoneInterviewAnimalsInfo,
  phoneInterviewDwellingInfo,
  phoneInterviewPersonalInfo
} from '../listContent/phoneInterviewInfo';
import { DescriptionList } from '../../../../../components';

const { Panel } = Collapse;
const { Text } = Typography;

const categoriesTitle = ['animals', 'dwelling', 'pensioner'];

/**
 * Component to display phone interview information cards.
 *
 * @param {object} props - Component props
 * @param {function} props.t - Translation function
 * @param {object} props.pensioner - Pensioner data object
 * @param {boolean} props.isLoading - Loading state for the component
 *
 * @returns {JSX.Element} JSX element representing the PhoneInterviewInfoCards component
 */
const PhoneInterviewInfoCards = ({
  t,
  pensioner,
  isLoading,
  setPhoneInterviewsDrawerOpen,
  form
}) => {
  const { phone_interview } = pensioner ?? {};

  /**
   * Sets the description list for the specified category.
   *
   * @param {string} category - The category for which to set the description list
   * @returns {array} - An array of description list items for the specified category
   */
  const setDescriptionList = (category) => {
    switch (category) {
      case 'animals':
        return phoneInterviewAnimalsInfo(phone_interview, t);
      case 'dwelling':
        return phoneInterviewDwellingInfo(pensioner.phone_interview || {}, t);
      case 'pensioner':
        return phoneInterviewPersonalInfo(pensioner.phone_interview || {}, t);
      default:
        return [];
    }
  };

  const setInterviewDrawerFields = () => {
    form.setFieldsValue({
      animals: pensioner.phone_interview?.animals,
      dwelling: pensioner.phone_interview?.dwelling,
      pensioner: pensioner.phone_interview?.pensioner
    });
  };

  return (
    <Collapse accordion>
      <Panel
        header={t('phone_interviews.header_panel')}
        key="1"
        extra={
          <Button
            onClick={() => {
              setPhoneInterviewsDrawerOpen(true);
              setInterviewDrawerFields();
            }}
            type="link"
          >
            <EditOutlined />
          </Button>
        }
      >
        <Row>
          {categoriesTitle.map((category) => (
            <Col xs={24} xxl={24}>
              <Text style={{ fontSize: '1.2em', fontWeight: '600' }}>
                {t(
                  `pensioners.show.phone_interviews.card.categories_title.${category}`
                )}
              </Text>
              <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
                <DescriptionList
                  data={setDescriptionList(category)}
                  translate
                />
              </Skeleton>
              <Divider />
            </Col>
          ))}
        </Row>
      </Panel>
    </Collapse>
  );
};

export default PhoneInterviewInfoCards;

PhoneInterviewInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({
    phone_interview: PropTypes.shape({
      animals: PropTypes.shape({}),
      dwelling: PropTypes.shape({}),
      pensioner: PropTypes.shape({})
    }).isRequired
  }).isRequired,
  setPhoneInterviewsDrawerOpen: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired
};
