import { Checkbox, Input, Radio, Select, Form, Button, Card } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const { TextArea } = Input;
const { Option } = Select;

/**
 * Custom hook for generating form fields related to phone interviews.
 *
 * @returns {object} An object containing two arrays of field configurations: `animalsFields` and `dwellingFields`.
 */
export const useFields = () => {
  const { t } = useTranslation();
  const [enums, setEnums] = useState({});
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage({});

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/phoneinterviews/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const walkingLeachOptions = [
    ...(enums?.animals_corpulence || []).map((corpulence) => ({
      label: t(
        `pensioners.show.phone_interviews.checkbox_options.${corpulence}`
      ),
      value: corpulence
    }))
  ];

  const treatmentsOptions = [
    ...(enums?.treatments || []).map((treatment) => ({
      label: t(
        `pensioners.show.phone_interviews.checkbox_options.${treatment}`
      ),
      value: treatment
    }))
  ];

  const dwellingOptions = [
    ...(enums?.dwelling || []).map((dwelling) => ({
      label: t(`pensioners.show.phone_interviews.checkbox_options.${dwelling}`),
      value: dwelling
    }))
  ];

  const dwellingWontOptions = [
    ...(enums?.dwelling_wont || []).map((wont) => ({
      label: t(`pensioners.show.phone_interviews.checkbox_options.${wont}`),
      value: wont
    }))
  ];

  const pensionerWontOptions = [
    ...(enums?.pensioner_wont || []).map((wont) => ({
      label: t(`pensioners.show.phone_interviews.checkbox_options.${wont}`),
      value: wont
    }))
  ];

  const animalsFields = [
    {
      name: ['animals', 'is_animals'],
      label: 'phone_interviews.labels.is_animals',
      input: (
        <Radio.Group>
          <Radio value>Oui</Radio>
          <Radio value={false}>Non</Radio>
        </Radio.Group>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['animals', 'current_animals_types'],
      dependencies: ['is_animals'],
      noLabel: true,
      input: (
        <Select
          mode="multiple"
          placeholder={t(
            'pensioners.show.phone_interviews.placeholders.current_animals_types'
          )}
        >
          {(enums.animals_types || []).map((type) => (
            <Option value={type} key={type}>
              {t(`animals.tags.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      label: 'phone_interviews.labels.had_animals',
      input: (
        <Form.List name={['animals', 'animals_type']}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ name }) => (
                <Card
                  className="interview-form-animal-card"
                  extra={
                    <Button type="link" onClick={() => remove(name)}>
                      <CloseOutlined />
                    </Button>
                  }
                >
                  <Form.Item name={[name, 'type']}>
                    <Select
                      placeholder={t(
                        'pensioners.show.phone_interviews.placeholders.had_animals_type'
                      )}
                    >
                      {(enums.animals_types || []).map((type) => (
                        <Option value={type} key={type}>
                          {t(`animals.tags.${type}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={[name, 'breed']}>
                    <Input
                      placeholder={t(
                        'pensioners.show.phone_interviews.placeholders.had_animals_breed'
                      )}
                    />
                  </Form.Item>
                  <Form.Item name={[name, 'age']}>
                    <Input
                      type="number"
                      placeholder={t(
                        'pensioners.show.phone_interviews.placeholders.had_animals_age'
                      )}
                    />
                  </Form.Item>
                </Card>
              ))}
              <Button
                type="dashed"
                block
                onClick={() => {
                  add();
                }}
              >
                {t('buttons.add_animal')}
              </Button>
            </>
          )}
        </Form.List>
      )
    },
    {
      name: ['animals', 'walking_leach'],
      label: 'phone_interviews.labels.walking_leach',
      input: <Checkbox.Group options={walkingLeachOptions} />
    },
    {
      name: ['animals', 'animals_treatments'],
      label: 'phone_interviews.labels.giving_treatments',
      input: <Checkbox.Group options={treatmentsOptions} />
    },
    {
      name: ['animals', 'other_animals_treatments'],
      label: 'phone_interviews.labels.other_animals_treatments'
    },
    {
      name: ['animals', 'additional_informations'],
      label: 'phone_interviews.labels.additional_informations',
      input: (
        <TextArea
          showCount
          maxLength={100}
          style={{ height: 120, resize: 'none' }}
        />
      )
    }
  ];

  const dwellingFields = [
    {
      name: ['dwelling', 'already_live_in'],
      label: 'phone_interviews.labels.already_live_in',
      input: <Checkbox.Group options={dwellingOptions} />,
      rules: [{ required: true }]
    },
    {
      name: ['dwelling', 'is_stair_problem'],
      label: 'phone_interviews.labels.is_stair_problem',
      input: (
        <Radio.Group>
          <Radio value>Oui</Radio>
          <Radio value={false}>Non</Radio>
        </Radio.Group>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['dwelling', 'wont'],
      label: 'phone_interviews.labels.dwelling_wont',
      input: <Checkbox.Group options={dwellingWontOptions} />
    },
    {
      name: ['pensioner', 'wont'],
      label: 'phone_interviews.labels.pensioner_wont',
      input: <Checkbox.Group options={pensionerWontOptions} />
    },
    {
      name: ['pensioner', 'send_photo_with_cell_phone'],
      label: 'phone_interviews.labels.send_photo_with_cell_phone',
      input: (
        <Radio.Group>
          <Radio value>Oui</Radio>
          <Radio value={false}>Non</Radio>
        </Radio.Group>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['pensioner', 'additional_informations'],
      label: 'phone_interviews.labels.additional_informations',
      input: (
        <TextArea
          showCount
          maxLength={100}
          style={{ height: 120, resize: 'none' }}
        />
      )
    }
  ];

  return {
    animalsFields,
    dwellingFields
  };
};
