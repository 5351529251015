import { Input, Form, Select } from 'antd';

const { Option } = Select;

/**
 * Custom hook for generating urgency fields.
 *
 * @returns {Object} An object containing the urgency fields.
 */

export const useUrgencyFields = () => {
  const urgencyFields = [
    {
      name: ['summary', 'urgency', 'last_name']
    },
    {
      name: ['summary', 'urgency', 'first_name']
    },
    {
      name: ['summary', 'urgency', 'address']
    },
    {
      name: ['summary', 'urgency'],
      label: 'summary.urgency.phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['summary', 'urgency', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={['summary', 'urgency', 'phone_number', 'number']}
          >
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return {
    urgencyFields
  };
};
