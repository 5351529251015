export const adminFormDataFormat = ({
  infosStep,
  firstStep,
  secondStep,
  lastStep,
  supervision,
  userRole
}) => ({
  ...infosStep,
  ...firstStep,
  ...secondStep,
  ...lastStep,
  supervision: { _id: supervision?._id },
  author:
    userRole === 'users:OWNER'
      ? { id: supervision.main_informations.owner.user, role: 'users:OWNER' }
      : {
          id: supervision.pensioners.filter(
            (pensioner) => pensioner._id === infosStep.author.id
          )[0].user,
          role: 'users:PENSIONER'
        },
  receiver: {
    id: infosStep.receiver.id,
    role: userRole === 'users:OWNER' ? 'users:PENSIONER' : 'users:OWNER'
  }
});
