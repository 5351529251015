import { useTranslation } from 'react-i18next';
import { Row, Card, Col, Collapse } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../../components';
import { useVeterinarianInfos } from '../listContent/veterinarianInfos';
import { useGroomerInfos } from '../listContent/groomerInfos';
import { TemplateDocumentButton } from '../../../../../components/TemplateDocumentButton/TemplateDocumentButton';

const { Panel } = Collapse;

export const VeterinarianGroomerCard = ({ veterinarian, groomer }) => {
  const { t } = useTranslation();

  const { veterinarianInfos } = useVeterinarianInfos(veterinarian || {});
  const { groomerInfos } = useGroomerInfos(groomer || {});

  return (
    <Collapse accordion>
      <Panel header={t('owners.show.veterinarian_groomer.title')} key="1">
        <Row gutter={[12, 12]}>
          <Col xs={12} xxl={12}>
            <Card
              bordered={false}
              title={t('owners.show.veterinarian_groomer.veterinarian')}
              className="form-card"
              extra={[
                <TemplateDocumentButton
                  parameters={[
                    { collection: 'Veterinarian', documentId: veterinarian._id }
                  ]}
                  use_case="VETERINARIAN_SYNTHESIS"
                />
              ]}
            >
              <DescriptionList data={veterinarianInfos} translate />
            </Card>
          </Col>
          <Col xs={12} xxl={12}>
            <Card
              bordered={false}
              title={t('owners.show.veterinarian_groomer.groomer')}
              className="form-card"
              extra={[
                <TemplateDocumentButton
                  parameters={[
                    { collection: 'Groomer', documentId: groomer._id }
                  ]}
                  use_case="GROOMER_SYNTHESIS"
                />
              ]}
            >
              <DescriptionList data={groomerInfos} translate />
            </Card>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

VeterinarianGroomerCard.propTypes = {
  veterinarian: PropTypes.shape({
    _id: PropTypes.string
  }),
  groomer: PropTypes.shape({
    _id: PropTypes.string
  })
};

VeterinarianGroomerCard.defaultProps = {
  veterinarian: {},
  groomer: {}
};
