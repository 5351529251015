import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook that returns the list content for a subscription.
 * @hook
 * @param {Object} subscription - The subscription object.
 * @returns {Object} - The list content object.
 */
export const useListContent = (subscription) => {
  const { t } = useTranslation();

  const listContent = [
    {
      label: t('dashboard.show.subscription.start_date'),
      content: subscription.start_date
        ? dayjs(subscription?.start_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('dashboard.show.subscription.end_date'),
      content: subscription.end_date
        ? dayjs(subscription?.end_date).format('DD/MM/YYYY')
        : '-'
    },
    {
      label: t('dashboard.show.subscription.payment_method'),
      content: t(`customerinvoices.tags.${subscription?.payment_method}`)
    }
  ];

  return { listContent };
};
