import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Datatable } from '../../../../../components';
import { useColumns } from '../listContent/detailColumns';

/**
 * DetailsInfoCards component for displaying detailed information cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - Type of the details info cards (e.g., 'customerinvoices', 'interninvoices').
 * @param {function} props.t - Translation function.
 * @param {boolean} props.isLoading - Flag indicating whether the data is loading.
 * @param {Object} props.invoice - The invoice object.
 * @returns {JSX.Element} DetailsInfoCards component.
 */
const DetailsInfoCards = ({ type, t, isLoading, invoice }) => {
  const { internInfo, customerInfo } = useColumns({ type, invoice });
  const navigate = useNavigate();

  return (
    <Card title={t(`${type}.show.details_info`)} className="form-card">
      <Row gutter={[0, 24]}>
        <Col xs={24} xxl={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Datatable
              navigate={navigate}
              columns={type === 'customerinvoices' ? customerInfo : internInfo}
              resourceName={`${type}/wordings/${invoice?._id}`}
              showAction={false}
              editAction={false}
              deleteAction={false}
              onDoubleClickAction={false}
            />
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

export default DetailsInfoCards;

DetailsInfoCards.propTypes = {
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
