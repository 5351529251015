import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Tag, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined,
  CheckOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { InvoiceStatus } from '../../../utils/constants/tagColors';
import DetailsCards from '../showInvoice/detailsCards/DetailsCards';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { deleteInvoice } from '../utils/deleteInvoice';
import { PaidInvoiceModal } from '../showInvoice/PaidInvoiceModal';

/**
 * Composant pour afficher les détails d'une facture interne.
 * @component
 * @returns {JSX.Element} Le composant de détails de la facture interne.
 */

export const ShowInternInvoice = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [internInvoice, setCustomerInvoice] = useState([]);
  const [isPaidInvoiceModalOpen, setIsPaidInvoiceModalOpen] = useState(false);
  const [enums, setEnums] = useState({});
  const [refreshData, setRefreshData] = useState(false);

  const getInternInvoice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/interninvoices/form/${id}?populate=files`
      });
      setCustomerInvoice(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  const getEnums = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      setEnums(data);
      setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getInternInvoice();
      await getEnums();
    })();
  }, [getInternInvoice, refreshData]);

  return (
    <>
      <PageHeaderCustom
        title={
          internInvoice &&
          internInvoice.reference &&
          internInvoice.status && (
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                <Typography>
                  {t('customerinvoices.show.title', {
                    reference: internInvoice.reference
                  })}
                </Typography>
              </Col>
              <Col>
                <Tag color={InvoiceStatus[internInvoice.status]}>
                  {t(`customerinvoices.tags.${internInvoice.status}`)}
                </Tag>
              </Col>
            </Row>
          )
        }
        extra={
          <>
            {internInvoice &&
              ['WAITING', 'UNPAID'].includes(internInvoice.status) && (
                <Button onClick={() => setIsPaidInvoiceModalOpen(true)}>
                  <CheckOutlined />
                  {t('customerinvoices.show.buttons.paid')}
                </Button>
              )}
            <Link
              to={{
                pathname: `${routes.INVOICES}${subRoutes.INVOICES.INTERN}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.archive.title')}
              okText={t('datatable.column.action.archive.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.archive.cancel')}
              onConfirm={() =>
                deleteInvoice({
                  id,
                  dispatchAPI,
                  message,
                  path: 'interninvoices',
                  navigate
                })
              }
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.archive')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <DetailsCards
          type="interninvoices"
          invoice={internInvoice}
          isLoading={isLoading}
        />
      </ContentCustom>
      {isPaidInvoiceModalOpen && (
        <PaidInvoiceModal
          t={t}
          isPaidInvoiceModalOpen={isPaidInvoiceModalOpen}
          setIsPaidInvoiceModalOpen={setIsPaidInvoiceModalOpen}
          enums={enums}
          id={id}
          dispatchAPI={dispatchAPI}
          message={message}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          resourceName="interninvoices"
          invoice={internInvoice}
        />
      )}
    </>
  );
};
