import dayjs from 'dayjs';

/**
 * Set the fields of a form based on supervision data.
 *
 * @param {Object} options - Options for setting form fields.
 * @param {Object} options.supervision - Supervision data containing information for form fields.
 * @param {Object} options.form - The form instance to set the fields.
 * @returns {void}
 */
export const setSupervisionFields = ({ supervision, form }) => {
  /**
   * Set the due_date field in the form based on the start_date from supervision data.
   * @type {dayjs}
   */
  if (supervision?.main_informations?.start_date) {
    form.setFieldsValue({
      due_date: dayjs(supervision?.main_informations?.start_date)
    });
  }
};
