import { useCallback, useEffect, useState } from 'react';
import { Input, Select, Tag, Checkbox, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { draggerProps } from '../utils/animalDraggerProps';
import { ownerDraggerProps } from '../../../usersShows/animals/utils/ownerDraggerProps';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

/**
 * `useFields` is a custom hook that provides an array of field configurations and loading state
 * based on the given parameters and current animal type. It handles file upload and field enumerations.
 *
 * @function
 * @param {Object} fileList - The list of files, indexed by the field name.
 * @param {Function} setFileList - A function to update the file list.
 * @param {string|number} name - The name or index representing the current field/animal.
 * @param {Object} form - The form instance on which the animal fields are based.
 * @param {boolean} dataFetched - Indicates whether the data has been fetched.
 *
 * @returns {Object} The return object contains two properties:
 * - fields: An array of field configurations for rendering inputs dynamically based on the animal type.
 * - isFieldsLoading: A boolean indicating if the enumeration fields are currently being loaded.
 *
 * @example
 * const { fields, isFieldsLoading } = useFields(fileList, setFileList, name, form);
 */
export const useFields = ({
  fileList,
  setFileList,
  name,
  form,
  dataFetched,
  ownerUser
}) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [animalType, setAnimalType] = useState('');

  useEffect(() => {
    setAnimalType(form.getFieldValue(['animals', name, 'type']));
  }, [form]);

  // if form is not undefined, if animalType is different from the one in the form, set the animalType
  useEffect(() => {
    if (
      form !== undefined &&
      animalType !== form.getFieldValue([name, 'type']) &&
      dataFetched
    ) {
      setAnimalType(form.getFieldValue([name, 'type']));
    }
  }, [dataFetched]);

  const fields = [
    {
      label: 'type',
      name: [name, 'type'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} onChange={(e) => setAnimalType(e)}>
          {(enums?.type || []).map((type) => (
            <Option key={type} value={type}>
              <Tag>{t(`animals.tags.${type}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      hidden: animalType === '' || animalType === 'CAT' || animalType === 'DOG',
      label: 'number',
      name: [name, 'number'],
      input: <Input type="number" />
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'name',
      name: [name, 'name'],
      rules: [
        {
          required: !animalType === 'BIRD'
        }
      ]
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'age',
      name: [name, 'age']
    },
    {
      label:
        animalType !== 'CAT' && animalType !== 'DOG' && animalType !== ''
          ? 'species'
          : 'breed',
      name: [name, 'breed']
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'sex',
      name: [name, 'sex']
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'tattoo_number',
      name: [name, 'tattoo_number']
    },
    {
      hidden: animalType !== 'BIRD' || animalType === '',
      label: 'cage_cleaning',
      name: [name, 'cage_cleaning'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'DOG' || animalType === '',
      label: 'walk_outside_property_number',
      name: [name, 'walk_outside_property_number'],
      input: <Input type="number" />
    },
    {
      hidden: animalType !== 'DOG' || animalType === '',
      label: 'walk_frequency',
      name: [name, 'walk_frequency']
    },
    {
      hidden: animalType !== 'DOG' || animalType === '',
      label: 'walk_duration',
      name: [name, 'walk_duration']
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'is_animal_leave_property',
      name: [name, 'is_animal_leave_property'],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'is_runaway',
      name: [name, 'is_runaway'],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'in_case_of_absence',
      name: [name, 'in_case_of_absence'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'where_it_sleep',
      name: [name, 'where_it_sleep'],
      input: <TextArea />
    },
    {
      label: 'alimentation_type_and_frequency',
      name: [name, 'alimentation_type_and_frequency'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'EQUINE' && animalType !== 'FARM',
      label: 'is_in_enclosure',
      name: [name, 'is_in_enclosure'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'EQUINE' && animalType !== 'FARM',
      label: animalType === 'EQUINE' ? 'box_cleaning' : 'enclosure_cleaning',
      name: [name, 'box_cleaning'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'diet',
      name: [name, 'diet'],
      input: <TextArea />
    },
    {
      label: 'treatment',
      name: [name, 'treatment'],
      input: <TextArea />
    },
    {
      hidden: animalType !== 'DOG' && animalType !== 'CAT',
      label: 'brushing',
      name: [name, 'brushing'],
      input: <TextArea />
    },
    {
      label: 'other_animals_presence',
      name: [name, 'other_animals_presence'],
      input: <TextArea />
    },
    {
      label: 'additional_informations',
      name: [name, 'additional_informations'],
      input: <TextArea />
    },
    {
      noLabel: true,
      input: (
        <Dragger
          {...(!ownerUser && {
            ...draggerProps(
              t,
              dispatchAPI,
              message,
              fileList,
              setFileList,
              name
            )
          })}
          {...(ownerUser && {
            ...ownerDraggerProps(t, dispatchAPI, message, fileList, setFileList)
          })}
        >
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'owners/animals/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) {
        message(e.response.status);
      }
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};
