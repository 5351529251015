import { useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { UserRouter } from './UserRouter';
import { DeveloperRouter } from './DeveloperRouter';
import { AdminRouter } from './AdminRouter';
import { useThemeContext } from '../contexts/ThemeContext';
import { OwnerRouter } from './OwnerRouter';
import { PensionerRouter } from './PensionerRouter';

export const RouterDispatch = () => {
  const { user } = useAuthContext();
  const { setTheme } = useThemeContext();

  useEffect(() => {
    const body = document.body;
    if (body && !user) {
      body.className = 'user';
      setTheme('user');
    }
  }, [user]);

  const dispatchRouter = () => {
    switch (user?.role) {
      case 'admins:ADMIN':
      case 'admins:SUPER-ADMIN':
        return <AdminRouter />;
      case 'developers:DEVELOPER':
        return <DeveloperRouter />;
      case 'users:OWNER':
        return <OwnerRouter />;
      case 'users:PENSIONER':
        return <PensionerRouter />;
      case 'users:SUPER-USER':
      default:
        return <UserRouter />;
    }
  };
  return dispatchRouter();
};
