import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { additionalInfo } from '../listContent/additionalInfo';
import { DescriptionList } from '../../../../../components';

const AdditionalInfoCards = ({ t, isLoading, pensioner }) => (
  <Card title={t('pensioners.show.additional_info')} className="form-card">
    <Row gutter={[0, 24]}>
      <Col xs={24} xxl={24}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={additionalInfo(pensioner || {}, t)}
            translate
          />
        </Skeleton>
      </Col>
    </Row>
  </Card>
);

export default AdditionalInfoCards;

AdditionalInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({}).isRequired
};
