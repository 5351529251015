import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PensionerInfoCards from './cards/PensionerInfoCards';
import AdditionalInfoCards from './cards/AdditionalInfoCards';
import SubscriptionInfoCards from './cards/SubscriptionInfoCards';
import ExtraInfoCards from './cards/ExtraInfoCards';
import DocumentInfoCards from './cards/DocumentInfoCards';
import CommentInfoCards from './cards/CommentInfoCards';
import RatingInfoCards from './cards/RatingInfoCards';
import HistoryInfoCards from './cards/HistoryInfoCards';
import PhoneInterviewInfoCards from './cards/PhoneInterviewInfoCards';
import SupervisionAndInvoiceInfoCards from './cards/SupervisionAndInvoiceInfoCards';

const DetailsCards = ({
  pensioner,
  isLoading,
  setRefreshData,
  setIsLoading,
  refreshData,
  setPhoneInterviewsDrawerOpen,
  setSubscriptionDrawerOpen,
  subscription,
  form,
  setQuestionnaireType,
  setSupervisionRating,
  setReviewDrawerOpen,
  questionnaireType,
  setShowReviewDrawer,
  subscriptionInvoice,
  setSubscriptonInfoModalOpen,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType,
  setReviewData,
  reviewData,
  setEditReviewDrawer
}) => {
  const { t } = useTranslation();
  const [reviewActiveKey, setReviewActiveKey] = useState('received_reviews');

  const getColWidth = (count) => {
    if (count === 1) {
      return 24;
    }
    return 12;
  };

  useEffect(() => {
    if (reviewActiveKey === 'received_reviews') {
      setQuestionnaireType('users:OWNER');
    }
    if (reviewActiveKey === 'left_reviews') {
      setQuestionnaireType('users:PENSIONER');
    }
  }, [reviewActiveKey]);

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xxl={24}>
        <PensionerInfoCards
          t={t}
          isLoading={isLoading}
          pensioner={pensioner}
          getColWidth={getColWidth}
        />
      </Col>
      <Col xs={24} xxl={17}>
        <AdditionalInfoCards
          t={t}
          isLoading={isLoading}
          pensioner={pensioner}
        />
      </Col>
      <Col xs={24} xxl={7}>
        <SubscriptionInfoCards
          isLoading={isLoading}
          subscription={subscription}
          setSubscriptionDrawerOpen={setSubscriptionDrawerOpen}
          subscriptionInvoice={subscriptionInvoice}
          setSubscriptonInfoModalOpen={setSubscriptonInfoModalOpen}
          pensioner={pensioner}
          setIsEmailModalOpen={setIsEmailModalOpen}
          setTemplateType={setTemplateType}
          setDocumentType={setDocumentType}
        />
      </Col>
      <Col xs={24} xxl={24}>
        <ExtraInfoCards
          t={t}
          isLoading={isLoading}
          pensioner={pensioner}
          getColWidth={getColWidth}
        />
      </Col>
      <Col xs={24} xxl={17}>
        <DocumentInfoCards
          t={t}
          isLoading={isLoading}
          pensioner={pensioner}
          setRefreshData={setRefreshData}
          setIsLoading={setIsLoading}
          refreshData={refreshData}
        />
      </Col>
      <Col xs={24} xxl={7}>
        <CommentInfoCards t={t} isLoading={isLoading} pensioner={pensioner} />
      </Col>
      <Col xs={24} xxl={24}>
        <RatingInfoCards
          t={t}
          pensioner={pensioner}
          setReviewActiveKey={setReviewActiveKey}
          reviewActiveKey={reviewActiveKey}
          setReviewDrawerOpen={setReviewDrawerOpen}
          questionnaireType={questionnaireType}
          setSupervisionRating={setSupervisionRating}
          form={form}
          setShowReviewDrawer={setShowReviewDrawer}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setReviewData={setReviewData}
          reviewData={reviewData}
          setEditReviewDrawer={setEditReviewDrawer}
        />
      </Col>
      {pensioner && pensioner.phone_interview ? (
        <Col xs={24} xxl={24}>
          <PhoneInterviewInfoCards
            t={t}
            pensioner={pensioner}
            isLoading={isLoading}
            setPhoneInterviewsDrawerOpen={setPhoneInterviewsDrawerOpen}
            form={form}
          />
        </Col>
      ) : null}
      <Col xs={24} xxl={7}>
        <HistoryInfoCards t={t} isLoading={isLoading} pensioner={pensioner} />
      </Col>
      <Col xs={24} xxl={17}>
        {Object.values(pensioner).length ? (
          <SupervisionAndInvoiceInfoCards t={t} pensioner={pensioner} />
        ) : null}
      </Col>
    </Row>
  );
};

export default DetailsCards;

DetailsCards.propTypes = {
  pensioner: PropTypes.shape({
    phone_interview: PropTypes.shape({})
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setPhoneInterviewsDrawerOpen: PropTypes.func.isRequired,
  setSubscriptionDrawerOpen: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  subscription: PropTypes.shape({}),
  setRefreshData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setQuestionnaireType: PropTypes.func.isRequired,
  setSupervisionRating: PropTypes.func.isRequired,
  setReviewDrawerOpen: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  subscriptionInvoice: PropTypes.shape({
    status: PropTypes.string
  }).isRequired,
  setSubscriptonInfoModalOpen: PropTypes.func.isRequired,
  setIsEmailModalOpen: PropTypes.func.isRequired,
  setTemplateType: PropTypes.func.isRequired,
  setDocumentType: PropTypes.func.isRequired,
  setReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({}),
  setEditReviewDrawer: PropTypes.func.isRequired
};

DetailsCards.defaultProps = {
  subscription: {},
  reviewData: {}
};
