import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useAuthContext } from './AuthContext';
import { useErrorMessage } from '../utils/errorMessage';

const SocketContext = createContext({});

/**
 * @description Socket context provider
 * @param {Object} props Props
 * @param {React.ReactNode} props.children Children
 * @returns {React.ReactNode} Socket context provider
 */
export const SocketContextProvider = ({ children }) => {
  const { token, dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [socket, setSocket] = useState();
  const [connectedUsers, setConnectedUsers] = useState([{}]);
  const [profileLock, setProfileLock] = useState([]);
  const [editSupervisionLock, setEditSupervisionLock] = useState([]);

  useEffect(() => {
    if (token) {
      setSocket(io(`${process.env.REACT_APP_API_URL}?token=${token}`));
    } else if (socket && !token) {
      socket.emit('logout');
      socket.close();
    }
    return () => {
      if (socket) socket.close();
    };
  }, [token]);

  useEffect(() => {
    if (socket) {
      socket.on('connectedUsers', (users) => setConnectedUsers(users));
      socket.on('forceDisconnect', () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
      });
    }
  }, [socket]);

  const onProfileLock = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/editing-rights`
      });

      const newProfileLock = [];
      const newEditSupervisionLock = [];

      data.forEach((item) => {
        if (item.action === 'editing_profile') {
          newProfileLock.push(item);
        }
        if (item.action === 'editing_supervision') {
          newEditSupervisionLock.push(item);
        }
      });

      setProfileLock(newProfileLock);
      setEditSupervisionLock(newEditSupervisionLock);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('profileLock', () => {
        onProfileLock();
      });
      socket.on('profileUnlock', () => {
        onProfileLock();
      });
      socket.on('editSupervisionLock', () => {
        onProfileLock();
      });
      socket.on('editSupervisionUnlock', () => {
        onProfileLock();
      });
    }
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        connectedUsers,
        onProfileLock,
        profileLock,
        editSupervisionLock
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (context === undefined)
    throw new Error('Context must be used within a context provider');
  return context;
};
