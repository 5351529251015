export const onFinishSupervisionAddressForm = async ({
  form,
  setSupervisionAddressData,
  setCurrent,
  Message,
  t
}) => {
  try {
    const values = await form.validateFields();
    setSupervisionAddressData(values);
    setCurrent(2);
    return null;
  } catch (errorInfo) {
    return Message.error(t('supervisions.messages.required_fields'));
  }
};
