const principalInfo = (data) => {
  const { duration } = data;

  return [
    {
      label: 'supervisionprices.form.duration',
      span: 1,
      content:
        (duration &&
          (duration?.extra_week
            ? duration.extra_week
            : `de ${duration.start_day} à ${duration.end_day} jours`)) ||
        '-'
    }
  ];
};

export { principalInfo };
