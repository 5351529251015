/**
 * Sets form fields based on customer data, including address information and associated supervisions.
 *
 * @param {Object} options - Options for setting customer form fields.
 * @param {Object} options.customer - The customer data.
 * @param {Object} options.form - The form instance.
 * @param {Array} options.supervisions - List of supervisions.
 * @param {Function} options.setSelectedSupervisions - Function to set the selected supervisions.
 * @returns {void}
 */
export const setCustomerFields = ({
  customer,
  form,
  supervisions,
  setSelectedSupervisions
}) => {
  // Set address-related fields in the form if available in customer data
  if (customer?.user?.address) {
    form.setFieldsValue({
      invoicing_address: `${customer?.user?.address?.number} ${customer?.user?.address?.street}`,
      postal_code: customer?.user?.address?.postal_code,
      city: customer?.user?.address?.city
    });
  }

  // Reset and filter supervision fields based on the customer ID
  form.resetFields(['supervision']);
  const filteredSupervisions = [];
  const filteredOwners = supervisions.filter((s) => s.owner === customer._id);
  const filteredPensioners = supervisions.filter((s) =>
    s.pensioners.includes(customer._id)
  );

  // Combine and set the filtered supervisions
  filteredSupervisions.push(...filteredOwners, ...filteredPensioners);
  setSelectedSupervisions(filteredSupervisions);
};
