import { message as antdMessage } from 'antd';

/**
 * Sends an email with the provided data.
 * @async
 * @function
 *
 * @param {Object} options - The options for sending the email.
 * @param {Object} options.value - The value object containing email data.
 * @param {Function} options.dispatchAPI - The function for dispatching API requests.
 * @param {Function} options.message - The function for displaying error messages.
 * @param {string} options.templateType - The type of email template.
 * @param {Function} options.setIsSubmitting - The function for setting the submitting state.
 * @param {Object} options.editorState - The editor state object.
 * @param {Function} options.setIsModalOpen - The function for setting the modal state.
 * @param {string} options.documentType - The type of document.
 * @param {string} options.recordID - The ID of the record.
 * @param {string} options.filterID - The ID of the filter.
 * @param {Array} options.filesList - The list of files to attach to the email.
 * @param {Function} options.t - The function for translating messages.
 * @param {Function} options.setRefreshData - The function for refreshing data.
 * @returns {Promise<void>} - A promise that resolves when the email is sent successfully.
 */
export const sendMail = async ({
  value,
  dispatchAPI,
  message,
  templateType,
  setIsSubmitting,
  editorState,
  setIsModalOpen,
  documentType,
  recordID,
  filterID,
  filesList,
  t,
  setRefreshData
}) => {
  try {
    if (setIsModalOpen) {
      setIsSubmitting(true);
    }

    const formData = new FormData();
    if (filesList.length) {
      filesList.forEach((file) => {
        formData.append('attachments', file);
      });
    }

    const newBody = {
      ...value,
      template_content: editorState
    };

    formData.append('values', JSON.stringify({ ...newBody }));

    await dispatchAPI('POST', {
      url: `/templates-mail/send/${templateType}/${documentType}/${recordID}/${filterID}`,
      body: formData
    });

    if (setIsModalOpen) {
      setIsSubmitting(false);
      setIsModalOpen(false);
      antdMessage.success(t('messages.mail_sent'));
    }
    if (setRefreshData) setRefreshData((prev) => !prev);
  } catch (err) {
    message(err);
  }
};
