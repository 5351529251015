import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import OwnerPensionerHeaderLayout from './HeaderLayout/OwnerPensionerHeaderLayout';
import { useHandleResize } from '../../utils/handleResize';

const { Content, Footer } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledContent = styled.div`
  margin: 72px 0 0 0;
  min-height: calc(100vh - 72px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.footer`
  padding: 8px 24px;
  text-align: center;
  background-color: var(--componentBackground);
  color: var(--textColor);
  box-shadow: 0px -2px 4px 0px rgba(217, 186, 156, 0.2);

  a {
    color: var(--secondaryColor);
  }
`;

export const OwnerPensionerLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    document
      .getElementById('mainContent')
      .scrollTo({ behavior: 'smooth', top: 0 });
  }, [location]);

  return (
    <StyledLayout as={Layout}>
      <Layout>
        <OwnerPensionerHeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content} id="mainContent">
          <Outlet />
          <StyledFooter as={Footer}>
            {`${t('global.title')} ©${moment().year()} powered by `}
            <a href="https://strateg.in">Strateg.in</a>
          </StyledFooter>
        </StyledContent>
      </Layout>
    </StyledLayout>
  );
};
