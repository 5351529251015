import { Flex, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

/**
 * A component representing additional filters for invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.setDateFilter - A function to set the date filter.
 * @param {Function} props.setTypes - A function to set the types filter.
 * @param {Function} props.setStatus - A function to set the status filter.
 * @param {Object} props.enums - An object containing enums for types and status.
 * @param {boolean} props.isFieldLoading - A boolean indicating whether fields are loading.
 * @param {string} props.invoiceType - The type of the invoice ('customer' or other).
 * @returns {React.Component} The rendered ExtraFilters component.
 */
export const ExtraFilters = ({
  setDateFilter,
  setTypes,
  setStatus,
  enums,
  isFieldLoading,
  invoiceType
}) => {
  const { t } = useTranslation();

  return (
    <Flex gap="middle" align="center">
      <DatePicker
        style={{ width: 200 }}
        format="DD/MM/YYYY"
        onChange={(e) => setDateFilter(e)}
      />
      {invoiceType === 'customer' ? (
        <Flex gap="small" align="center">
          {`${t('customerinvoices.form.type')} :`}
          <Select
            allowClear
            loading={isFieldLoading}
            style={{ width: 200 }}
            onChange={(value) => setTypes(value)}
          >
            {enums.type &&
              enums.type.map((typeEnum) => (
                <Option key={typeEnum} value={typeEnum}>
                  {t(`customerinvoices.tags.${typeEnum}`)}
                </Option>
              ))}
          </Select>
        </Flex>
      ) : null}
      <Flex gap="small" align="center">
        {`${t('customerinvoices.form.status')} :`}
        <Select
          allowClear
          loading={isFieldLoading}
          style={{ width: 200 }}
          onChange={(value) => setStatus(value)}
        >
          {enums.status &&
            enums.status.map((statusEnum) => (
              <Option key={statusEnum} value={statusEnum}>
                {t(`customerinvoices.tags.${statusEnum}`)}
              </Option>
            ))}
        </Select>
      </Flex>
    </Flex>
  );
};

ExtraFilters.propTypes = {
  setDateFilter: PropTypes.func,
  setTypes: PropTypes.func,
  setStatus: PropTypes.func,
  enums: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  isFieldLoading: PropTypes.bool,
  invoiceType: PropTypes.string.isRequired
};

ExtraFilters.defaultProps = {
  setDateFilter: () => {},
  setTypes: () => {},
  setStatus: () => {},
  enums: {},
  isFieldLoading: false
};
