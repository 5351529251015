import { processedTemplateContent } from './processedTemplateContent';

export const getTemplate = async ({
  dispatchAPI,
  templateType,
  setEditorState,
  customFormInstance,
  message,
  collectionData,
  recipient,
  setIsFieldsLoading,
  t
}) => {
  try {
    setIsFieldsLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `templates-mail/type/${templateType}`
    });

    if (data.length) {
      setEditorState(
        await processedTemplateContent({
          templateType,
          templateContent: data[0].template_content,
          collectionData,
          dispatchAPI,
          t
        })
      );

      customFormInstance.setFieldsValue({
        object: data[0].object,
        models: data[0].template_name,
        recipients: recipient
      });
    }
    setIsFieldsLoading(false);
  } catch (error) {
    message(error);
  }
};
