import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tag, Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ListResource } from '../../../../components/ListResource/ListResource';
import {
  InvoiceStatus,
  InvoiceType
} from '../../../../utils/constants/tagColors';
import { deleteInvoice } from '../../../invoices/utils/deleteInvoice';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const ListInvoices = ({ owner }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [refreshData, setRefreshData] = useState(false);

  const columns = [
    {
      title: t('customerinvoices.form.reference'),
      key: 'reference',
      render: (reference) => reference || '-',
      dataIndex: 'reference'
    },
    {
      title: t('customerinvoices.form.type'),
      key: 'type',
      render: (type) => (
        <Tag color={InvoiceType[type]}>
          {t(`customerinvoices.tags.${type}`)}
        </Tag>
      ),
      dataIndex: 'type'
    },
    {
      title: t('customerinvoices.form.emission_date'),
      key: 'emission_date',
      dataIndex: 'emission_date',
      render: (emission_date) =>
        (emission_date && moment(emission_date).format('DD/MM/YYYY')) || '-'
    },
    {
      title: t('customerinvoices.form.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc'
    },
    {
      title: t('customerinvoices.form.paid'),
      key: 'payment',
      dataIndex: 'payment',
      render: (payment) =>
        payment && payment.reduce((acc, obj) => acc + obj.amount, 0)
    },
    {
      title: t('customerinvoices.form.status'),
      key: 'status',
      render: (status) => (
        <Tag color={InvoiceStatus[status]}>
          {t(`customerinvoices.tags.${status}`)}
        </Tag>
      ),
      dataIndex: 'status'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/invoices/customers/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {!['PAID'].includes(record?.status) && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() =>
                  deleteInvoice({
                    dispatchAPI,
                    id: record._id,
                    message,
                    setRefreshData,
                    refreshData,
                    path: 'customerinvoices'
                  })
                }
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <ListResource
      resourceName="customerinvoices"
      extraQuery={`customer=${owner.user._id}&type!=SUBSCRIPTION`}
      columns={columns}
      showEdit={false}
      withPageHeader={false}
      withCreateButton={false}
      customActionColumn
      forceRefresh={refreshData}
      path="/invoices/customers"
    />
  );
};

export default ListInvoices;

ListInvoices.propTypes = {
  owner: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired
};
