import dayjs from 'dayjs';

/**
 * Custom hook for managing configuration options related to creating and updating user profiles.
 * @function
 * @param {Object} options - The configuration options.
 * @param {Function} options.createFileFromJSON - Function to create a file from JSON data.
 * @param {Function} options.dispatchAPI - Function to dispatch API requests.
 * @param {Function} options.message - Function to display error messages.
 * @param {Function} options.setPhoto - Function to set the profile photo.
 * @param {Array} options.photo - Array containing the profile photo.
 * @param {Function} options.setSecondaryProfilePhoto - Function to set the secondary profile photo.
 * @param {Array} options.secondaryProfilePhoto - Array containing the secondary profile photo.
 * @param {Function} options.setDocumentsFileList - Function to set the documents file list.
 * @param {Object} options.documentsFileList - Object containing the documents file list.
 * @returns {Object} - An object with configuration options.
 * @property {Object} config - Configuration object with methods for getting and updating resources.
 * @property {Object} config.onGetResource - Configuration for getting a resource.
 * @property {Function} config.onGetResource.setFields - Function to set fields when getting a resource.
 * @property {Object} config.onUpdateResource - Configuration for updating a resource.
 * @property {Function} config.onUpdateResource.setBody - Function to set the request body when updating a resource.
 * @example
 * // Example usage of the useCreateUpdateConfig hook:
 * const {
 *   config: { onGetResource, onUpdateResource }
 * } = useCreateUpdateConfig({
 *   createFileFromJSON,
 *   dispatchAPI,
 *   message,
 *   setPhoto,
 *   photo,
 *   setSecondaryProfilePhoto,
 *   secondaryProfilePhoto,
 *   setDocumentsFileList,
 *   documentsFileList
 * });
 * const onGetResourceSetFields = onGetResource.setFields(data);
 * const onUpdateResourceSetBody = onUpdateResource.setBody(data);
 */
export const useCreateUpdateConfig = ({
  createFileFromJSON,
  dispatchAPI,
  message,
  setPhoto,
  photo,
  setSecondaryProfilePhoto,
  secondaryProfilePhoto,
  setDocumentsFileList,
  documentsFileList,
  setUser,
  removedFiles,
  setCountry
}) => {
  const config = {
    onGetResource: {
      /**
       * Function to set fields when getting a resource.
       * @async
       * @function
       * @param {Object} data - The data received from the API.
       * @returns {Object} - Data with fields set as needed.
       */
      setFields: async (data) => {
        if (data.photo) {
          try {
            const profilePhoto = await createFileFromJSON(
              data.photo,
              dispatchAPI,
              message
            );

            setPhoto([profilePhoto]);
          } catch (e) {
            message(e);
          }
        }

        if (data?.secondary_profile?.photo) {
          try {
            const secondaryPhoto = await createFileFromJSON(
              data.secondary_profile.photo,
              dispatchAPI,
              message
            );
            setSecondaryProfilePhoto([secondaryPhoto]);
          } catch (e) {
            message(e);
          }
        }

        if (data?.documents?.length) {
          try {
            const results = {};
            const otherDocuments = [];
            const documentsPromises = (data.documents || []).map(
              async (doc) => {
                if (doc.type === 'other_documents') {
                  const otherDocument = await createFileFromJSON(
                    doc._id,
                    dispatchAPI,
                    message
                  );
                  otherDocument.file.id = otherDocument._id;
                  otherDocuments.push(otherDocument.file);
                  if (results[doc.type]) {
                    results[doc.type].push(otherDocument.file);
                  } else {
                    results[doc.type] = [otherDocument.file];
                  }
                  return { [doc.type]: otherDocument.file };
                }

                const file = await createFileFromJSON(
                  doc._id,
                  dispatchAPI,
                  message
                );
                file.file.id = file._id;
                results[doc.type] = [file.file];
                return {
                  [doc.type]: file.file
                };
              }
            );

            await Promise.all(documentsPromises);

            const transformedObject = {};

            Object.keys(results).forEach((key) => {
              transformedObject[key] = [results[key]];
            });

            setDocumentsFileList((prev) => ({
              ...prev,
              ...results
            }));
          } catch (error) {
            message(message(error));
          }
        }

        if (data?.secondary_profile?.documents?.length) {
          try {
            const otherDocuments = [];
            const documentsPromises = (
              data.secondary_profile.documents || []
            ).map(async (doc) => {
              if (doc.type === 'other_documents') {
                const otherDocument = await createFileFromJSON(
                  doc._id,
                  dispatchAPI,
                  message
                );
                otherDocument.file.id = otherDocument._id;
                otherDocuments.push(otherDocument.file);
                return { [doc.type]: otherDocuments };
              }
              const file = await createFileFromJSON(
                doc._id,
                dispatchAPI,
                message
              );
              file.file.id = file._id;
              return {
                [doc.type]: file.file
              };
            });

            const results = await Promise.all(documentsPromises);

            const mergedResult = results.reduce(
              (acc, obj) => ({ ...acc, ...obj }),
              {}
            );

            const transformedObject = {};

            Object.keys(mergedResult).forEach((key) => {
              transformedObject[key] = [mergedResult[key]];
            });

            setDocumentsFileList((prev) => ({
              ...prev,
              secondary_profile: transformedObject
            }));
          } catch (error) {
            message(message(error));
          }
        }

        setCountry(data?.address?.country);

        return {
          ...data,
          birth_date: data.birth_date && dayjs(data.birth_date),
          secondary_profile: {
            ...data?.secondary_profile,
            birth_date:
              data?.secondary_profile?.birth_date &&
              dayjs(data?.secondary_profile?.birth_date)
          }
        };
      }
    },
    onUpdateResource: {
      /**
       * Function to set the request body when updating a resource.
       * @function
       * @param {Object} data - The data to be sent in the request body.
       * @returns {FormData} - The FormData object with the request body.
       */
      setBody: (data) => {
        const formData = new FormData();
        try {
          if (photo.length) {
            formData.append('photo', photo[0]?.file || photo[0]);
          }

          if (secondaryProfilePhoto.length) {
            formData.append(
              'secondary_profile_photo',
              secondaryProfilePhoto[0]?.file || secondaryProfilePhoto[0]
            );
          }

          if (Object.keys(documentsFileList).length) {
            Object.keys(documentsFileList).forEach((key) => {
              if (key === 'secondary_profile') {
                Object.keys(documentsFileList[key]).forEach((nestedKey) => {
                  const file = documentsFileList[key][nestedKey];

                  formData.append(nestedKey, ...file);
                });
              }
              if (key === 'other_documents') {
                documentsFileList[key].forEach((file) => {
                  formData.append('other_documents', ...file);
                });
              }
              if (key !== 'secondary_profile' && key !== 'other_documents') {
                formData.append(key, ...documentsFileList[key]);
              }
            });
          }
        } catch (e) {
          if (e.response) message(e.response.status);
        }

        setUser((prevData) => ({ ...prevData, ...data }));

        const newData = { ...data, removedFiles };
        formData.append('values', JSON.stringify(newData));
        return formData;
      }
    }
  };

  return { config };
};
