export const getAnounces = async ({
  dispatchAPI,
  user,
  setAnounces,
  message,
  setReservations,
  setIsLoading
}) => {
  try {
    const { data: pensionerData } = await dispatchAPI('GET', {
      url: `/pensioners?user=${user._id}`
    });

    const { data: reservationsData } = await dispatchAPI('GET', {
      url: `/reservations?pensioner=${pensionerData[0]._id}`
    });

    const { data } = await dispatchAPI('GET', {
      url: '/anounces/form?status=PUBLISHED&populate=supervision,supervision.address.petsitting_address'
    });

    setReservations(reservationsData);
    setAnounces(data);
    setIsLoading(false);
  } catch (e) {
    message(e);
  }
};
