import JSZip from 'jszip';

/**
 * Generate a PDF document by sending a request to the specified API endpoint.
 *
 * @async
 * @function generatePDF
 * @param {Function} message - Function to display a message or handle an error.
 * @param {Function} dispatchAPI - Function to dispatch an API request.
 * @param {Object} parameters - Parameters to include in the API request body.
 * @param {string} use_case - Specific use case for generating the PDF.
 * @param {Function} setIsLoading - Function to set the loading state.
 * @param {boolean} [printPdf=true] - Whether to print the PDF or return the Blob object.
 * @returns {Promise<Blob|void>} - Returns a Blob object if `printPdf` is false, otherwise void.
 * @throws Will throw an error if the API request fails.
 *
 * @example
 * generatePDF(
 *   (msg) => console.log(msg),
 *   async (method, options) => axios({ method, ...options }),
 *   { param1: 'value1', param2: 'value2' },
 *   'use_case_example',
 *   (isLoading) => console.log('Loading:', isLoading),
 *   true
 * ).then(blob => {
 *   if (blob) {
 *     // Handle the Blob object
 *   }
 * }).catch(error => {
 *   console.error(error);
 * });
 */
export const generatePDF = async (
  message,
  dispatchAPI,
  parameters,
  use_case,
  setIsLoading,
  printPdf = true
) => {
  setIsLoading(true);
  try {
    const { data } = await dispatchAPI('POST', {
      url: `template-documents/generatePDF`,
      body: {
        parameters,
        use_case
      },
      responseType: 'arraybuffer' // Set the responseType to receive the response as an ArrayBuffer
    });
    setIsLoading(false);
    // Create a Blob object from the ArrayBuffer

    const blob = new Blob([data], { type: 'application/pdf' });

    if (printPdf) {
      // Create a URL from the Blob object
      const url = URL.createObjectURL(blob);

      // Use the URL to create a download link or display the PDF in the browser
      // Example: Create a download link
      const link = document.createElement('a');
      link.href = url;
      link.download = 'document.pdf';
      link.click();

      // Clean up the URL object
      return URL.revokeObjectURL(url);
    }
    return blob;
  } catch (e) {
    if (e.response.status === 404) return message('TEMPLATE_NOT_FOUND');
    return message(e);
  } finally {
    setIsLoading(false);
  }
};

/**
 * Downloads multiple PDFs as a zip file.
 * @async
 * @function
 * @param {Blob[]} pdfBlobs - An array of PDF blobs to be included in the zip file.
 * @param {string} [zipFilename='documents.zip'] - The name of the zip file to be downloaded.
 * @returns {Promise<void>} - A promise that resolves when the zip file is downloaded.
 */
export const downloadPDFsAsZip = async (
  pdfBlobs,
  zipFilename = 'documents.zip'
) => {
  const zip = new JSZip();

  // Ajouter chaque PDF au fichier ZIP
  pdfBlobs.forEach((blob, index) => {
    zip.file(`document-${index + 1}.pdf`, blob);
  });

  // Générer le fichier ZIP et créer un lien de téléchargement
  const zipBlob = await zip.generateAsync({ type: 'blob' });
  const url = URL.createObjectURL(zipBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = zipFilename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Nettoyer l'URL créée
  URL.revokeObjectURL(url);
};
