export const useItems = ({ t, status }) => {
  const arrStatus = [
    'REQUEST',
    'WAITING',
    'PUBLISHED',
    'WAITING_BOOKING',
    'BOOKED',
    'IN_PROGRESS',
    'FINISHED',
    'ARCHIVED',
    'CANCEL',
    'INTERRUPT'
  ];
  let items = [];

  switch (status) {
    case 'CANCEL':
      items = [
        {
          title: t('supervisions.show.steps.REQUEST')
        },
        {
          title: t('supervisions.show.steps.WAITING')
        },
        {
          title: t('supervisions.show.steps.PUBLISHED')
        },
        {
          title: t('supervisions.show.steps.CANCEL')
        }
      ];
      break;
    case 'INTERRUPT':
      items = [
        {
          title: t('supervisions.show.steps.REQUEST')
        },
        {
          title: t('supervisions.show.steps.WAITING')
        },
        {
          title: t('supervisions.show.steps.PUBLISHED')
        },
        {
          title: t('supervisions.show.steps.WAITING_BOOKING')
        },
        {
          title: t('supervisions.show.steps.BOOKED')
        },
        {
          title: t('supervisions.show.steps.INTERRUPT')
        }
      ];
      break;
    default:
      items = [
        {
          title: t('supervisions.show.steps.REQUEST')
        },
        {
          title: t('supervisions.show.steps.WAITING')
        },
        {
          title: t('supervisions.show.steps.PUBLISHED')
        },
        {
          title: t('supervisions.show.steps.WAITING_BOOKING')
        },
        {
          title: t('supervisions.show.steps.BOOKED')
        },
        {
          title: t('supervisions.show.steps.IN_PROGRESS')
        },
        {
          title: t('supervisions.show.steps.FINISHED')
        },
        {
          title: t('supervisions.show.steps.ARCHIVED')
        }
      ];
      break;
  }

  return { items, arrStatus };
};
