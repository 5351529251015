import { Image, Modal, Spin } from 'antd';
import { deleteFile } from './deleteFile';
import { onPreview } from '../../../../utils/filesManagement/onPreview';
import { compressFile } from '../../../../utils/filesManagement/compressFile';
import { fileExtensionManage } from '../../../../utils/filesManagement/fileExtensionManage';
import { fileToBase64 } from '../../../../utils/filesManagement/fileToBase64';

/**
 * Generates properties for the Dragger component from the 'antd' library. This function
 * configures the behavior of file uploads, including file preview, removal, and the
 * upload process itself (which includes a file type check and a file conversion to base64).
 *
 * @function
 * @param {function} t - The translation function from 'react-i18next'.
 * @param {function} dispatchAPI - The function to dispatch API calls.
 * @param {function} message - The function to display messages.
 * @param {Array} fileList - An array representing the list of files.
 * @param {function} setFileList - The function to update the file list state.
 * @param {string} token - The token for the current user.
 * @returns {object} The properties for the Dragger component, configuring file upload behavior.
 *
 * @example
 * const draggerProperties = draggerProps(t, dispatchAPI, message, fileList, setFileList);
 */
export const draggerProps = (
  t,
  dispatchAPI,
  message,
  fileList,
  setFileList,
  token
) => ({
  listType: 'picture-card',
  onRemove: (file) => {
    Modal.confirm({
      title: t('files.modal.confirm_delete_title'),
      content: t('files.modal.confirm_delete_photo'),
      okText: t('files.modal.ok'),
      cancelText: t('files.modal.cancel'),
      okType: 'warning',
      onOk: () => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        if (file._id) {
          deleteFile(file._id, dispatchAPI, message);
        }
      }
    });
  },
  onPreview: (file) => onPreview(file),
  beforeUpload: async (file) => {
    const newFile = await compressFile(file);
    const fileExtension = newFile.name.split('.').pop();
    if (fileExtensionManage(fileExtension)) {
      setFileList([
        ...fileList,
        { file: newFile, url: await fileToBase64(file) }
      ]);
      return false;
    }
    message('Not a PNG or JPG file.');
    return false;
  },
  itemRender: (_, file) => (
    <Image
      src={`${process.env.REACT_APP_API_URL}/anounces/get-url/${file}/${token}`}
      placeholder={<Spin loading size="large" />}
    />
  ),
  fileList
});
