import { sendMail } from '../../../../components/Mailing/utils/sendMail';
import { processedTemplateContent } from '../../../../components/Mailing/utils/processedTemplateContent';
import { getSupervision } from './getSupervision';
import { createSupervisionInvoices } from '../../../supervisions/ShowSupervision/utils/createSupervisionInvoices';

export const patchSupervisionStatus = async ({
  status,
  pensioners,
  dispatchAPI,
  supervision,
  message,
  setSupervision,
  setIsLoading,
  id,
  t
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/supervisions/${id}/status`,
      body: status
    });

    const customers = [];
    customers.push({
      id: supervision.main_informations.owner.user._id,
      template_type: 'OWNER_SUPERVISION_VALIDATION'
    });

    supervision.pensioners.forEach((pensioner) => {
      customers.push({
        id: pensioner.user._id,
        template_type: 'PENSIONER_SUPERVISION_VALIDATION'
      });
    });

    const mailPromise = customers.map(async (customer) => {
      const { data } = await dispatchAPI('GET', {
        url: `templates-mail/type/${customer.template_type}`
      });

      const content = await processedTemplateContent({
        templateType: customer.template_type,
        templateContent: data[0].template_content,
        collectionData: supervision,
        dispatchAPI,
        t
      });

      const newValue = {
        ...data[0],
        recipients: customer.id
      };

      await sendMail({
        value: newValue,
        dispatchAPI,
        message,
        templateType: customer.template_type,
        setIsSubmitting: null,
        editorState: content,
        setIsModalOpen: null,
        documentType: null,
        recordID: null,
        filterID: null,
        filesList: [],
        t
      });
    });

    await Promise.all(mailPromise);

    await getSupervision({
      dispatchAPI,
      id,
      setSupervision,
      message,
      setIsLoading
    });

    const updatePromises = pensioners.map(async (pensioner) => {
      await dispatchAPI('PATCH', {
        url: `pensioners/${pensioner._id}`,
        body: { is_in_supervision: true }
      });
    });

    await Promise.all(updatePromises);

    createSupervisionInvoices({ supervision, dispatchAPI, message });
  } catch (e) {
    message(e);
  }
};
