import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Button, Form, Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { AnimalItem } from './AnimalItem';

const { Panel } = Collapse;

/**
 * `AnimalPanel` is a component that renders a list of animal panels within a collapse component,
 * allowing the user to add or remove animal panels dynamically.
 *
 * @component
 * @param {Object} props - The properties object
 * @param {Object} props.fileList - The list of files associated with the animal panel, shape of object is not strictly defined
 * @param {Function} props.setFileList - The function to update the file list
 * @param {Object|null} props.form - The form instance on which the animal panel is based, can be null
 *
 * @returns {JSX.Element} A rendered AnimalPanel component
 */
const AnimalPanel = ({ fileList, setFileList, form }) => {
  const { t } = useTranslation();

  const handleRemove = (remove, name) => {
    Modal.confirm({
      title: t('owners.form.confirm_delete_animal'),
      content: t('owners.form.confirm_delete_animal_content'),
      okText: t('files.modal.ok'),
      cancelText: t('files.modal.cancel'),
      okType: 'warning',
      onOk: () => {
        remove(name);
        setFileList((prevFileList) => {
          const newFileList = {};
          Object.keys(prevFileList).forEach((key, index) => {
            if (index > name) {
              newFileList[`animal${index - 1}`] = prevFileList[key];
            } else if (index < name) {
              newFileList[key] = prevFileList[key];
            }
          });
          return newFileList;
        });
      }
    });
  };

  return (
    <Form.List name={['animals']}>
      {(fields, { add, remove }) => (
        <Collapse style={{ width: '100%' }} bordered={false}>
          {fields.map((field) => (
            <Panel
              style={{ width: '100%' }}
              header={
                <Flex justify="space-between" align="center">
                  {`${t('owners.form.animal')} ${field.name + 1}`}
                  <Button
                    type="link"
                    onClick={() => handleRemove(remove, field.name)}
                  >
                    <CloseOutlined />
                  </Button>
                </Flex>
              }
              key={field.key}
            >
              <AnimalItem
                key={field.key}
                name={field.name}
                remove={remove}
                fileList={fileList}
                setFileList={setFileList}
                form={form}
              />
            </Panel>
          ))}
          <Button
            type="dashed"
            block
            onClick={() => {
              add();
            }}
            icon={<PlusOutlined />}
          >
            {t('owners.form.add_animal')}
          </Button>
        </Collapse>
      )}
    </Form.List>
  );
};

export default AnimalPanel;

AnimalPanel.propTypes = {
  fileList: PropTypes.shape({}),
  setFileList: PropTypes.func.isRequired,
  form: PropTypes.shape({})
};

AnimalPanel.defaultProps = {
  fileList: {},
  form: null
};
