import { Input, Select } from 'antd';

const { Option } = Select;

/**
 * Hook pour générer les champs d'information de réservation.
 * @function
 * @param {Object} options - Les options du hook.
 * @param {Array} options.filteredReservations - Les réservations filtrées.
 * @param {Function} options.setSelectedReservations - Fonction pour définir les réservations sélectionnées.
 * @returns {Object} Les champs d'information de réservation.
 * @property {Array} reservationFields - Liste des champs de réservation.
 * @property {Array} reservationFields[].name - Les noms des champs.
 * @property {string} reservationFields[].label - L'étiquette associée au champ.
 * @property {JSX.Element} reservationFields[].input - Le composant d'entrée associé au champ.
 * @property {Array} reservationFields[].rules - Les règles de validation associées au champ (le cas échéant).
 */

export const useReservationInfoFields = ({
  filteredReservations,
  setSelectedReservations
}) => {
  const reservationFields = [
    {
      name: ['supervision'],
      label: 'supervision.reference',
      input: <Input disabled />
    },
    {
      name: ['owner'],
      label: 'owner.title',
      input: <Input disabled />
    },
    {
      name: ['pensioners'],
      label: 'pensioner.reference',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="children"
          maxTagCount={2}
          onChange={(e, option) => setSelectedReservations(option)}
        >
          {filteredReservations.map((item) => (
            <Option
              value={item.pensioner._id}
              key={item.pensioner._id}
              reservation={item._id}
            >
              {item.pensioner.reference}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { reservationFields };
};
