import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { CreateUpdateDrawer } from './createUpdateDrawer/CreateUpdateDrawer';

export const ListSubscriptionPrice = () => {
  const [visible, setVisible] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [subscriptionPrices, setSubscriptionPrices] = useState({});
  const [purpose, setPurpose] = useState('create');
  const [subscriptionPriceToEdit, setSubscriptionPriceToEdit] = useState(null);

  const columns = useColumns({
    setPurpose,
    setSubscriptionPriceToEdit,
    setVisible
  });

  return (
    <>
      <ListResource
        resourceName="subscription-prices"
        columns={columns}
        resourceModelName="Subscription-price"
        withCreateButton={false}
        withPageHeader={false}
        withSearchBar={false}
        withArchiveButton
        withCustomCreateButton
        customCreateButtonAction={() => {
          setVisible(true);
          setPurpose('create');
        }}
        withUploadButton={false}
        customActionColumn
        forceRefresh={refreshData}
        setResourceData={setSubscriptionPrices}
      />
      <CreateUpdateDrawer
        setRefreshData={setRefreshData}
        refresh={refreshData}
        visible={visible}
        setVisible={setVisible}
        subscriptionPrices={subscriptionPrices}
        purpose={purpose}
        subscriptionPriceToEdit={subscriptionPriceToEdit}
      />
    </>
  );
};
