import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * `EquipmentInformations` is a function component that generates a collection of checkbox fields representing various equipment information.
 *
 * @component
 * @returns {Object} - An object containing a `equipmentInformationsFields` property which is an array of field configurations to be used in a form.
 */
const EquipmentInformations = () => {
  const { t } = useTranslation();

  const equipmentInformationsFields = [
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'equipment',
        'television'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.equipment.television'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'equipment',
        'cable'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.equipment.cable'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'equipment',
        'internet'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.equipment.internet'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    equipmentInformationsFields
  };
};

export default EquipmentInformations;
