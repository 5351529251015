import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { ListResource } from '../../components';
import { useColumns } from './columns';
import { ExtraFilters } from './utils/ExtraFilters';

/**
 * ListSupervisions functional component.
 *
 * @returns {JSX.Element} The ListSupervisions component.
 */
export const ListSupervisions = () => {
  const [dateFilter, setDateFilter] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  // Define columns using the useColumns hook.
  const columns = useColumns(setForceRefresh);

  /**
   * Sets the extra query string based on the date filter.
   *
   * @returns {string} The extra query string for filtering by date.
   */
  const setExtraQuery = () => {
    let extraQuery = '';

    if (dateFilter) {
      extraQuery = `main_informations.start_date>=${dayjs(dateFilter).format(
        'YYYY-MM-DD'
      )}&main_informations.start_date<=${dayjs(dateFilter)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')}`;
    }

    return extraQuery;
  };

  // Trigger the setExtraQuery function when the dateFilter changes.
  useEffect(() => {
    setExtraQuery();
  }, [dateFilter]);

  // Reset forceRefresh state when it changes.
  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <ListResource
      resourceName="supervisions"
      columns={columns}
      forceRefresh={forceRefresh}
      customActionColumn
      resourceModelName="Supervision"
      populate="main_informations.owner,address.petsitting_address,pensioners"
      withArchiveButton
      scroll={{ x: 1600 }}
      extraFilters={<ExtraFilters setDateFilter={setDateFilter} />}
      extraQuery={setExtraQuery()}
      backIcon={false}
    />
  );
};
