export const initialState = {
  custormers: [],
  customer: {},
  supervisions: [],
  supervision: {},
  selectedSupervisions: [],
  supervisionPrices: {
    ttc: 0,
    ht: 0
  },
  enums: {}
};

/**
 * Reducer function for the invoices context.
 * @function
 *
 * @param {Object} state - The current state of the invoices context.
 * @param {Object} action - The action object that contains the type and payload.
 * @returns {Object} - The new state after applying the action.
 */
export const invoicesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CUSTOMERS':
      return {
        ...state,
        customers: action.payload
      };
    case 'SET_CUSTOMER':
      return {
        ...state,
        customer: action.payload
      };
    case 'SET_SUPERVISIONS':
      return {
        ...state,
        supervisions: action.payload
      };
    case 'SET_SUPERVISION':
      return {
        ...state,
        supervision: action.payload
      };
    case 'SET_SELECTED_SUPERVISIONS':
      return {
        ...state,
        selectedSupervisions: action.payload
      };
    case 'SET_SUPERVISION_PRICES':
      return {
        ...state,
        supervisionPrices: action.payload
      };
    case 'SET_ENUMS':
      return {
        ...state,
        enums: action.payload
      };
    default:
      return state;
  }
};
