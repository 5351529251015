export const getReviews = async ({
  dispatchAPI,
  supervision,
  setReviews,
  user,
  setUserAlreadySendReview,
  form,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/ratings?supervision=${supervision?._id}&populate=author.id,receiver.id`
    });
    setReviews(data);

    if (data.length) {
      const existingRewiew = data.filter(
        (review) => review.author.role === user.role
      );
      if (existingRewiew.length) {
        setUserAlreadySendReview(true);
        form.setFieldsValue(existingRewiew[0]);
      }
    }
  } catch (e) {
    message(e);
  }
};
