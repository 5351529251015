import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';

export const getAnimalsPictures = async ({
  animalsRef,
  setAnimalsFileList,
  dispatchAPI,
  message
}) => {
  setAnimalsFileList([]);
  if (animalsRef.current.length) {
    await animalsRef.current.map(async (animal) => {
      if (animal.animal_photo?.length) {
        try {
          const animalPhotosPromises = (animal.animal_photo || []).map(
            (photo) => createFileFromJSON(photo, dispatchAPI, message)
          );

          const results = await Promise.all(animalPhotosPromises);
          setAnimalsFileList((previousResult) =>
            previousResult.concat(results)
          );
        } catch (error) {
          message(message(error));
        }
      }
    });
  }
};
