import PropTypes from 'prop-types';
import { Typography, Row, Col, Card, Avatar, List, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { personnalListContent } from './personnalListContent';
import { sameListContent } from './sameListContent';
import { useAuthContext } from '../../../../contexts/AuthContext';

const { Title, Text } = Typography;
const { Meta } = Card;

/**
 * Renders the pensioners' profiles component.
 * @component
 * @param {Object} supervision - The supervision object.
 * @returns {JSX.Element} The pensioners' profiles component.
 */
export const PensionersProfiles = ({ supervision }) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();

  const { pensioners } = supervision || {};

  return (
    <Flex vertical>
      <Typography.Title className="underlined" level={5}>
        {t('pensioners.title')}
      </Typography.Title>
      {(pensioners || []).map((pensioner) => {
        const photo = pensioner?.documents?.find(
          (doc) => doc.type === 'identification_photo'
        );

        return (
          <Row gutter={[32, 24]}>
            <Col xs={24} xl={16}>
              <Row gutter={[32, 32]}>
                <Col xs={24} md={12} lg={10} xl={12} xxl={10}>
                  <Card className="profile-name-card">
                    <Title level={4}>{`${pensioner?.first_name || ''} ${
                      pensioner?.last_name || ''
                    }`}</Title>
                    <Meta
                      avatar={
                        <Avatar
                          src={
                            photo
                              ? `${process.env.REACT_APP_API_URL}/owners/get-url/${photo?._id}/${token}`
                              : null
                          }
                          size={120}
                        />
                      }
                    />
                  </Card>
                </Col>
                <Col xs={24} md={12} lg={12} xl={12} xxl={14}>
                  <List
                    itemLayout="vertical"
                    dataSource={personnalListContent(pensioner, t, supervision)}
                    renderItem={(item) => (
                      <List.Item>
                        <Text>{item.label} : </Text>
                        <Text strong>{item.content}</Text>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            {Object.keys(pensioner?.secondary_profile).length > 3 && (
              <Col xs={24} xl={16}>
                <Row gutter={[32, 32]}>
                  <Col xs={24} md={12} lg={10} xl={12} xxl={10}>
                    <Card className="profile-name-card">
                      <Title level={4}>{`${
                        pensioner?.secondary_profile?.first_name || ''
                      } ${
                        pensioner?.secondary_profile?.last_name || ''
                      }`}</Title>
                      <Meta
                        avatar={
                          <Avatar
                            src={pensioner?.secondary_profile?.photo?.url}
                            size={120}
                          />
                        }
                      />
                    </Card>
                  </Col>
                  <Col xs={24} md={12} lg={12} xl={12} xxl={14}>
                    <List
                      itemLayout="vertical"
                      dataSource={personnalListContent(
                        pensioner?.secondary_profile,
                        t
                      )}
                      renderItem={(item) => (
                        <List.Item>
                          <Text>{item.label} : </Text>
                          <Text strong>{item.content}</Text>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={24} xl={8}>
              <List
                itemLayout="vertical"
                dataSource={sameListContent(pensioner, t, supervision)}
                renderItem={(item) => (
                  <List.Item>
                    <Text>{item.label} : </Text>
                    <Text strong>{item.content}</Text>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        );
      })}
    </Flex>
  );
};

PensionersProfiles.propTypes = {
  supervision: PropTypes.shape({})
};

PensionersProfiles.defaultProps = {
  supervision: {}
};
