import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  outOfNavRoutes,
  routes
} from '../utils/constants/ownerPensionerRoutes';
import { Login } from '../routes/login';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { ProfileRouter } from '../routes/usersShows/profile/ProfileRouter';
import PostPwdForm from '../routes/login/PostPwdForm';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { RequireAuth } from './RequireAuth';
import { OwnerPensionerDashboard } from '../routes/dashboards/OwnerPensionerDashboard';
import { SupervisionsRouter } from '../routes/usersShows/supervisions/SupervisionsRouter';
import { ViewAdsRouter } from '../routes/usersShows/view-ads/ViewAdsRouter';
import { MySubscriptionRouter } from '../routes/usersShows/subscription/MySubscriptionRouter';
import { DocumentsRouter } from '../routes/usersShows/documents/DocumentsRouter';
import { ShowSettings } from '../routes/usersShows/settings/ShowSettings';

export const PensionerRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />*
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<ShowSettings />} />
          <Route
            path={routes.DASHBOARD}
            element={<OwnerPensionerDashboard />}
          />
          <Route
            path={`${routes.SUPERVISIONS}/*`}
            element={<SupervisionsRouter />}
          />
          <Route path={`${routes.VIEW_ADS}/*`} element={<ViewAdsRouter />} />
          <Route
            path={`${routes.SUBSCRIPTION}/*`}
            element={<MySubscriptionRouter />}
          />
          <Route path={`${routes.DOCUMENTS}/*`} element={<DocumentsRouter />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
