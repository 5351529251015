import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Datatable } from '../../../../../components';
import { useColumns } from '../listContent/priceManagementColumns';
import { deletePrices } from '../../../utils/deletePrices';

const PriceManagementInfoCards = ({
  t,
  isLoading,
  supervisionPrice,
  refreshData,
  setRefreshData,
  setVisible,
  setPurpose,
  setPriceToEdit
}) => {
  const { columns } = useColumns({
    deletePrices,
    supervisionPrice,
    refreshData,
    setRefreshData,
    setVisible,
    setPurpose,
    setPriceToEdit
  });
  const navigate = useNavigate();

  return (
    <Card
      title={t('supervisionprices.show.price_management_info')}
      className="form-card"
    >
      <Row gutter={[0, 24]}>
        <Col xs={24} xxl={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Datatable
              navigate={navigate}
              columns={columns}
              resourceName={`supervisionprices/prices/${supervisionPrice?._id}`}
              customActionColumn
              forceRefresh={refreshData}
              onDoubleClickAction={false}
            />
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

export default PriceManagementInfoCards;

PriceManagementInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  supervisionPrice: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  setPurpose: PropTypes.func.isRequired,
  setPriceToEdit: PropTypes.func.isRequired
};
