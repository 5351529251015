import dayjs from 'dayjs';

/**
 * Retrieves resources for editing a mail sender record.
 *
 * @function
 * @param {Object} params - The parameters for retrieving resources.
 * @param {function} params.dispatchAPI - A function for dispatching API requests.
 * @param {string} params.type - The type of mail sender record.
 * @param {string} params.id - The ID of the mail sender record.
 * @param {function} params.setSelectedRowKeys - A function to set selected row keys.
 * @param {object} params.form - The form object used for setting field values.
 * @param {function} params.message - A function for displaying messages.
 */

export const getResources = async ({
  dispatchAPI,
  type,
  id,
  setSelectedRowKeys,
  form,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/mail-sender/show/${type}/${id}?edit_data=${true}`
    });
    setSelectedRowKeys(data?.ids);
    form.setFieldValue(
      'previsional_sending_date',
      dayjs(data?.previsional_sending_date)
    );
  } catch (e) {
    message(e);
  }
};
