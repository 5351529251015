import { Card, Col, DatePicker } from 'antd';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import { useMonthlySupervisionsNumbers } from '../../../utils/setDataChart/monthlySupervisionsNumbers';
import { monthlySupervisionsChartConfig } from '../../../utils/setChartConfig/monthlySupervisionsNumbersConfig';

/**
 * Component to display a monthly supervisions chart.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.t - Translation function for localization.
 * @param {Array} props.supervisions - Array of supervisions data.
 * @param {function} props.setSupDate - Function to set supervisions date.
 *
 * @returns {React.ReactNode} - The rendered component.
 */
export const MonthlySupervisionsChart = ({ t, supervisions, setSupDate }) => {
  const [items, setItems] = useState({});

  // call hook to get the data
  const { setMonthlySupervisionsNumbers, monthlySupervisionsChartData } =
    useMonthlySupervisionsNumbers({
      supervisions,
      items,
      setItems,
      t
    });

  // set the data when the component is mounted
  useEffect(() => {
    if (supervisions) setMonthlySupervisionsNumbers();
  }, [supervisions]);

  return (
    <Col xs={24}>
      <DatePicker
        picker="year"
        onChange={(date) => setSupDate(date)}
        style={{ marginBottom: '20px' }}
      />
      <Card
        title={t('dashboard.tabs.supervisions.monthly')}
        className="dashboard-card"
      >
        <Column
          {...monthlySupervisionsChartConfig({
            monthlySupervisionsChartData,
            t
          })}
        />
      </Card>
    </Col>
  );
};

MonthlySupervisionsChart.propTypes = {
  t: PropTypes.func.isRequired,
  supervisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSupDate: PropTypes.func.isRequired
};
