import {
  Checkbox,
  Select,
  DatePicker,
  Tag,
  Form,
  Input,
  InputNumber
} from 'antd';
import { useCountryArray } from '../../../utils/countryArray';
import { useDateManagement } from '../utils/dateManagement';
import { translateAnimalsTypes } from '../utils/translateAnimalsTypes';

const { Option } = Select;

/**
 * Custom hook for generating main information fields.
 *
 * @param {Object} options - Options for generating main information fields.
 * @param {Object} options.enums - Enums for dropdown options.
 * @param {Function} options.t - Translation function.
 * @param {boolean} options.isFieldsLoading - Loading state of fields.
 * @param {Function} options.setOwnerId - Function to set owner id.
 * @param {Array} options.owners - List of owners.
 * @param {Function} options.setStartDate - Function to set start date.
 * @param {Function} options.setEndDate - Function to set end date.
 * @param {Function} options.setAnimals - Function to set animals.
 * @param {Object} options.owner - Selected owner object.
 * @returns {Object} An object containing the main information fields.
 */

export const useMainInfoFields = ({
  enums,
  t,
  isFieldsLoading,
  setOwnerId,
  owners,
  startDate,
  endDate,
  animalsRef,
  owner,
  userRole
}) => {
  const countries = useCountryArray();

  const startDateRef = startDate;
  const endDateRef = endDate;
  const animals = animalsRef;

  const { dateChangeAction, disabledDateAction, cellRenderer } =
    useDateManagement({
      startDate: startDateRef,
      endDate: endDateRef
    });

  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const handleChangeAnimals = (values, options) => {
    const selectedAnimalObjects =
      values.length > 0 ? options.map((option) => option.animal) : [];

    animals.current = selectedAnimalObjects;
  };

  const informationsFields = [
    {
      name: ['main_informations', 'owner'],
      label: 'owner_reference',
      rules: userRole === 'users:ADMIN' && [{ required: true }],
      input:
        userRole === 'users:OWNER' && Object.keys(owner).length ? (
          <Select disabled>
            <Option value={owner._id} key={owner._id}>
              {owner.reference}
            </Option>
          </Select>
        ) : (
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            onChange={(e, option) => setOwnerId(option.owner._id)}
            loading={isFieldsLoading}
          >
            {owners &&
              owners.map((item) => (
                <Option value={item._id} key={item._id} owner={item}>
                  {item.reference}
                </Option>
              ))}
          </Select>
        )
    },
    {
      name: ['main_informations', 'owner_name'],
      label: 'owner.name',
      input:
        userRole === 'users:OWNER' && Object.keys(owner).length ? (
          <Select loading={isFieldsLoading} disabled>
            <Option value={owner._id} key={owner._id}>
              {owner?.first_name} {owner?.last_name}
            </Option>
          </Select>
        ) : (
          <Select
            loading={isFieldsLoading}
            showSearch
            filterOption={filterOption}
            onChange={(e, option) => setOwnerId(option.owner._id)}
          >
            {owners &&
              owners.map((item) => (
                <Option value={item._id} key={item._id} owner={item}>
                  {item?.first_name} {item?.last_name}
                </Option>
              ))}
          </Select>
        )
    },
    {
      name: ['main_informations', 'start_date'],
      input: (
        <DatePicker
          onChange={dateChangeAction.startDate}
          disabledDate={disabledDateAction.startDate}
          cellRender={(current) => cellRenderer(current, 'startDate')}
        />
      ),
      rules: [{ required: true }]
    },
    {
      name: ['main_informations', 'end_date'],
      input: (
        <DatePicker
          onChange={dateChangeAction.endDate}
          disabledDate={disabledDateAction.endDate}
          cellRender={(current) => cellRenderer(current, 'endDate')}
        />
      ),
      rules: [{ required: true }]
    },
    {
      name: ['main_informations', 'animals'],
      input: (
        <Select
          loading={isFieldsLoading}
          showSearch
          filterOption={filterOption}
          onChange={handleChangeAnimals}
          mode="multiple"
        >
          {owner?.animals?.map((animal) => (
            <Option value={animal._id} key={animal._id} animal={animal}>
              {animal.name || translateAnimalsTypes(t)[animal.type]}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['main_informations', 'veterinarian', 'name']
    },
    {
      name: ['main_informations', 'veterinarian', 'address', 'number'],
      input: <InputNumber />
    },
    {
      name: ['main_informations', 'veterinarian', 'address', 'street']
    },
    {
      name: ['main_informations', 'veterinarian', 'address', 'city']
    },
    {
      name: ['main_informations', 'veterinarian', 'address', 'postal_code']
    },
    {
      name: ['main_informations', 'veterinarian', 'address', 'country'],
      input: (
        <Select showSearch filterOption={filterOption}>
          {(countries || []).map((country) => (
            <Option key={country} value={country}>
              <Tag>{country}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['main_informations', 'veterinarian', 'phone_number'],
      label: 'main_informations.veterinarian.phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={[
              'main_informations',
              'veterinarian',
              'phone_number',
              'country_code'
            ]}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            name={[
              'main_informations',
              'veterinarian',
              'phone_number',
              'number'
            ]}
          >
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['main_informations', 'accept'],
      input: (
        <Select
          loading={isFieldsLoading}
          mode="multiple"
          showSearch
          filterOption={filterOption}
        >
          {enums?.pensioner_type?.map((type) => (
            <Option value={type} key={type}>
              {t(`supervisions.form.tags.${type}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['main_informations', 'accept_smoker'],
      input: <Checkbox />,
      valuePropName: 'checked'
    }
  ];

  return { informationsFields };
};
