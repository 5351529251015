import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Form } from 'antd';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { ExtraFilters } from '../utils/ExtraFilters';
import { MailingModal } from '../../../components/Mailing/MailingModal';
import { useFormatter } from '../../../utils/formatter';
import { useInvoicesContext } from '../../../contexts/InvoicesContext/InvoicesContext';

/**
 * Composant de liste des factures client.
 * @component
 * @returns {JSX.Element} Composant React.
 */
export const ListCustomerInvoices = () => {
  const [form] = Form.useForm();
  const { formattedData } = useFormatter();

  const [dateFilter, setDateFilter] = useState(null);
  const [types, setTypes] = useState('');
  const [status, setStatus] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [customerInvoice, setCustomerInvoice] = useState({});
  const [recipient, setRecipient] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [recordID, setRecordID] = useState('');

  const { enums, customers, isFieldsLoading } = useInvoicesContext();

  const { columns, headers } = useColumns({
    enums,
    setRefreshData,
    refreshData,
    setRecipient,
    setIsReminderModalOpen,
    setCustomerInvoice,
    setDocumentType,
    setRecordID
  });

  useEffect(() => {
    if (refreshData) setRefreshData(false);
  }, [refreshData]);

  const setExtraQuery = () => {
    let extraQuery = '';
    if (types) {
      extraQuery = `type=${types}`;
    }
    if (status) {
      extraQuery = `${extraQuery ? `${extraQuery}&` : ''}status=${status}`;
    }
    if (dateFilter) {
      extraQuery = `${extraQuery ? `${extraQuery}&` : ''}due_date>=${dayjs(
        dateFilter
      ).format('YYYY-MM-DD')}&due_date<=${dayjs(dateFilter)
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss')}`;
    }

    return extraQuery;
  };

  useEffect(() => {
    setExtraQuery();
  }, [dateFilter, types, status]);

  return (
    <>
      <ListResource
        resourceName="customerinvoices"
        columns={columns}
        headers={headers}
        resourceModelName="Customerinvoice"
        populate="customer,subscription,subscription.pensioner,supervision,supervision.main_informations.owner"
        extraQuery={setExtraQuery()}
        customActionColumn
        forceRefresh={refreshData}
        withArchiveButton
        formatter={formattedData}
        withoutImportButton
        extraFilters={
          <ExtraFilters
            setDateFilter={setDateFilter}
            setTypes={setTypes}
            setStatus={setStatus}
            enums={enums}
            isFieldLoading={isFieldsLoading}
            invoiceType="customer"
          />
        }
        withoutBreadCrumb
      />
      {isReminderModalOpen && (
        <MailingModal
          isModalOpen={isReminderModalOpen}
          setIsModalOpen={setIsReminderModalOpen}
          recipients={customers}
          recipient={recipient}
          isFieldsLoading={isFieldsLoading}
          customFormInstance={form}
          templateType="UNPAID_INVOICE_REMINDER"
          collectionData={customerInvoice}
          collection="customerinvoices"
          documentType={documentType}
          recordID={recordID}
        />
      )}
    </>
  );
};
