// Main nav menuItems
export const routes = {
  DASHBOARD: '/',
  OWNERS: '/owners',
  PENSIONERS: '/pensioners',
  SUPERVISIONS: '/supervisions',
  RESERVATIONS: '/reservations',
  SUPERVISION_PRICES: '/supervision-prices',
  INVOICES: '/invoices',
  MAIL_SENDER: '/mail-sender',
  USERS: '/users',
  TEMPLATE_DOCS: '/template-docs'
};

// Main nav subMenuItems
export const subRoutes = {
  INVOICES: {
    CUSTOMERS: '/customers',
    INTERN: '/intern'
  }
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {};
