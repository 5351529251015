import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { SupervisionInfoCard } from './cards/SupervisionInfoCard';
import { OwnerInfoCard } from './cards/OwnerInfoCard';
import { AnimalCard } from './cards/AnimalCard';
import { VeterinarianCard } from './cards/VeterinarianCard';
import { CommentCard } from './cards/CommentCard';
import { DocumentCard } from './cards/DocumentCard';
import { HistoryCard } from './cards/HistoryCard';
import PetsittingaddressInfoCards from './cards/PetsittingAddressInfoCards';
import { PensionerCard } from './cards/PensionerCard';
import { RatingsCard } from './cards/RatingsCard';
import { SupervisionAnnouncementCard } from './cards/SupervisionAnnouncementCard';
import { InvoicesCard } from './cards/InvoicesCard';
import { ReservationsCard } from './cards/ReservationsCard';
import { CancelCard } from './cards/CancelCard';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useSupervisionContext } from '../../../../contexts/SupervisionContext/SupervisionContext';

/**
 * Renders a set of cards displaying details of a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Indicates whether the data is currently being loaded.
 * @param {Function} props.refreshData - Function to refresh the data.
 * @param {Function} props.setRefreshData - Function to set the refresh data flag.
 * @param {Function} props.setIsLoading - Function to set the loading state.
 * @param {Array} props.anounceFileList - List of announcement files.
 * @param {string} props.id - The ID of the supervision.
 * @param {Function} props.setAnounceModalVisible - Function to set the visibility of the announcement modal.
 * @param {Function} props.setCustomerInvoices - Function to set the customer invoices.
 * @returns {JSX.Element} The rendered component.
 */
export const DetailsCards = ({
  isLoading,
  refreshData,
  setRefreshData,
  setIsLoading,
  anounceFileList,
  id,
  setAnounceModalVisible,
  setCustomerInvoices
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [veterinarian, setVeterinarian] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const { state } = useSupervisionContext();
  const { supervision, anounce } = state;
  const {
    main_informations: { owner, animals, old_data_animals = {} } = {},
    pensioners,
    comment = ''
  } = supervision;

  const getVeterianian = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/veterinarians?supervision=${supervision._id}`
      });
      setVeterinarian(data[0]);
    } catch (error) {
      message(error);
    }
  };

  useEffect(() => {
    if (Object.keys(supervision).length) {
      getVeterianian();
    }
  }, [supervision]);

  const pensionerCount = pensioners?.length;

  const getColWidth = (count) => {
    if (count === 1) {
      return 24;
    }
    return 12;
  };

  return (
    <Row gutter={[12, 12]} style={{ marginTop: 16 }}>
      {['CANCEL', 'INTERRUPT'].includes(supervision?.status) ? (
        <Col span={24}>
          <CancelCard supervision={supervision} t={t} />
        </Col>
      ) : null}
      <Col xs={24} xxl={12}>
        <SupervisionInfoCard supervision={supervision} isLoading={isLoading} />
      </Col>
      <Col xs={24} xxl={12}>
        <OwnerInfoCard owner={owner} isLoading={isLoading} />
      </Col>
      <Col xs={24} xxl={12}>
        <PetsittingaddressInfoCards
          t={t}
          isLoading={isLoading}
          supervision={supervision}
        />
      </Col>
      <Col xs={24} xxl={12}>
        <Row gutter={[12, 12]}>
          {(animals && animals.length) || old_data_animals ? (
            <Col span={24}>
              <AnimalCard
                animals={animals}
                isLoading={isLoading}
                old_data_animals={old_data_animals}
              />
            </Col>
          ) : null}
          <Col span={24}>
            <VeterinarianCard
              veterinarian={veterinarian}
              t={t}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Col>
      {pensioners && pensioners.length
        ? pensioners.map((pensioner) => (
            <Col xs={24} xxl={getColWidth(pensionerCount)}>
              <PensionerCard
                t={t}
                pensioner={pensioner}
                supervision={supervision}
                refreshData={refreshData}
                setRefreshData={setRefreshData}
                pensionerCount={pensionerCount}
                setOpenModal={setOpenModal}
                openModal={openModal}
              />
            </Col>
          ))
        : null}
      {['FINISHED'].includes(supervision?.status) ? (
        <Col span={24}>
          <RatingsCard supervision={supervision} isLoading={isLoading} />
        </Col>
      ) : null}
      <Col xs={24} xxl={8}>
        <CommentCard comment={comment} isLoading={isLoading} />
      </Col>
      <Col xs={24} xxl={16}>
        <DocumentCard
          t={t}
          supervision={supervision}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </Col>
      {!['WAITING'].includes(supervision?.status) ? (
        <Col span={24}>
          <SupervisionAnnouncementCard
            anounce={anounce}
            anounceFileList={anounceFileList}
            setAnounceModalVisible={setAnounceModalVisible}
            supervision={supervision}
            isLoading={isLoading}
          />
        </Col>
      ) : null}
      {['PUBLISHED', 'WAITING_BOOKING', 'BOOKED', 'IN_PROGRESS'].includes(
        supervision?.status
      ) ? (
        <Col span={24}>
          <ReservationsCard
            id={id}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            supervision={supervision}
          />
        </Col>
      ) : null}
      <Col xs={24} xxl={8}>
        <HistoryCard supervision={supervision} isLoading={isLoading} />
      </Col>
      {['BOOKED', 'IN_PROGRESS', 'FINISHED', 'ARCHIVED'].includes(
        supervision?.status
      ) ? (
        <Col xs={24} xxl={16}>
          <InvoicesCard
            supervisionId={id}
            setCustomerInvoices={setCustomerInvoices}
            isLoading={isLoading}
          />
        </Col>
      ) : null}
    </Row>
  );
};

DetailsCards.propTypes = {
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    main_informations: PropTypes.shape({
      veterinarian: PropTypes.shape({})
    }),
    comment: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  owner: PropTypes.shape({}).isRequired,
  address: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  anounce: PropTypes.shape({}).isRequired,
  anounceFileList: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.string.isRequired,
  setAnounceModalVisible: PropTypes.func.isRequired,
  setCustomerInvoices: PropTypes.func.isRequired
};

DetailsCards.defaultProps = {
  anounceFileList: []
};
