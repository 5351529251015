import { Form, Input, Select, Tag } from 'antd';
import { useCountryArray } from '../../../../utils/countryArray';

const { Option } = Select;

/**
 * `useFields` is a custom hook that handles the management of fields in a form. It maintains the state of various fields and fetches necessary enums from the API.
 *
 * @returns {Object} - An object that contains a fields array with the structure and state of each form field.
 */
export const useFields = () => {
  const countries = useCountryArray();

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const fields = [
    {
      label: 'name',
      name: ['groomer', 'name']
    },
    {
      label: 'address.number',
      name: ['groomer', 'address', 'number'],
      input: <Input type="number" />
    },
    {
      label: 'address.street',
      name: ['groomer', 'address', 'street']
    },
    {
      label: 'address.city',
      name: ['groomer', 'address', 'city']
    },
    {
      label: 'address.postal_code',
      name: ['groomer', 'address', 'postal_code']
    },
    {
      label: 'address.country',
      name: ['groomer', 'address', 'country'],
      input: (
        <Select showSearch filterOption={filterOption}>
          {(countries || []).map((country) => (
            <Option key={country} value={country}>
              <Tag>{country}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['groomer', 'phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['groomer', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['groomer', 'phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    }
  ];

  return { fields };
};
