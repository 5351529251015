import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';

/**
 * Handles form submission for creating or editing a mail sender record.
 *
 * @function
 * @param {Object} params - The parameters for handling form submission.
 * @param {Object} params.form - The form object used for getting field values.
 * @param {string} params.sendingType - The type of sending (e.g., 'EMAIL', 'POST').
 * @param {string} params.purpose - The purpose of the submission (e.g., 'create', 'edit').
 * @param {function} params.dispatchAPI - A function for dispatching API requests.
 * @param {string} params.id - The ID of the mail sender record (for editing purpose).
 * @param {string} params.type - The type of mail sender record.
 * @param {Array} params.selectedRowKeys - An array of selected row keys.
 * @param {Object} params.user - The user object.
 * @param {function} params.navigate - A function for navigating to a different route.
 * @param {function} params.message - A function for displaying messages.
 * @param {function} params.t - A function for translating text.
 * @returns {null} - Returns null.
 */

export const handleSubmit = async ({
  form,
  sendingType,
  purpose,
  dispatchAPI,
  id,
  type,
  selectedRowKeys,
  user,
  navigate,
  message,
  t
}) => {
  try {
    const previsionalSendingDate = form.getFieldValue(
      'previsional_sending_date'
    );
    if (!previsionalSendingDate && sendingType === 'EMAIL') {
      return antdMessage.warning(t('mail-sender.messages.select_date'));
    }

    let resource;
    if (purpose === 'edit') {
      resource = await dispatchAPI('PATCH', {
        url: `/mail-sender/form/${id}`,
        body: {
          document_type: type,
          mail_number: selectedRowKeys.length,
          ids: selectedRowKeys,
          previsional_sending_date: previsionalSendingDate
            ? dayjs(previsionalSendingDate)
            : null
        }
      });
    } else {
      resource = await dispatchAPI('POST', {
        url: `/mail-sender`,
        body: {
          creation_date: dayjs(),
          created_by: user._id,
          document_type: type,
          mail_number: selectedRowKeys.length,
          ids: selectedRowKeys,
          type: sendingType,
          previsional_sending_date: previsionalSendingDate
            ? dayjs(previsionalSendingDate)
            : null
        }
      });
    }

    if (resource.data) {
      navigate(
        `/mail-sender/show/${type}/${resource.data._id}/${resource.data.status}/${sendingType}`
      );
    }
  } catch (e) {
    message(e);
  }

  return null;
};
