import { Tag, Tooltip, Popconfirm, Divider } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  WarningOutlined,
  ContainerOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../contexts/AuthContext';
import { InvoiceStatus } from '../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useListResourceArchiving } from '../../../utils/listResourceArchiving';

/**
 * Hook pour obtenir les colonnes à afficher dans la liste des factures internes.
 * @function
 * @returns {Array} Les colonnes à afficher.
 */

export const useColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();

  const { deleteResource, restoreResource } = useListResourceArchiving(
    setForceRefresh,
    'interninvoices/archiving'
  );

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/interninvoices/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const columns = [
    {
      title: t('interninvoices.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('interninvoices.form.emitter'),
      key: 'emitter',
      dataIndex: 'emitter',
      sorter: (a, b) => a.emitter.length - b.emitter.length
    },
    {
      title: t('interninvoices.form.wording'),
      key: 'wording',
      dataIndex: 'wording',
      sorter: true
    },
    {
      title: t('interninvoices.form.emission_date'),
      key: 'emission_date',
      dataIndex: 'emission_date',
      sorter: true,
      render: (emission_date) =>
        emission_date && moment(emission_date).format('DD/MM/YYYY')
    },
    {
      title: t('interninvoices.form.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      render: (total_ttc) => (total_ttc ? `${total_ttc} €` : ''),
      sorter: true
    },
    {
      title: t('interninvoices.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={InvoiceStatus[status]}>
            {t(`interninvoices.tags.${status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`interninvoices.tags.${r}`),
        value: r
      }))
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/invoices/intern/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {enums.status && ['UNPAID', 'WAITING'].includes(record.status) && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/invoices/intern/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
            </>
          )}
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.archive.hover')}
                  placement="left"
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                    type="delete"
                  />
                </Tooltip>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.unarchived.hover')}
                  placement="left"
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];

  const headers = [
    {
      label: 'reference',
      key: 'reference'
    },
    {
      label: 'emitter',
      key: 'emitter'
    },
    {
      label: 'wording',
      key: 'wording'
    },
    {
      label: 'emission_date',
      key: 'emission_date'
    },
    {
      label: 'total_ttc',
      key: 'total_ttc'
    },
    {
      label: 'status',
      key: 'interninvoice_status'
    }
  ];

  return { columns, headers };
};
