import { Form, Input, Select, Tag, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { TextArea } = Input;

export const extraInformations = ({
  isFieldsLoading,
  enums,
  isChildren,
  isGrandChildren,
  setIsChildren,
  setIsGrandChildren
}) => {
  const { t } = useTranslation();

  const handleRadioChange = ({ target: { value } }, setFunction) => {
    if (setFunction) setFunction(value);
  };

  const getRadioGroup = (setFunction) => (
    <Radio.Group
      onChange={(e) => handleRadioChange(e, setFunction)}
      options={[
        { label: t('pensioners.form.yes'), value: true },
        { label: t('pensioners.form.no'), value: false }
      ]}
    />
  );

  const extraInformationsFields = [
    {
      name: ['means_of_transport'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple">
          {(enums?.means_of_transport || []).map((means_of_transport) => (
            <Option key={means_of_transport} value={means_of_transport}>
              <Tag>{t(`pensioners.tags.${means_of_transport}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['disponibility']
    },
    {
      name: ['city_to_discover'],
      input: <TextArea />
    },
    {
      name: ['usage_of_gps'],
      input: getRadioGroup()
    },
    {
      label: 'urgency.name',
      name: ['urgency', 'name']
    },
    {
      name: ['urgency', 'phone_number'],
      label: 'urgency.phone_number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['urgency', 'phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: 70 }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['urgency', 'phone_number', 'number']}>
            <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
          </Form.Item>
        </Input.Group>
      )
    },

    {
      name: ['is_children'],
      input: getRadioGroup(setIsChildren)
    },
    {
      hidden: !isChildren,
      name: ['children_place']
    },
    {
      name: ['is_grand_children'],
      input: getRadioGroup(setIsGrandChildren)
    },
    {
      hidden: !isGrandChildren,
      name: ['grand_children_place']
    },
    {
      name: ['how_did_you_know_us'],
      input: <TextArea />
    },
    {
      name: ['comment'],
      input: <TextArea />
    }
  ];

  return { extraInformationsFields };
};
