import { Input, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

export const FormExtra = ({
  personnalInformationsFields,
  additionalInformationsFields,
  extraInformationsFields
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();

  return (
    <Input.Group>
      <Typography.Title level={5} className="underlined">
        {t('pensioners.form.sub_title.personal_informations')}
      </Typography.Title>
      {personnalInformationsFields.map((field) =>
        generateFields('pensioners', field)
      )}

      <Typography.Title level={5} className="underlined">
        {t('pensioners.form.sub_title.additional_informations')}
      </Typography.Title>
      {additionalInformationsFields.map((field) =>
        generateFields('pensioners', field)
      )}

      <Typography.Title level={5} className="underlined">
        {t('pensioners.form.sub_title.extra_informations')}
      </Typography.Title>
      {extraInformationsFields.map((field) =>
        generateFields('pensioners', field)
      )}
    </Input.Group>
  );
};

FormExtra.propTypes = {
  personnalInformationsFields: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  additionalInformationsFields: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  extraInformationsFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
