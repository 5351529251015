import React from 'react';
import { Input } from 'antd';
import { reviewDrawerQuestions } from '../../../routes/usersShows/supervisions/utils/reviewDrawerQuestions';
import { getRadioGroup } from '../utils/getRadioGroup';

const { TextArea } = Input;

/**
 * Custom hook that returns an array of fields for the second step of a review form.
 * @hook
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.questionnaireType - The type of questionnaire.
 * @param {string} options.userRole - The role of the user.
 * @param {boolean} options.showReviewDrawer - Flag indicating whether the review drawer is visible.
 * @returns {Object} - An object containing the second step fields.
 */
export const useSecondStepFields = ({
  questionnaireType,
  userRole,
  showReviewDrawer
}) => {
  const secondStepFields = [
    {
      name: ['animals_satisfaction', 'review'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole]
          .animalsSatisfaction || '-',
      rules: [{ required: true, message: 'supervision' }],
      input: getRadioGroup()
    },
    {
      name: ['animals_satisfaction', 'comment'],
      label: 'comment',
      dependencies: ['animals_satisfaction'],
      input: <TextArea disabled={showReviewDrawer} />
    },
    {
      name: ['house_satisfaction', 'review'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole]
          .houseSatisfaction || '-',
      rules: [{ required: true, message: 'supervision' }],
      input: getRadioGroup()
    },
    {
      name: ['house_satisfaction', 'comment'],
      label: 'comment',
      dependencies: ['house_satisfaction'],
      input: <TextArea disabled={showReviewDrawer} />
    },
    {
      name: ['return_contact', 'review'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole].returnContact ||
        '-',
      rules: [{ required: true, message: 'supervision' }],
      input: getRadioGroup()
    },
    {
      name: ['return_contact', 'comment'],
      label: 'comment',
      dependencies: ['return_contact'],
      input: <TextArea disabled={showReviewDrawer} />
    }
  ];

  return {
    secondStepFields
  };
};
