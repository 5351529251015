import { Button, Flex, Typography, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useSummaryFields } from '../fields/summaryFields';
import { useUrgencyFields } from '../fields/urgencyFields';
import { addTotalTTC } from '../utils/addTotalTTC';
import { AnouncePhotoGallery } from '../fields/AnouncePhotoGallery';

const { Title } = Typography;

/**
 * Form component for collecting summary information.
 *
 * @component
 * @param {object} props - The props that define this component.
 * @param {Function} props.onFinish - Function to handle form submission.
 * @param {Function} props.handlePrevious - Function to handle previous step.
 * @param {object[]} props.supervisionPrices - List of supervision price objects.
 * @param {Function} props.t - Function for translation.
 * @param {object} props.form - Ant Design form object.
 * @param {Function} props.setDuration - Function to set duration.
 * @param {object[]} props.duration - List of duration objects.
 * @param {number} props.differenceDays - Difference in days.
 * @param {object[]} props.animalsFileList - List of animal file objects.
 * @param {object[]} props.petsittingAddressFileList - List of petsitting address file objects.
 * @param {object[]} props.selectedAnouncePhotos - List of selected announce photo objects.
 * @param {Function} props.setSelectedAnouncePhotos - Function to set selected announce photos.
 * @param {number} props.reduction - Reduction value.
 * @param {Function} props.setReduction - Function to set reduction value.
 * @returns {JSX.Element}
 */

export const SummaryForm = ({
  t,
  onFinish,
  handlePrevious,
  supervisionPrices,
  form,
  setDuration,
  duration,
  differenceDays,
  animalsFileList,
  petsittingAddressFileList,
  selectedAnouncePhotos,
  setSelectedAnouncePhotos,
  reduction,
  setReduction,
  userRole,
  setSupervisionPrice,
  supervisionPrice
}) => {
  const generateFields = useGenerateFormItem();
  const [photos, setPhotos] = useState([]);
  const { summaryFields } = useSummaryFields({
    supervisionPrices,
    setDuration,
    duration,
    setReduction,
    userRole
  });
  const { urgencyFields } = useUrgencyFields({});

  useEffect(() => {
    addTotalTTC({
      duration,
      reduction,
      form,
      differenceDays,
      supervisionPrices,
      supervisionPrice,
      setSupervisionPrice
    });
  }, [duration, reduction]);

  useEffect(() => {
    const photosData = animalsFileList.concat(petsittingAddressFileList);
    setPhotos(photosData);
  }, []);

  return (
    <>
      <Input.Group name={['summary']}>
        {summaryFields.map((field) => generateFields('supervisions', field))}
        <Title level={4}>{t('supervisions.form.urgency_title')}</Title>
        {urgencyFields.map((field) => generateFields('supervisions', field))}
      </Input.Group>
      <Title level={4}>{t('supervisions.form.anounce_photos_title')}</Title>
      <AnouncePhotoGallery
        photos={photos}
        selectedAnouncePhotos={selectedAnouncePhotos}
        setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      />
      <Flex justify="flex-end" gap="small">
        <Button type="link" onClick={handlePrevious}>
          {t('buttons.previous')}
          <ArrowLeftOutlined />
        </Button>
        <Button type="primary" onClick={onFinish}>
          {t('buttons.validate')}
        </Button>
      </Flex>
    </>
  );
};

SummaryForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  supervisionPrices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  setDuration: PropTypes.func.isRequired,
  duration: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  differenceDays: PropTypes.number.isRequired,
  animalsFileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  petsittingAddressFileList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAnouncePhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedAnouncePhotos: PropTypes.func.isRequired,
  reduction: PropTypes.number,
  setReduction: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  setSupervisionPrice: PropTypes.func.isRequired,
  supervisionPrice: PropTypes.number.isRequired
};

SummaryForm.defaultProps = {
  reduction: 0,
  userRole: ''
};
