import { useState } from 'react';
import { ListResource } from '../../../components';
import { useColumns } from './columns';
import { CreateUpdateDrawer } from './createUpdateDrawer/CreateUpdateDrawer';

export const ListGeneralCondition = () => {
  const [visible, setVisible] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [generalConditions, setGeneralConditions] = useState({});
  const [purpose, setPurpose] = useState('create');
  const [generalConditionToEdit, setGeneralConditionToEdit] = useState(null);

  const columns = useColumns({
    setPurpose,
    setGeneralConditionToEdit,
    setVisible
  });

  return (
    <>
      <ListResource
        resourceName="general-conditions"
        columns={columns}
        resourceModelName="General-condition"
        withCreateButton={false}
        withPageHeader={false}
        withSearchBar={false}
        withArchiveButton
        withCustomCreateButton
        customCreateButtonAction={() => {
          setVisible(true);
          setPurpose('create');
        }}
        withUploadButton={false}
        customActionColumn
        forceRefresh={refreshData}
        setResourceData={setGeneralConditions}
      />
      <CreateUpdateDrawer
        setRefreshData={setRefreshData}
        refresh={refreshData}
        visible={visible}
        setVisible={setVisible}
        generalConditions={generalConditions}
        purpose={purpose}
        generalConditionToEdit={generalConditionToEdit}
      />
    </>
  );
};
