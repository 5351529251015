/**
 * Asynchronously deletes a file identified by the given id. In case of an error, it displays a message using the provided `message` function.
 *
 * @function
 * @async
 * @param {string} id - The unique identifier of the file to be deleted.
 * @param {function} dispatchAPI - A function to dispatch the API request.
 * @param {function} message - A function to display error messages in case of an API error.
 *
 * @returns {Promise<void>} A promise that resolves to undefined when the file has been deleted successfully.
 *
 * @example
 *
 * // Usage of deleteFile function
 * deleteFile('12345', dispatchAPI, message)
 */
export const deleteFile = async (id, dispatchAPI, message) => {
  try {
    await dispatchAPI('DELETE', {
      url: `files/${id}`
    });
  } catch (e) {
    message(e);
  }
};
