export const archiveSubscriptionPrice = async ({
  dispatchAPI,
  message,
  record
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `subscription-prices/${record._id}`,
      body: { is_archived: true }
    });
  } catch (error) {
    message(error);
  }
};
