import { message as antdMessage } from 'antd';
import dayjs from 'dayjs';

/**
 * Handles the submission and cancellation of a supervision.
 * @async
 * @function
 *
 * @param {Object} options - The options for handling the submission and cancellation.
 * @param {string} options.status - The status of the supervision ('CANCEL' or 'INTERRUPT').
 * @param {Object} options.form - The form object used for validation.
 * @param {Function} options.dispatchAPI - The function for dispatching API requests.
 * @param {string} options.id - The ID of the supervision.
 * @param {Function} options.setRefreshData - The function for setting the refresh data flag.
 * @param {boolean} options.refreshData - The flag indicating whether to refresh the data.
 * @param {Function} options.message - The function for displaying error messages.
 * @param {Function} options.setInterruptSupervisionModalVisible - The function for setting the visibility of the interrupt supervision modal.
 * @param {Function} options.setCancelSupervisionModalVisible - The function for setting the visibility of the cancel supervision modal.
 * @param {Function} options.t - The function for translating text.
 * @param {Function} options.setIsLoading - The function for setting the loading state.
 * @returns {Promise<void>} - A promise that resolves when the submission and cancellation are handled.
 */
export const handleSubmitCancelSupervision = async ({
  status,
  form,
  dispatchAPI,
  id,
  setRefreshData,
  refreshData,
  message,
  setInterruptSupervisionModalVisible,
  setCancelSupervisionModalVisible,
  t,
  setIsLoading
}) => {
  const values = await form.validateFields();
  let newBody = {};

  if (status === 'CANCEL') {
    newBody = { ...values, status };
  }

  if (status === 'INTERRUPT') {
    newBody = {
      interrupt: {
        ...values.interrupt,
        application_date: dayjs(values.interrupt.application_date)
      },
      status
    };
  }

  try {
    setIsLoading(true);
    const { data } = await dispatchAPI('PATCH', {
      url: `supervisions/${id}/status`,
      body: newBody
    });

    data.pensioners.map(async (pensioner) => {
      await dispatchAPI('PATCH', {
        url: `pensioners/${pensioner}`,
        body: { is_in_supervision: false }
      });
    });

    if (values?.interrupt?.duplicate_supervision) {
      const { data: reference } = await dispatchAPI('GET', {
        url: `supervisions/count_documents`
      });

      const duplicateBody = {
        ...data,
        main_informations: {
          ...data.main_informations,
          start_date: dayjs(values.interrupt.application_date)
        },
        _id: null,
        reference: reference + 10001,
        pensioners: [],
        interrupt: null,
        decline: null,
        status: 'WAITING'
      };

      const { data: newSupervision } = await dispatchAPI('POST', {
        url: `supervisions`,
        body: duplicateBody
      });

      await dispatchAPI('PATCH', {
        url: `supervisions/${id}`,
        body: {
          interrupt: {
            ...newBody.interrupt,
            duplicate_supervision_reference: duplicateBody.reference
          }
        }
      });

      if (newSupervision) {
        setInterruptSupervisionModalVisible(false);
        antdMessage.success(
          t('supervisions.show.messages.duplicate_supervision_success', {
            reference: newSupervision.reference
          })
        );
      }
    }

    if (status === 'CANCEL') {
      setCancelSupervisionModalVisible(false);
    }

    if (status === 'INTERRUPT') setInterruptSupervisionModalVisible(false);

    if (data) {
      setRefreshData(!refreshData);
    }
    setIsLoading(false);
  } catch (error) {
    message(error);
  } finally {
    setIsLoading(false);
  }
};
