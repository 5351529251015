export const useDocumentsManagement = (pensioner, t) => {
  const selectOptions = [
    {
      label: t('pensioners.show.documents.criminal_record_extract'),
      value: 'criminal_record_extract'
    },
    {
      label: t('pensioners.show.documents.identification_photocopy'),
      value: 'identification_photocopy'
    },
    {
      label: t('pensioners.show.documents.early_retirement_certificate'),
      value: 'early_retirement_certificate'
    },
    {
      label: t('pensioners.show.documents.insurance_certificate'),
      value: 'insurance_certificate'
    },
    {
      label: t('pensioners.show.documents.residence_proof'),
      value: 'residence_proof'
    }
  ];

  return { selectOptions };
};
