import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { detailAnimalInfo } from '../../../../../components/Animals/listContent/animalInfo';
import { DescriptionList } from '../../../../../components';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * Composant AnimalsInfoModal.
 *
 * Ce composant affiche une modale contenant les informations sur les animaux associés à un propriétaire.
 *
 * @component
 * @param {object} props - Les props pour le composant.
 * @param {Array} props.animals - La liste des animaux.
 * @param {boolean} props.isModalOpen - Indique si la modale est ouverte.
 * @param {function} props.setIsModalOpen - Fonction pour définir l'état de la modale.
 * @param {function} props.t - Fonction de traduction i18n.
 * @returns {JSX.Element} Composant React pour la modale d'informations sur les animaux.
 */

const AnimalsInfoModal = ({ animals, isModalOpen, setIsModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('supervisions.show.animals.modal_title')}
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
    >
      {animals.map((animal) => (
        <>
          <Col xs={24} style={{ marginBottom: 16 }}>
            <Carousel imagesIds={animal.animal_photo} />
          </Col>
          <Col xs={24}>
            <DescriptionList
              data={detailAnimalInfo(animal || {}, t)}
              translate
              skipEmpty
            />
          </Col>
        </>
      ))}
    </Modal>
  );
};

export default AnimalsInfoModal;

AnimalsInfoModal.propTypes = {
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(
      PropTypes.shape({
        animal_photo: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string })
        )
      })
    )
  }).isRequired,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  animals: PropTypes.arrayOf(
    PropTypes.shape({
      animal_photo: PropTypes.arrayOf(
        PropTypes.shape({ url: PropTypes.string })
      )
    })
  ).isRequired
};

AnimalsInfoModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null
};
