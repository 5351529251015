import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import { ReviewsCards } from './ReviewsCards';

export const ListReviews = ({ t, reviews }) => (
  <>
    <Col>
      <Typography.Title level={5} className="underlined">
        {t('owners.users_shows.reviews.given_reviews')}
      </Typography.Title>
    </Col>

    <Row gutter={[16, 16]}>
      {(reviews || []).map((review) => (
        <ReviewsCards t={t} review={review} />
      ))}
    </Row>
  </>
);

ListReviews.propTypes = {
  t: PropTypes.func.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      author: PropTypes.string,
      content: PropTypes.string
    })
  ).isRequired
};
