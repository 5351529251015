import { Tag, Divider, Popconfirm, Button, Tooltip } from 'antd';
import {
  ContainerOutlined,
  WarningOutlined,
  EditOutlined,
  EyeOutlined,
  DownloadOutlined,
  BellOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { InvoiceStatus } from '../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../utils/errorMessage';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { useListResourceArchiving } from '../../../utils/listResourceArchiving';

/**
 * Fonction utilitaire qui génère les colonnes pour la liste des factures client.
 * @function
 * @param {Object} options - Les options de configuration.
 * @param {Object} options.enums - Les énumérations pour les statuts et types de facture.
 * @param {boolean} options.refreshData - L'état pour forcer le rafraîchissement des données.
 * @param {Function} options.setRefreshData - Fonction pour définir l'état de rafraîchissement des données.
 * @param {Function} options.setRecipient - Fonction pour définir le destinataire de la facture.
 * @param {Function} options.setIsReminderModalOpen - Fonction pour définir l'ouverture du modal de rappel.
 * @returns {Array} Un tableau contenant les colonnes pour la liste des factures client.
 */

export const useColumns = ({
  enums,
  setRefreshData,
  setRecipient,
  setIsReminderModalOpen,
  setCustomerInvoice,
  setDocumentType,
  setRecordID
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const { deleteResource, restoreResource } = useListResourceArchiving(
    setRefreshData,
    'customerinvoices/archiving'
  );

  const templateType = {
    SUBSCRIPTION: 'SUBSCRIPTION-INVOICE',
    ADVANCE_PAYMENT: 'OWNER-INVOICE',
    BALANCE: 'OWNER-INVOICE',
    CREDIT_NOTE: 'OWNER-INVOICE'
  };

  const translateTypeSorting = {
    SUBSCRIPTION: t('customerinvoices.tags.SUBSCRIPTION'),
    ADVANCE_PAYMENT: t('customerinvoices.tags.ADVANCE_PAYMENT'),
    BALANCE: t('customerinvoices.tags.BALANCE'),
    CREDIT_NOTE: t('customerinvoices.tags.CREDIT_NOTE')
  };

  const columns = [
    {
      title: t('customerinvoices.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('customerinvoices.form.type'),
      key: 'type',
      dataIndex: ['type'],
      render: (type) =>
        type ? (
          <Tag color={InvoiceStatus[type]}>
            {t(`customerinvoices.tags.${type}`, {
              defaultValue: type
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) =>
        translateTypeSorting[a.type].length -
        translateTypeSorting[b.type].length
    },
    {
      title: t('customerinvoices.form.customer'),
      key: 'customer',
      dataIndex: 'customer',
      sorter: true,
      render: (customer) =>
        customer && `${customer?.last_name || ''} ${customer?.first_name || ''}`
    },
    {
      title: t('customerinvoices.form.customer_reference'),
      key: 'customer_reference',
      sorter: true,
      render: (invoice) => {
        if (invoice?.subscription) {
          return invoice?.subscription?.pensioner?.reference
            ? `n° ${invoice?.subscription?.pensioner?.reference}`
            : '';
        }
        if (invoice.supervision) {
          return invoice?.supervision?.main_informations?.owner?.reference
            ? `n° ${invoice?.supervision?.main_informations?.owner?.reference}`
            : '';
        }
        return '';
      }
    },
    {
      title: t('customerinvoices.form.supervision_reference'),
      key: 'supervision_reference',
      sorter: true,
      render: (invoice) =>
        invoice?.supervision ? `n° ${invoice?.supervision?.reference}` : ''
    },
    {
      title: t('customerinvoices.form.due_date'),
      key: 'due_date',
      dataIndex: 'due_date',
      sorter: true,
      render: (due_date) => due_date && moment(due_date).format('DD/MM/YYYY')
    },
    {
      title: t('customerinvoices.form.total_ttc'),
      key: 'total_ttc',
      render: (record) =>
        record?.total_ttc
          ? `${record?.total_ttc} ${record?.franc ? 'Fr' : '€'}`
          : `0 ${record?.franc ? 'Fr' : '€'}`,
      sorter: true
    },
    {
      title: t('customerinvoices.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={InvoiceStatus[status]}>
            {t(`customerinvoices.tags.${status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          {enums.status && ['UNPAID'].includes(record.status) && (
            <>
              <Button
                type="link"
                onClick={() => {
                  const documentType =
                    record.type === 'SUBSCRIPTION'
                      ? 'SUBSCRIPTION-INVOICE'
                      : 'OWNER-INVOICE';
                  setRecipient(record.customer._id);
                  setIsReminderModalOpen(true);
                  setCustomerInvoice(record);
                  setDocumentType(documentType);
                  setRecordID(record._id);
                }}
                style={{ padding: 0 }}
              >
                <BellOutlined style={{ fontSize: 18 }} />
              </Button>
              <Divider type="vertical" />
            </>
          )}
          <Link
            to={{
              pathname: `/invoices/customers/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {enums.status && ['UNPAID', 'WAITING'].includes(record.status) && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `/invoices/customers/edit/${record?._id}`
                }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Link>
            </>
          )}
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.archive.hover')}
                  placement="left"
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                    type="delete"
                  />
                </Tooltip>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.unarchived.hover')}
                  placement="left"
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
            {enums.status && ['PAID', 'WAITING'].includes(record.status) && (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() =>
                    generateDocumentsOld(
                      dispatchAPI,
                      message,
                      templateType[record.type],
                      record._id,
                      null,
                      t
                    )
                  }
                >
                  <DownloadOutlined style={{ fontSize: 18 }} />
                </Button>
              </>
            )}
          </>
        </>
      )
    }
  ];

  const headers = [
    {
      label: 'reference',
      key: 'reference'
    },
    {
      label: 'type',
      key: 'customerinvoice_type'
    },
    {
      label: 'customer',
      key: 'customer'
    },
    {
      label: 'customer_reference',
      key: 'customer_reference'
    },
    {
      label: 'supervision_reference',
      key: 'supervision_reference'
    },
    {
      label: 'due_date',
      key: 'due_date'
    },
    {
      label: 'total_ttc',
      key: 'total_ttc'
    },
    {
      label: 'status',
      key: 'customerinvoice_status'
    }
  ];

  return { columns, headers };
};
