import { Route, Routes } from 'react-router-dom';
import { ListInternInvoices } from './ListInternInvoices';
import { CreateUpdateInternInvoice } from './CreateUpdateInternInvoice';
import { ShowInternInvoice } from './ShowInternInvoice';
import { Exception } from '../../../components';

/**
 * Router component for managing routes related to intern invoices.
 *
 * @component
 * @returns {JSX.Element} InternInvoiceRouter component.
 */
export const InternInvoiceRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateInternInvoice purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateInternInvoice purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowInternInvoice />} />
    <Route index element={<ListInternInvoices />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
