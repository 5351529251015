import { useTranslation } from 'react-i18next';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Button, Typography, List, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { routes } from '../../../../utils/constants/ownerPensionerRoutes';
import { useSocketContext } from '../../../../contexts/SocketContext';
import { profileLockMessage } from '../../utils/profileLockMessage';
import { editingLocked } from '../../../../utils/editingLocked';

const { Text } = Typography;

/**
 * Renders a card component for displaying veterinarian information.
 * @function
 *
 * @param {Object} props - The component props.
 * @param {Array} props.veterinarian - The array of veterinarian objects.
 * @param {Function} props.navigate - The function to navigate to a different page.
 * @param {Object} props.owner - The owner object.
 * @param {boolean} props.isFieldsLoading - Indicates if the fields are loading.
 * @returns {JSX.Element} The rendered VeterinarianCard component.
 */
export const VeterinarianCard = ({
  veterinarian,
  navigate,
  owner,
  isFieldsLoading
}) => {
  const { t } = useTranslation();
  const { profileLock } = useSocketContext();

  const createPath = `${routes.VETERINARIAN_GROOMER}/create/veterinarian/${owner?._id}`;
  const editPath = `${routes.VETERINARIAN_GROOMER}/edit/veterinarian/${veterinarian[0]?._id}`;

  const items = veterinarian?.map((veto) => [
    {
      label: t('veterinarians.form.name'),
      content: veto.name || '-'
    },
    {
      label: t('veterinarians.postal_address'),
      content: veto.address
        ? `${veto?.address?.number || ''} ${veto?.address?.street || ''}`
        : '-'
    },
    {
      label: t('veterinarians.form.address.city'),
      content: veto.address ? veto?.address?.city : '-'
    },
    {
      label: t('veterinarians.form.address.country'),
      content: veto.address ? veto?.address?.country : '-'
    },
    {
      label: t('veterinarians.form.phone_number.number'),
      content: veto.phone_number.number || '-'
    }
  ]);

  return (
    <Card
      title={t('veterinarians.header_panel')}
      className="vet-card"
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (editingLocked(profileLock, owner?._id)) {
              profileLockMessage(t, 'profile');
              return;
            }
            navigate(`${veterinarian?.length ? editPath : createPath}`);
          }}
        >
          {veterinarian?.length ? <EditOutlined /> : <PlusOutlined />}
          {veterinarian?.length ? t('buttons.edit') : t('buttons.create')}
        </Button>
      }
    >
      {!veterinarian?.length && (
        <Text>{t('veterinarians.no_veterinarian')}</Text>
      )}
      {veterinarian.length > 0 && (
        <Skeleton loading={isFieldsLoading} active>
          <List
            itemLayout="vertical"
            dataSource={items.flat(1)}
            renderItem={(item) => (
              <List.Item>
                <Text>{item.label} : </Text>
                <Text strong>{item.content}</Text>
              </List.Item>
            )}
          />
        </Skeleton>
      )}
    </Card>
  );
};

VeterinarianCard.propTypes = {
  veterinarian: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string
    })
  ).isRequired,
  navigate: PropTypes.func.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired
};
