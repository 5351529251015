import { Tag, Tooltip, Divider, Popconfirm } from 'antd';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  EyeOutlined,
  WarningOutlined,
  RollbackOutlined,
  ContainerOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useListResourceArchiving } from '../../utils/listResourceArchiving';
import { useAuthContext } from '../../contexts/AuthContext';
import { SupervisionStatus } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import {
  lastNameFilter,
  postalCodeFilter,
  departmentFilter
} from './utils/supervisionsfilters';

/**
 * Custom hook for generating columns configuration for a list of supervisions.
 *
 * @param {function} setForceRefresh - A function to set the refresh state of the list.
 * @returns {Array} An array of column configuration objects for the list.
 */
export const useColumns = (setForceRefresh) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const searchInput = useRef(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/supervisions/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const { deleteResource, restoreResource } = useListResourceArchiving(
    setForceRefresh,
    'supervisions/archiving'
  );

  return [
    {
      title: t('supervisions.form.status'),
      key: 'status',
      dataIndex: ['status'],
      width: '10%',
      render: (status) =>
        status ? (
          <Tag color={SupervisionStatus[status]}>
            {t(`supervisions.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`supervisions.tags.${r}`),
        value: r
      }))
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true,
      width: '7%'
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.owner.name'),
      key: 'last_name',
      dataIndex: ['main_informations', 'owner'],
      ...lastNameFilter(searchInput, t),
      render: (owner) => owner?.last_name,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.main_informations.owner.last_name.length -
        b.main_informations.owner.last_name.length,
      width: '10%'
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.owner.reference'),
      key: 'reference',
      dataIndex: ['main_informations', 'owner'],
      render: (owner) => owner?.reference,
      sorter: true
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.address.postal_code'),
      key: 'address.postal_code',
      dataIndex: ['address', 'petsitting_address', 'address'],
      ...postalCodeFilter(searchInput, t),
      render: (address) => address?.postal_code,
      sorter: (a, b) =>
        a.address.petsitting_address.address.postal_code -
        b.address.petsitting_address.address.postal_code
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.department'),
      key: 'address.department',
      dataIndex: 'address',
      width: '10%',
      ...departmentFilter(searchInput, t),
      render: (address) => address?.petsitting_address?.address?.state,
      sorter: (a, b) =>
        a.address.petsitting_address.address.state.length -
        b.address.petsitting_address.address.state.length
    },
    {
      title: t('supervisions.form.address.city'),
      key: 'address.city',
      dataIndex: 'address',
      render: (address) => address?.petsitting_address?.address?.city,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) =>
        a.address.petsitting_address.address.city.length -
        b.address.petsitting_address.address.city.length
    },
    {
      title: t('supervisions.form.pensioner.name'),
      key: 'pensioners',
      dataIndex: 'pensioners',
      sorter: true,
      render: (pensioners) =>
        pensioners &&
        pensioners.map(
          (pensioner) =>
            `${
              pensioner?.civility
                ? t(`supervisions.form.civility.${pensioner?.civility}`)
                : ''
            } ${pensioner?.first_name || ''} ${pensioner?.last_name || ''}`
        )
    },
    {
      title: t('supervisions.form.pensioner.reference'),
      key: 'pensioners',
      dataIndex: 'pensioners',
      render: (pensioners) =>
        pensioners && pensioners.map((pensioner) => pensioner?.reference),
      sorter: true
    },
    {
      className: 'title-wrap',
      title: t('supervisions.form.start_date'),
      key: 'start_date',
      dataIndex: ['main_informations', 'start_date'],
      sorter: (a, b) =>
        dayjs(a.main_informations.start_date).isBefore(
          b.main_informations.start_date
        )
          ? -1
          : 1,
      render: (start_date) =>
        start_date && dayjs(start_date).format('DD/MM/YYYY')
    },
    {
      className: 'title-wrap',

      title: t('supervisions.form.end_date'),
      key: 'end_date',
      dataIndex: ['main_informations', 'end_date'],
      sorter: (a, b) =>
        dayjs(a.main_informations.end_date).isBefore(
          b.main_informations.end_date
        )
          ? -1
          : 1,
      render: (end_date) => end_date && dayjs(end_date).format('DD/MM/YYYY')
    },
    {
      key: 'actions',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (record) => (
        <>
          <Tooltip
            title={t('datatable.column.action.show.hover')}
            placement="left"
          >
            <Link to={{ pathname: `/supervisions/show/${record._id}` }}>
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              ['REQUEST', 'WAITING'].includes(record?.status)
                ? t('datatable.column.action.edit.hover')
                : t('datatable.column.action.edit.not_editable')
            }
            placement="left"
          >
            <Link
              to={{
                pathname: ['REQUEST', 'WAITING'].includes(record?.status)
                  ? `/supervisions/edit/${record._id}`
                  : ''
              }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Link>
          </Tooltip>
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.archive.hover')}
                  placement="left"
                >
                  <ContainerOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                    type="delete"
                  />
                </Tooltip>
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                onConfirm={() => restoreResource(record?._id)}
                icon={<WarningOutlined />}
              >
                <Tooltip
                  title={t('datatable.column.action.unarchived.hover')}
                  placement="left"
                >
                  <RollbackOutlined
                    style={{ color: '#b51010', fontSize: 18 }}
                  />
                </Tooltip>
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];
};
