import PropTypes from 'prop-types';
import { Button, Form, Flex } from 'antd';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { useSecondStepFields } from '../fields/secondStepFields';

export const SecondStep = ({
  form,
  secondStep,
  setSecondStep,
  setCurrent,
  t,
  questionnaireType,
  user,
  showReviewDrawer
}) => {
  const { secondStepFields } = useSecondStepFields({
    questionnaireType,
    userRole: user.role,
    showReviewDrawer
  });
  const gerenateFormItem = useGenerateFormItem();

  const handleSetSecondStepData = (data) => {
    setCurrent((current) => current + 1);
    setSecondStep({ ...secondStep, ...data });
  };

  return questionnaireType || user.role ? (
    <Form onFinish={handleSetSecondStepData} form={form} layout="vertical">
      {secondStepFields.map((field) =>
        gerenateFormItem('owners.users_shows.review_drawer', field)
      )}
      <Flex gap="small" justify="flex-end">
        <Button
          type="link"
          onClick={() => setCurrent((current) => current - 1)}
        >
          {t('buttons.previous')}
        </Button>
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
        </Button>
      </Flex>
    </Form>
  ) : null;
};

SecondStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  secondStep: PropTypes.shape({}).isRequired,
  setSecondStep: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  showReviewDrawer: PropTypes.bool
};

SecondStep.defaultProps = {
  showReviewDrawer: false
};
