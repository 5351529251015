export const useDeleteNotification = ({
  dispatchAPI,
  setForceRefresh,
  forceRefresh,
  message
}) => {
  const deleteNotification = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `notifications/delete/${id}`
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message(e);
    }
  };

  return { deleteNotification };
};
