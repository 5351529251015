import { ShowAlerts } from './alerts/ShowAlerts';
import { ShowFinances } from './finances/ShowFinances';
import { SupervisionsCharts } from './supervisions/SupervisionsCharts';
import { ShowStatistics } from './statistics/ShowStatistics';

/**
 * Function to generate tab items for a dashboard.
 *
 * @param {Object} options - Options for generating tab items.
 * @param {Array} options.notifications - Array of notifications.
 * @param {function} options.t - Translation function for localization.
 * @param {boolean} options.forceRefresh - Flag for force refreshing data.
 * @param {function} options.setForceRefresh - Function to set force refresh flag.
 * @param {string} options.message - Message for the dashboard.
 * @param {function} options.dispatchAPI - Function for API dispatch.
 * @param {Object} options.user - User object.
 * @param {boolean} options.loading - Loading flag.
 * @param {Array} options.supervisions - Array of supervisions data.
 * @param {Array} options.customerInvoices - Array of customer invoices.
 * @param {Array} options.internInvoices - Array of intern invoices.
 * @param {Array} options.registrations - Array of registrations data.
 * @param {Array} options.supervisionsStatus - Array of supervisions status data.
 * @param {function} options.setStatusDate - Function to set status date.
 * @param {function} options.setSupDate - Function to set supervision date.
 * @param {function} options.setStatisticsDate - Function to set statistics date.
 * @param {function} options.setFinanceDate - Function to set finance date.
 * @param {Array} options.subscriptions - Array of subscriptions data.
 * @param {string} options.statisticsDate - Date for statistics.
 *
 * @returns {Object} - Object containing an array of tab items.
 */
export const useTabsItems = ({
  notifications,
  t,
  forceRefresh,
  setForceRefresh,
  message,
  dispatchAPI,
  user,
  loading,
  supervisions,
  customerInvoices,
  internInvoices,
  registrations,
  supervisionsStatus,
  setStatusDate,
  setSupDate,
  setStatisticsDate,
  setFinanceDate,
  subscriptions,
  statisticsDate
}) => {
  /**
   * Tab items for the dashboard.
   *
   * @type {Array}
   * @property {number} key - Unique key for the tab.
   * @property {string} label - Label for the tab.
   * @property {ReactNode} children - React component for the tab content.
   */
  const tabsItems = [
    {
      key: 1,
      label: t('dashboard.tabs.alerts'),
      children: (
        <ShowAlerts
          notifications={notifications}
          t={t}
          forceRefresh={forceRefresh}
          setForceRefresh={setForceRefresh}
          message={message}
          dispatchAPI={dispatchAPI}
          user={user}
          loading={loading}
        />
      )
    },
    {
      key: 2,
      label: t('dashboard.tabs.supervisions.status'),
      children: (
        <SupervisionsCharts
          supervisions={supervisions}
          supervisionsStatus={supervisionsStatus}
          setStatusDate={setStatusDate}
          setSupDate={setSupDate}
        />
      )
    },
    {
      key: 3,
      label: t('dashboard.tabs.finances.title'),
      children: (
        <ShowFinances
          customerInvoices={customerInvoices}
          internInvoices={internInvoices}
          setFinanceDate={setFinanceDate}
        />
      )
    },
    {
      key: 4,
      label: t('dashboard.tabs.statistics.title'),
      children: (
        <ShowStatistics
          customerInvoices={customerInvoices}
          registrations={registrations}
          setStatisticsDate={setStatisticsDate}
          subscriptions={subscriptions}
          statisticsDate={statisticsDate}
        />
      )
    }
  ];

  return { tabsItems };
};
