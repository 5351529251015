import React from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Avatar,
  List,
  Skeleton,
  Button
} from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { bothListContent } from './listContent/bothListContent';
import { ListDocuments } from '../../../../../components/ListDocuments/ListDocuments';
import { outOfNavRoutes } from '../../../../../utils/constants/ownerPensionerRoutes';

const { Title, Text } = Typography;
const { Meta } = Card;

/**
 * React component for displaying general information about a user profile.
 * @component
 * @param {Object} props - The component properties.
 * @param {string} props.url - The URL of the profile photo.
 * @param {Object} props.profile - The profile information.
 * @param {string} props.profile.first_name - The first name of the user.
 * @param {string} props.profile.last_name - The last name of the user.
 * @param {string} [props.url=''] - The URL of the profile photo.
 * @param {Function} props.t - The translation function.
 * @param {Object} props.user - The user information.
 * @param {boolean} props.isLoading - Indicates whether the profile is in a loading state.
 * @param {Function} props.navigate - Function to navigate to a different page.
 * @param {string} props.profileID - The unique identifier of the user profile.
 * @param {Function} props.onPreview - Function to handle document preview.
 * @param {Array} props.personalDocuments - Array of personal documents associated with the profile.
 * @param {Function} props.downloadDocument - Function to handle document download.
 * @returns {JSX.Element} - React component.
 * @example
 * // Example usage of the GeneralInfos component:
 * <GeneralInfos
 *   url="/path/to/photo.jpg"
 *   profile={{
 *     first_name: "John",
 *     last_name: "Doe",
 *     _id: "123456789"
 *   }}
 *   t={translateFunction}
 *   user={{/* user information /*}}
 *   isLoading={false}
 *   navigate={navigateFunction}
 *   profileID="123456789"
 *   onPreview={handlePreview}
 *   personalDocuments={[/* array of personal documents /*]}
 *   downloadDocument={handleDownload}
 * />
 */
export const GeneralInfos = ({
  token,
  profile,
  photoId,
  t,
  user,
  isLoading,
  navigate,
  profileID,
  onPreview,
  personalDocuments,
  downloadDocument,
  checkProfileLock,
  profileLockMessage
}) => (
  <Skeleton loading={isLoading} active avatar>
    <Row gutter={[32, 32]}>
      <Col xs={24} md={12} lg={10} xl={8} xxl={6}>
        <Card className="profile-name-card">
          <Title
            level={4}
          >{`${profile.first_name} ${profile.last_name}`}</Title>
          {photoId && (
            <Meta
              avatar={
                <Avatar
                  src={`${process.env.REACT_APP_API_URL}/pensioners/get-url-file/${photoId}/${token}`}
                  size={160}
                  alt=""
                />
              }
            />
          )}
        </Card>
      </Col>
      <Col xs={24} md={12} lg={12} xl={14} xxl={16}>
        <Button
          onClick={() => {
            if (checkProfileLock) {
              profileLockMessage(t, 'profile');
              return;
            }
            navigate(
              `${outOfNavRoutes.PROFILE}/edit/${profileID}?fieldsActiveKey=${
                profile._id ? 'mainFields' : 'secondaryProfileFields'
              }`
            );
          }}
          type="primary"
          style={{ float: 'right', zIndex: 10 }}
        >
          <EditOutlined />
          {`${t('buttons.edit')} `}
        </Button>
        <List
          itemLayout="vertical"
          dataSource={bothListContent({
            profile,
            t,
            user
          })}
          renderItem={(item) => (
            <List.Item>
              <Text>{item.label} : </Text>
              <Text strong>{item.content}</Text>
            </List.Item>
          )}
        />
        {user.role === 'users:PENSIONER' && (
          <ListDocuments
            onPreview={onPreview}
            isLoading={isLoading}
            documents={personalDocuments}
            t={t}
            downloadDocument={downloadDocument}
            withTitle
          />
        )}
      </Col>
    </Row>
  </Skeleton>
);

GeneralInfos.propTypes = {
  photoId: PropTypes.string,
  profile: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    _id: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  profileID: PropTypes.string.isRequired,
  onPreview: PropTypes.func.isRequired,
  personalDocuments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  downloadDocument: PropTypes.func.isRequired,
  checkProfileLock: PropTypes.bool.isRequired,
  profileLockMessage: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired
};

GeneralInfos.defaultProps = {
  photoId: ''
};
