import { Select, InputNumber } from 'antd';

/**
 * Custom hook for generating summary fields.
 *
 * @param {Object} options - Options for generating summary fields.
 * @param {Array} options.supervisionPrices - List of supervision prices.
 * @param {Function} options.setDuration - Function to set duration.
 * @param {Function} options.setReduction - Function to set reduction.
 * @returns {Object} An object containing the summary fields.
 */

export const useSummaryFields = ({
  supervisionPrices,
  setDuration,
  setReduction,
  userRole
}) => {
  const supervisionsPricesOptions = supervisionPrices.map((price) => ({
    value: price._id,
    label: price.duration.extra_week
      ? price.duration.extra_week
      : `de ${price.duration.start_day} à ${price.duration.end_day} jours`,
    duration: price,
    disabled: true
  }));

  const summaryFields = [
    {
      name: ['summary', 'price_category', 'duration'],
      input: (
        <Select
          options={supervisionsPricesOptions}
          onChange={(e, option) => setDuration(option.duration)}
        />
      )
    },
    {
      name: ['summary', 'reduction'],
      input: (
        <InputNumber
          disabled={userRole === 'users:OWNER'}
          onChange={(e) => setReduction(e)}
          addonAfter="%"
        />
      )
    },
    {
      name: ['summary', 'price_category', 'total_ttc'],
      input: <InputNumber disabled addonAfter="€" />
    },
    {
      name: ['summary', 'price_category', 'advance_payment'],
      input: (
        <InputNumber disabled={userRole === 'users:OWNER'} addonAfter="€" />
      )
    }
  ];

  return { summaryFields };
};
