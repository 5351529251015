import { Route, Routes } from 'react-router-dom';
import { Exception } from '../../components';
import { MailSenderList } from './MailSenderList';
import { MailSenderCreateUpdate } from './MailSenderCreateUpdate';
import { MailSenderShow } from './MailSenderShow';

/**
 * Component for handling routes related to Mail Sender functionality.
 * It includes routes for listing, creating, updating, and showing mail senders.
 *
 * @component
 * @returns {JSX.Element} React element representing the component.
 */

export const MailSenderRouter = () => (
  <Routes>
    <Route index element={<MailSenderList />} />
    <Route
      path="/create/:type/:sendingType"
      element={<MailSenderCreateUpdate purpose="create" />}
    />
    <Route
      path="/edit/:type/:id/:sendingType"
      element={<MailSenderCreateUpdate purpose="edit" />}
    />
    <Route
      path="/show/:type/:id/:status/:sendingType"
      element={<MailSenderShow />}
    />
    <Route path="*" element={<Exception />} />
  </Routes>
);
