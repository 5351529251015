import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const useCustomSubmit = ({
  setIsSubmitting,
  onUpdateResource,
  onCreateResource,
  purpose,
  setIsLoading,
  onGetResource,
  form,
  id
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();
  const { message } = useErrorMessage();

  const customSubmit = async (body) => {
    setIsSubmitting(true);
    try {
      await dispatchAPI('PATCH', {
        url: `petsittingaddresses/form/${id}`,
        body:
          onUpdateResource && onUpdateResource.setBody && purpose === 'edit'
            ? onUpdateResource.setBody(body)
            : onCreateResource.setBody(body)
      });
      navigate(-1);
    } catch (e) {
      if (e.response.data.message.includes('E11000')) {
        message.warning(t('errors.message.E11000'));
      } else {
        message(e);
      }
      setIsSubmitting(false);
    }
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `petsittingaddresses/form/${id || ''}?populate=dwelling_photos`
      });
      form.setFieldsValue(
        onGetResource && onGetResource.setFields
          ? await onGetResource.setFields(data)
          : data
      );
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [purpose]);

  return { customSubmit, getResource };
};
