export const pensionersExtraInfosListContent = (
  {
    means_of_transport,
    disponibility,
    city_to_discover,
    usage_of_gps,
    urgency,
    isChildren,
    children_place,
    is_grand_children,
    grand_children_place
  },
  t
) => [
  {
    label: t('profile.personal_info.means_of_transport.title'),
    content:
      means_of_transport
        ?.map((type) => t(`profile.personal_info.means_of_transport.${type}`))
        .join(', ') || '-'
  },
  {
    label: t('profile.personal_info.disponibility'),
    content: disponibility || '-'
  },
  {
    label: t('profile.personal_info.city_to_discover'),
    content: city_to_discover || '-'
  },
  {
    label: t('profile.personal_info.usage_of_gps'),
    content: usage_of_gps ? t('yes') : t('no') || '-'
  },
  {
    label: t('profile.personal_info.urgency.name'),
    content: urgency?.name ? `${urgency?.name}` : '-'
  },
  {
    label: t('profile.personal_info.urgency.phone_number'),
    content: urgency?.phone_number?.number || '-'
  },
  {
    label: t('profile.personal_info.have_children'),
    content: isChildren
      ? t('profile.personal_info.yes')
      : t('profile.personal_info.no') || '-'
  },
  {
    label: t('profile.personal_info.children_place'),
    content: children_place || '-'
  },
  {
    label: t('profile.personal_info.is_grand_children'),
    content: is_grand_children
      ? t('profile.personal_info.yes')
      : t('profile.personal_info.no') || '-'
  },
  {
    label: t('profile.personal_info.grand_children_place'),
    content: grand_children_place || '-'
  }
];
