import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from './errorMessage';

export const useHandleProfileLock = (id) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const handleProfileLock = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/editing-rights/${id}`
      });
    } catch (e) {
      message(e);
    }
  };

  return { handleProfileLock };
};
