import React from 'react';
import { Button, Col, List, Row, Skeleton, Typography } from 'antd';
import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';

/**
 * React component for displaying a list of documents.
 * @component
 * @param {Object} props - The component properties.
 * @param {Function} props.onPreview - Function to handle document preview.
 * @param {boolean} props.isLoading - Indicates whether documents are in a loading state.
 * @param {Array} props.documents - Array of document objects.
 * @param {Function} props.downloadDocument - Function to handle document download.
 * @param {boolean} [props.withTitle=false] - Flag to display a title above the document list.
 * @returns {JSX.Element} - React component.
 * @example
 * // Example usage of the ListDocuments component:
 * <ListDocuments
 *   onPreview={handlePreview}
 *   isLoading={loading}
 *   documents={documentList}
 *   downloadDocument={handleDownload}
 *   withTitle={true}
 * />
 */
export const ListDocuments = ({
  onPreview,
  isLoading,
  documents,
  downloadDocument,
  withTitle
}) => (
  <>
    {withTitle && (
      <Row>
        <Col>
          <Typography.Title level={5} className="underlined">
            Documents
          </Typography.Title>
        </Col>
      </Row>
    )}
    <Row>
      <Col span={16}>
        <List
          className="demo-loadmore-list"
          loading={isLoading}
          itemLayout="horizontal"
          dataSource={documents}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type="link" onClick={() => onPreview(item)}>
                  <EyeOutlined />
                </Button>,
                <Button
                  type="link"
                  onClick={() =>
                    downloadDocument({
                      _id: item._id._id,
                      metadata: item._id.metadata,
                      contentType: item._id.contentType
                    })
                  }
                >
                  <DownloadOutlined />
                </Button>
              ]}
            >
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined />}
                  description={item._id.metadata.originalName}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  </>
);

ListDocuments.propTypes = {
  onPreview: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      metadata: PropTypes.shape({
        originalName: PropTypes.string.isRequired
      })
    })
  ),
  downloadDocument: PropTypes.func.isRequired,
  withTitle: PropTypes.bool
};

ListDocuments.defaultProps = {
  withTitle: false,
  documents: []
};
