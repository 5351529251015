import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Steps, message as Message, Form, Flex } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom } from '../../../components';
import { MainInformationsForm } from '../../supervisions/forms/MainInformations';
import { SupervisionAddressForm } from '../../supervisions/forms/SupervisionAddress';
import { SummaryForm } from '../../supervisions/forms/Summary';
import { useConfig } from './utils/supervisionsConfig';
import { CreateUpdateContainerSupervision } from '../../supervisions/utils/CreateUpdateContainerSupervision';
import { getEnums } from '../../supervisions/utils/getEnums';
import { getSupervisionPrices } from '../../supervisions/utils/getSupervisionPrices';
import { onFinishInfoForm } from '../../supervisions/utils/onFinishInfoForm';
import { onFinishSupervisionAddressForm } from '../../supervisions/utils/onFinishSupervisionAddressForm';
import { onFinishSummaryForm } from '../../supervisions/utils/onFinishSummaryForm';
import { submitForm } from '../../supervisions/utils/submitForm';
import { addDuration } from '../../supervisions/utils/addDuration';
import { changeForm } from '../../supervisions/utils/changeForm';
import { addSupervisionAddressData } from '../../supervisions/utils/addSupervisionAddressData';
import { handlePrevious } from '../../supervisions/utils/handlePrevious';
import { getAnimalsPictures } from '../../supervisions/utils/getAnimalsPitcures';
import { getOwner } from './utils/getOwner';
import { populateOwnerFields } from './utils/populateOwnerFields';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';
import { editingLocked } from '../../../utils/editingLocked';
import { formatNewPetsittingAddressFiles } from '../../../utils/formatNewPetsittingAddressFiles';

/**
 * Component for creating or updating a supervision record.
 *
 * @component
 * @param {Object} props - The props that define this component.
 * @param {string} props.purpose - The purpose of the form (create or update).
 * @returns {JSX.Element} - React component
 */
export const CreateUpdateSupervision = ({ purpose }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { editSupervisionLock } = useSocketContext();

  const [current, setCurrent] = useState(0);
  const [informationsData, setInformationData] = useState({});
  const [supervisionAddressData, setSupervisionAddressData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [supervisionPrices, setSupervisionPrices] = useState([]);
  const [supervisionPrice, setSupervisionPrice] = useState({});
  const [owner, setOwner] = useState({});
  const animalsRef = useRef([]);
  const startDateRef = useRef({});
  const endDateRef = useRef({});
  const [typeOfDwelling, setTypeOfDwelling] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [duration, setDuration] = useState([]);
  const [differenceDays, setDifferenceDays] = useState(0);
  const [selectedAnouncePhotos, setSelectedAnouncePhotos] = useState([]);
  const [animalsFileList, setAnimalsFileList] = useState([]);
  const [reduction, setReduction] = useState(0);
  const [petsittingAddressFileList, setPetsittingAddressFileList] = useState(
    []
  );
  const [formDataEnvironment, setFormDataEnvironment] = useState([]);
  const [country, setCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const userRole = user?.role;

  useEffect(async () => {
    if (purpose === 'create') {
      await addSupervisionAddressData({
        owner,
        setSupervisionAddressData,
        setPetsittingAddressFileList,
        message,
        dispatchAPI,
        setTypeOfDwelling
      });
    }
  }, [owner]);

  useEffect(async () => {
    await getAnimalsPictures({
      animalsRef,
      setAnimalsFileList,
      dispatchAPI,
      message
    });
  }, [animalsRef.current]);

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums({ dispatchAPI, setEnums, message });
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  useEffect(() => {
    if (Object.keys(owner).length) {
      populateOwnerFields({ dispatchAPI, owner, form });
    }
  }, [owner]);

  const config = useConfig({
    setPetsittingAddressFileList,
    dispatchAPI,
    message,
    setSelectedAnouncePhotos,
    setSupervisionAddressData,
    startDateRef,
    endDateRef,
    animals: animalsRef,
    setReduction,
    setOwner,
    setFormDataEnvironment,
    setCountry,
    setIsLoading
  });

  useEffect(() => {
    if (formSubmitted && Object.keys(summaryData).length) {
      (async () => {
        await submitForm({
          informationsData,
          supervisionAddressData,
          summaryData,
          petsittingAddressFileList,
          purpose,
          dispatchAPI,
          id,
          Message,
          t,
          navigate,
          message,
          selectedAnouncePhotos,
          supervisionPrice
        });
      })();
    }
  }, [formSubmitted, summaryData]);

  useEffect(() => {
    (async () => {
      await getSupervisionPrices({
        dispatchAPI,
        setSupervisionPrices,
        message
      });
      await getOwner({
        dispatchAPI,
        setOwner,
        message,
        user,
        setFormDataEnvironment
      });
    })();
  }, []);

  useEffect(() => {
    addDuration({
      startDate: startDateRef.current,
      endDate: endDateRef.current,
      setDifferenceDays,
      supervisionPrices,
      form,
      setDuration
    });
  }, [startDateRef.current, endDateRef.current]);

  useEffect(async () => {
    await formatNewPetsittingAddressFiles({
      petsittingAddressFileList,
      dispatchAPI,
      message,
      setPetsittingAddressFileList
    });
  }, [petsittingAddressFileList]);

  const forms = [
    <MainInformationsForm
      onFinish={() =>
        onFinishInfoForm({
          form,
          setInformationData,
          setCurrent,
          Message,
          t
        })
      }
      enums={enums}
      initialValues={informationsData}
      t={t}
      isFieldsLoading={isFieldsLoading}
      dispatchAPI={dispatchAPI}
      owner={owner}
      animalsRef={animalsRef}
      form={form}
      startDate={startDateRef}
      endDate={endDateRef}
      userRole={userRole}
    />,
    <SupervisionAddressForm
      onFinish={() =>
        onFinishSupervisionAddressForm({
          form,
          setSupervisionAddressData,
          setCurrent,
          Message,
          t
        })
      }
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      enums={enums}
      initialValues={supervisionAddressData}
      isFieldsLoading={isFieldsLoading}
      t={t}
      form={form}
      fileList={petsittingAddressFileList}
      setFileList={setPetsittingAddressFileList}
      formDataEnvironment={formDataEnvironment}
      setFormDataEnvironment={setFormDataEnvironment}
      country={country}
      setCountry={setCountry}
      typeOfDwelling={typeOfDwelling}
      setTypeOfDwelling={setTypeOfDwelling}
    />,
    <SummaryForm
      onFinish={() =>
        onFinishSummaryForm({
          form,
          setSummaryData,
          setCurrent,
          setFormSubmitted,
          Message,
          t,
          setIsLoading
        })
      }
      handlePrevious={() => handlePrevious({ setCurrent, current })}
      enums={enums}
      initialValues={summaryData}
      isFieldsLoading={isFieldsLoading}
      t={t}
      supervisionPrices={supervisionPrices}
      form={form}
      duration={duration}
      setDuration={setDuration}
      differenceDays={differenceDays}
      animalsFileList={animalsFileList}
      petsittingAddressFileList={petsittingAddressFileList}
      selectedAnouncePhotos={selectedAnouncePhotos}
      setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      reduction={reduction}
      setReduction={setReduction}
      userRole={userRole}
      supervisionPrice={supervisionPrice}
      setSupervisionPrice={setSupervisionPrice}
    />
  ];
  const steps = [
    {
      title: t('supervisions.form.main_informations.title'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.supervision_address'),
      icon: <CheckCircleOutlined />
    },
    {
      title: t('supervisions.form.summary.title'),
      icon: <CheckCircleOutlined />
    }
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title
  }));

  return (
    <>
      {editingLocked(editSupervisionLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'my_supervision',
            text: 'supervision'
          }}
          navigate={navigate}
        />
      )}
      <ContentCustom>
        <Flex vertical gap="large">
          <Steps
            current={current}
            items={items}
            onChange={(e) =>
              changeForm({
                nextStep: e,
                form,
                setCurrent,
                Message,
                t,
                current,
                setInformationData,
                setSupervisionAddressData,
                setSummaryData
              })
            }
          />
          <CreateUpdateContainerSupervision
            isParentLoading={isLoading}
            loadingFields={isFieldsLoading}
            purpose={purpose}
            customFormInstance={form}
            baseUrl="supervisions/form"
            resource="supervisions"
            config={config}
            formExtra={forms[current]}
            disabled={editingLocked(editSupervisionLock, id)}
          />
        </Flex>
      </ContentCustom>
    </>
  );
};

CreateUpdateSupervision.propTypes = {
  purpose: PropTypes.string.isRequired
};
