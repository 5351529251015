import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/userRoutes';
import { Login } from '../routes/login';
import { Dashboard } from '../routes/dashboards/Dashboard';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import PostPwdForm from '../routes/login/PostPwdForm';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter as UsrRouter } from '../routes/users/UserRouter';
import { UserTheme } from '../assets/styles/Theme/UserTheme';
import { RequireAuth } from './RequireAuth';

export const UserRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'user';
    }
  }, []);

  return (
    <BrowserRouter>
      <UserTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />*
        <Route
          path={`${outOfNavRoutes.PASSWORD_CREATION}/:urlToken`}
          element={<PostPwdForm />}
        />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route path={`${routes.USERS}/*`} element={<UsrRouter />} />
          <Route path={routes.DASHBOARD} element={<Dashboard />} />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
