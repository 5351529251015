import PropTypes from 'prop-types';
import { Button, Form, Flex } from 'antd';
import { useLastStepFields } from '../fields/lastStepFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

export const LastStep = ({
  lastStep,
  setLastStep,
  t,
  form,
  setCurrent,
  showReviewDrawer
}) => {
  const gerenateFormItem = useGenerateFormItem();
  const { lastStepFields } = useLastStepFields({ showReviewDrawer });

  const handleSetLastStepData = (data) => {
    setLastStep({ ...lastStep, ...data });
  };

  return (
    <Form layout="vertical" onFinish={handleSetLastStepData} form={form}>
      {lastStepFields.map((field) =>
        gerenateFormItem('owners.users_shows.review_drawer', field)
      )}
      <Flex gap="small" justify="flex-end">
        <Button
          type="link"
          onClick={() => setCurrent((current) => current - 1)}
        >
          {t('buttons.previous')}
        </Button>
        <Button type="primary" htmlType="submit">
          {t('buttons.validate')}
        </Button>
      </Flex>
    </Form>
  );
};

LastStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  lastStep: PropTypes.shape({}).isRequired,
  setLastStep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  showReviewDrawer: PropTypes.bool
};

LastStep.defaultProps = {
  showReviewDrawer: false
};
