import { useNavigate } from 'react-router-dom';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';

export const useCustomSubmit = (
  onCreateResource,
  onUpdateResource,
  ownerID,
  animalID,
  purpose
) => {
  const navigate = useNavigate();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const createResource = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `animals/form/${ownerID}`,
        body:
          onCreateResource && onCreateResource.setBody
            ? await onCreateResource.setBody(body)
            : body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `animals/form/${animalID}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? await onUpdateResource.setBody(body)
            : body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const handleSubmit = (body) => {
    if (purpose === 'create') {
      createResource(body);
    } else {
      updateResource(body);
    }
  };

  return { handleSubmit };
};
