import { useTranslation } from 'react-i18next';

export const useListContent = (data) => {
  const { t } = useTranslation();
  const { reference, address } = data || {};
  const { petsitting_address } = address || {};

  return [
    {
      label: t('supervisions.form.reference'),
      span: 1,
      content: (reference && reference) || '-'
    },
    {
      label: t('supervisions.form.address.city'),
      span: 2,
      content:
        (petsitting_address?.address?.city &&
          petsitting_address.address.city) ||
        '-'
    },
    {
      label: t('supervisions.form.address.state'),
      span: 3,
      content:
        (petsitting_address?.address?.state &&
          petsitting_address.address.state) ||
        '-'
    },
    {
      label: t('supervisions.form.address.postal_code'),
      span: 4,
      content:
        (petsitting_address?.address?.postal_code &&
          petsitting_address.address.postal_code) ||
        '-'
    }
  ];
};
