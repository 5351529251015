import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const useCallsAPI = ({
  setFunction,
  setIsLoading,
  populate,
  id = ''
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const route = `${user?.role?.split(':')[1].toLowerCase()}s`;

  const getProfile = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${route}?email=${user?.email}${
          populate ? `&populate=${populate}` : ''
        }`
      });
      if (setFunction) setFunction(data);
      if (setIsLoading) setIsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  const deleteProfile = async () => {
    try {
      await getProfile();
      await dispatchAPI('PATCH', {
        url: `/${route}/archiving/${id}/true`,
        body: { is_archived: true, status: 'ARCHIVED' }
      });
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      window.location.reload();
    } catch (e) {
      message(e);
    }
  };

  return { getProfile, deleteProfile };
};
