import dayjs from 'dayjs';
import { monthArray } from '../monthsArray';

/**
 * Set data for the internal expenses chart based on intern invoices.
 * @param {Object} params - Parameters for internExpencesSetData function.
 * @param {Object} params.internInvoices - Intern invoices data.
 * @param {Function} params.setItems - State setting function for items data.
 * @param {Function} params.t - Translation function for internationalization.
 * @returns {void}
 */
export const internExpencesSetData = ({ internInvoices, setItems, t }) => {
  const calculatedData = {};
  Object.entries(internInvoices).forEach(([key, invoices]) => {
    invoices.forEach((invoice) => {
      // Calculate month key using dayjs.
      const monthKey = dayjs(invoice.payment_date).format('MMMM');

      // Initialize calculatedData object for the key.
      calculatedData[key] = calculatedData[key] || {};

      // Update calculated data for the month and key.
      calculatedData[key][monthKey] =
        (calculatedData[key]?.[monthKey] || 0) + (invoice.total_ttc || 0);
    });
  });

  // Map over monthsArray and set items data.
  monthArray(t).map((month) =>
    setItems((prev) => [
      ...prev,
      {
        month,
        salesNMinus1: calculatedData['N-1']?.[month.toLowerCase()] || 0,
        salesN: calculatedData.N?.[month.toLowerCase()] || 0
      }
    ])
  );
};
