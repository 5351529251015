import { Col, Modal, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { downloadPDFsAsZip } from '../../../utils/generatePDF';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';

/**
 * Generates contract documents based on the provided parameters.
 * @async
 * @function
 * @param {Object} options - The options for generating contract documents.
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API requests.
 * @param {string} options.message - The message to display during document generation.
 * @param {string} options.documentType - The type of document to generate.
 * @param {string} options.id - The ID of the document.
 * @param {Object} options.option - The options for generating the document.
 * @param {Object} options.t - The translation function for localization.
 * @param {Object} options.supervision - The supervision object.
 * @param {Function} options.setRefreshData - The function to refresh data after document generation.
 * @returns {void}
 */
export const generateContractDocuments = async ({
  dispatchAPI,
  message,
  documentType,
  id,
  option,
  t,
  supervision,
  setRefreshData
}) => {
  const modal = Modal.info({
    title: null,
    content: (
      <Row style={{ flexDirection: 'column' }} gutter={[8, 8]}>
        <Col align="center" justify="center" span={24}>
          <Spin />
        </Col>
        <Col align="center" justify="center" span={24}>
          {t('template-documents.form.document_being_generated')}
        </Col>
      </Row>
    ),
    footer: null,
    maskClosable: false
  });
  const {
    main_informations: { start_date }
  } = supervision;
  const daysDifference = dayjs(start_date).diff(dayjs(), 'day');

  const invoiceType = daysDifference <= 10 ? 'BALANCE' : 'ADVANCE_PAYMENT';

  const ownerDocumentType = [
    { type: 'OWNER_CONTRACT', record_id: option._id },
    { type: 'OWNER-INVOICE', invoice_type: invoiceType }
  ];

  if (documentType === 'OWNER_CONTRACT') {
    const responses = await Promise.all(
      ownerDocumentType.map(async (doc) => {
        if (doc.type === 'OWNER-INVOICE') {
          const { data } = await dispatchAPI('GET', {
            url: `customerinvoices?supervision=${supervision._id}&type=${doc.invoice_type}`
          });

          if (data.length) {
            return generateDocumentsOld(
              dispatchAPI,
              message,
              doc.type,
              data[0]._id,
              null,
              t,
              setRefreshData,
              false
            );
          }
        } else {
          return generateDocumentsOld(
            dispatchAPI,
            message,
            doc.type,
            id,
            null,
            t,
            setRefreshData,
            false
          );
        }
        return null;
      })
    );

    await downloadPDFsAsZip(responses, 'contract_documents');
  } else {
    generateDocumentsOld(
      dispatchAPI,
      message,
      documentType,
      id,
      option._id,
      t,
      setRefreshData
    );
  }
  modal.destroy();
};
