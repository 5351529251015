import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import dayjs from 'dayjs';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSocketContext } from '../../contexts/SocketContext';
import { useTabsItems } from './tabs/tabsItems';
import { useCallsAPI } from './utils/callsAPI';

/**
 * Dashboard component for displaying user data and notifications.
 *
 * @component
 * @returns {React.ReactNode} - The rendered component.
 */
export const Dashboard = () => {
  const [forceRefresh, setForceRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [supervisions, setSupervisions] = useState([]);
  const [supervisionsStatus, setSupervisionsStatus] = useState();
  const [customerInvoices, setCustomerInvoices] = useState({});
  const [internInvoices, setInternInvoices] = useState({});
  const [registrations, setRegistrations] = useState({});
  const [subscriptions, setSubscriptions] = useState({});

  const [statusDate, setStatusDate] = useState(null);
  const [supDate, setSupDate] = useState(null);
  const [financeDate, setFinanceDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [statisticsDate, setStatisticsDate] = useState(
    dayjs().format('YYYY-MM-DD')
  );

  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { socket } = useSocketContext();

  // hooks for get all statistics data
  const {
    getNotifications,
    getSupervisionsData,
    getCustomerInvoicesData,
    getInternInvoicesData,
    getRegistrationsData,
    getSupervisionsStatus,
    getSubscriptions
  } = useCallsAPI({
    setSupervisions,
    setCustomerInvoices,
    setNotifications,
    setInternInvoices,
    setRegistrations,
    setLoading,
    setSupervisionsStatus,
    statusDate,
    supDate,
    financeDate,
    statisticsDate,
    setSubscriptions
  });

  // tabs array for the dashboard
  const { tabsItems } = useTabsItems({
    notifications,
    t,
    forceRefresh,
    setForceRefresh,
    message,
    dispatchAPI,
    user,
    loading,
    supervisions,
    customerInvoices,
    internInvoices,
    registrations,
    supervisionsStatus,
    setStatusDate,
    setSupDate,
    setFinanceDate,
    setStatisticsDate,
    subscriptions,
    statisticsDate
  });

  useEffect(async () => {
    await getNotifications();
  }, []);

  useEffect(async () => {
    await getSupervisionsStatus();
  }, [statusDate]);

  useEffect(async () => {
    await getSupervisionsData();
  }, [supDate]);

  useEffect(async () => {
    await getCustomerInvoicesData();
    await getInternInvoicesData();
  }, [financeDate]);

  useEffect(async () => {
    await Promise.all([getRegistrationsData(), getSubscriptions()]);
  }, [statisticsDate]);

  // socket listeners for notifications
  useEffect(() => {
    if (socket) {
      socket.on('newNotification', () => {
        getNotifications();
      });
      socket.on('deleteNotification', () => {
        getNotifications();
      });
      socket.on('updateNotification', () => {
        getNotifications();
      });
    }
  }, [socket]);

  return (
    <>
      <PageHeaderCustom title={t('basiclayout.menu.dashboard')} />
      <ContentCustom>
        <Tabs items={tabsItems} />
      </ContentCustom>
    </>
  );
};
