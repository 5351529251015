import { Modal } from 'antd';
import { deleteFile } from '../../../owners/form/utils/deleteFile';
import { onPreview } from '../../../../utils/filesManagement/onPreview';
import { fileToBase64 } from '../../../../utils/filesManagement/fileToBase64';
import { fileExtensionManage } from '../../../../utils/filesManagement/fileExtensionManage';
import { compressFile } from '../../../../utils/filesManagement/compressFile';

/**
 * Generates drag-and-drop properties for an Ant Design Modal with picture-card list type.
 *
 * @param {function} t - Translation function for internationalization.
 * @param {function} dispatchAPI - API dispatch function.
 * @param {function} message - Function for displaying messages.
 * @param {Array} fileList - List of files.
 * @param {function} setFileList - Function to set the file list.
 * @returns {Object} - Drag-and-drop properties for Ant Design Modal.
 */
export const ownerDraggerProps = (
  t,
  dispatchAPI,
  message,
  fileList,
  setFileList
) => ({
  listType: 'picture-card',
  onRemove: (file) => {
    Modal.confirm({
      title: t('files.modal.animal_delete'),
      content: t('files.modal.confirm_animal_delete'),
      okText: t('files.modal.ok'),
      cancelText: t('files.modal.cancel'),
      okType: 'warning',
      onOk: () => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        if (file._id) {
          deleteFile(file._id, dispatchAPI, message);
        }
      }
    });
  },
  onPreview: (file) => onPreview(file),
  beforeUpload: async (file) => {
    const newFile = await compressFile(file);
    const fileExtension = file.name.split('.').pop();
    if (fileExtensionManage(fileExtension)) {
      setFileList([
        ...fileList,
        { file: newFile, url: await fileToBase64(file) }
      ]);
      return false;
    }
    message('Not a PNG or JPG file.');
    return false;
  },
  fileList
});
