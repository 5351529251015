import dayjs from 'dayjs';
import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

/**
 * Custom hook for configuring actions related to resource creation, retrieval, and update.
 *
 * @param {Object} options.filesList - List of files.
 * @param {Function} options.setFilesList - Function to set the list of files.
 * @param {Function} options.dispatchAPI - Function for making API requests.
 * @param {Function} options.message - Function to display error messages.
 * @returns {Object} Configured actions for resource creation, retrieval, and update.
 */
export const useConfig = ({
  filesList,
  setFilesList,
  setSupervisionPrices
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        const formData = new FormData();
        if (filesList.length) {
          filesList.forEach((file) => {
            formData.append('files_metadata', file);
          });
        }

        const newData = {
          ...data,
          wordings: data.wordings && Object.values(data.wordings)
        };

        formData.append('values', JSON.stringify(newData));

        return formData;
      }
    },
    onGetResource: {
      setFields: async (data) => {
        if (data?.files.length) {
          try {
            const filePromise = (data.files || []).map((file) =>
              createFileFromJSON(file, dispatchAPI, message)
            );

            const results = await Promise.all(filePromise);
            setFilesList(results.map((file) => file.file));
          } catch (error) {
            message(message(error));
          }
        }

        if (data?.supervision_price)
          setSupervisionPrices({
            ttc: data.supervision_price,
            ht: data.supervision_price_ht
          });

        return {
          ...data,
          emission_date: dayjs(data.emission_date),
          due_date: dayjs(data.due_date)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        const formData = new FormData();
        if (filesList.length) {
          filesList.forEach((file) => {
            formData.append('files_metadata', file);
          });
        }

        const newData = {
          ...data,
          wordings: data.wordings && Object.values(data.wordings)
        };

        formData.append('values', JSON.stringify(newData));

        return formData;
      }
    }
  };

  return { config };
};
