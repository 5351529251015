import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const useFormatter = () => {
  const { t } = useTranslation();

  const setPensionerFullName = (record) => {
    const civility = record?.civility
      ? t(`pensioners.tags.${record?.civility}`)
      : '';
    const lastName = record?.last_name || '';
    const firstName = record?.first_name || '';

    return `${civility} ${lastName} ${firstName}`;
  };

  const setSecondaryPensionerFullName = (record) => {
    const civility = record?.secondary_profile?.civility
      ? t(`pensioners.tags.${record?.secondary_profile?.civility}`)
      : '';
    const lastName = record?.secondary_profile?.last_name || '';
    const firstName = record?.secondary_profile?.first_name || '';

    return `${civility} ${lastName} ${firstName}`;
  };

  const setCustomerReference = (record) => {
    if (record?.subscription) {
      return record?.subscription?.pensioner?.reference
        ? `n° ${record?.subscription?.pensioner?.reference}`
        : '';
    }
    if (record.supervision) {
      return record?.supervision?.main_informations?.owner?.reference
        ? `n° ${record?.supervision?.main_informations?.owner?.reference}`
        : '';
    }
    return '';
  };

  const formattedData = (data) => ({
    ...data,
    phone_number: {
      number: data?.phone_number?.number ? `+33${data.phone_number.number}` : ''
    },
    cell_phone_number: {
      number: data?.cell_phone_number?.number
        ? `+33${data.cell_phone_number.number}`
        : ''
    },
    owner_status: data.status ? t(`owners.tags.${data.status}`) : '',
    pensioner_status: data?.status ? t(`pensioners.tags.${data?.status}`) : '',
    principal_pensioner: setPensionerFullName(data),
    secondary_pensioner: setSecondaryPensionerFullName(data),
    customerinvoice_type: data?.type
      ? t(`customerinvoices.tags.${data?.type}`)
      : '',
    customer:
      (data.customer &&
        `${data?.customer?.last_name || ''} ${
          data?.customer?.first_name || ''
        }`) ||
      '',
    customer_reference: setCustomerReference(data),
    supervision_reference: data?.supervision
      ? `n° ${data?.supervision?.reference}`
      : '',
    due_date: data.due_date ? dayjs(data?.due_date).format('DD/MM/YYYY') : '',
    total_ttc: data?.total_ttc ? `${data?.total_ttc} €` : '',
    customerinvoice_status: data?.status
      ? t(`customerinvoices.tags.${data?.status}`)
      : '',
    interninvoice_status: data?.status
      ? t(`interninvoices.tags.${data?.status}`)
      : '',
    emission_date: data.emission_date
      ? dayjs(data?.emission_date).format('DD/MM/YYYY')
      : ''
  });

  return { formattedData };
};
