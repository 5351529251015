export const initialState = {
  status: '',
  current: 0,
  isLoading: false,
  supervision: {},
  openModal: false,
  anounceModalVisible: false,
  cancelSupervisionModalVisible: false,
  interruptSupervisionModalVisible: false,
  isEmailModalOpen: false,
  anounce: {}
};

/**
 * Reducer function for the SupervisionContext.
 *
 * @param {Object} state - The current state of the context.
 * @param {Object} action - The action object that contains the type and payload.
 * @returns {Object} - The new state after applying the action.
 */
export const supervisionReducer = (state, action) => {
  switch (action.type) {
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload
      };
    case 'SET_CURRENT':
      return {
        ...state,
        current: action.payload
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'SET_SUPERVISION':
      return {
        ...state,
        supervision: action.payload
      };
    case 'SET_ANOUNCE_MODAL_VISIBLE':
      return {
        ...state,
        anounceModalVisible: action.payload
      };
    case 'SET_CANCEL_SUPERVISION_MODAL_VISIBLE':
      return {
        ...state,
        cancelSupervisionModalVisible: action.payload
      };
    case 'SET_INTERRUPT_SUPERVISION_MODAL_VISIBLE':
      return {
        ...state,
        interruptSupervisionModalVisible: action.payload
      };
    case 'SET_IS_EMAIL_MODAL_OPEN':
      return {
        ...state,
        isEmailModalOpen: action.payload
      };
    case 'SET_ANOUNCE':
      return {
        ...state,
        anounce: action.payload
      };
    default:
      return state;
  }
};
