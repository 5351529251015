import { Card, Col, Row, Button, Form, Spin } from 'antd';
import { ExportOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Proptypes from 'prop-types';
import { useState } from 'react';
import { DescriptionList } from '../../../../../components';
import { ownerInfo } from '../listContent/ownerInfo';
import { MailingModal } from '../../../../../components/Mailing/MailingModal';

/**
 * OwnerInfoCard component displays owner information in a card format.
 *
 * @component
 * @param {Object} props - The component's props.
 * @param {Object} props.owner - The owner object containing owner information.
 * @param {boolean} props.isLoading - A flag indicating whether the data is currently being loaded.
 *
 * @return {JSX.Element} The rendered OwnerInfoCard component.
 */
export const OwnerInfoCard = ({ owner, isLoading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [templateType, setTemplateType] = useState('SEND_MAIL');
  const [documentType, setDocumentType] = useState(null);

  return (
    <Spin spinning={isLoading}>
      <Card
        title={t('supervisions.show.owner_infos')}
        extra={[
          <Button
            type="link"
            style={{ fontSize: 18 }}
            onClick={() => {
              setIsEmailModalOpen(true);
              setTemplateType('SEND_MAIL');
              setDocumentType(null);
            }}
          >
            <MailOutlined />
          </Button>,
          <Link to={`/owners/show/${owner?._id}`}>
            <Button type="link" style={{ fontSize: 18 }}>
              <ExportOutlined />
            </Button>
          </Link>
        ]}
      >
        <Row>
          <Col span={24}>
            <DescriptionList data={ownerInfo(owner, t)} translate />
            {isEmailModalOpen && (
              <MailingModal
                isModalOpen={isEmailModalOpen}
                setIsModalOpen={setIsEmailModalOpen}
                recipients={[{ ...owner, customer_type: 'OWNER' }]}
                recipient={owner?.user?._id}
                customFormInstance={form}
                templateType={templateType}
                documentType={documentType}
                collection="owners"
              />
            )}
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

OwnerInfoCard.propTypes = {
  owner: Proptypes.shape({
    _id: Proptypes.string.isRequired,
    user: Proptypes.shape({
      _id: Proptypes.string.isRequired
    }).isRequired
  }).isRequired,
  isLoading: Proptypes.bool.isRequired
};
