export const usersFormDataFormat = ({
  firstStep,
  secondStep,
  lastStep,
  supervision,
  user,
  userRole
}) => ({
  ...firstStep,
  ...secondStep,
  ...lastStep,
  supervision: { _id: supervision?._id },
  author: { id: user?._id, role: user?.role },
  receiver: {
    id:
      userRole === 'users:OWNER'
        ? supervision?.pensioners.map((pensioner) => pensioner._id)
        : supervision?.main_informations?.owner?._id,
    role: userRole === 'users:OWNER' ? 'users:PENSIONER' : 'users:OWNER'
  }
});
