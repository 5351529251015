export const getOwner = async ({
  dispatchAPI,
  setOwner,
  message,
  user,
  setFormDataEnvironment
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `owners?email=${user?.email}&populate=animals,animals.animal_photo,petsitting_address,petsitting_address.dwelling_photos`
    });
    setOwner(data[0]);
    setFormDataEnvironment(
      data[0]?.petsitting_address?.type_of_environment || []
    );
  } catch (e) {
    message(e);
  }
};
