import { Card, Col, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text, Title } = Typography;

export const ReviewsCards = ({ review, t }) => {
  const receiverName = review?.receiver?.id?.map((item) => ({
    first_name: item?.first_name,
    last_name: item?.last_name
  }));

  return (
    <Col span={8}>
      <Card key={review._id}>
        <Title level={5}>
          {t('owners.users_shows.reviews.given_to', ...receiverName)}
        </Title>
        <Text>{review?.comment_or_suggestion}</Text>
      </Card>
    </Col>
  );
};

ReviewsCards.propTypes = {
  review: PropTypes.shape({
    _id: PropTypes.string,
    receiver: PropTypes.shape({
      id: PropTypes.arrayOf(
        PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        })
      )
    }),
    comment_or_suggestion: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired
};
