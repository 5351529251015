import { updateSupervisionStatus } from './updateSupervisionStatus';

/**
 * Handles the submission of an announcement.
 * @async
 * @function
 *
 * @param {Object} options - The options for handling the submission.
 * @param {string} options.status - The status of the announcement.
 * @param {Object} options.form - The form object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {string} options.id - The ID of the supervision.
 * @param {Array} options.selectedAnouncePhotos - The selected announcement photos.
 * @param {Object} options.editorState - The editor state.
 * @param {Function} options.setAnounceModalVisible - The function to set the visibility of the announcement modal.
 * @param {Function} options.setPhotos - The function to set the photos.
 * @param {Function} options.setRefreshData - The function to set the refresh data flag.
 * @param {boolean} options.refreshData - The refresh data flag.
 * @param {Function} options.message - The function to display a message.
 * @returns {Promise<void>} - A promise that resolves when the announcement is submitted.
 */
export const handleSubmitAnounce = async ({
  status,
  form,
  dispatchAPI,
  id,
  selectedAnouncePhotos,
  editorState,
  setAnounceModalVisible,
  setPhotos,
  setRefreshData,
  refreshData,
  message
}) => {
  try {
    const values = await form.validateFields();
    const { data } = await dispatchAPI('POST', {
      url: `/anounces/form?supervision=${id}&status=${status}`,
      body: {
        anounce_photos: selectedAnouncePhotos,
        description: editorState,
        state: values.state
      }
    });
    if (data) {
      setAnounceModalVisible(false);
      setPhotos([]);

      if (status === 'PUBLISHED') {
        updateSupervisionStatus({
          nextStatus: 'PUBLISHED',
          dispatchAPI,
          id,
          setRefreshData,
          refreshData,
          message
        });
      } else {
        setRefreshData(!refreshData);
      }
    }
  } catch (err) {
    message(err);
  }
};
