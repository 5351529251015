import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { initialState, invoicesReducer } from './reducer';
import { useErrorMessage } from '../../utils/errorMessage';
import { useAuthContext } from '../AuthContext';

const InvoicesContext = createContext();

/**
 * Provides the context for managing invoices.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const InvoicesContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [state, dispatch] = useReducer(invoicesReducer, initialState);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getOwnerCustomer = async (ownerId) => {
    try {
      const { data: ownerData } = await dispatchAPI('GET', {
        url: `owners/${ownerId}?populate=user`
      });

      dispatch({ type: 'SET_CUSTOMER', payload: ownerData });
    } catch (error) {
      message(error);
    }
  };

  const getSupervision = async (supervisionId) => {
    try {
      const { data: supervisionData } = await dispatchAPI('GET', {
        url: `supervisions/${supervisionId}`
      });

      dispatch({ type: 'SET_SUPERVISION', payload: supervisionData });
    } catch (error) {
      message(error);
    }
  };

  const getOwnerSupervisions = async (ownerId) => {
    try {
      const { data: supervisionsData } = await dispatchAPI('GET', {
        url: `supervisions?owner=${ownerId}`
      });

      dispatch({ type: 'SET_SUPERVISIONS', payload: supervisionsData });
    } catch (error) {
      message(error);
    }
  };

  const getCustomers = async () => {
    try {
      setIsFieldsLoading(true);
      const filteredCustomers = [];

      // Fetch owners data
      const { data: ownersData } = await dispatchAPI('GET', {
        url: 'owners?populate=user'
      });

      // Fetch pensioners data
      const { data: pensionersData } = await dispatchAPI('GET', {
        url: 'pensioners?populate=user'
      });

      // Map and merge owner data with customer_type 'owner'
      ownersData.map((owner) =>
        filteredCustomers.push({ ...owner, customer_type: 'owner' })
      );

      // Map and merge pensioner data with customer_type 'pensioner'
      pensionersData.map((pensioner) =>
        filteredCustomers.push({ ...pensioner, customer_type: 'pensioner' })
      );

      // Set the customers in the state
      dispatch({ type: 'SET_CUSTOMERS', payload: filteredCustomers });
      setIsFieldsLoading(false);
    } catch (error) {
      message(error);
    }
  };

  const getSupervisions = async () => {
    try {
      // Fetch a list of supervisions from the server
      const { data } = await dispatchAPI('GET', { url: 'supervisions' });

      // Set the list of supervisions in the state
      dispatch({ type: 'SET_SUPERVISIONS', payload: data });

      // Set the selected supervisions in the state
      dispatch({ type: 'SET_SELECTED_SUPERVISIONS', payload: data });
    } catch (error) {
      message(error);
    }
  };

  const getEnums = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      dispatch({ type: 'SET_ENUMS', payload: data });
      setIsFieldsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await Promise.all([getCustomers(), getSupervisions(), getEnums()]);
    })();
  }, []);

  return (
    <InvoicesContext.Provider
      value={{
        ...state,
        dispatch,
        getOwnerCustomer,
        getSupervision,
        getOwnerSupervisions,
        isFieldsLoading
      }}
    >
      {children}
    </InvoicesContext.Provider>
  );
};

export const useInvoicesContext = () => {
  const context = useContext(InvoicesContext);

  if (!context) {
    throw new Error(
      'useInvoicesContext must be used within an InvoicesContextProvider'
    );
  }

  return context;
};
