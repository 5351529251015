/**
 * Calculates and updates the totalHT, totalTTC, and total quantity fields based on the values
 * of individual wordings in the form.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.form - The form instance from which to retrieve and update values.
 * @returns {void}
 */
export const addInternTotalTTC = ({ form }) => {
  let totalHT = 0;
  let totalTTC = 0;

  // Retrieve the values of wordings from the form
  const wordingsValues = form.getFieldValue('wordings') || [];

  // Iterate through each wording and calculate totalHT, totalTTC
  wordingsValues.forEach((wording, index) => {
    let totalTTCValue = null;

    // Extract individual values from the wording
    const unitPrice = wording.unit_price || 0;
    const totalTVAValue = wording.tva || 0;
    const quantity = wording.quantity || 0;

    // Calculate total price and totalTTC for the current wording
    const totalPrice = unitPrice * quantity;
    totalTTCValue = totalPrice + (totalPrice * totalTVAValue) / 100;

    // Update the total_ttc field for the current wording in the form
    form.setFieldValue(
      ['wordings', index, 'total_ttc'],
      totalTTCValue.toFixed(2)
    );

    // Accumulate totalHT and totalTTC values
    totalHT += totalPrice;
    totalTTC += totalTTCValue;

    // Update the total_ht and total_ttc fields in the form
    form.setFieldsValue({
      total_ht: totalHT.toFixed(2),
      total_ttc: totalTTC.toFixed(2)
    });
  });
};
