import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { getSupervisions } from './utils/getSupervisions';
import { getPensioners } from './utils/getPensioners';

/**
 * Composant pour la création/mise à jour d'une réservation.
 * @param {Object} props - Les propriétés du composant.
 * @param {string} props.purpose - Le but de la création/mise à jour de la réservation.
 * @param {boolean} props.isCreateUpdateModalOpen - Indique si la fenêtre modale est ouverte.
 * @param {function} props.setIsCreateUpdateModalOpen - Fonction pour définir l'état de l'ouverture de la fenêtre modale.
 * @param {function} props.setRefreshData - Fonction pour déclencher le rafraîchissement des données.
 * @param {boolean} props.refreshData - Indique si les données doivent être rafraîchies.
 * @param {string} props.customId - Identifiant personnalisé.
 * @param {Object} props.form - Instance du formulaire antd.
 * @param {string[]} props.disabledFields - Liste des champs désactivés.
 * @returns {JSX.Element} Le composant de création/mise à jour de réservation.
 */

export const CreateUpdateReservation = ({
  purpose,
  isCreateUpdateModalOpen,
  setIsCreateUpdateModalOpen,
  setRefreshData,
  refreshData,
  customId,
  form,
  disabledFields
}) => {
  const [supervisions, setSupervions] = useState([]);
  const [pensioners, setPensioners] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { fields } = useFields({
    supervisions,
    pensioners,
    isFieldsLoading,
    disabledFields
  });
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getSupervisions({
        dispatchAPI,
        setSupervions,
        message,
        extraQuery: '?status=PUBLISHED'
      });
      await getPensioners({ dispatchAPI, setPensioners, message });
      setIsFieldsLoading(false);
    })();
  }, []);

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        date: dayjs()
      })
    }
  };

  return (
    <Modal
      footer={null}
      title={t(`reservations.form.modal.${purpose}_title`)}
      open={isCreateUpdateModalOpen}
      onOk={() => setIsCreateUpdateModalOpen(false)}
      onCancel={() => setIsCreateUpdateModalOpen(false)}
      className="create-update-modal"
    >
      <CreateUpdateContainer
        fields={fields}
        loadingFields={isFieldsLoading}
        purpose={purpose}
        baseUrl="reservations"
        resource="reservations"
        withPageHeaderCustom={false}
        config={config}
        customNavigate={() => {
          setIsCreateUpdateModalOpen(false);
          setRefreshData(!refreshData);
        }}
        customId={customId}
        customFormInstance={form}
      />
    </Modal>
  );
};

CreateUpdateReservation.propTypes = {
  purpose: PropTypes.string.isRequired,
  isCreateUpdateModalOpen: PropTypes.bool,
  setIsCreateUpdateModalOpen: PropTypes.func,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool,
  customId: PropTypes.string,
  form: PropTypes.shape({}),
  disabledFields: PropTypes.arrayOf(PropTypes.string)
};

CreateUpdateReservation.defaultProps = {
  isCreateUpdateModalOpen: false,
  setIsCreateUpdateModalOpen: null,
  refreshData: false,
  customId: null,
  disabledFields: [],
  setRefreshData: () => {},
  form: {}
};
