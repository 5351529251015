import { ListResource } from '../../components';
import { useColumns } from './columns';

export const ListSupervisionPrices = () => {
  const columns = useColumns();

  return (
    <ListResource
      resourceName="supervisionprices"
      columns={columns}
      resourceModelName="Supervisionprice"
      withCreateButton={false}
      customActionColumn
      extraQuery="sort=duration&"
      backIcon={false}
    />
  );
};
