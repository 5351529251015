import { Alert, Button, Card, Col, Row, Typography, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useDashboardCards } from './utils/useOwnerPensionerDashboardCards';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { AnounceInfoModal } from './utils/AnounceInfoModal';
import { setConsultingAnounceAuthorization } from './utils/setConsultingAnounceAuthorization';
import { useCallsAPI } from '../usersShows/utils/callAPI';

const { Title, Text } = Typography;

/**
 * OwnerPensionerDashboard component for rendering the dashboard based on user role.
 *
 * @component
 * @returns {React.ReactNode} - The rendered component.
 */
export const OwnerPensionerDashboard = () => {
  const {
    user: { role },
    pensionerInfo,
    ownerInfo,
    getUserProfiles
  } = useAuthContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [authorizationValid, setAuthorizationValid] = useState(false);
  const { pensionerCards, ownerCards } = useDashboardCards({
    authorizationValid,
    pensionerID: pensionerInfo?._id,
    ownerID: ownerInfo?._id,
    role
  });
  const [anounceModalOpen, setAnounceModalOpen] = useState(false);
  const [missingConditionInfo, setMissingConditionInfo] = useState({
    status: '',
    principalProfileNecessaryDocuments: {},
    secondaryProfileNecessaryDocuments: {}
  });
  const [displayInfos, setDisplayInfos] = useState(false);
  const [profile, setProfile] = useState();

  const { getProfile } = useCallsAPI({
    setFunction: (data) => setProfile(data[0])
  });

  useEffect(() => {
    (async () => {
      await getProfile();
    })();
  }, []);

  const cardsRender = {
    'users:PENSIONER': pensionerCards,
    'users:OWNER': ownerCards
  };

  useEffect(async () => {
    await getUserProfiles();
  }, []);

  const newUserInformationskeys = ['civility', 'petsitting_address', 'animals'];
  useEffect(() => {
    if (ownerInfo?._id) {
      const notCompletedOwnerProfile = newUserInformationskeys.every(
        (key) => ownerInfo[key] !== undefined
      );
      setDisplayInfos(notCompletedOwnerProfile);
    }
  }, [ownerInfo]);

  useEffect(() => {
    if (Object.keys(pensionerInfo).length) {
      setConsultingAnounceAuthorization({
        pensionerInfo,
        setMissingConditionInfo,
        setAuthorizationValid
      });
    }
  }, [pensionerInfo]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        withoutBackIcon
        title={t('home.title')}
        extra={
          role === 'users:OWNER' && (
            <Button
              type="primary"
              onClick={() => {
                if (profile?.animals?.length) {
                  return navigate('/supervisions/create');
                }
                return notification.warning({
                  message: t('owners.users_shows.messages.create_animals')
                });
              }}
            >
              <PlusOutlined />
              {t('owners.users_shows.buttons.new_supervision_request')}
            </Button>
          )
        }
      />
      <ContentCustom>
        <Row gutter={[16, 16]}>
          {role === 'users:OWNER' && ownerInfo?._id && !displayInfos && (
            <Alert
              className="dashboard-alert"
              type="info"
              closable
              message={t(
                'owners.users_shows.messages.new_account_informations.title'
              )}
              description={t(
                'owners.users_shows.messages.new_account_informations.message'
              )}
              showIcon
            />
          )}
        </Row>
        <Row gutter={[24, 24]} className="dashboard-card-wrapper">
          {cardsRender[role].map((card) => (
            <Col key={card.title} xs={24} sm={12} lg={8} xxl={6}>
              <Link to={card.link}>
                <Card
                  hoverable
                  className="dashboard-card"
                  onClick={() =>
                    authorizationValid ? false : setAnounceModalOpen(true)
                  }
                >
                  {card.icon}
                  <Title level={4}>{card.title}</Title>
                  <Text>{card.content}</Text>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </ContentCustom>
      {anounceModalOpen && (
        <AnounceInfoModal
          anounceModalOpen={anounceModalOpen}
          setAnounceModalOpen={setAnounceModalOpen}
          missingConditionInfo={missingConditionInfo}
          pensionerInfo={pensionerInfo}
          t={t}
        />
      )}
    </>
  );
};
