import { useEffect, useState } from 'react';
import { Col, Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPwdForm from './ForgotPwdForm';
import ChangePwdForm from './ChangePwdForm';
import Logo from '../../assets/images/Logo-login.png';

/**
 * React component for user authentication login page.
 *
 * @component
 * @returns {JSX.Element} React component.
 */

export const Login = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const { Title } = Typography;
  const [currentForm, setCurrentForm] = useState('login');

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shouldDisplayRegister = queryParams.get('register') === 'true';

  /**
   * Object mapping form names to their corresponding components.
   * @type {Object}
   * @property {JSX.Element} login - Login form component.
   * @property {JSX.Element} register - Register form component.
   * @property {JSX.Element} forgotPwd - Forgot password form component.
   * @property {JSX.Element} changePwd - Change password form component.
   */
  const forms = {
    login: <LoginForm switchForm={(f) => setCurrentForm(f)} />,
    register: <RegisterForm switchForm={(f) => setCurrentForm(f)} />,
    forgotPwd: <ForgotPwdForm switchForm={(f) => setCurrentForm(f)} />,
    changePwd: (
      <ChangePwdForm switchForm={(f) => setCurrentForm(f)} token={token} />
    )
  };

  useEffect(() => {
    if (token) setCurrentForm('changePwd');
  }, [token]);

  return (
    <Layout className="login-layout">
      <Row>
        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          xl={{ span: 7 }}
          xxl={{ span: 6 }}
          className="login-form-col"
        >
          <img
            style={{
              width: 300,
              marginBottom: 56
            }}
            alt="Logo"
            src={Logo}
          />
          <Title className="login-title">
            {t(`login.title.${currentForm}`)}
          </Title>
          {shouldDisplayRegister ? forms.register : forms[currentForm]}
        </Col>
      </Row>
    </Layout>
  );
};
