const ownerPersonalInfo = (data) => {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    cell_phone_number,
    address,
    first_profession,
    second_profession
  } = data;

  const { number = '', additional = '', street = '' } = address || {};

  return [
    {
      label: 'owners.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'owners.form.first_name',
      span: 1,
      content: first_name || '-'
    },
    {
      label: 'owners.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'owners.form.phone_number.number',
      span: 1,
      content: phone_number?.number || '-'
    },
    {
      label: 'owners.form.cell_phone_number.number',
      span: 1,
      content: cell_phone_number?.number || '-'
    },
    {
      label: 'owners.form.address.street',
      span: 1,
      content:
        number || additional || street
          ? `${number} ${additional} ${street}`
          : '-'
    },
    {
      label: 'owners.form.address.country',
      span: 1,
      content: (address && address?.country) || '-'
    },
    {
      label: 'owners.form.first_profession',
      span: 1,
      content: first_profession || '-'
    },
    {
      label: 'owners.form.second_profession',
      span: 1,
      content: second_profession || '-'
    }
  ];
};

export { ownerPersonalInfo };
