import dayjs from 'dayjs';
import { monthArray } from '../monthsArray';

/**
 * Hook for generating monthly supervisions numbers.
 * @param {Object} params - Parameters for useMonthlySupervisionsNumbers hook.
 * @param {Object} params.supervisions - Supervisions data.
 * @param {Function} params.setItems - State setting function for items data.
 * @param {Object} params.items - State for items data.
 * @param {Function} params.t - Translation function for internationalization.
 * @returns {Object} - Object containing monthly supervisions numbers and set function.
 * @property {Array} monthlySupervisionsChartData - Array of objects representing monthly supervisions numbers.
 * @property {Function} setMonthlySupervisionsNumbers - Function to set monthly supervisions numbers.
 */
export const useMonthlySupervisionsNumbers = ({
  supervisions,
  setItems,
  items,
  t
}) => {
  // Function to calculate and set monthly supervisions numbers.
  const setMonthlySupervisionsNumbers = () => {
    const monthCount = {};

    // Iterate over supervisions and calculate counts.
    Object.values(supervisions).forEach((value) => {
      value.forEach((item) => {
        // Calculate month key using dayjs.
        const monthKey = dayjs(item.main_informations.start_date).format(
          'MMMM'
        );

        // Update count for the month.
        monthCount[monthKey] = (monthCount[monthKey] || 0) + 1;
      });
    });

    // Set the calculated counts in the state.
    setItems(monthCount);
  };

  // Array of objects representing monthly supervisions numbers.
  const monthlySupervisionsChartData = monthArray(t).map((month) => ({
    month,
    number: items?.[month.toLowerCase()] || 0
  }));

  return { monthlySupervisionsChartData, setMonthlySupervisionsNumbers };
};
