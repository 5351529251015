import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

/**
 * Returns an array of columns configuration for the supervision prices table.
 * @hook
 * @returns {Array} The array of columns configuration.
 */
export const useColumns = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  return [
    {
      title: t('supervisionprices.form.duration'),
      key: 'duration',
      dataIndex: 'duration',
      render: (duration) => {
        const extra_week = duration?.extra_week;

        return extra_week
          ? duration.extra_week
          : `de ${duration.start_day} à ${duration.end_day} jours`;
      },
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.tva'),
      key: 'rates',
      dataIndex: 'rates',
      sorter: true,
      render: (rates) => {
        const defaultRate = rates.find((rate) => rate.default);
        const actualRate = rates.find((rate) =>
          moment().isBetween(
            moment(rate.application_date).startOf('day'),
            moment(rate.end_date).endOf('day'),
            '[)'
          )
        );

        return actualRate ? `${actualRate?.tva} %` : `${defaultRate?.tva} %`;
      }
    },
    {
      title: t('supervisionprices.form.rate.total_ttc'),
      key: 'rates',
      dataIndex: 'rates',
      sorter: true,
      render: (rates) => {
        const defaultRate = rates.find((rate) => rate.default);
        const actualRate = rates.find((rate) =>
          moment().isBetween(
            moment(rate.application_date).startOf('day'),
            moment(rate.end_date).endOf('day'),
            '[)'
          )
        );

        return actualRate
          ? `${actualRate?.total_ttc} €`
          : `${defaultRate?.total_ttc} €`;
      }
    },
    {
      title: t('supervisionprices.form.rate.advance_payment'),
      key: 'rates',
      dataIndex: 'rates',
      sorter: true,
      render: (rates) => {
        const defaultRate = rates.find((rate) => rate.default);
        const actualRate = rates.find((rate) =>
          moment().isBetween(
            moment(rate.application_date).startOf('day'),
            moment(rate.end_date).endOf('day'),
            '[)'
          )
        );

        return actualRate
          ? `${actualRate?.advance_payment} €`
          : `${defaultRate?.advance_payment} €`;
      }
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <Link
          to={{
            pathname: `${pathname}/show/${record?._id}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      )
    }
  ];
};
