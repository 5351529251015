import { Col, Modal, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * A component that displays a loading modal.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.loadingText - The text to display inside the loading modal.
 * @param {boolean} props.openModal - Whether the loading modal should be open or not.
 * @returns {JSX.Element} The loading modal component.
 */
export const LoadingModal = ({ loadingText, openModal }) => {
  const { t } = useTranslation();

  return (
    <Modal open={openModal} footer={null}>
      <Row style={{ flexDirection: 'column' }} gutter={[8, 8]}>
        <Col align="center" justify="center" span={24}>
          <Spin />
        </Col>
        <Col align="center" justify="center" span={24}>
          {t(`${loadingText}`)}
        </Col>
      </Row>
    </Modal>
  );
};

LoadingModal.propTypes = {
  loadingText: PropTypes.string.isRequired,
  openModal: PropTypes.bool
};

LoadingModal.defaultProps = {
  openModal: false
};
