import dayjs from 'dayjs';

/**
 * Cancels a reservation.
 * @function
 * @param {Object} options - The options for canceling the reservation.
 * @param {string} options.reservationId - The ID of the reservation to cancel.
 * @param {string} options.supervisionId - The ID of the supervision associated with the reservation.
 * @param {string} options.pensionerId - The ID of the pensioner associated with the reservation.
 * @param {Function} options.dispatchAPI - The function to dispatch API requests.
 * @param {Function} options.navigate - The function to navigate to a different page.
 * @param {Function} options.message - The function to display a message.
 * @param {boolean} options.refreshData - The flag indicating whether to refresh the data.
 * @param {Function} options.setRefreshData - The function to set the refresh data flag.
 * @param {Function} options.setIsCancelModalOpen - The function to set the cancel modal open flag.
 * @param {Function} options.setIsLoading - The function to set the loading flag.
 * @param {Function} options.setIsSubmiting - The function to set the submitting flag.
 * @returns {Promise<void>} - A promise that resolves when the reservation is canceled.
 */
export const cancelReservation = async ({
  reservationId,
  supervision,
  pensionerId,
  dispatchAPI,
  navigate,
  message,
  refreshData,
  setRefreshData,
  setIsCancelModalOpen,
  setIsLoading,
  setIsSubmiting = () => {},
  _id
}) => {
  try {
    if (setIsLoading) setIsLoading({ [reservationId]: true });
    setIsSubmiting(true);

    const supervisionId = supervision?._id;

    await dispatchAPI('PATCH', {
      url: `/reservations/${reservationId}`,
      body: { status: 'WAITING' }
    });

    const historyBody =
      supervision?.status === 'BOOKED'
        ? {
            $push: {
              history: { type: 'UNBOOKED_SUPERVISION', date: dayjs(), by: _id }
            }
          }
        : {};

    const { data: supervisionData } = await dispatchAPI('PATCH', {
      url: `/supervisions/${supervisionId}`,
      body: {
        $pull: { pensioners: pensionerId },
        ...historyBody
      }
    });

    if (!supervisionData.pensioners.length) {
      await dispatchAPI('PATCH', {
        url: `/supervisions/${supervisionId}`,
        body: { status: 'PUBLISHED' }
      });
    }
    const { data } = await dispatchAPI('GET', {
      url: `/anounces/form?supervision=${supervisionId}&populate=supervision`
    });

    if (data.length) {
      await dispatchAPI('PATCH', {
        url: `/anounces/${data[0]._id}`,
        body: { status: 'PUBLISHED' }
      });
    }

    await dispatchAPI('PATCH', {
      url: `/pensioners/${pensionerId}`,
      body: { is_in_supervision: false }
    });

    const { data: customerInvoices } = await dispatchAPI('GET', {
      url: `/customerinvoices?supervision=${supervisionId}`
    });

    let deletedInvoicePromises;
    if (customerInvoices?.length) {
      deletedInvoicePromises = customerInvoices.map(async (customerInvoice) => {
        await dispatchAPI('DELETE', {
          url: `/customerinvoices/${customerInvoice._id}`
        });
      });
      await Promise.all(deletedInvoicePromises);
    }

    if (setIsLoading) setIsLoading({ [reservationId]: false });

    if (navigate) {
      navigate(`/supervisions/show/${supervisionId}`);
    }
    if (setRefreshData) {
      setRefreshData(!refreshData);
    }
    if (setIsCancelModalOpen) {
      setIsCancelModalOpen(false);
    }
    setIsSubmiting(false);
  } catch (e) {
    message(e);
  } finally {
    setIsSubmiting(false);
  }
};
