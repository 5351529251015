import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { SetAnounceMessages } from './SetAnounceMessages';

/**
 * AnounceInfoModal component for displaying announcement information in a modal.
 * @param {Object} props - Props for AnounceInfoModal component.
 * @param {boolean} props.anounceModalOpen - Flag indicating whether the modal is open.
 * @param {Function} props.setAnounceModalOpen - Function to set the modal open state.
 * @param {Object} props.missingConditionInfo - Information for missing conditions.
 * @param {Object} props.pensionerInfo - Information for pensioners.
 * @param {Function} props.t - Translation function for internationalization.
 * @returns {JSX.Element} - AnounceInfoModal JSX element.
 */
export const AnounceInfoModal = ({
  anounceModalOpen,
  setAnounceModalOpen,
  missingConditionInfo,
  pensionerInfo,
  t
}) => (
  <Modal
    title={t('dashboard.messages.modal_title')}
    visible={anounceModalOpen}
    onOk={() => setAnounceModalOpen(false)}
    onCancel={() => setAnounceModalOpen(false)}
  >
    <SetAnounceMessages
      missingConditionInfo={missingConditionInfo}
      pensionerInfo={pensionerInfo}
      t={t}
    />
  </Modal>
);

AnounceInfoModal.propTypes = {
  anounceModalOpen: PropTypes.bool.isRequired,
  setAnounceModalOpen: PropTypes.func.isRequired,
  missingConditionInfo: PropTypes.shape({}).isRequired,
  pensionerInfo: PropTypes.shape({}).isRequired,
  t: PropTypes.func.isRequired
};
