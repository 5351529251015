import { Route, Routes } from 'react-router-dom';
import { ListPensioners } from './ListPensioners';
import { CreateUpdatePensioner } from './CreateUpdatePensioner';
import { ShowPensioner } from './ShowPensioner/ShowPensioner';
import { Exception } from '../../components';

export const PensionerRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdatePensioner purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePensioner purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowPensioner />} />
    <Route index element={<ListPensioners />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
