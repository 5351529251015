import { DatePicker, Input } from 'antd';

export const useAnounceInfoFields = () => {
  const anounceFields = [
    {
      name: ['reference'],
      rules: [{ required: true }],
      input: <Input disabled />
    },
    {
      name: ['start_date'],
      input: <DatePicker disabled />
    },
    {
      name: ['end_date'],
      input: <DatePicker disabled />
    },
    {
      name: ['state']
    }
  ];

  return { anounceFields };
};
