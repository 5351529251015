import { Route, Routes } from 'react-router-dom';
import { ListSupervisions } from './ListSupervisions';
import { CreateUpdateSupervision } from './CreateUpdateSupervision';
import { ShowSupervision } from './ShowSupervision';
import { Exception } from '../../components';
import { SupervisionContextProvider } from '../../contexts/SupervisionContext/SupervisionContext';

/**
 * Renders the SupervisionRouter component.
 * @component
 *
 * @returns {JSX.Element} The rendered SupervisionRouter component.
 */
export const SupervisionRouter = () => (
  <SupervisionContextProvider>
    <Routes>
      <Route
        path="/create"
        element={<CreateUpdateSupervision purpose="create" />}
      />
      <Route
        path="/edit/:id"
        element={<CreateUpdateSupervision purpose="edit" />}
      />
      <Route path="/show/:id" element={<ShowSupervision />} />
      <Route index element={<ListSupervisions />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </SupervisionContextProvider>
);
