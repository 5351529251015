import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Divider } from 'antd';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { VeterinarianCard } from './cards/VeterinarianCard';
import { GroomerCard } from './cards/GroomerCard';
import { useCallsAPI } from '../utils/callAPI';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useAuthContext } from '../../../contexts/AuthContext';

export const ShowVeterinarianGroomer = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();

  const [owner, setOwner] = useState(null);
  const [veterinarian, setVeterinarian] = useState([]);
  const [groomer, setGroomer] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const { getProfile } = useCallsAPI({
    setFunction: (data) => setOwner(data[0]),
    setIsLoading,
    populate: 'petsitting_address'
  });

  const getVeterinarianGroomer = async () => {
    try {
      const { data: vete } = await dispatchAPI('GET', {
        url: `veterinarians/details/${owner?._id}`
      });
      setVeterinarian(vete);

      const { data: groom } = await dispatchAPI('GET', {
        url: `groomers/details/${owner?._id}`
      });
      setGroomer(groom);

      setIsFieldsLoading(false);
    } catch (error) {
      message(error);
    }
  };

  useEffect(async () => {
    await getProfile();
  }, []);

  useEffect(async () => {
    if (owner) await getVeterinarianGroomer();
  }, [owner]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        title={t('owners.users_shows.title.veterinarian_groomer')}
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <VeterinarianCard
            veterinarian={veterinarian}
            navigate={navigate}
            owner={owner}
            isFieldsLoading={isFieldsLoading}
          />
        </Skeleton>
        <Divider />
        <Skeleton loading={isLoading} active>
          <GroomerCard
            owner={owner}
            groomers={groomer}
            navigate={navigate}
            isFieldsLoading={isFieldsLoading}
          />
        </Skeleton>
      </ContentCustom>
    </>
  );
};
