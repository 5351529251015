/**
 * Deletes a MailSender entry by marking it as archived.
 *
 * @function
 * @param {Object} params - The parameters for deleting the MailSender entry.
 * @param {string} params.id - The ID of the MailSender entry to be deleted.
 * @param {function} params.dispatchAPI - A function for dispatching API requests.
 * @param {function} params.message - A function for displaying error messages.
 * @param {boolean} params.refreshData - Indicates whether to refresh the data after deletion.
 * @param {function} params.setRefreshData - A function to set the refreshData state.
 */

export const deleteMailSender = async ({
  id,
  dispatchAPI,
  message,
  refreshData,
  setRefreshData
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/mail-sender/${id}`,
      body: { is_archived: true, status: 'ARCHIVED' }
    });
    setRefreshData(!refreshData);
  } catch (e) {
    message(e);
  }
};
