import dayjs from 'dayjs';

/**
 * Set the status of an invoice to 'UNPAID' and perform additional actions based on the invoice details.
 *
 * @param {Object} options - Options for setting the invoice status to 'UNPAID'.
 * @param {Function} options.dispatchAPI - Function to dispatch API requests.
 * @param {Function} options.message - Function to display error messages.
 * @param {string} options.id - The ID of the invoice to be updated.
 * @param {boolean} options.refreshData - The current state of the data refresh flag.
 * @param {Function} options.setRefreshData - Function to set the data refresh flag.
 * @param {Object} options.invoice - The invoice data.
 * @returns {Promise<void>} A promise that resolves once the invoice status is updated successfully.
 */
export const setInvoiceStatusToUnpaid = async ({
  dispatchAPI,
  message,
  id,
  refreshData,
  setRefreshData,
  invoice
}) => {
  try {
    // Prepare form data for the PATCH request
    const formData = new FormData();
    const newBody = {
      status: 'UNPAID'
    };
    formData.append('values', JSON.stringify(newBody));

    // Update the invoice status to 'UNPAID'
    await dispatchAPI('PATCH', {
      url: `customerinvoices/form/${id}`,
      body: formData
    });

    // Additional actions based on the invoice details
    if (invoice) {
      const emissionDate = dayjs(invoice.emission_date);
      const dueDate = dayjs(invoice.due_date);
      const subscriptionStartDate = dayjs(invoice.subscription_start_date);
      const subscriptionEndDate = dayjs(invoice.subscription_end_date);

      // Check if the invoice is related to a subscription
      if (
        emissionDate.startOf('day') < subscriptionStartDate &&
        dueDate.endOf('day') > subscriptionEndDate
      ) {
        // If conditions match, update the pensioner's status to 'ACTIVE'
        await dispatchAPI('PATCH', {
          url: `pensioners/${invoice.subscription.pensioner}`,
          body: { status: 'INACTIVE' }
        });
      }
    }

    setRefreshData(!refreshData);
  } catch (e) {
    message(e);
  }
};
