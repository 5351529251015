import { Route, Routes } from 'react-router-dom';
import { ListOwners } from './ListOwners';
import { CreateUpdateOwner } from './form/CreateUpdateOwner';
import { ShowOwner } from './showOwner/ShowOwner';
import { Exception } from '../../components';
import { OwnerContextProvider } from '../../contexts/OwnerContext/OwnerContext';

/**
 * The `OwnerRouter` component is a router component that directs users to different pages
 * related to owner operations like creating, editing, showing a list, or showing details of an owner.
 * It uses the 'react-router-dom' library to manage routing.
 *
 * @component
 * @example
 *
 * @returns {React.Element}
 * return (
 *   <OwnerRouter />
 * );
 */
export const OwnerRouter = () => (
  <OwnerContextProvider>
    <Routes>
      <Route path="/create" element={<CreateUpdateOwner purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateOwner purpose="edit" />} />
      <Route path="/show/:id" element={<ShowOwner />} />
      <Route index element={<ListOwners />} />
      <Route path="*" element={<Exception />} />
    </Routes>
  </OwnerContextProvider>
);
