import { useTranslation } from 'react-i18next';
import profileIcon from '../../../assets/images/noto_bust-in-silhouette.svg';
import documentIcon from '../../../assets/images/noto_open-file-folder.svg';
import settingsIcon from '../../../assets/images/noto_gear.svg';
import supervisionIcon from '../../../assets/images/noto_old-key.svg';
import addressIcon from '../../../assets/images/noto_house.svg';
import petIcon from '../../../assets/images/noto_paw-prints.svg';
import vetIcon from '../../../assets/images/noto_health-worker-medium-light-skin-tone.svg';
import adIcon from '../../../assets/images/noto_rolled-up-newspaper.svg';
import subscriptionIcon from '../../../assets/images/noto_fleur-de-lis.svg';

/**
 * Custom hook for generating dashboard cards based on user type and authorization validity.
 * @param {Object} options - Options for generating dashboard cards.
 * @param {boolean} options.authorizationValid - Flag indicating whether the authorization is valid.
 * @returns {Object} - Object containing arrays of pensionerCards and ownerCards.
 */
export const useDashboardCards = ({
  authorizationValid,
  ownerID,
  pensionerID,
  role
}) => {
  const { t } = useTranslation();

  /**
   * Profile card data for the dashboard.
   * @type {Object}
   */
  const profileCard = {
    icon: (
      <img src={profileIcon} alt={t('dashboard.cards_titles.my_profile')} />
    ),
    title: t('dashboard.cards_titles.my_profile'),
    content: t('dashboard.cards_contents.profile'),
    link: `/profile/${role === 'users:OWNER' ? ownerID : pensionerID}`
  };

  /**
   * Documents card data for the dashboard.
   * @type {Object}
   */
  const documentsCard = {
    icon: (
      <img src={documentIcon} alt={t('dashboard.cards_titles.my_documents')} />
    ),
    title: t('dashboard.cards_titles.my_documents'),
    content: t('dashboard.cards_contents.documents'),
    link: '/documents'
  };

  /**
   * Settings card data for the dashboard.
   * @type {Object}
   */
  const settings = {
    icon: <img src={settingsIcon} alt={t('dashboard.cards_titles.settings')} />,
    title: t('dashboard.cards_titles.settings'),
    content: t('dashboard.cards_contents.settings'),
    link: '/settings'
  };

  /**
   * Cards data for pensioners.
   * @type {Object[]}
   */
  const pensionerCards = [
    profileCard,
    {
      icon: <img src={adIcon} alt={t('dashboard.cards_titles.view_ads')} />,
      title: t('dashboard.cards_titles.view_ads'),
      content: t('dashboard.cards_contents.view_ads'),
      link: authorizationValid ? '/view-ads' : ''
    },
    {
      icon: (
        <img
          src={supervisionIcon}
          alt={t('dashboard.cards_titles.my_supervisions')}
        />
      ),
      title: t('dashboard.cards_titles.my_supervisions'),
      content: t('dashboard.cards_contents.supervisions'),
      link: '/supervisions'
    },
    {
      icon: (
        <img
          src={subscriptionIcon}
          alt={t('dashboard.cards_titles.subscription')}
        />
      ),
      title: t('dashboard.cards_titles.subscription'),
      content: t('dashboard.cards_contents.subscription'),
      link: '/subscription/details'
    },
    documentsCard,
    settings
  ];

  /**
   * Cards data for owners.
   * @type {Object[]}
   */
  const ownerCards = [
    profileCard,
    {
      icon: (
        <img
          src={addressIcon}
          alt={t('dashboard.cards_titles.my_petsitting_address')}
        />
      ),
      title: t('dashboard.cards_titles.my_petsitting_address'),
      content: t('dashboard.cards_contents.petsitting_address'),
      link: `/petsitting-address`
    },
    {
      icon: <img src={petIcon} alt={t('dashboard.cards_titles.my_pets')} />,
      title: t('dashboard.cards_titles.my_pets'),
      content: t('dashboard.cards_contents.my_pets'),
      link: `/my-pets/${ownerID}`
    },
    {
      icon: (
        <img
          src={supervisionIcon}
          alt={t('dashboard.cards_titles.my_supervisions')}
        />
      ),
      title: t('dashboard.cards_titles.my_supervisions'),
      content: t('dashboard.cards_contents.supervisions'),

      link: '/supervisions'
    },
    {
      icon: (
        <img
          src={vetIcon}
          alt={t('dashboard.cards_titles.veterinarian_groomer')}
        />
      ),
      title: t('dashboard.cards_titles.veterinarian_groomer'),
      content: t('dashboard.cards_contents.veterinarian_groomer'),
      link: '/veterinarian-groomer'
    },
    documentsCard,
    settings
  ];

  return {
    pensionerCards,
    ownerCards
  };
};
