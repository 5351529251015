import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../errorMessage';

/**
 * Custom hook for viewing a document.
 * @hook
 *
 * @param {function} setIsLoading - A function to set the loading state.
 * @returns {object} - An object containing the viewDocument function.
 */
export const useViewDocument = (setIsLoading) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  /**
   * Function to view a document.
   * @async
   * @function
   *
   * @param {object} doc - The document object to view.
   * @returns {void}
   */
  const viewDocument = async (doc) => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/files/${doc._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });

      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  return { viewDocument };
};
