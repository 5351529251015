import PropTypes from 'prop-types';
import {
  Button,
  Col,
  List,
  Skeleton,
  Typography,
  Row,
  Upload,
  Select,
  notification,
  Space,
  Flex
} from 'antd';
import {
  DownloadOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  InboxOutlined
} from '@ant-design/icons';
import { useState } from 'react';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useDocumentsManagement } from '../utils/documentsManagement';
import { useViewDocument } from '../../../../utils/filesManagement/viewDocument';
import { LoadingModal } from '../../../../components/LoadingModal/LoadingModal';

const { Dragger } = Upload;

/**
 * Renders a list of documents for a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object.
 * @param {boolean} props.isLoading - Indicates if the component is in a loading state.
 * @param {function} props.setIsLoading - A function to set the loading state.
 * @param {boolean} props.refreshData - Indicates if the data needs to be refreshed.
 * @param {function} props.setRefreshData - A function to set the refresh data state.
 * @param {function} props.t - A translation function.
 * @returns {JSX.Element} The rendered component.
 */
export const ListDocuments = ({
  supervision,
  isLoading,
  setIsLoading,
  refreshData,
  setRefreshData,
  t
}) => {
  const [filesList, setFilesList] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const { downloadDocument } = useDownloadDocument();
  const { viewDocument } = useViewDocument(setDownloading);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { selectOptions } = useDocumentsManagement(supervision, t, user);

  const handleSelectChange = (value, file) => {
    const newFile = file;
    newFile.documentType = value;
    newFile.status = 'done';
    const findIndex = filesList.findIndex((f) => f.newFile.uid === newFile.uid);
    if (findIndex !== -1) {
      const updatedFileList = [...filesList];
      updatedFileList[findIndex] = { newFile };
      setFilesList(updatedFileList);
    } else {
      setFilesList((prevFileList) => [
        ...prevFileList,
        {
          newFile
        }
      ]);
    }
  };

  const draggerProps = () => ({
    onRemove: (file) => {
      setFilesList(filesList.filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const newFile = file;
      newFile.status = 'error';
      setFilesList((prevFileList) => [
        {
          ...prevFileList,
          newFile
        }
      ]);

      return false;
    },
    // eslint-disable-next-line react/no-unstable-nested-components
    itemRender: (originNode, file) => (
      <Row>
        <Col span={12}>
          <div>{originNode}</div>
        </Col>
        <Col span={12}>
          <Select
            onChange={(value) => handleSelectChange(value, file)}
            style={{ height: 22, width: '100%' }}
            options={selectOptions}
          />
        </Col>
      </Row>
    ),
    filesList
  });

  const translateDocumentType = {
    PENSIONER_CONTRACT_1: t(
      'supervisions.show.documents.PENSIONER_CONTRACT_1',
      { reference: supervision.pensioners?.[0]?.reference }
    ),
    PENSIONER_RATING_SHEET_1: t(
      'supervisions.show.documents.PENSIONER_RATING_SHEET_1'
    ),
    OWNER_CONTRACT__: t('supervisions.show.documents.OWNER_CONTRACT'),
    OWNER_RATING_SHEET__: t('supervisions.show.documents.OWNER_RATING_SHEET'),
    OTHER_DOCUMENT__: t('supervisions.show.documents.OTHER_DOCUMENT')
  };

  const arrayDocumentType = ['PENSIONER', 'OWNER', 'OTHER'];

  const idByDocumentType = {
    OWNER: () => supervision?.main_informations?.owner.user._id,
    PENSIONER: () =>
      supervision.pensioners.map((pensioner) => pensioner.user._id),
    OTHER: () => user?._id
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const data = {};

    if (filesList.length) {
      filesList.forEach((file) => {
        if (file.newFile.status === 'error') {
          return notification.warning({
            message: t('supervisions.show.documents.message')
          });
        }

        if (
          arrayDocumentType.includes(file.newFile.documentType.split('_')[0])
        ) {
          data[file.newFile.documentType.slice(0, -2)] =
            idByDocumentType[file.newFile.documentType.split('_')[0]]();
        }

        return formData.append(
          file.newFile.documentType,
          file.newFile.originFileObj
        );
      });

      formData.append('values', JSON.stringify(data));

      if (filesList.every((file) => file.newFile.status === 'done')) {
        try {
          await dispatchAPI('PATCH', {
            url: `/supervisions/documents/${supervision._id}`,
            body: formData
          });

          setRefreshData(!refreshData);
          setIsLoading(false);
        } catch (error) {
          message(error);
        }
      }
    }
    setFilesList([]);
  };

  const filteredDocuments = {
    'users:PENSIONER': supervision?.documents?.filter(
      (doc) => doc.user === user._id
    ),
    'users:OWNER': supervision?.documents?.filter(
      (doc) => doc.user === supervision?.main_informations?.owner.user._id
    )
  };

  return (
    <Flex vertical>
      <Typography.Title className="underlined" level={5}>
        Documents
      </Typography.Title>
      <Row>
        <Col xs={24} lg={16}>
          <List
            className="demo-loadmore-list"
            loading={isLoading}
            itemLayout="horizontal"
            dataSource={filteredDocuments[user.role]}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    onClick={async () => {
                      await viewDocument(item._id);
                    }}
                  >
                    <EyeOutlined />
                  </Button>,
                  <Button
                    type="link"
                    onClick={() =>
                      downloadDocument({
                        _id: item._id._id,
                        metadata: item._id.metadata,
                        contentType: item._id.contentType
                      })
                    }
                  >
                    <DownloadOutlined />
                  </Button>
                ]}
              >
                <Skeleton avatar title={false} loading={item.loading} active>
                  <List.Item.Meta
                    avatar={<CheckCircleOutlined />}
                    description={
                      <Space>
                        <p>{item?._id?.metadata?.originalName}</p>
                        <p>
                          {translateDocumentType[item?._id?.metadata?.type]}
                        </p>
                      </Space>
                    }
                  />
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} lg={8}>
          <Col span={24}>
            <Dragger {...draggerProps()}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('files.create.action')}</p>
            </Dragger>
            <Button onClick={() => handleSubmit()}>{t('buttons.save')}</Button>
          </Col>
        </Col>
      </Row>
      <LoadingModal
        openModal={downloading}
        loadingText="files.modal.preparing_document"
      />
    </Flex>
  );
};

ListDocuments.propTypes = {
  supervision: PropTypes.shape({
    _id: PropTypes.string,
    main_informations: PropTypes.shape({
      owner: PropTypes.shape({
        user: PropTypes.shape({
          _id: PropTypes.string
        })
      })
    }),
    pensioners: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
        user: PropTypes.shape({
          _id: PropTypes.string
        })
      })
    ),
    documents: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string }))
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
