import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Form, Modal, Button, Row, Col } from 'antd';
import { usePaidInvoiceFields } from '../customers/fields/paidInvoiceFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { paidInvoice } from '../customers/utils/paidInvoice';

/**
 * Composant Modal pour marquer une facture comme payée.
 * @component
 * @param {Object} props - Les propriétés du composant.
 * @param {Object} props.enums - Les énumérations pour les statuts et types de facture.
 * @param {boolean} props.isPaidInvoiceModalOpen - Indique si la modal est ouverte.
 * @param {Function} props.setIsPaidInvoiceModalOpen - Fonction pour définir l'état de l'ouverture de la modal.
 * @param {Function} props.t - Fonction pour traduire les textes.
 * @param {string} props.id - L'identifiant de la facture.
 * @param {Function} props.dispatchAPI - Fonction de dispatch pour appeler l'API.
 * @param {Function} props.message - Fonction pour afficher un message d'erreur.
 * @param {Function} props.setRefreshData - Fonction pour définir l'état de rafraîchissement des données.
 * @param {boolean} props.refreshData - Indique si les données doivent être rafraîchies.
 * @returns {JSX.Element}
 */

export const PaidInvoiceModal = ({
  enums,
  isPaidInvoiceModalOpen,
  setIsPaidInvoiceModalOpen,
  t,
  id,
  dispatchAPI,
  message,
  setRefreshData,
  refreshData,
  resourceName,
  invoice
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { paidInvoiceFields } = usePaidInvoiceFields({ enums, t });
  const generateFields = useGenerateFormItem();

  useEffect(() => {
    form.setFieldsValue({
      payment_date: dayjs(),
      payment_amount: invoice.total_ttc - (invoice.payment_amount || 0)
    });
  }, []);

  return (
    <Modal
      title={t('customerinvoices.show.modals.paid_invoice')}
      open={isPaidInvoiceModalOpen}
      onOk={() => setIsPaidInvoiceModalOpen(false)}
      onCancel={() => setIsPaidInvoiceModalOpen(false)}
      footer={null}
    >
      <Form
        form={form}
        onFinish={(body) =>
          paidInvoice({
            body,
            dispatchAPI,
            message,
            id,
            setIsPaidInvoiceModalOpen,
            setRefreshData,
            refreshData,
            resourceName,
            invoice,
            setLoading
          })
        }
      >
        {paidInvoiceFields &&
          paidInvoiceFields.map((field) =>
            generateFields('customerinvoices', field)
          )}
        <Row>
          <Col>
            <Form.Item>
              <Button onClick={() => setIsPaidInvoiceModalOpen(false)}>
                {t('buttons.cancel')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button loading={loading} htmlType="submit">
                {t('buttons.save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

PaidInvoiceModal.propTypes = {
  enums: PropTypes.shape({}).isRequired,
  isPaidInvoiceModalOpen: PropTypes.bool.isRequired,
  setIsPaidInvoiceModalOpen: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  resourceName: PropTypes.string.isRequired,
  invoice: PropTypes.shape({
    total_ttc: PropTypes.number,
    payment_amount: PropTypes.number
  }).isRequired
};
