export const archiveGeneralCondition = async ({
  dispatchAPI,
  message,
  record
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `general-conditions/${record._id}`,
      body: { is_archived: true }
    });
  } catch (error) {
    message(error);
  }
};
