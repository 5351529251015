import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Button } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { MailOutlined, EyeOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import ReservationModal from '../../../../reservation/ReservationModal';

export const ListReservations = ({
  supervisionId,
  refreshData,
  setRefreshData,
  supervision
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [pensionerId, setPensionerId] = useState(null);
  const [reservationId, setReservationId] = useState(null);

  const formatPensionerName = (pensioner) => {
    let result;
    if (pensioner) {
      result = `${
        pensioner?.civility ? t(`pensioners.tags.${pensioner?.civility}`) : ''
      } ${pensioner?.last_name || ''} ${pensioner?.first_name || ''}`;
    }
    if (
      pensioner?.secondary_profile &&
      Object.keys(pensioner?.secondary_profile)?.length
    ) {
      result += `${
        pensioner?.secondary_profile?.civility
          ? t(`pensioners.tags.${pensioner?.secondary_profile?.civility}`)
          : ''
      }  ${pensioner?.secondary_profile?.last_name || ''} ${
        pensioner?.secondary_profile?.first_name || ''
      }`;
    }

    return result;
  };

  const columns = [
    {
      title: t('reservations.form.pensioner.reference'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => (pensioner ? pensioner?.reference : '-'),
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.name'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => (pensioner ? formatPensionerName(pensioner) : '-'),
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.address.city'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => (pensioner ? pensioner?.address?.city : '-'),
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.address.postal_code'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) =>
        pensioner ? pensioner?.address?.postal_code : '-',
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.email'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => (pensioner ? pensioner?.email : '-'),
      sorter: true
    },
    {
      title: t('reservations.form.date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : '-'),
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.phone_number'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) =>
        pensioner ? pensioner?.phone_number?.number : '-',
      sorter: true
    },
    {
      title: t('reservations.form.comment'),
      key: 'comment',
      dataIndex: 'comment',
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => {
        const pensionerAlreadyHasReservation = reservations.some(
          (reservation) =>
            reservation.status === 'RESERVE' &&
            reservation.supervision._id !== record?.supervision?._id &&
            reservation.pensioner._id === record?.pensioner?._id &&
            !(
              dayjs(
                reservation.supervision.main_informations.end_date
              ).isBefore(record.supervision.main_informations.start_date) ||
              dayjs(
                reservation.supervision.main_informations.start_date
              ).isAfter(record.supervision.main_informations.end_date)
            )
        );

        return (
          <>
            <MailOutlined style={{ fontSize: 18 }} />
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/pensioners/show/${record?.pensioner?._id}`
              }}
            >
              <EyeOutlined style={{ fontSize: 18 }} />
            </Link>
            {record.status === 'WAITING' &&
            !reservations.some((element) => element.status === 'RESERVE') &&
            !pensionerAlreadyHasReservation ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() => {
                    setIsModalOpen(true);
                    setPensionerId(record?.pensioner?._id);
                    setReservationId(record._id);
                  }}
                >
                  <CheckOutlined style={{ fontSize: 18, color: 'green' }} />
                </Button>
              </>
            ) : null}
          </>
        );
      }
    }
  ];

  return (
    <>
      <ListResource
        forceRefresh={refreshData}
        resourceName="reservations"
        columns={columns}
        extraQuery={`supervision=${supervisionId}`}
        populate="supervision,pensioner,supervision.address.petsitting_address"
        customActionColumn
        withPageHeader={false}
        withCreateButton={false}
        setResourceData={setReservations}
      />
      <ReservationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        t={t}
        supervision={supervision}
        reservations={reservations}
        pensionerId={pensionerId}
        reservationId={reservationId}
        message={message}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        dispatchAPI={dispatchAPI}
      />
    </>
  );
};

ListReservations.propTypes = {
  supervisionId: PropTypes.string.isRequired,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func,
  supervision: PropTypes.shape({}).isRequired
};

ListReservations.defaultProps = {
  refreshData: false,
  setRefreshData: () => {}
};
