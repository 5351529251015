import { createFileFromJSON } from './filesManagement/createFileFromJSON';

/**
 * Formats and uploads new pet sitting address files.
 * @function
 * @async
 * @param {Object} options - The options object.
 * @param {Array} options.petsittingAddressFileList - The list of pet sitting address files.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {string} options.message - The message.
 * @param {Function} options.setPetsittingAddressFileList - The setPetsittingAddressFileList function.
 * @returns {Promise<void>} - A promise that resolves when the files are formatted and uploaded.
 */
export const formatNewPetsittingAddressFiles = async ({
  petsittingAddressFileList,
  dispatchAPI,
  message,
  setPetsittingAddressFileList
}) => {
  const formData = new FormData();
  await petsittingAddressFileList.map(async (file, index) => {
    if (!file._id) {
      formData.append('dwelling_photos', file.file, file.file.name);

      const { data } = await dispatchAPI('POST', {
        url: 'petsittingaddresses/dwelling_photos',
        body: formData
      });

      if (data) {
        const formattedFile = await createFileFromJSON(
          data,
          dispatchAPI,
          message
        );

        if (formattedFile) {
          const newFiles = [...petsittingAddressFileList];
          newFiles[index] = formattedFile;
          setPetsittingAddressFileList(newFiles);
        }
      }
    }
  });
};
