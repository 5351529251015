/**
 * Custom hook for generating details cards based on the selected tab.
 * @function
 *
 * @param {string} tabsKey - The key of the selected tab.
 * @param {function} t - The translation function.
 * @param {object} supervision - The supervision object.
 * @returns {Array} - An array of details cards.
 */
export const detailsCards = (tabsKey, t, supervision = {}) => {
  const { address: { petsitting_address: { address } = {} } = {}, comment } =
    supervision;

  return [
    ...(tabsKey === 'BOOKED'
      ? [
          {
            label: t('supervisions.show.petsitting_address'),
            content:
              `${address?.number || ''} ${address?.additional || ''} ${
                address?.street || ''
              } ${address?.postal_code || ''} ${address?.city || ''} ${
                address?.country || ''
              }` || '-',
            comment: comment || '-',
            withDivider: true
          }
        ]
      : [
          {
            label: t('supervisions.show.city'),
            content: address?.city || '-',
            withDivider: true
          },
          {
            label: t('supervisions.show.department'),
            content: address?.state || '-',
            comment: comment || '-',
            withDivider: true
          }
        ])
  ];
};
