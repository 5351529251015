/**
 * Represents a mapping of European countries to their corresponding country codes.
 * @typedef {Object} EuropeanCountries
 * @property {string} Allemagne - The country code for Germany.
 * @property {string} Autriche - The country code for Austria.
 * @property {string} Belgique - The country code for Belgium.
 * @property {string} Bulgarie - The country code for Bulgaria.
 * @property {string} Croatie - The country code for Croatia.
 * @property {string} Danemark - The country code for Denmark.
 * @property {string} Espagne - The country code for Spain.
 * @property {string} Estonie - The country code for Estonia.
 * @property {string} Finlande - The country code for Finland.
 * @property {string} France - The country code for France.
 * @property {string} Grèce - The country code for Greece.
 * @property {string} Hongrie - The country code for Hungary.
 * @property {string} Irlande - The country code for Ireland.
 * @property {string} Islande - The country code for Iceland.
 * @property {string} Italie - The country code for Italy.
 * @property {string} Lettonie - The country code for Latvia.
 * @property {string} Liechtenstein - The country code for Liechtenstein.
 * @property {string} Lituanie - The country code for Lithuania.
 * @property {string} Luxembourg - The country code for Luxembourg.
 * @property {string} Malte - The country code for Malta.
 * @property {string} Norvège - The country code for Norway.
 * @property {string} Pays-Bas - The country code for Netherlands.
 * @property {string} Pologne - The country code for Poland.
 * @property {string} Portugal - The country code for Portugal.
 * @property {string} République tchèque - The country code for Czech Republic.
 * @property {string} Roumanie - The country code for Romania.
 * @property {string} Royaume-Uni - The country code for United Kingdom.
 * @property {string} Slovaquie - The country code for Slovakia.
 * @property {string} Slovénie - The country code for Slovenia.
 * @property {string} Suède - The country code for Sweden.
 * @property {string} Suisse - The country code for Switzerland.
 */
export default {
  Allemagne: 'DE',
  Autriche: 'AT',
  Belgique: 'BE',
  Bulgarie: 'BG',
  Croatie: 'HR',
  Danemark: 'DK',
  Espagne: 'ES',
  Estonie: 'EE',
  Finlande: 'FI',
  France: 'FR',
  Grèce: 'GR',
  Hongrie: 'HU',
  Irlande: 'IE',
  Islande: 'IS',
  Italie: 'IT',
  Lettonie: 'LV',
  Liechtenstein: 'LI',
  Lituanie: 'LT',
  Luxembourg: 'LU',
  Malte: 'MT',
  Norvège: 'NO',
  'Pays-Bas': 'NL',
  Pologne: 'PL',
  Portugal: 'PT',
  'République tchèque': 'CZ',
  Roumanie: 'RO',
  'Royaume-Uni': 'GB',
  Slovaquie: 'SK',
  Slovénie: 'SI',
  Suède: 'SE',
  Suisse: 'CH'
};
