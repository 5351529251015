export const getPensionerReservation = async ({
  dispatchAPI,
  pensioner,
  supervision,
  setReservation,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `reservations?pensioner=${pensioner._id}&supervision=${supervision._id}`
    });
    setReservation(data[0]);
  } catch (error) {
    message(error);
  }
};
