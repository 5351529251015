import moment from 'moment';

/**
 * Calculates the total TTC (Toutes Taxes Comprises) price for a supervision.
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Object} options.duration - The duration object.
 * @param {number} options.reduction - The reduction percentage.
 * @param {Object} options.form - The form object.
 * @param {number} options.differenceDays - The difference in days.
 * @param {Array} options.supervisionPrices - The array of supervision prices.
 * @param {Function} options.setSupervisionPrice - The function to set the supervision price.
 * @returns {void}
 */
export const addTotalTTC = ({
  duration,
  reduction,
  form,
  differenceDays,
  supervisionPrices,
  setSupervisionPrice
}) => {
  let actualPrice = duration?.rates?.find((obj) => {
    if (obj.application_date && obj.end_date) {
      return moment().isBetween(
        moment(obj.application_date).startOf('day'),
        moment(obj.end_date).endOf('day'),
        '[)'
      );
    }
    return false;
  });

  if (!actualPrice) {
    actualPrice = duration?.rates?.find((obj) => obj.default);
  }

  let totalTTC = actualPrice?.total_ttc;
  let advencePaymentWithExtraWeek;

  if (duration?.duration?.extra_week) {
    const maxDuration = supervisionPrices.find(
      (obj) => obj.duration.start_day === 24
    );
    const maxRate = maxDuration.rates.find((obj) =>
      moment().isBetween(
        moment(obj.application_date).startOf('day'),
        moment(obj.end_date).endOf('day'),
        '[)'
      )
    );
    const additionalDays = differenceDays - 28;
    const additionalWeeks = Math.floor(additionalDays / 7);
    const additionalPrice =
      additionalWeeks === 0 ? totalTTC : totalTTC * additionalWeeks;
    totalTTC = additionalPrice + maxRate.total_ttc;

    advencePaymentWithExtraWeek =
      maxRate.advance_payment + actualPrice.advance_payment * additionalWeeks;
  }

  if (actualPrice) {
    const reductionPercentage = reduction || 0;
    const reductionValue = (totalTTC * reductionPercentage) / 100;
    const advancePayment = actualPrice.advance_payment;
    const advanceWithReduction =
      advancePayment - (advancePayment * reductionPercentage) / 100;
    const advencePaymentWithExtraWeekReduction = advencePaymentWithExtraWeek
      ? advencePaymentWithExtraWeek -
        (advencePaymentWithExtraWeek * reductionPercentage) / 100
      : 0;
    const newTotalTTC = totalTTC - reductionValue;
    setSupervisionPrice({
      supervision_price: totalTTC,
      reduction_ttc: reductionValue
    });

    form.setFieldsValue({
      summary: {
        price_category: {
          total_ttc: newTotalTTC,
          advance_payment: reductionPercentage
            ? advencePaymentWithExtraWeekReduction || advanceWithReduction
            : advencePaymentWithExtraWeek || advancePayment
        }
      }
    });
  }
};
