import dayjs from 'dayjs';
import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

/**
 * Configuration hook for handling file uploads and resource creation, retrieval, and update.
 *
 * @param {Object} params - Configuration parameters.
 * @param {Array} params.filesList - List of files.
 * @param {Function} params.setFilesList - Function to set the list of files.
 * @param {Function} params.dispatchAPI - Function to dispatch API requests.
 * @param {Function} params.message - Function to display messages.
 * @returns {Object} Configuration object with methods for handling resource creation, retrieval, and update.
 */
export const useConfig = ({
  filesList,
  setFilesList,
  dispatchAPI,
  message
}) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();
      if (filesList.length) {
        filesList.forEach((file) => {
          formData.append('files_metadata', file);
        });
      }

      const newData = {
        ...data,
        wordings: data.wordings && Object.values(data.wordings)
      };

      formData.append('values', JSON.stringify(newData));

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data?.files.length) {
        try {
          const filePromise = (data.files || []).map((file) =>
            createFileFromJSON(file, dispatchAPI, message)
          );

          const results = await Promise.all(filePromise);
          setFilesList(results.map((file) => file.file));
        } catch (error) {
          message(message(error));
        }
      }

      return {
        ...data,
        emission_date: dayjs(data.emission_date),
        due_date: dayjs(data.due_date)
      };
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();
      if (filesList.length) {
        filesList.forEach((file) => {
          formData.append('files_metadata', file);
        });
      }

      const newData = {
        ...data,
        wordings: data.wordings && Object.values(data.wordings)
      };

      formData.append('values', JSON.stringify(newData));

      return formData;
    }
  }
});
