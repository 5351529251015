import { Card, Col } from 'antd';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import { setSubscriptionChart } from '../../../utils/setDataChart/setSubscriptionChart';
import { setSubscriptionChartConfig } from '../../../utils/setChartConfig/subscriptionsChartConfig';

/**
 * SubscriptionsChart component for displaying monthly subscription data.
 * @component
 * @param {Object} props - The properties of the SubscriptionsChart component.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Object} props.subscriptions - Object containing subscription data.
 * @param {Array} props.subscriptions.SUBSCRIPTION - Array of subscription data.
 * @param {string} props.statisticsDate - Date for the statistics.
 * @returns {JSX.Element} - JSX element representing the SubscriptionsChart component.
 */
export const SubscriptionsChart = ({ t, subscriptions, statisticsDate }) => {
  const [items, setItems] = useState([]);

  // Effect hook for setting chart data based on subscription data.
  useEffect(() => {
    setItems([]);
    setSubscriptionChart({
      subscriptions: subscriptions?.SUBSCRIPTION,
      setItems,
      t,
      statisticsDate
    });
  }, [subscriptions]);

  // Transform chart data for rendering.
  const data = items.reduce((acc, item) => {
    acc.push({
      month: item.month,
      sales: item.salesNMinus1,
      type: 'N-1'
    });

    acc.push({
      month: item.month,
      sales: item.salesN,
      type: 'N'
    });

    return acc;
  }, []);

  return (
    <Col span={24}>
      <Card
        title={t('dashboard.tabs.statistics.monthly_subscriptions')}
        className="dashboard-card"
      >
        <Column {...setSubscriptionChartConfig(data)} />
      </Card>
    </Col>
  );
};

SubscriptionsChart.propTypes = {
  t: PropTypes.func.isRequired,
  subscriptions: PropTypes.shape({
    SUBSCRIPTION: PropTypes.shape([])
  }).isRequired,
  statisticsDate: PropTypes.string
};

SubscriptionsChart.defaultProps = {
  statisticsDate: null
};
