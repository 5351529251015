import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import DetailsCards from './detailsCards/DetailsCards';
import PriceManagementDrawer from './priceManagementDrawer/PriceManagementDrawer';

export const ShowSupervisionPrice = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [supervisionPrice, setSupervisionPrice] = useState({});
  const [purpose, setPurpose] = useState('create');
  const [priceToEdit, setPriceToEdit] = useState(null);

  const getSupervisionPrice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/supervisionprices/${id}`
      });
      setSupervisionPrice(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getSupervisionPrice();
    })();
  }, [getSupervisionPrice, refreshData]);

  return (
    <>
      <PageHeaderCustom
        title={t('supervisionprices.show.title')}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setPurpose('create');
              setVisible(true);
            }}
          >
            <PlusOutlined />
            {`${t('supervisionprices.show.add_price')} `}
          </Button>
        }
      />
      <ContentCustom>
        <DetailsCards
          supervisionPrice={supervisionPrice}
          isLoading={isLoading}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          setPurpose={setPurpose}
          setVisible={setVisible}
          setPriceToEdit={setPriceToEdit}
        />
      </ContentCustom>
      <PriceManagementDrawer
        setRefreshData={setRefreshData}
        refresh={refreshData}
        visible={visible}
        setVisible={setVisible}
        supervisionPrice={supervisionPrice}
        purpose={purpose}
        priceToEdit={priceToEdit}
      />
    </>
  );
};
