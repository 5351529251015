import { createFilter } from '../../../utils/columnFilters/createFilter';

export const postalCodeFilter = (searchInput, t) =>
  createFilter({
    dataIndex: 'address',
    placeHolder: 'supervisions.place_holders.search_by_postal_code',
    filterFunction: (value, record, dataIndex) =>
      record.address.petsitting_address[dataIndex].postal_code
        .toString()
        .toLowerCase()
        .includes(value),
    searchInput,
    t
  });

export const departmentFilter = (searchInput, t) =>
  createFilter({
    dataIndex: 'address',
    placeHolder: 'supervisions.place_holders.search_by_department',
    filterFunction: (value, record, dataIndex) =>
      record.address.petsitting_address[dataIndex].state
        .toString()
        .toLowerCase()
        .includes(value),
    searchInput,
    t
  });

export const lastNameFilter = (searchInput, t) =>
  createFilter({
    dataIndex: 'owner',
    placeHolder: 'supervisions.place_holders.search_by_owner_name',
    filterFunction: (value, record, dataIndex) =>
      record?.main_informations?.[dataIndex]?.last_name
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    searchInput,
    t
  });
