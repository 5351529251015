/**
 * Configuration function for internal expenses chart.
 * @param {Array} data - Data for the internal expenses chart.
 * @returns {Object} - Configuration object for the internal expenses chart.
 */
export const internExpencesConfig = (data) => ({
  data,
  xField: 'month',
  yField: 'sales',
  seriesField: 'type',
  color: ['#ABABAB', '#EA5B1F'],
  isGroup: true,
  tooltip: {
    /**
     * Formatter function for the tooltip.
     * @type {Function}
     * @param {Object} item - Data item for the tooltip.
     * @returns {Object} - Formatted tooltip content.
     */
    formatter: (item) => ({ name: item.type, value: `${item.sales} €` })
  },
  legend: {
    position: 'bottom',
    marker: {
      symbol: 'circle'
    },
    itemName: {
      style: {
        fontSize: 15,
        fontWeight: 400
      }
    }
  }
});
