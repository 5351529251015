import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, List, Button } from 'antd';
import { DownloadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useDownloadDocument } from '../../../../../utils/downloadDoc';

/**
 * DocumentsInfoCards component for displaying document information cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - Type of the documents info cards (e.g., 'customerinvoices', 'interninvoices').
 * @param {function} props.t - Translation function.
 * @param {boolean} props.isLoading - Flag indicating whether the data is loading.
 * @param {Object} props.invoice - The invoice object.
 * @returns {JSX.Element} DocumentsInfoCards component.
 */
const DocumentsInfoCards = ({ type, t, isLoading, invoice }) => {
  const { downloadDocument } = useDownloadDocument();

  return (
    <Card title={t(`${type}.show.documents_info`)} className="form-card">
      <Row gutter={[0, 24]}>
        <Col xs={24} xxl={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <List
              className="demo-loadmore-list"
              loading={isLoading}
              itemLayout="horizontal"
              dataSource={invoice.files}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      onClick={() =>
                        downloadDocument({
                          _id: item._id,
                          metadata: item.metadata,
                          contentType: item.contentType
                        })
                      }
                      type="link"
                    >
                      <DownloadOutlined style={{ fontSize: 18 }} />
                    </Button>
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={
                        <CheckCircleOutlined style={{ color: 'green' }} />
                      }
                      description={item.metadata.originalName}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

export default DocumentsInfoCards;

DocumentsInfoCards.propTypes = {
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
