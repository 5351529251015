import React, { useState } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { DetailsDrawer } from './detailsDrawer/DetailsDrawer';

export const PhoneInterviewDrawer = ({
  t,
  setPhoneInterviewsDrawerOpen,
  phoneInterviewsDrawerOpen,
  setRefreshData,
  refreshData,
  form,
  pensioner
}) => {
  const [nextForm, setNextForm] = useState(false);
  const [itemsData, setItemsData] = useState([]);

  return (
    <Drawer
      title={t('pensioners.show.phone_interviews.title')}
      width={620}
      onClose={() => setPhoneInterviewsDrawerOpen(false)}
      open={phoneInterviewsDrawerOpen}
      bodyStyle={{
        paddingBottom: 80
      }}
    >
      <DetailsDrawer
        nextForm={nextForm}
        setNextForm={setNextForm}
        setItemsData={setItemsData}
        itemsData={itemsData}
        setPhoneInterviewsDrawerOpen={setPhoneInterviewsDrawerOpen}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        form={form}
        pensioner={pensioner}
      />
    </Drawer>
  );
};

PhoneInterviewDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  setPhoneInterviewsDrawerOpen: PropTypes.func.isRequired,
  phoneInterviewsDrawerOpen: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  pensioner: PropTypes.shape({
    phone_interview: PropTypes.shape({})
  }).isRequired
};
