import { createGlobalStyle } from 'styled-components';
import '../SCSS/user/index.scss';

export const UserTheme = createGlobalStyle`
  body {
    --clientColor: #fff;
    --primaryColor: var(--pear);
    --addColor: var(--pear);
    --itemHoverColor: var(--primaryColor);
    --componentBackground: #fff;
    --secondaryHover: var(--componentBackground);
    --subMenuBackground: var(--componentBackground);
    --menuDropdownBackground: var(--componentBackground);
    --pickerDropdown: var(--componentBackground);
    --primaryHover:  #e6f7ff;
    --secondaryColor: var(--cambridge);
    --disabledColor: #bfbfbf;
    --bodyBackground: var(--cream);
    --textColor: var(--darkGray);
    --textColorSecondary: rgba(0, 0, 0, 0.45);
    --itemActiveBackground: #F2F7C8;
    --itemHoverBackground: #f0f0f0;
    --borderColor: var(--lightGray);
    --contrastBackground: #f5f5f5;
    --errorColor: #e31919;
    --pear: #C0D11C;
    --cambridge: #6AA197;
    --cream: #FAF8EA;
    --dimGray: #706A65;
    --darkGray: #3F3F3F;
    --lightGray: #CFCEC9;
    --veryLightGray: #F3F3F3;
    --opacitySVG: 1;
    --primaryColorA04
    --primaryColorA07
    --primaryContrast
    --tabsGray
    --textColorInvert
    --textColorHover
    --textColorA015
    --textColorA075
    --borderColorA06
    --disabledColorA04
    --disabledColorA06
    --itemActiveColor
    --logo
    --logoSmall
    --error100
    --errorA08
  }
`;
