import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Spin, Card } from 'antd';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../utils/generateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';

/**
 * The `CreateUpdateContainerSupervision` component is a higher-order component responsible for the Create and Update functionalities.
 * It displays a form with input fields defined in the `fields` or `formCards` props, potentially grouped in cards.
 * It handles the form submission, where it either creates or updates a resource based on the `purpose` prop.
 * It also handles loading states during resource fetching and submission.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of this component, which can either be "create" or "edit".
 * @param {Object[]} props.fields - An array of objects representing the fields in the form.
 * @param {string} props.baseUrl - The base URL for the resource API endpoint.
 * @param {string} props.resource - The name of the resource being created or updated.
 * @param {boolean} [props.loadingFields=false] - Indicates whether the fields are loading.
 * @param {Object} [props.config={}] - Configuration object for fetching and submitting data.
 * @param {React.Element} [props.formExtra=null] - Additional React element to be displayed at the end of the form.
 * @param {string} [props.tradKey=null] - The translation key prefix for field labels and messages.
 * @param {string} [props.submitLabel=null] - The label for the submit button.
 * @param {function} [props.customSubmit=null] - A custom submit function to be called on form submission.
 * @param {boolean} [props.isParentLoading=false] - Indicates whether the parent component is loading.
 * @param {Object[]} [props.formCards=null] - An array of objects representing groups of fields in the form, to be displayed inside cards.
 * @param {string[]} [props.formCardsTitles=null] - Titles for the form cards.
 * @param {Object} [props.wordingFields=null] - Additional field properties for customization.
 * @param {string} [props.populate=null] - The population parameter for the GET request when fetching data for editing.
 * @param {Object} [props.customFormInstance=null] - The custom form instance passed down from the parent component.
 *
 * @example
 *
 * @returns {JSX.Element} The CreateUpdateContainerSupervision component.
 * return (
 *   <CreateUpdateContainerSupervision purpose="create" fields={fields} baseUrl="/api/resource" resource="resource" />
 * );
 */
export const CreateUpdateContainerSupervision = forwardRef(
  (
    {
      customFormInstance,
      purpose,
      fields,
      formCards,
      formCardsTitles,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      isParentLoading,
      wordingFields,
      populate,
      disabled
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);

    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource } = config;

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${id}${populate ? `?populate=${populate}` : ''}`
        });
        (customFormInstance || form).setFieldsValue(
          onGetResource && onGetResource.setFields
            ? await onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl, wordingFields]);

    useEffect(() => {
      if (purpose === 'edit' && id) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    return (
      <Spin spinning={isLoading || isParentLoading}>
        <Form
          disabled={disabled}
          ref={ref}
          {...formItemLayout}
          form={customFormInstance || form}
        >
          {formCards &&
            formCards.map((formFields, index) => (
              <Card
                className="form-card"
                key={formCardsTitles[index]}
                title={t(`card_titles.${resource}.${formCardsTitles[index]}`)}
              >
                {formFields.map((field) =>
                  generateFields(tradKey || resource, field)
                )}
              </Card>
            ))}
          {fields &&
            fields.map((field) => generateFields(tradKey || resource, field))}
          {formExtra}
        </Form>
      </Spin>
    );
  }
);

CreateUpdateContainerSupervision.propTypes = {
  purpose: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  formCards: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  formCardsTitles: PropTypes.arrayOf(PropTypes.string),
  wordingFields: PropTypes.shape({}),
  populate: PropTypes.string,
  customFormInstance: PropTypes.shape({}),
  disabled: PropTypes.bool
};

CreateUpdateContainerSupervision.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  formCards: null,
  formCardsTitles: null,
  wordingFields: null,
  populate: null,
  customFormInstance: null,
  fields: [],
  disabled: false
};
