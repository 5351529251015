import dayjs from 'dayjs';
import { createFileFromJSON } from '../../../utils/filesManagement/createFileFromJSON';

export const useConfig = ({
  filesList,
  setFilesList,
  dispatchAPI,
  message,
  setInternetAccess,
  setShowSecondProfile,
  setIsChildren,
  setIsGrandChildren,
  removedFiles,
  setCountry
}) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (Object.keys(filesList).length) {
        Object.keys(filesList).forEach((key) => {
          if (key === 'secondary_profile') {
            Object.keys(filesList[key]).forEach((nestedKey) => {
              const file = filesList[key][nestedKey];
              if (file.length) {
                formData.append(nestedKey, ...file);
              }
            });
          }
          if (key === 'other_documents') {
            filesList[key].forEach((file) => {
              formData.append('other_documents', file);
            });
          }
          if (key !== 'secondary_profile' && key !== 'other_documents') {
            if (filesList[key].length) {
              formData.append(key, ...filesList[key]);
            }
          }
        });
      }

      formData.append('values', JSON.stringify(data));

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data?.documents?.length) {
        try {
          const results = {};
          const otherDocuments = [];
          const documentsPromises = (data.documents || []).map(async (doc) => {
            if (doc.type === 'other_documents') {
              const otherDocument = await createFileFromJSON(
                doc._id,
                dispatchAPI,
                message
              );
              otherDocument.file.id = otherDocument._id;
              otherDocuments.push(otherDocument.file);
              if (results[doc.type]) {
                results[doc.type].push(otherDocument.file);
              } else {
                results[doc.type] = [otherDocument.file];
              }
              return { [doc.type]: otherDocument.file };
            }

            const file = await createFileFromJSON(
              doc._id,
              dispatchAPI,
              message
            );
            file.file.id = file._id;
            results[doc.type] = [file.file];
            return {
              [doc.type]: file.file
            };
          });

          await Promise.all(documentsPromises);

          const transformedObject = {};

          Object.keys(results).forEach((key) => {
            transformedObject[key] = [results[key]];
          });

          setCountry(data?.address?.country);

          setFilesList((prev) => ({
            ...prev,
            ...results
          }));
        } catch (error) {
          message(message(error));
        }
      }

      if (data?.secondary_profile?.documents?.length) {
        try {
          const otherDocuments = [];
          const documentsPromises = (
            data.secondary_profile.documents || []
          ).map(async (doc) => {
            if (doc.type === 'other_documents') {
              const otherDocument = await createFileFromJSON(
                doc._id,
                dispatchAPI,
                message
              );
              otherDocument.file.id = otherDocument._id;
              otherDocuments.push(otherDocument.file);
              return { [doc.type]: otherDocuments };
            }
            const file = await createFileFromJSON(
              doc._id,
              dispatchAPI,
              message
            );
            file.file.id = file._id;
            return {
              [doc.type]: file.file
            };
          });

          const results = await Promise.all(documentsPromises);

          const mergedResult = results.reduce(
            (acc, obj) => ({ ...acc, ...obj }),
            {}
          );

          const transformedObject = {};

          Object.keys(mergedResult).forEach((key) => {
            transformedObject[key] = [mergedResult[key]];
          });

          setFilesList((prev) => ({
            ...prev,
            secondary_profile: {
              ...prev.secondary_profile,
              ...transformedObject
            }
          }));
        } catch (error) {
          message(message(error));
        }
      }

      if (Object.keys(data?.secondary_profile).length > 2) {
        setShowSecondProfile(true);
      }

      if (data?.photo) {
        const photo = await createFileFromJSON(
          data.photo,
          dispatchAPI,
          message
        );

        photo.id = photo._id;
        setFilesList((prev) => ({
          ...prev,
          photo: [photo]
        }));
      }

      if (data?.secondary_profile?.photo) {
        const photo = await createFileFromJSON(
          data?.secondary_profile?.photo,
          dispatchAPI,
          message
        );

        photo.id = photo._id;
        setFilesList((prev) => ({
          ...prev,
          secondary_profile: {
            ...prev.secondary_profile,
            secondary_profile_photo: [photo]
          }
        }));
      }

      setInternetAccess(data.internet_access);

      if (data.is_children) {
        setIsChildren(data.is_children);
      }

      if (data.is_grand_children) {
        setIsGrandChildren(data.is_grand_children);
      }

      return {
        ...data,
        birth_date: data.birth_date && dayjs(data.birth_date),
        secondary_profile: {
          ...data.secondary_profile,
          birth_date:
            data.secondary_profile.birth_date &&
            dayjs(data.secondary_profile.birth_date)
        }
      };
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();
      if (Object.keys(filesList).length) {
        Object.keys(filesList).forEach((key) => {
          if (key === 'secondary_profile') {
            Object.keys(filesList[key]).forEach((nestedKey) => {
              const file = filesList[key][nestedKey];
              if (file.length) {
                if (
                  nestedKey === 'secondary_profile_photo' &&
                  file[0]?.file &&
                  !file[0]?.file.id
                ) {
                  formData.append(nestedKey, file[0]?.file);
                } else if (!file[0].id) {
                  formData.append(nestedKey, ...file);
                }
              }
            });
          }
          if (key === 'other_documents') {
            filesList[key].forEach((file) => {
              if (!file.id) {
                formData.append('other_documents', file);
              }
            });
          }
          if (key !== 'secondary_profile' && key !== 'other_documents') {
            if (filesList[key].length) {
              if (
                key === 'photo' &&
                filesList[key][0]?.file &&
                !filesList[key][0]?.file.id
              ) {
                formData.append(key, filesList[key][0]?.file);
              } else if (!filesList[key][0].id) {
                formData.append(key, ...filesList[key], filesList[key][0].name);
              }
            }
          }
        });
      }
      const newData = { ...data, removedFiles };
      formData.append('values', JSON.stringify(newData));

      return formData;
    }
  }
});
