import { Button, Form, message, Space } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useFields } from '../fields';
import { useAuthContext } from '../../../../../contexts/AuthContext';

/**
 * Component to display a details drawer for phone interviews.
 *
 * @param {object} props - Component props
 * @param {boolean} props.nextForm - Flag to determine if the next form should be shown
 * @param {function} props.setNextForm - Function to set the next form flag
 * @param {function} props.setItemsData - Function to set the items data
 * @param {object} props.itemsData - Data for items
 * @returns {JSX.Element} JSX element representing the DetailsDrawer component
 */
export const DetailsDrawer = ({
  nextForm,
  setNextForm,
  setItemsData,
  itemsData,
  setPhoneInterviewsDrawerOpen,
  setRefreshData,
  refreshData,
  form,
  pensioner
}) => {
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { animalsFields, dwellingFields } = useFields();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();

  /**
   * Handle pushing items to the data.
   *
   * @param {object} values - Form values
   * @returns {void}
   */
  const handlePush = (values) => {
    setItemsData((prevList) => ({ ...prevList, ...values }));
    setNextForm(true);
  };

  /**
   * Handle going back to the previous form.
   * @returns {void}
   */
  const handlePrevious = () => {
    setNextForm(false);
  };

  /**
   * Handle submitting the form data.
   *
   * @returns {Promise<void>} A promise that resolves when the form data is successfully submitted.
   */
  const handleSubmit = async () => {
    try {
      if (pensioner.phone_interview) {
        await dispatchAPI('PATCH', {
          url: `/phoneinterviews/${pensioner.phone_interview._id}`,
          body: itemsData
        });
      } else {
        const { data } = await dispatchAPI('POST', {
          url: '/phoneinterviews',
          body: itemsData
        });
        const { _id: phoneInterviewId } = data;
        await dispatchAPI('PATCH', {
          url: `/pensioners/${id}`,
          body: { phone_interview: phoneInterviewId }
        });
      }
      setPhoneInterviewsDrawerOpen(false);
      setRefreshData(!refreshData);
      setNextForm(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      if (Object.entries(itemsData).length > 2) {
        await handleSubmit();
      }
    })();
  }, [itemsData]);

  return (
    <Form layout="vertical" form={form} onFinish={handlePush}>
      {!nextForm
        ? animalsFields.map((field) => generateFields('pensioners', field))
        : dwellingFields.map((field) => generateFields('pensioners', field))}

      <Space>
        <Button type="text" onClick={handlePrevious}>
          {!nextForm ? t('buttons.cancel') : t('buttons.previous')}
        </Button>
        <Button type="add" htmlType="submit">
          {!nextForm ? t('buttons.next') : t('buttons.validate')}
        </Button>
      </Space>
    </Form>
  );
};

DetailsDrawer.propTypes = {
  nextForm: PropTypes.bool.isRequired,
  setNextForm: PropTypes.func.isRequired,
  setItemsData: PropTypes.func.isRequired,
  itemsData: PropTypes.shape({}),
  setPhoneInterviewsDrawerOpen: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  form: PropTypes.shape({}).isRequired,
  pensioner: PropTypes.shape({
    phone_interview: PropTypes.shape({
      _id: PropTypes.string
    })
  })
};

DetailsDrawer.defaultProps = {
  itemsData: {},
  pensioner: {}
};
