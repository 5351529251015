import { Form, Button, Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useFirstStepFields } from '../fields/firstStepFields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

export const FirstStep = ({
  form,
  firstStep,
  setFirstStep,
  setCurrent,
  t,
  questionnaireType,
  user,
  showReviewDrawer
}) => {
  const { firstStepCompanyFields, firstStepSupervisionFields } =
    useFirstStepFields({
      questionnaireType,
      userRole: user.role,
      showReviewDrawer
    });
  const generateFormItem = useGenerateFormItem();

  const handleSetFirstStepData = (data) => {
    setCurrent((current) => current + 1);
    setFirstStep({ ...firstStep, ...data });
  };

  return questionnaireType || user.role ? (
    <Form form={form} layout="vertical" onFinish={handleSetFirstStepData}>
      <Typography.Title level={5}>
        {t('owners.users_shows.review_drawer.form.sub_title.the_company')}
      </Typography.Title>
      {firstStepCompanyFields.map((field) =>
        generateFormItem('owners.users_shows.review_drawer', field)
      )}

      <Typography.Title level={5}>
        {t('owners.users_shows.review_drawer.form.sub_title.the_supervision')}
      </Typography.Title>
      {firstStepSupervisionFields.map((field) =>
        generateFormItem('owners.users_shows.review_drawer', field)
      )}

      <Flex gap="small" justify="flex-end">
        {user.role === 'admins:ADMIN' ? (
          <Button onClick={() => setCurrent((current) => current - 1)}>
            {t('buttons.previous')}
          </Button>
        ) : null}
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
        </Button>
      </Flex>
    </Form>
  ) : null;
};

FirstStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  firstStep: PropTypes.shape({}).isRequired,
  setFirstStep: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string
  }).isRequired,
  showReviewDrawer: PropTypes.bool
};

FirstStep.defaultProps = {
  showReviewDrawer: false
};
