export const addDuration = ({
  startDate,
  endDate,
  setDifferenceDays,
  supervisionPrices,
  form,
  setDuration
}) => {
  if (Object.keys(startDate).length && Object.keys(endDate).length) {
    const differenceDaysNumber = endDate.diff(startDate, 'days') + 1;
    setDifferenceDays(differenceDaysNumber);
    const durationData = supervisionPrices.find(
      (obj) =>
        obj.duration.start_day <= differenceDaysNumber &&
        differenceDaysNumber <= obj.duration.end_day
    );

    if (durationData) {
      form.setFieldValue(
        ['summary', 'price_category', 'duration'],
        durationData._id
      );
      setDuration(durationData);
    } else {
      const extraWeekSupervisionPrice = supervisionPrices.find(
        (obj) => obj.duration.extra_week
      );
      form.setFieldValue(
        ['summary', 'price_category', 'duration'],
        extraWeekSupervisionPrice._id
      );
      setDuration(extraWeekSupervisionPrice);
    }
  }
};
