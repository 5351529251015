export const headers = [
  {
    label: 'reference',
    key: 'reference'
  },
  {
    label: 'principal_pensioner',
    key: 'principal_pensioner'
  },
  {
    label: 'secondary_pensioner',
    key: 'secondary_pensioner'
  },
  {
    label: 'address.city',
    key: 'address.city'
  },
  {
    label: 'address.postal_code',
    key: 'address.postal_code'
  },
  {
    label: 'address.state',
    key: 'address.state'
  },
  {
    label: 'phone_number.number',
    key: 'phone_number.number'
  },
  {
    label: 'cell_phone_number.number',
    key: 'cell_phone_number.number'
  },
  {
    label: 'email',
    key: 'email'
  },
  {
    label: 'status',
    key: 'pensioner_status'
  }
];
