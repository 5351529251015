import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'antd';
import ReactQuill from 'react-quill';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useAnounceInfoFields } from '../../../fields/anounceFields';
import { AnouncePhotoGallery } from '../../../fields/AnouncePhotoGallery';
import { handleSubmitAnounce } from '../../utils/handleSubmitAnounce';
import 'react-quill/dist/quill.snow.css';

/**
 * AnounceModal component displays a modal for creating or editing an announcement.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.supervision - The supervision object.
 * @param {boolean} props.anounceModalVisible - Flag indicating if the modal is visible.
 * @param {function} props.setAnounceModalVisible - Function to set the visibility of the modal.
 * @param {function} props.t - Translation function.
 * @param {Array} props.selectedAnouncePhotos - Array of selected announcement photos.
 * @param {function} props.setSelectedAnouncePhotos - Function to set the selected announcement photos.
 * @param {Array} props.anounce - Array of announcement data.
 * @param {function} props.setRefreshData - Function to refresh data.
 * @param {boolean} props.refreshData - Flag indicating if data should be refreshed.
 * @param {function} props.setAnimalsFileList - Function to set the animals file list.
 * @param {function} props.setPetsittingAddressFileList - Function to set the petsitting address file list.
 * @returns {JSX.Element} The AnounceModal component.
 */
export const AnounceModal = ({
  supervision,
  anounceModalVisible,
  setAnounceModalVisible,
  t,
  selectedAnouncePhotos,
  setSelectedAnouncePhotos,
  anounce,
  setRefreshData,
  refreshData,
  setAnimalsFileList,
  setPetsittingAddressFileList
}) => {
  const [form] = Form.useForm();
  const { anounceFields } = useAnounceInfoFields();
  const generateFields = useGenerateFormItem();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [photos, setPhotos] = useState([]);
  const [editorState, setEditorState] = useState('');

  const {
    main_informations: { animals } = {},
    address: { petsitting_address } = {}
  } = supervision || {};

  useEffect(() => {
    if (Object.keys(supervision).length) {
      form.setFieldsValue({
        reference: supervision.reference,
        start_date: dayjs(supervision.main_informations.start_date),
        end_date: dayjs(supervision.main_informations.end_date),
        state: supervision.address.petsitting_address.address.state
      });
    }
  }, [supervision]);

  useEffect(() => {
    if (anounce.length) {
      setEditorState(anounce[0].description);
    }
  }, [anounce]);

  useEffect(() => {
    const animalsPhotos = animals.flatMap((animal) => animal.animal_photo);
    if (selectedAnouncePhotos.length) {
      const photosData = animalsPhotos.concat(
        petsitting_address?.dwelling_photos
      );
      setPhotos(photosData);
    }
  }, [selectedAnouncePhotos]);

  return (
    <Modal
      footer={null}
      title={t('supervisions.show.modal.title.anounce')}
      open={anounceModalVisible}
      onOk={() => setAnounceModalVisible(false)}
      onCancel={() => setAnounceModalVisible(false)}
    >
      <Form form={form}>
        {anounceFields &&
          anounceFields.map((field) => generateFields('supervisions', field))}
      </Form>
      <ReactQuill
        style={{ width: '100%', height: '100%' }}
        value={editorState}
        onChange={(value) => setEditorState(value)}
      />
      <AnouncePhotoGallery
        photos={photos}
        selectedAnouncePhotos={selectedAnouncePhotos}
        setSelectedAnouncePhotos={setSelectedAnouncePhotos}
      />
      <Button onClick={() => setAnounceModalVisible(false)}>
        {t('buttons.cancel')}
      </Button>
      <Button
        onClick={() =>
          handleSubmitAnounce({
            status: 'SAVE',
            form,
            dispatchAPI,
            id,
            selectedAnouncePhotos,
            editorState,
            setAnounceModalVisible,
            setPhotos,
            setRefreshData,
            refreshData,
            message
          })
        }
      >
        {t('buttons.save')}
      </Button>
      {!['BOOKED', 'PUBLISHED', 'RESERVE', 'WAITING_BOOKING'].includes(
        supervision?.status
      ) ? (
        <Button
          onClick={() =>
            handleSubmitAnounce({
              status: 'PUBLISHED',
              form,
              dispatchAPI,
              id,
              selectedAnouncePhotos,
              editorState,
              setAnounceModalVisible,
              setPhotos,
              setAnimalsFileList,
              setPetsittingAddressFileList,
              setRefreshData,
              refreshData,
              message
            })
          }
        >
          {t('buttons.publish')}
        </Button>
      ) : null}
    </Modal>
  );
};

AnounceModal.propTypes = {
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(
      PropTypes.shape({
        animal_photo: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string })
        )
      })
    )
  }).isRequired,
  anounceModalVisible: PropTypes.bool,
  setAnounceModalVisible: PropTypes.func,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    reference: PropTypes.string,
    main_informations: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }),
    status: PropTypes.string,
    address: PropTypes.shape({
      petsitting_address: PropTypes.shape({
        address: PropTypes.shape({
          state: PropTypes.string
        })
      })
    })
  }).isRequired,
  selectedAnouncePhotos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedAnouncePhotos: PropTypes.func.isRequired,
  anounce: PropTypes.arrayOf(PropTypes.shape({ description: PropTypes.string }))
    .isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setAnimalsFileList: PropTypes.func.isRequired,
  setPetsittingAddressFileList: PropTypes.func.isRequired
};

AnounceModal.defaultProps = {
  anounceModalVisible: false,
  setAnounceModalVisible: null
};
