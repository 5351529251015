import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Row,
  Skeleton,
  Typography,
  message as antdMessage
} from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSupervision } from '../supervisions/utils/getSupervision';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import {
  ContentCustom,
  DescriptionList,
  PageHeaderCustom
} from '../../../components';
import { ConfirmReservationModal } from './ConfirmReservationModal';
import { ImagesLayout } from '../supervisions/ImagesLayout';
import { useListContent } from './listContent';
import { dateFormat } from '../supervisions/utils/dateFormat';
import { getAnounce } from './utils/getAnounce';
import { getReservation } from './utils/getReservation';
import { convertToHTML } from '../../../utils/convertToHTML';

const { Title, Text } = Typography;

/**
 * Renders the ShowViewAds component.
 * This component displays the details of a specific ad.
 * It includes information about the supervision, reservation, and location.
 * @component
 *
 * @returns {JSX.Element} The rendered ShowViewAds component.
 */
export const ShowViewAds = () => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { supervisionId, anounceId } = useParams();
  const [supervision, setSupervision] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
  const [anounce, setAnounce] = useState({});
  const [reservation, setReservation] = useState({});
  const [pensioner, setPensioner] = useState({});
  const [displayMap, setDisplayMap] = useState(false);

  useEffect(() => {
    (async () => {
      await getSupervision({
        dispatchAPI,
        id: supervisionId,
        setSupervision,
        message,
        setIsLoading
      });
      await getAnounce({ dispatchAPI, anounceId, setAnounce, message });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isReservationModalOpen) {
        await getReservation({
          dispatchAPI,
          user,
          supervisionId,
          message,
          setReservation,
          setPensioner
        });
      }
    })();
  }, [isReservationModalOpen]);

  const title = t(
    `supervisions.users_shows.supervision_date`,
    dateFormat(
      supervision?.main_informations?.start_date,
      supervision?.main_informations?.end_date
    )
  );

  let map;
  const initMap = async () => {
    // eslint-disable-next-line no-undef
    const { Map } = await google.maps.importLibrary('maps');
    // eslint-disable-next-line no-undef
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
    // eslint-disable-next-line no-undef
    const geocoder = new google.maps.Geocoder();

    const { address: { petsitting_address } = {} } = supervision || {};
    const { address: { city } = {} } = petsitting_address || {};
    const address = city;

    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK') {
        map = new Map(document.getElementById('map'), {
          zoom: 11,
          center: results[0].geometry.location,
          mapId: 'DEMO_MAP_ID',
          fullscreenControl: false,
          mapTypeControl: false
        });

        map.setCenter(results[0].geometry.location);
        return new AdvancedMarkerElement({
          map,
          position: results[0].geometry.location,
          title: address
        });
      }
      return antdMessage.warning(t('view-ads.messages.geocode_error'));
    });
  };

  useEffect(() => {
    if (Object.keys(supervision).length > 2) {
      initMap();
      setDisplayMap(true);
    }

    return () => {
      if (map) {
        map?.destroy();
      }
    };
  }, [supervision]);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        title={
          Object.keys(supervision).length
            ? t('owners.users_shows.title.supervision_details', supervision)
            : null
        }
        extra={
          Object.keys(reservation).length ? (
            <Text>{t(`reservations.tags.${reservation?.status}`)}</Text>
          ) : (
            <Button
              onClick={
                ['ACTIVE', 'FREE'].includes(pensioner?.status)
                  ? () => setIsReservationModalOpen(true)
                  : () =>
                      antdMessage.warning(
                        t('view-ads.messages.not_subscription')
                      )
              }
              type="primary"
            >
              {t('view-ads.buttons.reserve')}
            </Button>
          )
        }
      />
      <ContentCustom>
        <Skeleton
          loading={isLoading}
          active
          avatar={{ shape: 'square', size: 400 }}
        >
          <Row>
            <Title level={3}>{title}</Title>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} xl={16}>
              <ImagesLayout url={supervision?.summary?.anounce_photos || []} />
              <DescriptionList
                layout="horizontal"
                data={useListContent(supervision, t)}
              />
            </Col>
            {anounce?.description && (
              <Col xs={24} xl={8}>
                <Title level={5} className="underlined">
                  {t('view-ads.show.titles.comment')}
                </Title>
                {convertToHTML({
                  htmlContent: anounce?.description
                })}
              </Col>
            )}
          </Row>
        </Skeleton>
        {displayMap && (
          <Col xs={24} xl={8}>
            <Title level={5} className="underlined">
              {t('view-ads.show.titles.location')}
            </Title>
            <div id="map" style={{ width: 600, height: 450 }} />
          </Col>
        )}
      </ContentCustom>
      <ConfirmReservationModal
        dispatchAPI={dispatchAPI}
        setIsReservationModalOpen={setIsReservationModalOpen}
        isReservationModalOpen={isReservationModalOpen}
        message={message}
        user={user}
        supervisionId={supervisionId}
      />
    </>
  );
};
