import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export const createFilter = ({
  dataIndex,
  t,
  searchInput,
  placeHolder,
  filterFunction
}) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    close,
    confirm,
    clearFilters
  }) => (
    <div
      style={{
        padding: 8
      }}
    >
      <Input
        ref={searchInput}
        placeholder={t(placeHolder)}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm({ closeDropdown: true })}
        style={{
          marginBottom: 8,
          display: 'block'
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm({ closeDropdown: true })}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90
          }}
        >
          {t('buttons.filter')}
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm({ closeDropdown: true });
          }}
          size="small"
          style={{
            width: '100'
          }}
        >
          {t('buttons.reset')}
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          {t('buttons.close')}
        </Button>
      </Space>
    </div>
  ),
  onFilter: (value, record) => filterFunction(value, record, dataIndex)
});
