import React from 'react';
import { Radio, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { reviewDrawerQuestions } from '../../../routes/usersShows/supervisions/utils/reviewDrawerQuestions';
import { getRadioGroup } from '../utils/getRadioGroup';

const { TextArea } = Input;

export const useFirstStepFields = ({
  questionnaireType,
  userRole,
  showReviewDrawer
}) => {
  const { t } = useTranslation();

  const firstStepCompanyFields = [
    {
      name: ['ani_seniors_phone_service'],
      label: reviewDrawerQuestions[questionnaireType || userRole].aniService,
      rules: [{ required: true }],
      input: getRadioGroup()
    },
    {
      name: ['sufficient_documents'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole].documents || '-',
      rules: [{ required: true }],
      input: (
        <Radio.Group>
          <Radio value disabled={showReviewDrawer}>
            {t('owners.users_shows.review_drawer.form.answers.yes')}
          </Radio>
          <Radio value={false} disabled={showReviewDrawer}>
            {t('owners.users_shows.review_drawer.form.answers.no')}
          </Radio>
        </Radio.Group>
      )
    },
    {
      name: ['conviviality'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole].conviviality ||
        '-',
      rules: [{ required: true }],
      input: getRadioGroup()
    }
  ];

  const firstStepSupervisionFields = [
    {
      name: ['arival_contact', 'review'],
      label:
        reviewDrawerQuestions[questionnaireType || userRole].arrivalContact ||
        '-',
      rules: [{ required: true }],
      input: getRadioGroup()
    },
    {
      name: ['arival_contact', 'comment'],
      label: 'comment',
      dependencies: ['contact_with_pensioners_to_back'],
      input: <TextArea disabled={showReviewDrawer} />
    }
  ];

  return {
    firstStepCompanyFields,
    firstStepSupervisionFields
  };
};
