import { Input, DatePicker, InputNumber, Checkbox } from 'antd';
import dayjs from 'dayjs';

const { TextArea } = Input;

export const useInterruptSupervisionInfoFields = ({ supervision }) => {
  const { main_informations } = supervision || {};
  const { start_date, end_date } = main_informations || {};

  const disabledDate = (current) =>
    current &&
    (current < dayjs(start_date).startOf('day') ||
      current > dayjs(end_date).endOf('day'));

  const dateRender = (current) => {
    const isDisabled = disabledDate(current);
    return isDisabled ? (
      <div style={{ color: 'red' }}>{current.date()}</div>
    ) : (
      current.date()
    );
  };

  const interruptSupervisionFields = [
    ...(supervision?.status === 'IN_PROGRESS'
      ? [
          {
            name: ['interrupt', 'application_date'],
            input: (
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
                dateRender={dateRender}
              />
            )
          }
        ]
      : []),
    {
      name: ['interrupt', 'reason'],
      rules: [{ required: true }]
    },
    {
      name: ['interrupt', 'comment'],
      input: <TextArea />
    },
    {
      name: ['interrupt', 'duplicate_supervision'],
      input: <Checkbox />,
      valuePropName: 'checked'
    },
    {
      name: ['interrupt', 'remaining_amount'],
      input: <InputNumber />
    }
  ];

  return { interruptSupervisionFields };
};
