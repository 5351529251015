import dayjs from 'dayjs';

/**
 * Marks an invoice as paid, updates payment-related information, and handles special cases for subscription invoices.
 *
 * @param {Object} options - Options for marking an invoice as paid.
 * @param {Object} options.body - The data for the payment.
 * @param {Function} options.dispatchAPI - Function for making API requests.
 * @param {Function} options.message - Function to display error messages.
 * @param {string} options.id - The ID of the invoice.
 * @param {Function} options.setIsPaidInvoiceModalOpen - Function to toggle the visibility of the paid invoice modal.
 * @param {boolean} options.refreshData - The current state of data refreshing.
 * @param {Function} options.setRefreshData - Function to toggle the data refresh state.
 * @param {string} options.resourceName - The name of the resource associated with the invoice.
 * @param {Object} options.invoice - The invoice data.
 * @returns {Promise<void>} A promise that resolves once the invoice is marked as paid.
 */
export const paidInvoice = async ({
  body,
  dispatchAPI,
  message,
  id,
  setIsPaidInvoiceModalOpen,
  refreshData,
  setRefreshData,
  resourceName,
  invoice,
  setLoading
}) => {
  try {
    setLoading(true);
    const formData = new FormData();

    // Calculate the new payment amount
    const newPaymentAmount =
      (invoice?.payment_amount || 0) + body.payment_amount;

    // Prepare the updated data for the invoice
    const newBody = {
      ...body,
      status: newPaymentAmount >= invoice.total_ttc ? 'PAID' : invoice.status,
      payment_date: dayjs(body.payment_date),
      payment_amount: body.payment_method === 'FREE' ? 0 : body.payment_amount
    };

    // Append the data to the FormData
    formData.append('values', JSON.stringify(newBody));

    // Update the invoice with the new payment information
    const { data } = await dispatchAPI('PATCH', {
      url: `${resourceName}/form/${id}`,
      body: formData
    });

    // Handle special cases for subscription invoices
    if (invoice.type === 'SUBSCRIPTION' && data.status === 'PAID') {
      await dispatchAPI('PATCH', {
        url: `subscriptions/${invoice.subscription._id}`,
        body: { start_date: dayjs(), end_date: dayjs().add(1, 'year') }
      });
      await dispatchAPI('PATCH', {
        url: `pensioners/${invoice.subscription.pensioner}`,
        body: { status: 'ACTIVE' }
      });
    }

    // Toggle the visibility of the paid invoice modal and trigger data refresh
    setIsPaidInvoiceModalOpen(false);
    setRefreshData(!refreshData);
    setLoading(false);
  } catch (e) {
    message(e);
    setLoading(false);
  }
};
