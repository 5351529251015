import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'antd';
import dayjs from 'dayjs';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useInterruptSupervisionInfoFields } from '../../../fields/interruptSupervisionFields';
import { handleSubmitCancelSupervision } from '../../utils/handleSubmitCancelSupervision';

/**
 * Renders a modal component for interrupting a supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.interruptSupervisionModalVisible - Flag indicating whether the interrupt supervision modal is visible.
 * @param {function} props.setInterruptSupervisionModalVisible - Function to set the visibility of the interrupt supervision modal.
 * @param {function} props.t - Translation function for internationalization.
 * @param {function} props.setRefreshData - Function to refresh the data after interrupting the supervision.
 * @param {boolean} props.refreshData - Flag indicating whether the data needs to be refreshed.
 * @param {Object} props.supervision - The supervision object.
 * @returns {JSX.Element} The interrupt supervision modal component.
 */
export const InterruptSupervisionModal = ({
  interruptSupervisionModalVisible,
  setInterruptSupervisionModalVisible,
  t,
  setRefreshData,
  refreshData,
  supervision
}) => {
  const [form] = Form.useForm();
  const { interruptSupervisionFields } = useInterruptSupervisionInfoFields({
    supervision
  });
  const generateFields = useGenerateFormItem();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldValue(['interrupt', 'application_date'], dayjs());
  }, []);

  return (
    <Modal
      footer={null}
      title={t('supervisions.show.modal.title.interrupt_supervision')}
      open={interruptSupervisionModalVisible}
      onOk={() => setInterruptSupervisionModalVisible(false)}
      onCancel={() => setInterruptSupervisionModalVisible(false)}
    >
      <Form form={form}>
        {interruptSupervisionFields &&
          interruptSupervisionFields.map((field) =>
            generateFields('supervisions', field)
          )}
      </Form>
      <Button onClick={() => setInterruptSupervisionModalVisible(false)}>
        {t('buttons.cancel')}
      </Button>
      <Button
        loading={isLoading}
        onClick={() =>
          handleSubmitCancelSupervision({
            status: 'INTERRUPT',
            form,
            dispatchAPI,
            id,
            setInterruptSupervisionModalVisible,
            setRefreshData,
            refreshData,
            message,
            t,
            setIsLoading
          })
        }
      >
        {t('buttons.save')}
      </Button>
    </Modal>
  );
};

InterruptSupervisionModal.propTypes = {
  interruptSupervisionModalVisible: PropTypes.bool,
  setInterruptSupervisionModalVisible: PropTypes.func,
  t: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  supervision: PropTypes.shape({
    main_informations: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }),
    status: PropTypes.string
  })
};

InterruptSupervisionModal.defaultProps = {
  interruptSupervisionModalVisible: false,
  setInterruptSupervisionModalVisible: null,
  supervision: null
};
