export const getSupervisionPrices = async ({
  dispatchAPI,
  setSupervisionPrices,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/supervisionprices'
    });
    setSupervisionPrices(data);
  } catch (e) {
    message(e);
  }
};
