import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListMails from './ListMails';
import ShowMail from './ShowMail';

/**
 * Renders the MailRouter component.
 * @component
 *
 * @returns {JSX.Element} The rendered MailRouter component.
 */
export const MailRouter = () => (
  <Routes>
    <Route path="/" element={<ListMails />} />
    <Route path="/show/:id" element={<ShowMail />} />
  </Routes>
);
