import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Typography, Timeline, Card } from 'antd';

const { Text } = Typography;

const HistoryInfoCards = ({ t, owner }) => (
  <Card title={t('owners.show.history_info')}>
    <Timeline
      style={{
        maxHeight: '400px',
        overflowY: 'scroll',
        width: '100%'
      }}
    >
      {(owner?.history || []).map((event) => {
        const formattedDate = dayjs(event.date).format('DD/MM/YYYY à HH:mm');

        return (
          <Timeline.Item
            style={{
              marginTop: '10px'
            }}
            key={`${event.type}-${formattedDate}`}
          >
            <Text>{t(`history.${event.type}`)}</Text>
            <br />
            <Text type="secondary" italic style={{ color: 'grey' }}>
              {t('history.item', {
                first_name: event?.by?.first_name || '',
                last_name: event?.by?.last_name || '',
                date: formattedDate
              })}
            </Text>
          </Timeline.Item>
        );
      })}
    </Timeline>
  </Card>
);

export default HistoryInfoCards;

HistoryInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  owner: PropTypes.shape({
    history: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};
