import dayjs from 'dayjs';

/**
 * Creates supervision invoices.
 * @function
 *
 * @param {Object} options - The options for creating supervision invoices.
 * @param {Object} options.supervision - The supervision object.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Function} options.message - The message function.
 * @returns {Promise<void>} - A promise that resolves when the invoices are created.
 */
export const createSupervisionInvoices = async ({
  supervision,
  dispatchAPI,
  message
}) => {
  const supervisionTotalTTC =
    supervision?.summary?.price_category?.total_ttc || 0;
  const supervisionTotalHT = (
    supervisionTotalTTC -
    (supervisionTotalTTC * 20) / 100
  ).toFixed(2);
  const advanceTotalTTC =
    supervision?.summary?.price_category?.advance_payment || 0;
  const advanceTotalHT = (
    advanceTotalTTC -
    (advanceTotalTTC * 20) / 100
  ).toFixed(2);

  const invoicesType = [
    {
      type: 'ADVANCE_PAYMENT',
      title: "Facture d'acompte",
      total_ht: advanceTotalHT,
      total_ttc: advanceTotalTTC,
      reduction: 0
    },
    {
      type: 'BALANCE',
      title: 'Facture de solde',
      total_ht: supervisionTotalHT,
      total_ttc: supervisionTotalTTC,
      reduction: supervision?.summary?.reduction,
      supervision_price:
        supervision?.summary?.price_category?.supervision_price,
      total_reduction_ttc: supervision?.summary?.price_category?.reduction_ttc
    }
  ];

  try {
    const invoicePromise = invoicesType.map(async (invoice) => {
      const invoiceFormData = new FormData();

      const newInvoiceBody = {
        type: invoice.type,
        customer: supervision.main_informations.owner.user._id,
        supervision: supervision._id,
        invoicing_address: `${supervision?.main_informations?.owner?.address?.number} ${supervision?.main_informations?.owner?.address?.street}`,
        postal_code:
          supervision?.main_informations?.owner?.address?.postal_code,
        city: supervision?.main_informations?.owner?.address?.city,
        emission_date: dayjs(),
        due_date: dayjs(supervision.main_informations.end_date),
        status: 'WAITING',
        wordings: [
          {
            credit_note: false,
            title: invoice.title,
            total_ht: invoice.total_ht,
            tva: 20,
            total_ttc: invoice.total_ttc,
            reduction: invoice.reduction
          }
        ],
        total_ht: invoice.total_ht,
        total_reduction_ttc: invoice.total_reduction_ttc,
        supervision_price: invoice.supervision_price,
        total_ttc: invoice.total_ttc
      };
      invoiceFormData.append('values', JSON.stringify(newInvoiceBody));

      await dispatchAPI('POST', {
        url: `/customerinvoices/form`,
        body: invoiceFormData
      });
    });

    await Promise.all(invoicePromise);
  } catch (error) {
    message(error);
  }
};
