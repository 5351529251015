import { Route, Routes } from 'react-router-dom';
import { ListSupervisionPrices } from './ListSupervisionPrices';
import { CreateUpdateSupervisionPrice } from './CreateUpdateSupervisionPrice';
import { ShowSupervisionPrice } from './showSupervisionPrice/ShowSupervisionPrice';
import { Exception } from '../../components';

export const SupervisionPriceRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateSupervisionPrice purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateSupervisionPrice purpose="edit" />}
    />
    <Route path="/show/:id" element={<ShowSupervisionPrice />} />
    <Route index element={<ListSupervisionPrices />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
