export const deleteOwner = async ({ dispatchAPI, navigate, id, message }) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/owners/${id}`,
      body: { status: 'ARCHIVED', is_archived: true }
    });
    navigate(-1);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
