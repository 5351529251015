import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';

export const onFinishForm = async ({
  values,
  purpose,
  supervisionPrice,
  dispatchAPI,
  priceToEdit,
  onClose,
  setTVA,
  setTotalHT,
  setRefreshData,
  refreshData,
  message
}) => {
  try {
    let isPeriodTaken = false;

    if (purpose === 'create') {
      supervisionPrice.rates
        .filter((el) => !el.default)
        .forEach((rate) => {
          if (
            dayjs(values.rate.application_date).startOf('day') <=
              dayjs(rate.application_date) &&
            dayjs(values.rate.end_date).endOf('day') >= dayjs(rate.end_date)
          ) {
            if (isPeriodTaken) return;
            antdMessage.warning('La période est déjà prise en compte');
            isPeriodTaken = true;
          }
        });

      if (isPeriodTaken) {
        return;
      }

      await dispatchAPI('PATCH', {
        url: `/supervisionprices/${supervisionPrice._id}?type=add_price`,
        body: values.rate
      });
    } else {
      supervisionPrice.rates
        .filter((el) => el._id !== priceToEdit._id)
        .filter((el) => !el.default)
        .forEach((rate) => {
          if (
            dayjs(values.rate.application_date).startOf('day') <=
              dayjs(rate.application_date) &&
            dayjs(values.rate.end_date).endOf('day') >= dayjs(rate.end_date)
          ) {
            if (isPeriodTaken) return;
            antdMessage.warning('La période est déjà prise en compte');
            isPeriodTaken = true;
          }
        });

      if (isPeriodTaken) {
        return;
      }

      await dispatchAPI('PATCH', {
        url: `/supervisionprices/${supervisionPrice._id}?type=edit_price&price_to_edit=${priceToEdit._id}`,
        body: values.rate
      });
    }
    onClose();
    setTVA(null);
    setTotalHT(null);
    setRefreshData(!refreshData);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
