/**
 * Calculates and updates totalHT and totalTTC based on the provided wordings data.
 *
 * @param {Object[]} options.wordings - List of wordings.
 * @param {Object} options.wordingTotalHT - Total HT values for each wording.
 * @param {Object} options.wordingTotalTVA - Total TVA values for each wording.
 * @param {Function} options.setTotalHT - Setter for totalHT.
 * @param {Function} options.setTotalTTC - Setter for totalTTC.
 * @param {Object} options.form - Ant Design Form instance.
 * @param {string} options.updatedWordingType - Updated wording type ('HT' or 'TTC').
 * @param {Object} options.wordingTotalTTC - Total TTC values for each wording.
 * @returns {void}
 */
export const addCreditNoteTotalTTC = ({
  wordings,
  wordingTotalHT,
  wordingTotalTVA,
  setTotalHT,
  setTotalTTC,
  form,
  updatedWordingType,
  wordingTotalTTC
}) => {
  let totalHT = 0;
  let totalTTC = 0;

  // Iterate through each wording to calculate totalHT and totalTTC
  wordings.forEach((wording) => {
    const key = wording.id;
    const totalHTValue = wordingTotalHT[key] || 0;
    const totalTVAValue = wordingTotalTVA[key] || 0;
    const totalTTCValue = wordingTotalTTC[key] || 0;

    if (updatedWordingType === 'HT') {
      // Calculate finalValue for HT
      const finalValue = totalHTValue + (totalHTValue * totalTVAValue) / 100;

      // Update form fields with the calculated total_ttc value
      form.setFieldsValue({
        wordings: {
          [key]: {
            total_ttc: finalValue.toFixed(2)
          }
        }
      });

      // Update totalHT and totalTTC values
      totalHT += totalHTValue;
      totalTTC += finalValue;
    }

    if (updatedWordingType === 'TTC') {
      // Calculate finalValue for TTC
      const finalValue = totalTTCValue / (1 + totalTVAValue / 100);

      // Update form fields with the calculated total_ht value
      form.setFieldsValue({
        wordings: {
          [key]: {
            total_ht: finalValue.toFixed(2)
          }
        }
      });

      // Update totalHT and totalTTC values
      totalHT += finalValue;
      totalTTC += totalTTCValue;
    }
  });

  // Set the calculated totalHT and totalTTC values
  setTotalHT(totalHT.toFixed(2));
  setTotalTTC(totalTTC.toFixed(2));
};
