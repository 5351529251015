import React from 'react';
import { Button, Flex, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const flexStyle = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(106, 161, 151, 0.8)',
  position: 'fixed',
  zIndex: 100
};

export const EditingLocked = ({ t, resources, navigate }) => (
  <Flex style={flexStyle} justify="center" align="center" vertical>
    <Spin spinning size="large" />
    <Text>
      {t('profile.editActionLocked', {
        resource: t(`profile.resources.${resources.text}`)
      })}
    </Text>
    <Flex>
      <Button onClick={() => navigate('/')}>{t('home.title')}</Button>
      <Button onClick={() => navigate(-1)}>
        {t(`dashboard.cards_titles.${resources.button}`)}
      </Button>
    </Flex>
  </Flex>
);

EditingLocked.propTypes = {
  t: PropTypes.func.isRequired,
  resources: PropTypes.arrayOf(PropTypes.string).isRequired,
  navigate: PropTypes.func.isRequired
};
