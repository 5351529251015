import dayjs from 'dayjs';

export const getSubscription = async ({
  dispatchAPI,
  pensioner,
  setSubscription,
  setSubscriptionInvoice,
  setRecordID,
  message
}) => {
  try {
    const todayStartOfDay = dayjs()
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss');
    const todayEndOfDay = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss');

    const { data } = await dispatchAPI('GET', {
      url: `/subscriptions?pensioner=${pensioner._id}&start_date<${todayEndOfDay}&end_date>${todayStartOfDay}&populate=invoice_file`
    });

    if (data.length) {
      setSubscription(data[0]);

      const { data: invoiceData } = await dispatchAPI('GET', {
        url: `/customerinvoices?subscription=${data[0]._id}`
      });

      if (invoiceData.length && setSubscriptionInvoice) {
        setSubscriptionInvoice(invoiceData[0]);
        setRecordID(invoiceData[0]._id);
      }
    }
  } catch (e) {
    message(e);
  }
};
