import { setShowParameters } from './setShowParameters';
import { setCreateUpdateParameters } from './setCreateUpdateParameters';

/**
 * Retrieves enums (enumerated values) for a specific resource based on the provided parameters.
 *
 * @function
 * @param {Object} params - The parameters for retrieving enums.
 * @param {function} params.dispatchAPI - A function for dispatching API requests.
 * @param {string} params.type - The type of resource.
 * @param {Array} params.pensionerColumns - Columns specific to pensioners.
 * @param {Array} params.ownerColumns - Columns specific to owners.
 * @param {Array} params.veterinarianAndGroomerColumns - Columns specific to veterinarians and groomers.
 * @param {function} params.setEnums - A function to set the enums state.
 * @param {function} params.message - A function for displaying error messages.
 * @param {string} params.purpose - The purpose of retrieving enums (show or create/update).
 */

export const getEnums = async ({
  dispatchAPI,
  type,
  pensionerColumns,
  ownerColumns,
  veterinarianAndGroomerColumns,
  setEnums,
  message,
  purpose
}) => {
  try {
    const showResourceName = setShowParameters({
      type,
      pensionerColumns,
      ownerColumns,
      veterinarianAndGroomerColumns
    }).resourceName;

    const createUpdateResourceName = setCreateUpdateParameters({
      type,
      pensionerColumns,
      ownerColumns,
      veterinarianAndGroomerColumns
    }).resourceName;

    const { data } = await dispatchAPI('GET', {
      url: `/${
        purpose === 'show' ? showResourceName : createUpdateResourceName
      }/enums`
    });
    setEnums(data);
  } catch (e) {
    message(e);
  }
};
