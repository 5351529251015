import { Radio, Select, Tag } from 'antd';

const { Option } = Select;

export const useExtraInfosFields = ({ t, enums, isFieldsLoading }) => {
  const getRadioGroup = () => (
    <Radio.Group>
      <Radio value>{t('pensioners.form.yes')}</Radio>
      <Radio value={false}>{t('pensioners.form.no')}</Radio>
    </Radio.Group>
  );

  const extraInfosFields = [
    {
      name: ['means_of_transport'],
      input: (
        <Select loading={isFieldsLoading} mode="multiple">
          {(enums?.means_of_transport || []).map((means_of_transport) => (
            <Option key={means_of_transport} value={means_of_transport}>
              <Tag>{t(`pensioners.tags.${means_of_transport}`)}</Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['disponibility']
    },
    {
      name: ['city_to_discover']
    },
    {
      name: ['usage_of_gps'],
      input: getRadioGroup()
    },
    {
      name: ['urgency', 'name']
    },
    {
      name: ['urgency', 'phone_number', 'number'],
      label: 'urgency.phone_number'
    },
    {
      name: ['is_children'],
      input: getRadioGroup()
    },
    {
      name: ['children_place']
    },
    {
      name: ['is_grand_children'],
      input: getRadioGroup()
    },
    {
      name: ['grand_children_place']
    }
  ];

  return { extraInfosFields };
};
