export const sameListContent = (pensioner, t, supervision) => {
  const {
    address: { city, state } = {},
    is_animals,
    is_smoker,
    phone_interview
  } = pensioner;

  const AlreadyHadAnimals = () => {
    if (phone_interview?.animals?.animals_type?.length) {
      const animalTypes = phone_interview.animals.animals_type.map((animal) =>
        t(`animals.tags.${animal.type}`)
      );

      return animalTypes.join(', ');
    }

    return t('owners.users_shows.none');
  };

  return [
    ...(supervision?.status !== 'FINISHED'
      ? [
          {
            label: t('owners.users_shows.city'),
            content: city || '-',
            span: 3
          },
          {
            label: t('owners.users_shows.state'),
            content: state || '-',
            span: 3
          }
        ]
      : []),

    {
      label: t('owners.users_shows.have_animals'),
      content: is_animals
        ? t('owners.users_shows.yes')
        : t('owners.users_shows.no'),
      span: 3
    },
    {
      label: t('owners.users_shows.already_had_animals'),
      content: AlreadyHadAnimals(),
      span: 3
    },
    {
      label: t('owners.users_shows.is_smoker'),
      content: is_smoker
        ? t('owners.users_shows.yes')
        : t('owners.users_shows.no'),
      span: 3
    }
  ];
};
