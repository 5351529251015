/**
 * Confirms a reservation.
 * @async
 * @function
 *
 * @param {Object} options - The options for confirming the reservation.
 * @param {string} options.reservationId - The ID of the reservation.
 * @param {string} options.supervisionId - The ID of the supervision.
 * @param {Function} options.dispatchAPI - The dispatchAPI function.
 * @param {Function} options.navigate - The navigate function.
 * @param {Function} options.message - The message function.
 * @param {boolean} options.refreshData - The refreshData flag.
 * @param {Function} options.setRefreshData - The setRefreshData function.
 * @param {Object} options.form - The form object.
 * @param {Function} options.setIsModalOpen - The setIsModalOpen function.
 * @param {Array} options.selectedReservations - The selected reservations array.
 * @param {Function} options.setIsLoading - The setIsLoading function.
 * @returns {void}
 */
export const confirmReservation = async ({
  reservationId,
  supervisionId,
  dispatchAPI,
  navigate,
  message,
  refreshData,
  setRefreshData,
  form,
  setIsModalOpen,
  selectedReservations,
  setIsLoading
}) => {
  try {
    setIsLoading(true);
    const values = await form.validateFields();

    if (selectedReservations.length) {
      selectedReservations.map(async (item) => {
        await dispatchAPI('PATCH', {
          url: `/reservations/${item.reservation}`,
          body: { status: 'RESERVE' }
        });
      });
    } else {
      await dispatchAPI('PATCH', {
        url: `/reservations/${reservationId}`,
        body: { status: 'RESERVE' }
      });
    }

    await dispatchAPI('PATCH', {
      url: `/supervisions/${supervisionId}`,
      body: { pensioners: values.pensioners, status: 'WAITING_BOOKING' }
    });

    const { data } = await dispatchAPI('GET', {
      url: `/anounces/form?supervision=${supervisionId}&populate=supervision`
    });

    if (data.length) {
      await dispatchAPI('PATCH', {
        url: `/anounces/${data[0]._id}`,
        body: { status: 'ARCHIVED' }
      });
    }

    if (navigate) {
      navigate(`/supervisions/show/${supervisionId}`);
    }

    if (setRefreshData) {
      setRefreshData(!refreshData);
      setIsModalOpen(false);
    }
    setIsLoading(false);
  } catch (e) {
    message(e);
  } finally {
    setIsLoading(false);
  }
};
