export const deleteSupervision = async ({
  dispatchAPI,
  id,
  message,
  setRefreshData,
  refreshData
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/supervisions/${id}`,
      body: { status: 'ARCHIVED', is_archived: true }
    });
    setRefreshData(!refreshData);
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
