import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

export const useHandleSubmitDocuments = ({
  setFilesList,
  setIsLoading,
  filesList,
  notification,
  supervision,
  setRefreshData,
  refreshData,
  t
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const arrayDocumentType = ['PENSIONER', 'OWNER', 'OTHER'];

  const idByDocumentType = {
    OWNER: () => supervision?.main_informations?.owner.user._id,
    PENSIONER: () =>
      supervision.pensioners.map((pensioner) => pensioner.user._id),
    OTHER: () => user?._id
  };

  const handleSubmitDocuments = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const data = {};
    if (filesList.length) {
      filesList.forEach((file) => {
        if (file?.newFile?.status === 'error') {
          return notification.warning({
            message: t('supervisions.show.documents.message')
          });
        }

        if (
          arrayDocumentType.includes(file?.newFile?.documentType.split('_')[0])
        ) {
          data[file.newFile.documentType.slice(0, -2)] =
            idByDocumentType[file.newFile.documentType.split('_')[0]]();
        }

        return formData.append(
          file?.newFile?.documentType,
          file?.newFile?.originFileObj
        );
      });

      formData.append('values', JSON.stringify(data));

      if (filesList.every((file) => file?.newFile?.status === 'done')) {
        try {
          await dispatchAPI('PATCH', {
            url: `/supervisions/documents/${supervision._id}`,
            body: formData
          });

          setRefreshData(!refreshData);
          setIsLoading(false);
        } catch (error) {
          message(error);
        }
      }
    }
    setFilesList([]);
  };

  return { handleSubmitDocuments };
};
