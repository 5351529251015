import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import ReactQuill from 'react-quill';

export const useFields = ({
  generalConditions,
  purpose,
  generalConditionToEdit
}) => {
  const disabledDate = (current) => {
    let isDisabled = false;

    if (purpose === 'create') {
      generalConditions.forEach(({ application_date, end_date }) => {
        if (
          dayjs(current) >= dayjs(application_date).startOf('day') &&
          dayjs(current) <= dayjs(end_date).endOf('day')
        ) {
          isDisabled = true;
        }
      });
    } else {
      generalConditions
        .filter((el) => el._id !== generalConditionToEdit._id)
        .forEach(({ application_date, end_date }) => {
          if (
            dayjs(current) >= dayjs(application_date).startOf('day') &&
            dayjs(current) <= dayjs(end_date).endOf('day')
          ) {
            isDisabled = true;
          }
        });
    }

    return isDisabled;
  };

  const fields = [
    {
      name: ['application_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabledDate={disabledDate}
          dateRender={(current) => {
            const isDisabled = disabledDate(current);
            return isDisabled ? (
              <div style={{ color: 'red' }}>{current.date()}</div>
            ) : (
              current.date()
            );
          }}
        />
      )
    },
    {
      name: ['end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabledDate={disabledDate}
          dateRender={(current) => {
            const isDisabled = disabledDate(current);
            return isDisabled ? (
              <div style={{ color: 'red' }}>{current.date()}</div>
            ) : (
              current.date()
            );
          }}
        />
      )
    },
    {
      name: ['content'],
      rules: [{ required: true }],
      input: <ReactQuill />
    }
  ];

  return { fields };
};
