import ReactQuill from 'react-quill';

/**
 * useFields hook for defining form fields for template creation or update.
 *
 * @returns {Object} - An object containing the form fields configuration.
 * @property {Array} fields - An array of field objects with name, rules, and optional input component.
 * @property {Array} fields.name - The name of the field (array for nested fields).
 * @property {Array} fields.rules - An array of validation rules for the field.
 * @property {ReactNode} fields.input - An optional React component for custom input (e.g., ReactQuill).
 */
export const useFields = () => {
  const fields = [
    {
      name: ['template_name'],
      rules: [{ required: true }]
    },
    {
      name: ['object'],
      rules: [{ required: true }]
    },
    {
      name: ['type'],
      rules: [{ required: true }]
    },
    {
      name: ['template_content'],
      rules: [{ required: true }],
      input: <ReactQuill />
    }
  ];
  return {
    fields
  };
};
