import { Image, Carousel as AntdCarousel, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * A carousel component that displays a slideshow of images.
 *
 * @component
 * @param {Object[]} images - An array of image objects to be displayed in the carousel.
 * @returns {JSX.Element} The rendered carousel component.
 */
export const Carousel = ({ imagesIds }) => {
  const { token } = useAuthContext();

  return (
    <AntdCarousel autoplay dots>
      {imagesIds?.map((image) => (
        <Image
          src={`${process.env.REACT_APP_API_URL}/get-file-url/${image}/${token}`}
          placeholder={<Spin loading size="large" />}
        />
      ))}
    </AntdCarousel>
  );
};

Carousel.propTypes = {
  imagesIds: PropTypes.arrayOf(PropTypes.shape({}))
};

Carousel.defaultProps = {
  imagesIds: []
};
