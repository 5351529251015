export const getSupervisions = async ({
  dispatchAPI,
  setSupervions,
  message,
  extraQuery
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `supervisions${extraQuery || ''}`
    });
    setSupervions(data);
  } catch (error) {
    message(error);
  }
};
