import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { handleSubmitCreditNote } from '../../../../customers/utils/handleSubmitCreditNote';
import { useGenerateFormItem } from '../../../../../../utils/generateFormItem';
import { useErrorMessage } from '../../../../../../utils/errorMessage';
import { useAuthContext } from '../../../../../../contexts/AuthContext';

/**
 * CreditNoteForm component for displaying and managing the credit note form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - Ant Design form instance.
 * @param {Object} props.invoice - The invoice object.
 * @param {number} props.totalHT - Total HT (excluding tax) value.
 * @param {number} props.totalTTC - Total TTC (including tax) value.
 * @param {function} props.setIsCreditNoteCardVisible - Function to set the visibility of the credit note card.
 * @param {boolean} props.isCreditNoteCardVisible - Flag indicating whether the credit note card is visible.
 * @param {string[]} props.wordingsIds - Array of wordings IDs.
 * @param {Object[]} props.creditNoteFields - Array of credit note form fields.
 * @returns {JSX.Element} CreditNoteForm component.
 */
export const CreditNoteForm = ({
  form,
  invoice,
  totalHT,
  totalTTC,
  setIsCreditNoteCardVisible,
  isCreditNoteCardVisible,
  wordingsIds,
  creditNoteFields
}) => {
  const generateFields = useGenerateFormItem();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const navigate = useNavigate();

  /**
   * Handles form submission for creating a credit note.
   *
   * @param {Object} body - Form data.
   * @returns {void}
   */
  const handleFormSubmit = (body) => {
    handleSubmitCreditNote({
      body,
      invoice,
      totalHT,
      totalTTC,
      dispatchAPI,
      message,
      setIsCreditNoteCardVisible,
      isCreditNoteCardVisible,
      navigate,
      wordingsIds
    });
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      {creditNoteFields &&
        creditNoteFields.map((field) =>
          generateFields('customerinvoices', field)
        )}
      <Row>
        <Col>
          <Form.Item>
            <Button onClick={() => setIsCreditNoteCardVisible(false)}>
              {t('buttons.cancel')}
            </Button>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Button htmlType="submit">
              {t('customerinvoices.show.buttons.create_credit_note')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

CreditNoteForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  invoice: PropTypes.shape({}).isRequired,
  totalHT: PropTypes.number.isRequired,
  totalTTC: PropTypes.number.isRequired,
  setIsCreditNoteCardVisible: PropTypes.func.isRequired,
  isCreditNoteCardVisible: PropTypes.bool.isRequired,
  wordingsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  creditNoteFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
