import { Input, Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

/**
 * Hook qui génère les champs de formulaire pour la création/mise à jour d'une réservation.
 * @param {Object} options - Les options pour générer les champs.
 * @param {Array} options.supervisions - La liste des supervisions disponibles.
 * @param {Array} options.pensioners - La liste des pensionnés disponibles.
 * @param {boolean} options.isFieldsLoading - Indique si les champs sont en cours de chargement.
 * @param {Array} options.disabledFields - Liste des champs désactivés.
 * @returns {Object} Les champs de formulaire et l'indicateur de chargement.
 */

export const useFields = ({
  supervisions,
  pensioners,
  isFieldsLoading,
  disabledFields
}) => {
  const filterOption = (input, option) => {
    const children = String(option?.children);
    return children.toLowerCase().includes(input.toLowerCase());
  };

  const fields = [
    {
      name: ['supervision'],
      label: 'supervision.reference',
      rules: [{ required: true }],
      input: (
        <Select
          disabled={disabledFields?.includes('supervision')}
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
        >
          {supervisions.map((item) => (
            <Option value={item._id} key={item._id}>
              {item.reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['pensioner'],
      label: 'pensioner.title',
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
        >
          {pensioners.map((item) => (
            <Option value={item._id} key={item._id}>
              {item.first_name} {item.last_name} | {item.reference}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['comment'],
      label: 'comment',
      input: <TextArea />
    }
  ];

  return {
    fields,
    isFieldsLoading
  };
};
