import { Form, Button, Flex } from 'antd';
import PropTypes from 'prop-types';
import { useFields } from '../fields/fields';
import { useGenerateFormItem } from '../../../utils/generateFormItem';

export const InfosStep = ({
  form,
  infosStep,
  setInfosStep,
  setCurrent,
  t,
  supervision,
  setOpen,
  questionnaireType,
  setQuestionnaireType,
  setRatingDate,
  showReviewDrawer,
  supervisions,
  setSupervision
}) => {
  const { infosSupervisionFields } = useFields({
    supervision,
    setQuestionnaireType,
    questionnaireType,
    setRatingDate,
    showReviewDrawer,
    supervisions,
    setSupervision
  });

  const generateFormItem = useGenerateFormItem();

  const handleFinish = (data) => {
    setCurrent((current) => current + 1);
    setInfosStep({ ...infosStep, ...data });
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      {infosSupervisionFields.map((field) =>
        generateFormItem('owners.users_shows.review_drawer', field)
      )}
      <Flex gap="small" justify="flex-end">
        <Button type="link" onClick={() => setOpen(false)}>
          {t('buttons.cancel')}
        </Button>
        <Button type="primary" htmlType="submit">
          {t('buttons.next')}
        </Button>
      </Flex>
    </Form>
  );
};

InfosStep.propTypes = {
  form: PropTypes.shape({}).isRequired,
  infosStep: PropTypes.shape({}).isRequired,
  setInfosStep: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({}).isRequired,
  setOpen: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  setQuestionnaireType: PropTypes.func.isRequired,
  setRatingDate: PropTypes.func.isRequired,
  showReviewDrawer: PropTypes.bool,
  supervisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSupervision: PropTypes.func.isRequired
};

InfosStep.defaultProps = {
  showReviewDrawer: false
};
