import { Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ownerListContent } from './litContent';
import { DescriptionList } from '../../../../components';

export const OwnerProfile = ({ supervision }) => {
  const { t } = useTranslation();

  const { main_informations: { owner } = {} } = supervision || {};

  return (
    <Flex vertical>
      <Typography.Title level={5} className="underlined">
        Propriétaire
      </Typography.Title>
      <DescriptionList
        data={ownerListContent(owner, t, supervision)}
        layout="horizontal"
      />
    </Flex>
  );
};

OwnerProfile.propTypes = {
  supervision: PropTypes.shape({}).isRequired
};
