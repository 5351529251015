import { useAuthContext } from '../contexts/AuthContext';
import { useErrorMessage } from './errorMessage';

export const useListResourceArchiving = (setForceRefresh, resourceName) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const deleteStatus = {
    users: '',
    owners: 'ARCHIVED',
    pensioners: 'ARCHIVED',
    customerinvoices: ''
  };

  const restoreStatus = {
    users: '',
    owners: 'ACTIVE',
    pensioners: 'INACTIVE',
    customerinvoices: ''
  };

  const statusByRole = resourceName.split('/')[0];

  const deleteResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${resourceName}/${id}/false`,
        body: { is_archived: true, status: deleteStatus[statusByRole] }
      });
      setForceRefresh(true);
    } catch (e) {
      message(e);
    }
  };

  const restoreResource = async (id) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/${resourceName}/${id}/false`,
        body: { is_archived: false, status: restoreStatus[statusByRole] }
      });
      setForceRefresh(true);
    } catch (e) {
      message(e);
    }
  };

  return { deleteResource, restoreResource };
};
