import { InputNumber } from 'antd';

export const useFields = () => {
  const fields = [
    {
      name: ['start_day'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['end_day'],
      rules: [{ required: true }],
      input: <InputNumber />
    }
  ];

  return {
    fields
  };
};
