export const getReservation = async ({
  dispatchAPI,
  user,
  supervisionId,
  message,
  setReservation,
  setPensioner
}) => {
  try {
    const { data: pensionerData } = await dispatchAPI('GET', {
      url: `/pensioners?user=${user._id}`
    });

    if (pensionerData.length) {
      setPensioner(pensionerData[0]);
    }

    const { data } = await dispatchAPI('GET', {
      url: `/reservations?supervision=${supervisionId}&pensioner=${pensionerData[0]._id}`
    });

    if (data.length) {
      setReservation(data[0]);
    }
  } catch (e) {
    message(e);
  }
};
