export const getPensioners = async ({
  dispatchAPI,
  setPensioners,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', { url: 'pensioners' });
    setPensioners(data);
  } catch (error) {
    message(error);
  }
};
