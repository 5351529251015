import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

/**
 * Submits the form data for creating or editing a supervision.
 * @function
 *
 * @param {Object} options - The options object.
 * @param {Object} options.informationsData - The information data.
 * @param {Object} options.supervisionAddressData - The supervision address data.
 * @param {Object} options.summaryData - The summary data.
 * @param {Array} options.petsittingAddressFileList - The list of petsitting address files.
 * @param {string} options.purpose - The purpose of the form submission ('create' or 'edit').
 * @param {Function} options.dispatchAPI - The dispatchAPI function for making API calls.
 * @param {string} options.id - The ID of the supervision (only used for editing).
 * @param {Object} options.Message - The Message object for displaying messages.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.navigate - The navigate function for navigation.
 * @param {Function} options.message - The message function for displaying error messages.
 * @param {Array} options.selectedAnouncePhotos - The list of selected announcement photos.
 * @param {Object} options.supervisionPrice - The supervision price data.
 * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
 */
export const submitForm = async ({
  informationsData,
  supervisionAddressData,
  summaryData,
  purpose,
  dispatchAPI,
  id,
  Message,
  t,
  navigate,
  message,
  selectedAnouncePhotos,
  supervisionPrice
}) => {
  try {
    const formData = new FormData();

    const startDate = informationsData.main_informations.start_date;
    const endDate = informationsData.main_informations.end_date;

    const formattedStartDate = dayjs(startDate)
      .utcOffset(0)
      .year(startDate.year())
      .month(startDate.month())
      .date(startDate.date())
      .hour(startDate.hour())
      .minute(startDate.minute());

    const formattedEndDate = dayjs(endDate)
      .utcOffset(0)
      .year(endDate.year())
      .month(endDate.month())
      .date(endDate.date())
      .hour(endDate.hour())
      .minute(endDate.minute());

    const body = {
      main_informations: {
        ...informationsData.main_informations,
        start_date: formattedStartDate,
        end_date: formattedEndDate
      },
      petsitting_address: supervisionAddressData.petsitting_address,
      summary: {
        ...summaryData.summary,
        anounce_photos: selectedAnouncePhotos,
        price_category: {
          ...summaryData.summary.price_category,
          supervision_price: supervisionPrice.supervision_price,
          reduction_ttc: supervisionPrice.reduction_ttc
        }
      }
    };

    formData.append('values', JSON.stringify(body));

    const setApiCall = () => {
      switch (purpose) {
        case 'create':
          return { url: '/supervisions/form', action: 'POST' };
        case 'edit':
          return { url: `/supervisions/form/${id}`, action: 'PATCH' };
        default:
          return { url: '/supervisions', action: 'POST' };
      }
    };

    await dispatchAPI(setApiCall().action, {
      url: setApiCall().url,
      body: formData
    });

    Message.success(t(`supervisions.messages.success.${purpose}`));
    return navigate('/supervisions');
  } catch (e) {
    return message(e);
  }
};
