import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal } from 'antd';
import { detailAnimalInfo } from '../../../../../components/Animals/listContent/animalInfo';
import { DescriptionList } from '../../../../../components';
import { Carousel } from '../../../../../components/Carousel/Carousel';

/**
 * `AnimalInfoModal` is a component that displays a modal with detailed information about a specific animal.
 * The modal contains a carousel of images and a description list.
 *
 * @component
 * @param {Object} props - The properties passed down from the parent component.
 * @param {Object} props.owner - An object containing details about the owner and an array of animal objects.
 * @param {boolean} props.isModalOpen - A boolean flag indicating whether the modal is open.
 * @param {Function} props.setIsModalOpen - A function to set the `isModalOpen` state.
 * @param {Function} props.t - A function to handle the translation of strings.
 * @param {number} props.index - The index of the selected animal in the owner's animals array.
 *
 * @returns {React.Element} JSX representation of the component.
 */
const AnimalInfoModal = ({ owner, isModalOpen, setIsModalOpen, t, index }) => (
  <Modal
    title={t('owners.show.animal_info', {
      animal_number: index + 1
    })}
    open={isModalOpen}
    onOk={() => setIsModalOpen(false)}
    onCancel={() => setIsModalOpen(false)}
  >
    <Col xs={24} xxl={24} style={{ marginBottom: 16 }}>
      <Carousel imagesIds={owner?.animals[index]?.animal_photo} />
    </Col>
    <Col xs={24} xxl={24}>
      <DescriptionList
        data={detailAnimalInfo(owner.animals[index] || {}, t)}
        translate
        skipEmpty
      />
    </Col>
  </Modal>
);

export default AnimalInfoModal;

AnimalInfoModal.propTypes = {
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(
      PropTypes.shape({
        animal_photo: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string })
        )
      })
    )
  }).isRequired,
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  t: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

AnimalInfoModal.defaultProps = {
  isModalOpen: false,
  setIsModalOpen: null
};
