import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { ListResource } from '../../../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { InvoiceStatus } from '../../../../../utils/constants/tagColors';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { deleteInvoice } from '../../../../invoices/utils/deleteInvoice';

export const ListInvoices = ({ supervisionId, setCustomerInvoices }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const navigate = useNavigate();

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/customerinvoices/enums'
      });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const columns = [
    {
      title: t('customerinvoices.form.reference'),
      key: 'reference',
      dataIndex: 'reference'
    },
    {
      title: t('customerinvoices.form.type'),
      key: 'type',
      dataIndex: 'type',
      render: (type) =>
        type ? (
          <Tag color={InvoiceStatus[type]}>
            {t(`customerinvoices.tags.${type}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true
    },
    {
      title: t('customerinvoices.form.emission_date'),
      key: 'emission_date',
      dataIndex: 'emission_date',
      sorter: true,
      render: (emission_date) => moment(emission_date).format('DD/MM/YYYY')
    },
    {
      title: t('customerinvoices.form.total_ttc'),
      key: 'total_ttc',
      render: (record) =>
        record?.total_ttc
          ? `${record?.total_ttc} ${record?.franc ? 'Fr' : '€'}`
          : '0 €',
      sorter: true
    },
    {
      title: t('customerinvoices.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={InvoiceStatus[status]}>
            {t(`customerinvoices.tags.${status}`)}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.roles?.map((r) => ({
        text: t(`customerinvoices.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('customerinvoices.form.payment_amount'),
      key: 'payment_amount',
      render: (record) => {
        if (record?.payment_amount) {
          return `${record.payment_amount} ${record.franc ? 'Fr' : '€'}`;
        }

        return record.franc ? '0 Fr' : '0 €';
      },
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/invoices/customers/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {!['PAID'].includes(record?.status) && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() =>
                  deleteInvoice({
                    dispatchAPI,
                    id: record._id,
                    message,
                    setRefreshData,
                    refreshData,
                    path: 'customerinvoices'
                  })
                }
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <ListResource
      resourceName="customerinvoices"
      columns={columns}
      extraQuery={`supervision=${supervisionId}`}
      customActionColumn
      withPageHeader={false}
      withCreateButton={false}
      widthSearchBar={false}
      forceRefresh={refreshData}
      setResourceData={setCustomerInvoices}
      onDoubleClickAction={{
        action: (record) => navigate(`/invoices/customers/show/${record?._id}`)
      }}
    />
  );
};

ListInvoices.propTypes = {
  supervisionId: PropTypes.string.isRequired,
  setCustomerInvoices: PropTypes.func.isRequired
};
