import { Card, Col } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@ant-design/charts';
import PropTypes from 'prop-types';
import { useMonthlySalesChart } from '../../../utils/setDataChart/monthlySalesChart';
import { monthlySalesChartConfig } from '../../../utils/setChartConfig/monthlySalesChartConfig';

/**
 * MonthlySalesChart component for displaying monthly sales data.
 * @component
 * @param {Object} props - The properties of the MonthlySalesChart component.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Object} props.customerInvoices - Object containing customer invoices data.
 * @param {Object} props.customerInvoices.BALANCE - Balance data in customer invoices.
 * @param {Object} props.customerInvoices.ADVANCE_PAYMENT - Advance payment data in customer invoices.
 * @returns {JSX.Element} - JSX element representing the MonthlySalesChart component.
 */
export const MonthlySalesChart = ({ t, customerInvoices }) => {
  const { monthlySalesChartData } = useMonthlySalesChart({
    customerInvoices,
    t
  });

  return (
    <Col span={24}>
      <Card
        title={t('dashboard.tabs.finances.monthly_sales')}
        className="dashboard-card"
      >
        <Column {...monthlySalesChartConfig({ monthlySalesChartData })} />
      </Card>
    </Col>
  );
};

MonthlySalesChart.propTypes = {
  t: PropTypes.func.isRequired,
  customerInvoices: PropTypes.shape({
    BALANCE: PropTypes.shape({}),
    ADVANCE_PAYMENT: PropTypes.shape({})
  }).isRequired
};
