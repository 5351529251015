import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PrincipalInfoCards from './cards/PrincipalInfoCards';
import PriceManagementInfoCards from './cards/PriceManagementInfoCards';

const DetailsCards = ({
  supervisionPrice,
  isLoading,
  refreshData,
  setRefreshData,
  setPurpose,
  setVisible,
  setPriceToEdit
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xxl={24}>
        <PrincipalInfoCards
          t={t}
          isLoading={isLoading}
          supervisionPrice={supervisionPrice}
        />
      </Col>
      <Col xs={24} xxl={24}>
        {Object.keys(supervisionPrice).length ? (
          <PriceManagementInfoCards
            t={t}
            isLoading={isLoading}
            supervisionPrice={supervisionPrice}
            refreshData={refreshData}
            setRefreshData={setRefreshData}
            setVisible={setVisible}
            setPurpose={setPurpose}
            setPriceToEdit={setPriceToEdit}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default DetailsCards;

DetailsCards.propTypes = {
  supervisionPrice: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  setPurpose: PropTypes.func.isRequired,
  setPriceToEdit: PropTypes.func.isRequired
};

DetailsCards.defaultProps = {};
