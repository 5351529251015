import { monthArray } from '../monthsArray';

/**
 * Hook for generating monthly sales chart data.
 * @param {Object} params - Parameters for useMonthlySalesChart hook.
 * @param {Function} params.t - Translation function for internationalization.
 * @param {Object} params.customerInvoices - Customer invoices data.
 * @returns {Object} - Object containing monthly sales chart data.
 * @property {Array} monthlySalesChartData - Array of objects representing monthly sales chart data.
 */
export const useMonthlySalesChart = ({ t, customerInvoices }) => {
  // Object for translating types.
  const typesTranslation = {
    BALANCE: t('dashboard.tabs.finances.balance'),
    ADVANCE_PAYMENT: t('dashboard.tabs.finances.advance_payment'),
    TOTAL: t('dashboard.tabs.finances.total')
  };

  // Array of objects representing monthly sales chart data.
  const monthlySalesChartData = monthArray(t).map((month) => {
    // Filtered data object.
    const filteredData = {
      BALANCE: customerInvoices?.BALANCE,
      ADVANCE_PAYMENT: customerInvoices?.ADVANCE_PAYMENT,
      TOTAL: {
        [month.toLowerCase()]:
          (customerInvoices.BALANCE?.[month.toLowerCase()] || 0) +
          (customerInvoices.ADVANCE_PAYMENT?.[month.toLowerCase()] || 0)
      }
    };

    // Map entries of filtered data to generate sales chart data.
    return Object.entries(filteredData).map(([key, value]) => ({
      month,
      type: typesTranslation?.[key],
      sales: value?.[month.toLowerCase()] || 0
    }));
  });

  return { monthlySalesChartData };
};
