import { Button, List, Typography, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import { pensionersExtraInfosListContent } from './listContent/pensionersExtraInfosListContent';
import { outOfNavRoutes } from '../../../../../utils/constants/ownerPensionerRoutes';

const { Text } = Typography;

export const ExtraInfos = ({
  profile,
  t,
  navigate,
  isLoading,
  checkProfileLock,
  profileLockMessage
}) => (
  <Skeleton loading={isLoading} active>
    <Button
      onClick={() => {
        if (checkProfileLock) {
          profileLockMessage(t, 'profile');
          return;
        }
        navigate(
          `${outOfNavRoutes.PROFILE}/edit/${profile?._id}?fieldsActiveKey=extraInfosFields`
        );
      }}
      type="primary"
      style={{ float: 'right', zIndex: 10 }}
    >
      <EditOutlined />
      {t('buttons.edit')}
    </Button>
    <List
      itemLayout="vertical"
      dataSource={pensionersExtraInfosListContent(profile, t)}
      renderItem={(item) => (
        <List.Item>
          <Text>{item.label} : </Text>
          <Text strong>{item.content}</Text>
        </List.Item>
      )}
    />
  </Skeleton>
);

ExtraInfos.propTypes = {
  profile: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  checkProfileLock: PropTypes.bool.isRequired,
  profileLockMessage: PropTypes.func.isRequired
};
