import { useTranslation } from 'react-i18next';

/**
 * Hook personnalisé pour générer les colonnes de tableau de factures.
 * @returns {Object} Les colonnes pour les informations client et les informations internes.
 */

export const useColumns = ({ type, invoice }) => {
  const { t } = useTranslation();

  const customerInfo = [
    {
      title: t('customerinvoices.form.wordings.title'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('customerinvoices.form.wordings.total_ht'),
      key: 'total_ht',
      dataIndex: 'total_ht',
      render: (total_ht) =>
        total_ht && `${invoice.associated_invoice ? '-' : ''} ${total_ht} €`,
      sorter: true
    },
    {
      title: t('customerinvoices.form.wordings.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => tva && `${tva} %`,
      sorter: true
    },
    ...(type !== 'interninvoices' && !invoice.associated_invoice
      ? [
          {
            title: t('customerinvoices.form.wordings.reduction'),
            key: 'reduction',
            dataIndex: 'reduction',
            render: (reduction) => reduction && `${reduction} %`,
            sorter: true
          }
        ]
      : []),

    {
      title: t('customerinvoices.form.wordings.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      render: (total_ttc) =>
        total_ttc && `${invoice.associated_invoice ? '-' : ''} ${total_ttc} €`,
      sorter: true
    }
  ];

  const internInfo = [
    {
      title: t('interninvoices.form.wordings.title'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('interninvoices.form.wordings.unit_price'),
      key: 'unit_price',
      dataIndex: 'unit_price',
      render: (unit_price) => unit_price && `${unit_price} €`,
      sorter: true
    },
    {
      title: t('interninvoices.form.wordings.quantity'),
      key: 'quantity',
      dataIndex: 'quantity',
      render: (quantity) => quantity && quantity,
      sorter: true
    },
    {
      title: t('interninvoices.form.wordings.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => tva && `${tva} %`,
      sorter: true
    },
    {
      title: t('interninvoices.form.wordings.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      render: (total_ttc) => total_ttc && `${total_ttc} €`,
      sorter: true
    }
  ];

  return { customerInfo, internInfo };
};
