import React from 'react';
import { Menu, Typography, Col, Row } from 'antd';
import { MailOutlined, DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { generateDocumentsOld } from '../../../mail-sender/utils/generateDocumentsOld';

const { Text } = Typography;

export const ExplanatoryDocumentMenu = ({
  owner,
  setIsEmailModalOpen,
  setTemplateType,
  setDocumentType
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  return (
    <Menu>
      <Menu.Item
        key={owner._id}
        onClick={() => {
          setIsEmailModalOpen(true);
          setTemplateType('SEND_EXPLANATORY_DOCUMENT');
          setDocumentType('OWNER_EXPLANATORY_DOCUMENT_WITHOUT_NAME');
        }}
      >
        <Row gutter={10}>
          <Col>
            <MailOutlined />
          </Col>
          <Col>
            <Text>{t('owners.show.buttons.send_mail')}</Text>
          </Col>
        </Row>
      </Menu.Item>

      <Menu.Item
        key={`dl${owner._id}`}
        onClick={() =>
          generateDocumentsOld(
            dispatchAPI,
            message,
            'OWNER_EXPLANATORY_DOCUMENT',
            null,
            owner._id,
            t
          )
        }
      >
        <Row gutter={10}>
          <Col>
            <DownloadOutlined />
          </Col>
          <Col>
            <Text>{t('owners.show.buttons.dl_doc')}</Text>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );
};

ExplanatoryDocumentMenu.propTypes = {
  owner: PropTypes.shape({
    _id: PropTypes.string,
    type: PropTypes.string,
    number: PropTypes.number
  }).isRequired,
  setIsEmailModalOpen: PropTypes.func,
  setTemplateType: PropTypes.func,
  setDocumentType: PropTypes.func
};

ExplanatoryDocumentMenu.defaultProps = {
  setIsEmailModalOpen: () => {},
  setTemplateType: () => {},
  setDocumentType: () => {}
};
