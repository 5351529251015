export const getOwners = async ({ dispatchAPI, setOwners, message }) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: '/owners?is_archived=false'
    });
    setOwners(data);
  } catch (e) {
    message(e);
  }
};
