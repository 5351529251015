import { Divider, Popconfirm, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ContainerOutlined,
  EditOutlined,
  WarningOutlined,
  RollbackOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { archiveGeneralCondition } from './utils/archiveGeneralCondition';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

export const useColumns = ({
  setPurpose,
  setGeneralConditionToEdit,
  setVisible
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  return [
    {
      title: t('general-conditions.form.application_date'),
      key: 'application_date',
      dataIndex: 'application_date',
      render: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : ''),
      sorter: true
    },
    {
      title: t('general-conditions.form.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : ''),
      sorter: true
    },
    {
      key: 'actions',
      align: 'right',
      fixed: 'right',
      width: 100,
      render: (record) => (
        <>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => {
              setPurpose('edit');
              setGeneralConditionToEdit(record);
              setVisible(true);
            }}
          >
            <EditOutlined style={{ fontSize: 18 }} />
          </Button>
          <>
            <Divider type="vertical" />
            {!record.is_archived ? (
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() =>
                  archiveGeneralCondition({ dispatchAPI, message, record })
                }
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            ) : (
              <Popconfirm
                title={t('datatable.column.action.unarchived.title')}
                okText={t('datatable.column.action.unarchived.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.unarchived.cancel')}
                icon={<WarningOutlined />}
              >
                <RollbackOutlined style={{ color: '#b51010', fontSize: 18 }} />
              </Popconfirm>
            )}
          </>
        </>
      )
    }
  ];
};
