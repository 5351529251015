import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { outOfNavRoutes, routes } from '../utils/constants/adminRoutes';
import { Login } from '../routes/login';
import { Dashboard } from '../routes/dashboards/Dashboard';
import { Exception } from '../components';
import { useAuthContext } from '../contexts/AuthContext';
import { ProfileRouter } from '../routes/profile/ProfileRouter';
import { Confirmation } from '../routes/emailConfirmation/Confirmation';
import { Settings } from '../routes/settings/settings';
import { UserRouter } from '../routes/users/UserRouter';
import { OwnerRouter } from '../routes/owners/OwnerRouter';
import { PensionerRouter } from '../routes/pensioners/PensionerRouter';
import { SupervisionRouter } from '../routes/supervisions/SupervisionRouter';
import { ReservationRouter } from '../routes/reservation/ReservationRouter';
import { SupervisionPriceRouter } from '../routes/supervision-prices/SupervisionPriceRouter';
import { InvoiceRouter } from '../routes/invoices/InvoiceRouter';
import { MailSenderRouter } from '../routes/mail-sender/MailSenderRouter';
import { TemplateDocRouter } from '../routes/template-docs/TemplateDocsRouter';
import { AdminTheme } from '../assets/styles/Theme/AdminTheme';
import { RequireAuth } from './RequireAuth';

export const AdminRouter = () => {
  const { isValid } = useAuthContext();

  useEffect(() => {
    const body = document.body;
    if (body) {
      body.className = 'admin';
    }
  });

  return (
    <BrowserRouter>
      <AdminTheme />
      <Routes>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} element={<Login />} />
        <Route path={outOfNavRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          element={<Confirmation />}
        />
        <Route element={<RequireAuth isValid={isValid} />}>
          <Route
            path={`${outOfNavRoutes.PROFILE}/*`}
            element={<ProfileRouter />}
          />
          <Route path={outOfNavRoutes.SETTINGS} element={<Settings />} />
          <Route index element={<Dashboard />} />
          <Route path={`${routes.USERS}/*`} element={<UserRouter />} />
          <Route path={`${routes.OWNERS}/*`} element={<OwnerRouter />} />
          <Route
            path={`${routes.PENSIONERS}/*`}
            element={<PensionerRouter />}
          />
          <Route
            path={`${routes.SUPERVISIONS}/*`}
            element={<SupervisionRouter />}
          />
          <Route
            path={`${routes.RESERVATIONS}/*`}
            element={<ReservationRouter />}
          />
          <Route
            path={`${routes.SUPERVISION_PRICES}/*`}
            element={<SupervisionPriceRouter />}
          />
          <Route path={`${routes.INVOICES}/*`} element={<InvoiceRouter />} />
          <Route
            path={`${routes.MAIL_SENDER}/*`}
            element={<MailSenderRouter />}
          />
          <Route
            path={`${routes.TEMPLATE_DOCS}/*`}
            element={<TemplateDocRouter />}
          />
          <Route path="*" element={<Exception />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
