import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Tag, Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  WarningOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ListResource } from '../../../../components/ListResource/ListResource';
import { SupervisionStatus } from '../../../../utils/constants/tagColors';
import { deleteSupervision } from '../../../supervisions/ShowSupervision/utils/deleteSupervision';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

const ListSupervisions = ({ pensioner }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const [refreshData, setRefreshData] = useState(false);

  const columns = [
    {
      title: t('supervisions.form.reference'),
      key: 'reference',
      dataIndex: 'reference'
    },
    {
      title: t('supervisions.form.pensioners'),
      key: 'pensioners',
      dataIndex: 'pensioners',
      render: (pensioners) => {
        if (pensioners && pensioners.length > 0) {
          return pensioners
            .map(
              (element) =>
                `${t(`pensioners.tags.${element.civility}`)} ${
                  element.last_name
                } ${element.first_name}`
            )
            .join(', ');
        }
        return '-';
      }
    },
    {
      title: t('supervisions.form.address.city'),
      key: 'address',
      dataIndex: 'address',
      render: (address) =>
        (address.petsitting_address &&
          address?.petsitting_address?.address?.city) ||
        '-'
    },
    {
      title: t('supervisions.form.start_date'),
      key: 'main_informations.start_date',
      dataIndex: 'main_informations',
      render: (main_informations) =>
        (main_informations &&
          dayjs(main_informations.start_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      title: t('supervisions.form.end_date'),
      key: 'main_informations.end_date',
      dataIndex: 'main_informations',
      render: (main_informations) =>
        (main_informations &&
          dayjs(main_informations.end_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      title: t('supervisions.form.status'),
      key: 'status',
      render: (status) => (
        <Tag color={SupervisionStatus[status]}>
          {t(`supervisions.tags.${status}`)}
        </Tag>
      ),
      dataIndex: 'status'
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Link
            to={{
              pathname: `/supervisions/show/${record?._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
          {['WAITING', 'REQUEST', 'FINISHED'].includes(record?.status) && (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() =>
                  deleteSupervision({
                    dispatchAPI,
                    id: record._id,
                    message,
                    setRefreshData,
                    refreshData
                  })
                }
                icon={<WarningOutlined />}
              >
                <ContainerOutlined
                  style={{ color: '#b51010', fontSize: 18 }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </>
      )
    }
  ];

  return (
    <ListResource
      resourceName="supervisions"
      extraQuery={`pensioners=${pensioner._id}`}
      populate="pensioners,address.petsitting_address"
      columns={columns}
      showEdit={false}
      withPageHeader={false}
      withCreateButton={false}
      forceRefresh={refreshData}
      customActionColumn
      onDoubleClickAction={{
        action: (record) => {
          navigate(`/supervisions/show/${record?._id}`);
        }
      }}
    />
  );
};

export default ListSupervisions;

ListSupervisions.propTypes = {
  pensioner: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired
};
