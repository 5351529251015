import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { personalInformations } from './fields/personalInformationsFields';
import { additionalInformations } from './fields/additionalInformationsFields';
import { extraInformations } from './fields/extraInformationsFields';

export const useFields = ({
  filesList,
  setFilesList,
  setShowSecondProfile,
  showSecondProfile,
  form,
  setInternetAccess,
  internetAccess,
  isChildren,
  isGrandChildren,
  setIsChildren,
  setIsGrandChildren,
  setRemovedFiles,
  country,
  setCountry
}) => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const { personnalInformationsFields } = personalInformations({
    filesList,
    setFilesList,
    enums,
    isFieldsLoading,
    setShowSecondProfile,
    showSecondProfile,
    setRemovedFiles
  });
  const { additionalInformationsFields } = additionalInformations({
    filesList,
    setFilesList,
    isFieldsLoading,
    form,
    setInternetAccess,
    internetAccess,
    setRemovedFiles,
    country,
    setCountry
  });
  const { extraInformationsFields } = extraInformations({
    enums,
    isFieldsLoading,
    isChildren,
    isGrandChildren,
    setIsChildren,
    setIsGrandChildren
  });

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/pensioners/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    personnalInformationsFields,
    additionalInformationsFields,
    extraInformationsFields,
    isFieldsLoading
  };
};
