import React from 'react';
import { Modal, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

export const SubscriptionInfoModal = ({
  subscriptonInfoModalOpen,
  setSubscriptonInfoModalOpen,
  subscriptionInvoice,
  t
}) => (
  <Modal
    title={t('subscriptions.info_modal.title')}
    visible={subscriptonInfoModalOpen}
    onOk={() => setSubscriptonInfoModalOpen(false)}
    onCancel={() => setSubscriptonInfoModalOpen(false)}
  >
    <Text>
      {t('subscriptions.info_modal.content', {
        invoice_reference: subscriptionInvoice.reference
      })}
    </Text>
  </Modal>
);

SubscriptionInfoModal.propTypes = {
  subscriptonInfoModalOpen: PropTypes.bool.isRequired,
  setSubscriptonInfoModalOpen: PropTypes.func.isRequired,
  subscriptionInvoice: PropTypes.shape({
    reference: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired
};
