/**
 * Deletes an invoice by setting the 'is_archived' flag to true.
 *
 * @async
 * @function
 * @param {Object} params - Parameters for deleting the invoice.
 * @param {string} params.id - The ID of the invoice to be deleted.
 * @param {function} params.dispatchAPI - A function to dispatch API requests.
 * @param {function} params.message - A function to display messages or errors.
 * @param {function} params.setRefreshData - A function to update the data refresh state.
 * @param {boolean} params.refreshData - The current state of data refresh.
 * @param {string} params.path - The path of the API endpoint for deleting the invoice.
 * @param {function} [params.navigate] - A function to navigate to a different route (optional).
 * @returns {Promise<void>} A Promise that resolves when the invoice is successfully deleted.
 */
export const deleteInvoice = async ({
  id,
  dispatchAPI,
  message,
  setRefreshData,
  refreshData,
  path,
  navigate
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/${path}/${id}`,
      body: { is_archived: true }
    });

    if (navigate) {
      navigate(-1);
    } else {
      await setRefreshData(!refreshData);
    }
  } catch (e) {
    message(e);
  }
};
