import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Popconfirm, Divider, Button, Typography } from 'antd';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';

const { Text } = Typography;

export const useColumns = ({
  deletePrices,
  supervisionPrice,
  refreshData,
  setRefreshData,
  setVisible,
  setPurpose,
  setPriceToEdit
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const columns = [
    {
      title: t('supervisionprices.form.rate.total_ht'),
      key: 'total_ht',
      dataIndex: 'total_ht',
      render: (total_ht) => total_ht && `${total_ht} €`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.tva'),
      key: 'tva',
      dataIndex: 'tva',
      render: (tva) => tva && `${tva} %`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.total_ttc'),
      key: 'total_ttc',
      dataIndex: 'total_ttc',
      render: (total_ttc) => total_ttc && `${total_ttc} €`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.advance_payment'),
      key: 'advance_payment',
      dataIndex: 'advance_payment',
      render: (advance_payment) => advance_payment && `${advance_payment} €`,
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.application_date'),
      key: 'application_date',
      dataIndex: 'application_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('supervisionprices.form.rate.end_date'),
      key: 'end_date',
      dataIndex: 'end_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 120,
      render: (record) =>
        record.default ? (
          <Text>{t('supervisionprices.form.rate.default')}</Text>
        ) : (
          <>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setPurpose('edit');
                setPriceToEdit(record);
                setVisible(true);
              }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Button>

            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={() =>
                deletePrices({
                  record,
                  dispatchAPI,
                  message,
                  setRefreshData,
                  refreshData,
                  supervisionPrice
                })
              }
              icon={<WarningOutlined />}
            >
              <DeleteOutlined
                style={{ color: '#b51010', fontSize: 18 }}
                type="delete"
              />
            </Popconfirm>
          </>
        )
    }
  ];

  return { columns };
};
