export const populateOwnerFields = async ({ dispatchAPI, owner, form }) => {
  const { data } = await dispatchAPI('GET', {
    url: `/veterinarians?owner=${owner._id}`
  });

  if (data.length) {
    form.setFieldsValue({
      main_information: {
        veterinarian: {
          name: data[0].name,
          address: {
            number: data[0].address.number,
            street: data[0].address.street,
            postal_code: data[0].address.postal_code,
            city: data[0].address.city,
            country: data[0].address.country
          }
        }
      }
    });
  }

  form.setFieldsValue({
    main_information: { owner: owner._id, owner_name: owner._id }
  });
};
