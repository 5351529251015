import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

/**
 * `BathroomInformations` is a hook that manages the structure and properties of bathroom information fields.
 * It returns an array of objects where each object defines a checkbox field with associated properties such as `name`, `input`, and `valuePropName`.
 * The `input` property contains a Checkbox component with localized labels retrieved using `useTranslation` hook.
 *
 * @hook
 * @returns {Object} - An object containing an array of field configurations for the bathroom information section in the form.
 */
const BathroomInformations = () => {
  const { t } = useTranslation();

  const bathroomInformationsFields = [
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'bathroom',
        'independant_bathroom'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.independant_bathroom'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'bathroom',
        'shower'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.shower'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    },
    {
      label: 'ghost',
      name: [
        'petsitting_address',
        'type_of_storage_for_pensioner',
        'bathroom',
        'bathroom_towel'
      ],
      input: (
        <Checkbox>
          {t(
            'petsittingaddress.form.type_of_storage_for_pensioner.bathroom.bathroom_towel'
          )}
        </Checkbox>
      ),
      valuePropName: 'checked'
    }
  ];

  return {
    bathroomInformationsFields
  };
};

export default BathroomInformations;
