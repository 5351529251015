import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components';
import { useCustomSubmit } from './utils/customSubmit';
import { useGroomersFields } from './fields/groomersFields';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';
import { editingLocked } from '../../../utils/editingLocked';

export const CreateUpdateGroomer = ({ purpose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profileLock } = useSocketContext();
  const { id, groomId } = useParams();

  const { customSubmit } = useCustomSubmit({
    baseUrl: 'groomers',
    purpose
  });

  const { groomersFields } = useGroomersFields();

  return (
    <>
      {editingLocked(profileLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'veterinarian_groomer',
            text: 'profile'
          }}
          navigate={navigate}
        />
      )}
      <CreateUpdateContainer
        purpose={purpose}
        baseUrl="groomers"
        resource="groomers"
        fields={groomersFields}
        customSubmit={customSubmit}
        customId={groomId}
        disabled={editingLocked(profileLock, id)}
      />
    </>
  );
};

CreateUpdateGroomer.propTypes = {
  purpose: PropTypes.string.isRequired
};
