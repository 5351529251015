import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useConfig } from './utils/createUpdateConfig';
import { FormExtra } from './form/FormExtra';
import { useUnlockEditProfile } from '../../utils/useUnlockEditProfile';
import { useHandleProfileLock } from '../../utils/useHandleProfileLock';

/**
 * CreateUpdatePensioner component for creating or updating pensioner information.
 * @module CreateUpdatePensioner
 * @param {Object} props - Component props.
 * @param {string} props.purpose - Purpose of the component (e.g., 'create' or 'update').
 * @returns {JSX.Element} React component.
 */
export const CreateUpdatePensioner = ({ purpose }) => {
  const [filesList, setFilesList] = useState({
    photo: [],
    secondary_profile: { secondary_profile_photo: [] }
  });
  const [isChildren, setIsChildren] = useState(false);
  const [isGrandChildren, setIsGrandChildren] = useState(false);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [showSecondProfile, setShowSecondProfile] = useState(false);
  const [internetAccess, setInternetAccess] = useState(true);
  const [country, setCountry] = useState('');

  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { unlockEditProfile } = useUnlockEditProfile(id, 'pensioners');
  const [form] = Form.useForm();
  const { handleProfileLock } = useHandleProfileLock(id);
  const {
    personnalInformationsFields,
    additionalInformationsFields,
    extraInformationsFields,
    isFieldsLoading
  } = useFields({
    filesList,
    setFilesList,
    setShowSecondProfile,
    showSecondProfile,
    form,
    setInternetAccess,
    internetAccess,
    isChildren,
    isGrandChildren,
    setIsChildren,
    setIsGrandChildren,
    setRemovedFiles,
    country,
    setCountry
  });
  const config = useConfig({
    filesList,
    setFilesList,
    dispatchAPI,
    message,
    setInternetAccess,
    setShowSecondProfile,
    setIsChildren,
    setIsGrandChildren,
    form,
    removedFiles,
    setCountry
  });

  /**
   * Effect hook to call the unlock function before closing the tab and clean up on unmount.
   * @listens beforeunload
   */
  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await handleProfileLock();
      })();

      window.addEventListener('beforeunload', async () => {
        await unlockEditProfile();
      });
    }

    return async () => {
      if (purpose === 'edit') {
        // clean up the event handler when the component unmounts
        window.removeEventListener('beforeunload', async () => {
          await unlockEditProfile();
        });
        // call the unlock func when the component unmounts
        await unlockEditProfile();
      }
    };
  }, []);

  return (
    <CreateUpdateContainer
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="pensioners/form"
      resource="pensioners"
      populate="photo"
      config={config}
      customFormInstance={form}
      formExtra={
        <FormExtra
          personnalInformationsFields={personnalInformationsFields}
          additionalInformationsFields={additionalInformationsFields}
          extraInformationsFields={extraInformationsFields}
        />
      }
    />
  );
};

CreateUpdatePensioner.propTypes = {
  purpose: PropTypes.string.isRequired
};
