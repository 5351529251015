/**
 * Function to format owner information for display.
 *
 * @param {Object} owner - The owner object containing owner information.
 * @param {string} owner.reference - The owner's ID number.
 * @param {string} owner.first_name - The owner's first name.
 * @param {string} owner.last_name - The owner's last name.
 * @param {Object} owner.cell_phone_number - The owner's cell phone number.
 * @param {string} owner.cell_phone_number.number - The cell phone number of the owner.
 * @param {Object} owner.phone_number - The owner's phone number.
 * @param {string} owner.phone_number.number - The phone number of the owner.
 * @param {string} owner.email - The email address of the owner.
 * @param {Object} owner.address - The owner's address.
 * @param {string} owner.address.number - The street number of the owner's address.
 * @param {string} owner.address.city - The city where the owner is located.
 * @param {string} owner.address.postal_code - The postal code of the owner's address.
 * @param {string} owner.address.street - The street name of the owner's address.
 * @returns {Array} - An array of objects containing formatted owner information.
 */
export const ownerInfo = (owner) => {
  const {
    reference,
    first_name,
    last_name,
    cell_phone_number,
    phone_number,
    email,
    address
  } = owner || {};

  const { number, city, postal_code, street } = address || {};

  return [
    {
      label: 'supervisions.form.owner_reference',
      span: 1,
      content: (reference && reference) || '-'
    },
    {
      label: 'supervisions.form.owner.last_name',
      span: 2,
      content: (first_name && last_name && `${first_name} ${last_name}`) || '-'
    },
    {
      label: 'supervisions.form.owner.cell_phone_number',
      span: 1,
      content: (cell_phone_number && cell_phone_number.number) || '-'
    },
    {
      label: 'supervisions.form.owner.phone_number',
      span: 2,
      content: (phone_number && phone_number.number) || '-'
    },
    {
      label: 'supervisions.form.owner.email',
      span: 1,
      content: (email && email) || '-'
    },
    {
      label: 'supervisions.form.owner.address',
      span: 2,
      content:
        (address &&
          `${number || ''} ${street || ''}, ${city || ''}, ${
            postal_code || ''
          }`) ||
        '-'
    }
  ];
};
