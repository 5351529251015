import { notification } from 'antd';

export const profileLockMessage = (t, resource) => {
  notification.warning({
    message: t('profile.editActionLocked', {
      resource: t(`profile.resources.${resource}`)
    }),
    duration: false
  });
};
