import { Card, Col } from 'antd';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Column } from '@ant-design/charts';
import { useEffect, useState } from 'react';
import { setRegistrationsChart } from '../../../utils/setDataChart/setRegistrationsChart';
import { registrationConfig } from '../../../utils/setChartConfig/registrationChartConfig';

/**
 * Component to display a registrations chart.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.t - Translation function for localization.
 * @param {Object} props.registrations - Registrations data.
 *
 * @returns {React.ReactNode} - The rendered component.
 */
export const RegistrationsChart = ({ t, registrations }) => {
  const [items, setItems] = useState([]);

  // set registration on mount or registration change
  useEffect(() => {
    setItems([]);
    setRegistrationsChart({ registrations, setItems, t });
  }, [registrations]);

  // Transformed chart data for ant-design/charts
  const data = items.reduce((acc, item) => {
    acc.push({
      month: item.month,
      sales: item.owners,
      type: 'Propriétaires'
    });

    acc.push({
      month: item.month,
      sales: item.pensioners,
      type: 'Retraités'
    });

    return acc;
  }, []);

  return (
    <Col span={24} style={{ marginTop: '20px' }}>
      <Card
        title={t('dashboard.tabs.statistics.monthly_registrations')}
        className="dashboard-card"
      >
        <Column {...registrationConfig(data)} />
      </Card>
    </Col>
  );
};

RegistrationsChart.propTypes = {
  t: PropTypes.func.isRequired,
  registrations: PropTypes.shape({}).isRequired
};
