export const translateDocumentsType = (t, supervision) => ({
  PENSIONER_CONTRACT_1: t('supervisions.show.documents.PENSIONER_CONTRACT_1', {
    reference: supervision.pensioners?.[0]?.reference
  }),
  PENSIONER_CONTRACT_2: t('supervisions.show.documents.PENSIONER_CONTRACT_2', {
    reference: supervision?.pensioners?.[1]?.reference
  }),
  OWNER_CONTRACT__: t('supervisions.show.documents.OWNER_CONTRACT'),
  PENSIONER_RATING_SHEET_1: t(
    'supervisions.show.documents.PENSIONER_RATING_SHEET_1'
  ),
  PENSIONER_RATING_SHEET_2: t(
    'supervisions.show.documents.PENSIONER_RATING_SHEET_2'
  ),
  OWNER_RATING_SHEET__: t('supervisions.show.documents.OWNER_RATING_SHEET'),
  OTHER_DOCUMENT__: t('supervisions.show.documents.OTHER_DOCUMENT')
});
