import dayjs from 'dayjs';
import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

export const useConfig = ({
  setPetsittingAddressFileList,
  dispatchAPI,
  message,
  setSelectedAnouncePhotos,
  setSupervisionAddressData,
  startDateRef,
  endDateRef,
  animals,
  setReduction,
  setOwner,
  setFormDataEnvironment,
  setCountry
}) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();

      formData.append('values', JSON.stringify(data));

      return formData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();

      formData.append('values', JSON.stringify(data));

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      const startDate = startDateRef;
      const endDate = endDateRef;

      setOwner(data.main_informations.owner);
      setSupervisionAddressData({
        petsitting_address: data.address.petsitting_address
      });
      startDate.current = dayjs(data.main_informations.start_date);
      endDate.current = dayjs(data.main_informations.end_date);

      setReduction(data.summary.reduction);

      if (data.address.petsitting_address.dwelling_photos.length) {
        try {
          const dwellingPhotosPromises = (
            data.address.petsitting_address.dwelling_photos || []
          ).map((photo) => createFileFromJSON(photo, dispatchAPI, message));

          const results = await Promise.all(dwellingPhotosPromises);
          setPetsittingAddressFileList(results);
        } catch (error) {
          message(message(error));
        }
      }

      if (data?.address.petsitting_address?.type_of_environment) {
        setFormDataEnvironment(
          data.address.petsitting_address.type_of_environment
        );
      }

      if (data.summary.anounce_photos.length) {
        try {
          const anouncePhotosPromises = (data.summary.anounce_photos || []).map(
            (photo) => createFileFromJSON(photo, dispatchAPI, message)
          );

          const results = await Promise.all(anouncePhotosPromises);
          const resultIds = results.map((result) => ({
            _id: result._id,
            name: result.metadata.originalName
          }));
          setSelectedAnouncePhotos(resultIds);
        } catch (error) {
          message(message(error));
        }
      }

      if (data.main_informations.animals.length) {
        const animalsRef = animals;
        animalsRef.current = data.main_informations.animals;
      }

      const newData = {
        main_informations: {
          ...data.main_informations.owner,
          owner: data?.main_informations?.owner?._id,
          accept: data.main_informations.accept,
          owner_name: data?.main_informations?.owner?._id,
          animals: data.main_informations.animals.map((animal) => animal._id),
          accept_smoker: data.main_informations.accept_smoker,
          start_date: dayjs(data.main_informations.start_date),
          end_date: dayjs(data.main_informations.end_date)
        },
        petsitting_address: data.address.petsitting_address,
        summary: {
          ...data.summary,
          price_category: {
            duration: data.summary.price_category.duration._id
          }
        }
      };

      setCountry(data?.petsitting_address?.address?.country);

      return newData;
    }
  }
});
