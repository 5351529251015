/**
 * Formats a comment into an object with label and content.
 *
 * @param {string|null} comment - The comment to format.
 * @returns {Object} An object with label and content for the comment.
 */
export const commentInfo = (comment) => [
  {
    label: '',
    span: 3,
    content: (comment && comment) || '-'
  }
];
