import { downloadPDFsAsZip, generatePDF } from '../../../utils/generatePDF';
import { generateDocumentsOld } from './generateDocumentsOld';

export const generateDocuments = async (
  record,
  dispatchAPI,
  message,
  t,
  setLoading = () => {}
) => {
  setLoading(true);
  let responses = [];
  switch (record.document_type) {
    case 'OTHER_PENSIONER':
      await record.pensioners.map(async (pensionerId) => {
        await generatePDF({
          dispatchAPI,
          id: pensionerId,
          collection: 'Pensioner',
          use_case: 'OTHER_PENSIONER',
          message
        });
      });
      break;
    case 'OWNER_WISH':
      responses = await Promise.all(
        record.owners.map((ownerId) =>
          generatePDF(
            message,
            dispatchAPI,
            [
              {
                collection: 'Owner',
                documentId: ownerId
              }
            ],
            'OWNER_WISH',
            () => {},
            false
          )
        )
      );
      await downloadPDFsAsZip(responses, 'owner_wish.zip');
      break;
    case 'PENSIONER_WISH':
      responses = await Promise.all(
        record.pensioners.map((pensionerId) =>
          generatePDF({
            dispatchAPI,
            id: pensionerId,
            collection: 'Pensioner',
            use_case: 'PENSIONER_WISH',
            message
          })
        )
      );
      await downloadPDFsAsZip(responses, 'pensioner_wish.zip');
      break;
    case 'OWNER_EXPLANATORY_DOCUMENT':
      await record.owners.map(async (ownerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          null,
          ownerId,
          t
        );
      });
      break;
    case 'PENSIONER_EXPLANATORY_DOCUMENT':
      await record.pensioners.map(async (pensionerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          null,
          pensionerId,
          t
        );
      });
      break;
    case 'GROOMER_VETERINARIAN_ADVERT':
      if (record.veterinarians.length) {
        await downloadPDFsAsZip(
          await Promise.all(
            record.veterinarians.map((veterinarianId) =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                record.document_type,
                veterinarianId,
                null,
                t,
                () => {},
                false
              )
            )
          ),
          'veterinary_ad.zip'
        );
      }

      if (record.groomers.length) {
        await downloadPDFsAsZip(
          await Promise.all(
            record.groomers.map((groomerId) =>
              generateDocumentsOld(
                dispatchAPI,
                message,
                record.document_type,
                groomerId,
                null,
                t,
                () => {},
                false
              )
            )
          ),
          'groomer_ad.zip'
        );
      }
      break;
    case 'OWNER_EXPLANATORY_DOCUMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    case 'PENSIONER_EXPLANATORY_DOCUMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    case 'ANOUNCEMENT_WITHOUT_NAME':
      await generateDocumentsOld(
        dispatchAPI,
        message,
        record.document_type,
        null,
        null,
        t
      );
      break;
    default:
      await record.pensioners.map(async (pensionerId) => {
        await generateDocumentsOld(
          dispatchAPI,
          message,
          record.document_type,
          pensionerId,
          null,
          t
        );
      });
  }

  setLoading(false);
  return null;
};
