import { Route, Routes } from 'react-router-dom';
import { ShowPetSittingAddress } from './ShowPetSittingAddress';
import { CreateUpdatePetsittingAddress } from './CreateUpdatePetsittingAddress';

/**
 * Renders the router for managing pet sitting addresses.
 *
 * @returns {JSX.Element} The JSX element representing the pet sitting address router.
 */
export const PetSittingAddressRouter = () => (
  <Routes>
    <Route
      path="/create/:id"
      element={<CreateUpdatePetsittingAddress purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdatePetsittingAddress purpose="edit" />}
    />
    <Route path="/" element={<ShowPetSittingAddress />} />
  </Routes>
);
