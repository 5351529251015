import React from 'react';
import { Row, Col, Select } from 'antd';
import { handleSelectChangeDoc } from './handleSelectChangeDoc';

export const draggerProps = ({ setFilesList, filesList, selectOptions }) => ({
  onRemove: (file) => {
    setFilesList(filesList.filter((f) => f.uid !== file.uid));
  },
  beforeUpload: (file) => {
    const newFile = file;
    newFile.status = 'error';
    setFilesList((prevFileList) => [
      ...prevFileList,
      {
        newFile
      }
    ]);
    return false;
  },
  // eslint-disable-next-line react/no-unstable-nested-components
  itemRender: (originNode, file) => (
    <Row>
      <Col span={12}>
        <div>{originNode}</div>
      </Col>
      <Col span={12}>
        <Select
          onChange={(value) =>
            handleSelectChangeDoc({ value, file, setFilesList, filesList })
          }
          style={{ height: 22, width: '100%' }}
          options={selectOptions}
        />
      </Col>
    </Row>
  ),
  filesList
});
