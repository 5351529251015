import { useState, useEffect } from 'react';
import { Card, Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListReservations } from '../dataTable/ListReservations';
import { CreateUpdateReservation } from '../../../../reservation/CreateUpdateReservation';

/**
 * Renders a card component that displays reservations for a supervision.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.id - The ID of the supervision.
 * @param {function} props.refreshData - A function to refresh the data.
 * @param {function} props.setRefreshData - A function to set the refresh data.
 * @param {Object} props.supervision - The supervision object.
 * @returns {JSX.Element} The reservations card component.
 */
export const ReservationsCard = ({
  id,
  refreshData,
  setRefreshData,
  supervision
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);

  useEffect(() => {
    form.setFieldValue(['supervision'], supervision._id);
  }, []);
  return (
    <>
      <Card
        title={t('supervisions.show.reservation.title')}
        extra={
          <Button onClick={() => setIsCreateUpdateModalOpen(true)}>
            {t('supervisions.show.reservation.create')}
          </Button>
        }
      >
        <ListReservations
          supervisionId={id}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          supervision={supervision}
        />
      </Card>
      <CreateUpdateReservation
        isCreateUpdateModalOpen={isCreateUpdateModalOpen}
        setIsCreateUpdateModalOpen={setIsCreateUpdateModalOpen}
        purpose="create"
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        customId={null}
        form={form}
        disabledFields={['supervision']}
      />
    </>
  );
};

ReservationsCard.propTypes = {
  supervision: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired,
  id: PropTypes.string.isRequired,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func
};

ReservationsCard.defaultProps = {
  refreshData: false,
  setRefreshData: () => {}
};
