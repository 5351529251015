import React from 'react';
import { Card, Image, Skeleton, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../contexts/AuthContext';

/**
 * Renders a layout for displaying images.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string[]} props.url - The array of image URLs.
 * @param {boolean} props.isLoading - Indicates if the images are currently loading.
 * @returns {JSX.Element} The rendered ImagesLayout component.
 */
export const ImagesLayout = ({ url, isLoading }) => {
  const { token } = useAuthContext();

  const mainImage = url[0];
  const restImages = url.slice(1);

  return (
    <Image.PreviewGroup>
      <div className="image-container">
        <div className="main-image">
          {!url.length || isLoading ? (
            <Skeleton.Image
              active={!!isLoading}
              loading={isLoading}
              style={{ width: 400, height: 200 }}
            />
          ) : (
            <Image
              width={400}
              src={`${process.env.REACT_APP_API_URL}/get-file-url/${mainImage}/${token}`}
              placeholder={
                <Card style={{ width: 400, height: 200 }}>
                  <Spin loading />
                </Card>
              }
            />
          )}
        </div>
        <div className="sub-images-container">
          {restImages.map((image) =>
            !url.length || isLoading ? (
              <Skeleton.Image
                key={image?._id}
                active={!!isLoading}
                loading={isLoading}
                style={{ width: 200, height: 100 }}
              />
            ) : (
              <Image
                key={image?._id}
                width={200}
                src={`${process.env.REACT_APP_API_URL}/get-file-url/${image}/${token}`}
                placeholder={
                  <Card style={{ width: 200, height: 200 }}>
                    <Spin loading />
                  </Card>
                }
              />
            )
          )}
        </div>
      </div>
    </Image.PreviewGroup>
  );
};

ImagesLayout.propTypes = {
  url: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired
    })
  ),
  isLoading: PropTypes.bool.isRequired
};

ImagesLayout.defaultProps = {
  url: []
};
