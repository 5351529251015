import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button, Form, Modal } from 'antd';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useGenerateFormItem } from '../../../../../utils/generateFormItem';
import { useCancelSupervisionInfoFields } from '../../../fields/cancelSupervisionFields';
import { handleSubmitCancelSupervision } from '../../utils/handleSubmitCancelSupervision';

/**
 * Represents a modal component for canceling a supervision.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.cancelSupervisionModalVisible - Determines if the cancel supervision modal is visible.
 * @param {Function} props.setCancelSupervisionModalVisible - A function to set the visibility of the cancel supervision modal.
 * @param {Function} props.t - A translation function for internationalization.
 * @param {Function} props.setRefreshData - A function to refresh the data after canceling the supervision.
 * @param {boolean} props.refreshData - Determines if the data needs to be refreshed.
 * @returns {JSX.Element} The cancel supervision modal component.
 */
export const CancelSupervisionModal = ({
  cancelSupervisionModalVisible,
  setCancelSupervisionModalVisible,
  t,
  setRefreshData,
  refreshData
}) => {
  const [form] = Form.useForm();
  const { cancelSupervisionFields } = useCancelSupervisionInfoFields();
  const generateFields = useGenerateFormItem();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      footer={null}
      title={t('supervisions.show.modal.title.cancel_anounce')}
      open={cancelSupervisionModalVisible}
      onOk={() => setCancelSupervisionModalVisible(false)}
      onCancel={() => setCancelSupervisionModalVisible(false)}
    >
      <Form form={form}>
        {cancelSupervisionFields &&
          cancelSupervisionFields.map((field) =>
            generateFields('supervisions', field)
          )}
      </Form>
      <Button onClick={() => setCancelSupervisionModalVisible(false)}>
        {t('buttons.cancel')}
      </Button>
      <Button
        loading={isLoading}
        onClick={() =>
          handleSubmitCancelSupervision({
            status: 'CANCEL',
            form,
            dispatchAPI,
            id,
            setCancelSupervisionModalVisible,
            setRefreshData,
            refreshData,
            message,
            setIsLoading
          })
        }
      >
        {t('buttons.save')}
      </Button>
    </Modal>
  );
};

CancelSupervisionModal.propTypes = {
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(
      PropTypes.shape({
        animal_photo: PropTypes.arrayOf(
          PropTypes.shape({ url: PropTypes.string })
        )
      })
    )
  }).isRequired,
  cancelSupervisionModalVisible: PropTypes.bool,
  setCancelSupervisionModalVisible: PropTypes.func,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    reference: PropTypes.string,
    main_informations: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }),
    address: PropTypes.shape({
      petsitting_address: PropTypes.shape({
        address: PropTypes.shape({
          state: PropTypes.string
        })
      })
    })
  }).isRequired,
  anounce: PropTypes.shape({
    description: PropTypes.string
  }).isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired
};

CancelSupervisionModal.defaultProps = {
  cancelSupervisionModalVisible: false,
  setCancelSupervisionModalVisible: null
};
