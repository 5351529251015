import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFields } from './fieldsMailingTemplate';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * CreateUpdateTemplateMails component for creating or updating template mails.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the template (create/update).
 * @returns {React.ReactNode} - The rendered component.
 */
export const CreateUpdateTemplateMails = ({ purpose }) => {
  const { fields } = useFields();
  const { t } = useTranslation();

  return (
    <CreateUpdateContainer
      fields={fields}
      purpose={purpose}
      title={t('template_mails.template_mail_create_edit')}
      baseUrl="templates-mails"
      resource="templates-mails"
      tradKey="template_mails"
    />
  );
};

CreateUpdateTemplateMails.propTypes = {
  purpose: PropTypes.string.isRequired
};
