/**
 * Calculates and updates totalHT, totalTTC, and totalReduction based on the provided wordings data.
 *
 * @param {Object} options.updatedWordingType - Updated wording type ('HT', 'TTC', or 'REDUCTION').
 * @param {Object} options.form - Ant Design Form instance.
 * @param {Object} options.supervisionPrices - Supervision prices data.
 * @returns {void}
 */
export const addCustomerTotalTTC = ({
  updatedWordingType,
  form,
  supervisionPrices
}) => {
  let totalHT = 0;
  let totalTTC = 0;
  let totalReduction = 0;

  /**
   * Wordings values obtained from the form.
   * @type {Array}
   */
  const wordingsValues = form.getFieldValue('wordings') || [];

  // Iterate through each wording to calculate totalHT, totalTTC, and totalReduction
  wordingsValues.forEach((wording, index) => {
    const totalTTCValue = supervisionPrices.ttc || wording.total_ttc || 0;
    const tva = wording.tva || 20;
    const reduction = wording.reduction || 0;

    if (updatedWordingType === 'HT' || updatedWordingType === 'REDUCTION') {
      const totalTTCWithReduction =
        totalTTCValue - (totalTTCValue * reduction) / 100;

      totalTTC += totalTTCWithReduction;
      totalHT += totalTTC - (totalTTC * tva) / 100;
      totalReduction += (totalTTCValue * reduction) / 100;

      form.setFieldValue(['wordings', index, 'total_ttc'], totalTTC.toFixed(2));
      form.setFieldValue(['wordings', index, 'total_ht'], totalHT.toFixed(2));
    }

    if (updatedWordingType === 'TTC') {
      totalHT += totalTTCValue - (totalTTCValue * tva) / 100;
      totalTTC += totalTTCValue;

      form.setFieldValue(['wordings', index, 'total_ht'], totalHT.toFixed(2));
    }

    form.setFieldsValue({
      total_ht: totalHT.toFixed(2),
      total_ttc: totalTTC.toFixed(2),
      total_reduction_ttc: totalReduction.toFixed(2)
    });
  });
};
