import React from 'react';
import { Input, Form } from 'antd';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * `VeterinarianFields` is a React component that renders a group of form items
 * pertaining to veterinarian information. It utilizes the `useFields` hook to get
 * the field configurations and `useGenerateFormItem` hook to dynamically generate
 * form items based on the field configurations.
 *
 * @component
 * @example
 *
 * @returns {React.Element}
 * return (
 *   <VeterinarianFields />
 * );
 */
const VeterinarianFields = () => {
  const { fields } = useFields();
  const generateFields = useGenerateFormItem();

  return (
    <Input.Group name={['veterinarian']}>
      <Form.Item noStyle name={['veterinarian', `veterinarian`]}>
        {fields.map((field) => generateFields('veterinarians', field))}
      </Form.Item>
    </Input.Group>
  );
};

export default VeterinarianFields;
