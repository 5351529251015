import { Flex, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Option } = Select;

export const ExtraFilters = ({
  cityOptions,
  departmentOptions,
  setCityFilter,
  setDepartmentFilter,
  isFieldsLoading
}) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" gap="middle" wrap="wrap">
      <Flex align="center" gap="small">
        {`${t('pensioners.form.address.city')} :`}
        <Select
          loading={isFieldsLoading}
          showSearch
          allowClear
          style={{ width: 200 }}
          onChange={(value) => setCityFilter(value)}
        >
          {cityOptions.map((city) => (
            <Option key={city} value={city}>
              {city}
            </Option>
          ))}
        </Select>
      </Flex>
      <Flex align="center" gap="small">
        {`${t('pensioners.form.department')} :`}
        <Select
          loading={isFieldsLoading}
          showSearch
          allowClear
          style={{ width: 200 }}
          onChange={(value) => setDepartmentFilter(value)}
        >
          {departmentOptions.map((department) => (
            <Option key={department} value={department}>
              {department}
            </Option>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
};

ExtraFilters.propTypes = {
  departmentOptions: PropTypes.arrayOf(PropTypes.string),
  cityOptions: PropTypes.arrayOf(PropTypes.string),
  setCityFilter: PropTypes.func,
  setDepartmentFilter: PropTypes.func,
  isFieldsLoading: PropTypes.bool
};

ExtraFilters.defaultProps = {
  departmentOptions: null,
  cityOptions: null,
  setCityFilter: null,
  setDepartmentFilter: null,
  isFieldsLoading: false
};
