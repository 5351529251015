import { Route, Routes } from 'react-router-dom';
import { ListViewAds } from './ListViewAds';
import { ShowViewAds } from './ShowViewAds';

export const ViewAdsRouter = () => (
  <Routes>
    <Route index element={<ListViewAds />} />
    <Route path="/show/:supervisionId/:anounceId" element={<ShowViewAds />} />
  </Routes>
);
