import React from 'react';
import { DatePicker, InputNumber } from 'antd';
import dayjs from 'dayjs';

export const useFields = ({
  setTotalHT,
  setTVA,
  supervisionPrice,
  purpose,
  priceToEdit
}) => {
  const disabledDate = (current) => {
    let isDisabled = false;

    if (purpose === 'create') {
      supervisionPrice.rates
        .filter((el) => !el.default)
        .forEach(({ application_date, end_date }) => {
          if (
            dayjs(current) >= dayjs(application_date).startOf('day') &&
            dayjs(current) <= dayjs(end_date).endOf('day')
          ) {
            isDisabled = true;
          }
        });
    } else {
      supervisionPrice.rates
        .filter((el) => el._id !== priceToEdit._id)
        .filter((el) => !el.default)
        .forEach(({ application_date, end_date }) => {
          if (
            dayjs(current) >= dayjs(application_date).startOf('day') &&
            dayjs(current) <= dayjs(end_date).endOf('day')
          ) {
            isDisabled = true;
          }
        });
    }

    return isDisabled;
  };

  const fields = [
    {
      name: ['rate', 'total_ht'],
      rules: [{ required: true }],
      input: <InputNumber onChange={(e) => setTotalHT(e)} />
    },
    {
      name: ['rate', 'tva'],
      input: <InputNumber onChange={(e) => setTVA(e)} addonAfter="%" />
    },
    {
      name: ['rate', 'total_ttc'],
      input: <InputNumber disabled />
    },
    {
      name: ['rate', 'advance_payment'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['rate', 'application_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabledDate={disabledDate}
          dateRender={(current) => {
            const isDisabled = disabledDate(current);
            return isDisabled ? (
              <div style={{ color: 'red' }}>{current.date()}</div>
            ) : (
              current.date()
            );
          }}
        />
      )
    },
    {
      name: ['rate', 'end_date'],
      rules: [{ required: true }],
      input: (
        <DatePicker
          disabledDate={disabledDate}
          dateRender={(current) => {
            const isDisabled = disabledDate(current);
            return isDisabled ? (
              <div style={{ color: 'red' }}>{current.date()}</div>
            ) : (
              current.date()
            );
          }}
        />
      )
    }
  ];

  return { fields };
};
