import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { useFields } from './fields';

/**
 * `AnimalItem` is a component that maps through `animalFields` to generate form items for each field.
 *
 * @component
 * @param {Object} props - The properties object
 * @param {string} props.name - The name property for the form item
 * @param {Array} props.fileList - The list of files associated with the animal item
 * @param {Function} props.setFileList - The function to update the file list
 * @param {Object} props.form - The form instance on which the form item is being generated
 *
 * @returns {Array} - An array of generated form items
 */
export const AnimalItem = ({ name, fileList, setFileList, form }) => {
  const generateFields = useGenerateFormItem();
  const { fields: animalFields } = useFields({
    fileList,
    setFileList,
    name,
    form
  });

  return animalFields.map((field) => generateFields('animals', field));
};
