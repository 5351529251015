import { Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { DescriptionList } from '../../../../../components';
import { useCancelInfo } from '../listContent/cancelInfo';

export const CancelCard = ({ supervision, t }) => {
  const { cancelInfo, interruptInfo } = useCancelInfo({ supervision });
  return (
    <Card title={t('supervisions.show.cancel_info')}>
      <Row>
        <Col span={24}>
          {supervision.status === 'CANCEL' ? (
            <DescriptionList data={cancelInfo} translate />
          ) : (
            <DescriptionList data={interruptInfo} translate />
          )}
        </Col>
      </Row>
    </Card>
  );
};

CancelCard.propTypes = {
  supervision: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};
