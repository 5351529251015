import {
  UserOutlined,
  BranchesOutlined,
  SettingOutlined,
  FileAddOutlined,
  TeamOutlined,
  DashboardOutlined,
  IdcardOutlined,
  HomeOutlined,
  ThunderboltOutlined,
  ProfileOutlined,
  EuroOutlined,
  MailOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  DASHBOARD: <DashboardOutlined />,
  USERS: <UserOutlined />,
  OWNERS: <TeamOutlined />,
  PENSIONERS: <IdcardOutlined />,
  TEMPLATE_DOCS: <FileAddOutlined />,
  RESERVATIONS: <ThunderboltOutlined />,
  SUPERVISIONS: <HomeOutlined />,
  SUPERVISION_PRICES: <ProfileOutlined />,
  INVOICES: <EuroOutlined />,
  WORKFLOWS: <BranchesOutlined />,
  CONFIGURATIONS: <SettingOutlined />,
  MAIL_SENDER: <MailOutlined />
};

export default navMenuLogos;
