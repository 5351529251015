import { Input, Upload, Radio, Row, Space, Select, Tag } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { useCountryArray } from '../../../../utils/countryArray';
import { useCountryCodeInfos } from '../../../owners/form/utils/useCountryCodeInfos';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * Custom hook that returns an array of fields for the "Other Infos" section in the user's profile form.
 * @hook
 *
 * @param {Object} options - The options object.
 * @param {Function} options.t - The translation function.
 * @param {Function} options.setDocumentsFileList - The function to set the documents file list.
 * @param {Object} options.documentsFileList - The documents file list.
 * @param {Function} options.setCountry - The function to set the country.
 * @param {string} options.country - The country.
 * @param {Object} options.form - The form object.
 * @param {boolean} options.loading - The loading state.
 * @returns {Object} - The otherInfosFields array.
 */
export const useOtherInfosFields = ({
  t,
  setDocumentsFileList,
  documentsFileList,
  setCountry,
  country,
  form,
  loading
}) => {
  const countries = useCountryArray();
  const { handleCountryCodeInfos } = useCountryCodeInfos(country);

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const getRadioGroup = () => (
    <Radio.Group>
      <Radio value>{t('pensioners.form.yes')}</Radio>
      <Radio value={false}>{t('pensioners.form.no')}</Radio>
    </Radio.Group>
  );

  const draggerProps = (name) => ({
    onRemove: () => {
      const updatedFileList = { ...documentsFileList };
      delete updatedFileList[name];
      setDocumentsFileList(updatedFileList);
    },
    beforeUpload: (file) => {
      setDocumentsFileList((prevFileList) => ({
        ...prevFileList,
        [name]: [file]
      }));

      return false;
    },
    fileList: documentsFileList[name]
  });

  const otherInfosFields = [
    {
      name: ['address', 'number'],
      label: 'address.number'
    },
    {
      name: ['address', 'street']
    },
    {
      name: ['address', 'postal_code'],
      input: (
        <Input
          onChange={(e) =>
            handleCountryCodeInfos(e.target.value).then((values) => {
              form.setFieldValue(['address', 'city'], values?.city);
              form.setFieldValue(['address', 'state'], values?.state);
            })
          }
        />
      )
    },
    {
      label: 'address.state',
      name: ['address', 'state'],
      rules: [{ required: true }]
    },
    {
      name: ['address', 'city']
    },
    {
      name: ['address', 'country'],
      input: (
        <Select
          showSearch
          filterOption={filterOption}
          isFieldsLoading={loading}
        >
          {(countries || []).map((value) => (
            <Option key={value} value={value}>
              <Tag>{value}</Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [
        { required: true },
        ({ getFieldValue }) => {
          setCountry(getFieldValue(['address', 'country']));
        }
      ]
    },
    {
      name: ['phone_number', 'number'],
      input: <Input type="number" />
    },
    {
      name: ['email']
    },
    {
      name: ['is_animals'],
      input: getRadioGroup()
    },
    {
      name: ['is_smoker'],
      input: getRadioGroup()
    },
    {
      label: 'pensioner_certificate',
      input: (
        <Dragger {...draggerProps('early_retirement_certificate')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'insurance_certificate',
      input: (
        <Dragger {...draggerProps('insurance_certificate')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'proof_of_address',
      input: (
        <Dragger {...draggerProps('residence_proof')}>
          <Space>
            <UploadOutlined />
            {t('users.form.upload_file')}
          </Space>
        </Dragger>
      )
    },
    {
      label: 'other_documents',
      input: (
        <Dragger {...draggerProps('other_documents')}>
          <Row justify="center">
            <InboxOutlined style={{ fontSize: 40 }} />
          </Row>
          <Row justify="center">{t('users.form.upload_other')}</Row>
        </Dragger>
      )
    }
  ];

  return { otherInfosFields };
};
