/**
 * Configuration function for set supervisions pie chart data.
 * @param {Array} supervisionsPieChartData - Data for the supervisions pie chart.
 * @returns {Object} - Configuration object for the supervisions pie chart.
 */
export const supervisionsPieChartConfig = (supervisionsPieChartData) => ({
  appendPadding: 50,
  data: supervisionsPieChartData,
  height: 400,
  angleField: 'value',
  colorField: 'status',
  color: ['#A5A8D3', '#094452', '#90C4D9', '#EA5B1F', '#77AC7A', '#F8B238'],
  radius: 1,
  innerRadius: 0.8,
  label: {
    offset: 20,
    type: 'spiders',
    labelHeight: 100,
    content: '{value}',
    labelLine: {
      style: {
        lineWidth: 1,
        height: 100
      }
    }
  },
  legend: {
    position: 'bottom',
    marker: {
      symbol: 'circle'
    },
    maxRow: 3,
    flipPage: false,
    itemName: {
      style: {
        fontSize: 15,
        fontWeight: 600
      }
    }
  },
  interactions: [
    {
      type: 'element-selected'
    },
    {
      type: 'element-active'
    }
  ],
  statistic: {
    title: false,
    content: false
  }
});
