/**
 * Function to format veterinarian information for display.
 *
 * @param {Object} data - The data object containing veterinarian information.
 * @param {string} data.name - The veterinarian's name.
 * @param {Object} data.address - The veterinarian's address.
 * @param {string} data.address.city - The city where the veterinarian is located.
 * @param {string} data.address.country - The country where the veterinarian is located.
 * @param {string} data.address.number - The street number of the veterinarian's address.
 * @param {string} data.address.postal_code - The postal code of the veterinarian's address.
 * @param {string} data.address.street - The street name of the veterinarian's address.
 * @param {Object} data.phone_number - The veterinarian's phone number.
 * @param {string} data.phone_number.number - The phone number of the veterinarian.
 * @param {string} data.send_ad - Information about sending advertisements to the veterinarian.
 * @returns {Array} - An array of objects containing formatted veterinarian information.
 */
export const veterinarianInfo = (data = {}) => {
  const { name, address, phone_number, send_ad } = data || {};
  const { city, country, number, postal_code, street } = address || {};
  const { number: phoneNumber } = phone_number || {};

  return [
    {
      label: 'supervisions.show.veterinarian.name',
      span: 1,
      content: (name && name) || '-'
    },
    {
      label: 'supervisions.show.veterinarian.address',
      span: 1,
      content:
        address &&
        `${number || ''} ${street || ''}, ${city || ''} ${postal_code || ''}`
    },
    {
      label: 'supervisions.show.veterinarian.country',
      span: 1,
      content: (country && country) || '-'
    },
    {
      label: 'supervisions.show.veterinarian.phone_number',
      span: 1,
      content: (phoneNumber && phoneNumber) || '-'
    },
    {
      label: 'supervisions.show.veterinarian.send_ad',
      span: 1,
      content: (send_ad && send_ad) || '-'
    }
  ];
};
