import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Tag, Typography, Row, Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  BellOutlined,
  DownloadOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { InvoiceStatus } from '../../../utils/constants/tagColors';
import DetailsCards from '../showInvoice/detailsCards/DetailsCards';
import { deleteInvoice } from '../utils/deleteInvoice';
import { PaidInvoiceModal } from '../showInvoice/PaidInvoiceModal';
import { setInvoiceStatusToUnpaid } from './utils/setInvoiceStatusToUnpaid';
import { MailingModal } from '../../../components/Mailing/MailingModal';
import { generateDocumentsOld } from '../../mail-sender/utils/generateDocumentsOld';
import { useInvoicesContext } from '../../../contexts/InvoicesContext/InvoicesContext';

/**
 * Component for displaying details of a customer invoice.
 * @component
 * @returns {JSX.Element} React component.
 */
export const ShowCustomerInvoice = () => {
  // hooks
  const { id } = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [customerInvoice, setCustomerInvoice] = useState({});
  const [isPaidInvoiceModalOpen, setIsPaidInvoiceModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [isCreditNoteCardVisible, setIsCreditNoteCardVisible] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [recordID, setRecordID] = useState('');

  const { customers, enums } = useInvoicesContext();

  const getCustomerInvoice = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/customerinvoices/form/${id}?populate=supervision,customer,files,associated_invoice,subscription`
      });
      setCustomerInvoice(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getCustomerInvoice();
    })();
  }, [getCustomerInvoice, refreshData]);

  const scrollToCard = () => {
    setTimeout(() => {
      const cardElement = document.getElementById('credit-note-row');

      if (cardElement) {
        cardElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const templateType = {
    SUBSCRIPTION: 'SUBSCRIPTION-INVOICE',
    ADVANCE_PAYMENT: 'OWNER-INVOICE',
    BALANCE: 'OWNER-INVOICE',
    CREDIT_NOTE: 'OWNER-INVOICE'
  };

  return (
    <>
      <PageHeaderCustom
        title={
          customerInvoice &&
          customerInvoice.reference &&
          customerInvoice.status && (
            <Row align="middle" gutter={[16, 16]}>
              <Col>
                <Typography>
                  {t('customerinvoices.show.title', {
                    reference: customerInvoice.reference
                  })}
                </Typography>
              </Col>
              <Col>
                <Tag color={InvoiceStatus[customerInvoice.status]}>
                  {t(`customerinvoices.tags.${customerInvoice.status}`)}
                </Tag>
              </Col>
            </Row>
          )
        }
        extra={
          <>
            {customerInvoice && ['UNPAID'].includes(customerInvoice.status) && (
              <>
                <Button
                  onClick={() => {
                    const type =
                      customerInvoice.type === 'SUBSCRIPTION'
                        ? 'SUBSCRIPTION-INVOICE'
                        : 'OWNER-INVOICE';
                    setIsReminderModalOpen(true);
                    setDocumentType(type);
                    setRecordID(id);
                  }}
                >
                  <BellOutlined />
                  {t('customerinvoices.show.buttons.reminder')}
                </Button>
                <Button onClick={() => setIsPaidInvoiceModalOpen(true)}>
                  <CheckOutlined />
                  {t('customerinvoices.show.buttons.paid')}
                </Button>
              </>
            )}
            {customerInvoice &&
              ['WAITING'].includes(customerInvoice.status) && (
                <>
                  <Button onClick={() => setIsPaidInvoiceModalOpen(true)}>
                    <CheckOutlined />
                    {t('customerinvoices.show.buttons.paid')}
                  </Button>
                  {customerInvoice.type !== 'CREDIT_NOTE' && (
                    <Button
                      onClick={() =>
                        setInvoiceStatusToUnpaid({
                          dispatchAPI,
                          message,
                          id,
                          setRefreshData,
                          refreshData,
                          invoice: customerInvoice
                        })
                      }
                    >
                      <CloseOutlined />
                      {t('customerinvoices.show.buttons.unpaid')}
                    </Button>
                  )}
                </>
              )}

            <>
              <Button
                onClick={() =>
                  generateDocumentsOld(
                    dispatchAPI,
                    message,
                    templateType[customerInvoice.type],
                    customerInvoice._id,
                    null,
                    t
                  )
                }
              >
                <DownloadOutlined />
                {t('customerinvoices.show.buttons.download')}
              </Button>
              {customerInvoice &&
                ['PAID'].includes(customerInvoice.status) &&
                customerInvoice.type !== 'CREDIT_NOTE' &&
                customerInvoice.wordings.some(
                  (wording) => !wording.credit_note
                ) && (
                  <Button
                    onClick={() => {
                      setIsCreditNoteCardVisible(true);
                      scrollToCard();
                    }}
                  >
                    <FileTextOutlined />
                    {t('customerinvoices.show.buttons.credit_note')}
                  </Button>
                )}
            </>

            {customerInvoice &&
              ['UNPAID', 'WAITING'].includes(customerInvoice.status) && (
                <>
                  <Link
                    to={{
                      pathname: `${routes.INVOICES}${subRoutes.INVOICES.CUSTOMERS}/edit/${id}`
                    }}
                  >
                    <Button type="primary">
                      {`${t('buttons.edit')} `}
                      <EditOutlined />
                    </Button>
                  </Link>
                  <Popconfirm
                    title={t('datatable.column.action.archive.title')}
                    okText={t('datatable.column.action.archive.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.archive.cancel')}
                    onConfirm={() =>
                      deleteInvoice({
                        id,
                        dispatchAPI,
                        message,
                        path: 'customerinvoices',
                        navigate
                      })
                    }
                    icon={<WarningOutlined />}
                  >
                    <Button type="link" danger>
                      {`${t('buttons.archive')} `}
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </>
              )}
          </>
        }
      />
      <ContentCustom>
        <DetailsCards
          type="customerinvoices"
          invoice={customerInvoice}
          isLoading={isLoading}
          setIsCreditNoteCardVisible={setIsCreditNoteCardVisible}
          isCreditNoteCardVisible={isCreditNoteCardVisible}
        />
      </ContentCustom>
      {isPaidInvoiceModalOpen && (
        <PaidInvoiceModal
          t={t}
          isPaidInvoiceModalOpen={isPaidInvoiceModalOpen}
          setIsPaidInvoiceModalOpen={setIsPaidInvoiceModalOpen}
          enums={enums}
          id={id}
          dispatchAPI={dispatchAPI}
          message={message}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          resourceName="customerinvoices"
          invoice={customerInvoice}
        />
      )}
      {isReminderModalOpen && (
        <MailingModal
          isModalOpen={isReminderModalOpen}
          setIsModalOpen={setIsReminderModalOpen}
          recipients={customers}
          recipient={customerInvoice?.customer?._id}
          isFieldsLoading={isLoading}
          customFormInstance={form}
          templateType="UNPAID_INVOICE_REMINDER"
          collectionData={customerInvoice}
          collection="customerinvoices"
          documentType={documentType}
          recordID={recordID}
        />
      )}
    </>
  );
};
