import dayjs from 'dayjs';

/**
 * setCustomerInvoicesData function for calculating customer invoices statistics.
 * @param {Object} params - The parameters for the setCustomerInvoicesData function.
 * @param {Object} params.customerInvoices - Object containing customer invoices data.
 * @param {Function} params.setCalculatedCustomerInvoices - Function to set the calculated customer invoices statistics.
 * @returns {void} - This function does not return a value.
 */
export const setCustomerInvoicesData = ({
  customerInvoices,
  setCalculatedCustomerInvoices
}) => {
  /**
   * Object for storing calculated statistics.
   * @type {Object}
   */
  const statistics = {};

  /**
   * Iterate over customer invoices and calculate statistics.
   */
  Object.entries(customerInvoices).forEach(([key, value]) => {
    value.forEach((invoice) => {
      /**
       * Key representing the month in the format 'MMMM'.
       * @type {string}
       */
      const monthKey = dayjs(invoice.payment_date).format('MMMM');

      /**
       * Update statistics object with calculated values.
       */
      statistics[key] = {
        ...statistics[key],
        [monthKey]: (statistics[key]?.[monthKey] || 0) + invoice.total_ttc
      };
    });
  });

  /**
   * Set the calculated statistics using the provided function.
   */
  setCalculatedCustomerInvoices(statistics);
};
