import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';

/**
 * Returns filter configuration for date columns using Ant Design components.
 * @function
 *
 * @param {React.RefObject} searchInput - Reference to the DatePicker component.
 * @param {function} t - Translation function for localization.
 * @returns {object} - Filter configuration object.
 */
export const dateFilterColumns = (searchInput, t) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div>
      <DatePicker
        ref={searchInput}
        value={selectedKeys.length ? dayjs(selectedKeys[0]) : null}
        onChange={(e) => {
          setSelectedKeys(e ? [dayjs(e).format('YYYY-MM-DDTHH:mm:ss')] : []);
          confirm({ closeDropdown: true });
          clearFilters();
        }}
        onPressEnter={() => confirm()}
      />
      <Button onClick={() => confirm()}>{t('buttons.filter')}</Button>
    </div>
  )
});
