import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { PDFDocument } from 'pdf-lib';
import { useErrorMessage } from './errorMessage';
import { useAuthContext } from '../contexts/AuthContext';

export const useDownloadDocument = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();

  /**
   * Downloads a document from the server.
   *
   * @param {Object} document - The document object to be downloaded.
   * @param {string} document._id - The ID of the document.
   * @param {Object} document.metadata - The metadata of the document.
   * @param {string} document.metadata.originalName - The original name of the document.
   * @param {string} document.contentType - The content type of the document.
   * @returns {void}
   */
  const downloadDocument = async ({
    _id,
    metadata: { originalName },
    contentType
  }) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${_id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], { type: contentType });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      if (originalName.includes('.pdf')) {
        a.target = '_blank';
      } else a.download = originalName;
      a.click();
      notification.success({ message: t('export.messages.success') });
    } catch (e) {
      message(e);
    }
  };

  /**
   * @function printDocument
   * @async
   * @description Print a document
   * @param {Object} doc Document to print
   * @returns {void}
   * @example
   * printDocument(doc);
   * @example
   * printDocument({
   * _id: '5f7b1b7b9f6b2e0017b0b3b5',
   * metadata: { originalName: 'test.pdf' },
   * contentType: 'application/pdf'
   * });
   */
  const printDocument = async (doc) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${doc._id}`,
        responseType: 'blob'
      });
      const blob = new Blob([data], {
        type: data.type
      });

      const url = window.URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;

      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  /**
   * Prints multiple documents by merging them into a single PDF and opening a print dialog.
   * @function
   *
   * @param {Array<string>} documentsIds - An array of document IDs.
   * @returns {void}
   */
  const printMultipleDocuments = async (documentsIds) => {
    /**
     * Merges an array of PDF blobs into a single PDF document.
     * @function
     *
     * @param {Blob[]} blobs - An array of PDF blobs to merge.
     * @returns {Promise<Blob>} - A Promise that resolves to the merged PDF blob.
     */
    const mergePDFs = async (blobs) => {
      const mergedPdfDoc = await PDFDocument.create();

      const promises = blobs.map(async (blob) => {
        const pdfBytes = await blob.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);

        const copiedPages = await mergedPdfDoc.copyPages(
          pdfDoc,
          pdfDoc.getPageIndices()
        );

        copiedPages.forEach((page) => {
          mergedPdfDoc.addPage(page);
        });
      });

      await Promise.all(promises);

      const mergedPdfBytes = await mergedPdfDoc.save();

      return new Blob([mergedPdfBytes], { type: 'application/pdf' });
    };
    /**
     * Merges multiple documents into a single Blob object.
     * @function
     *
     * @param {Array<string>} prescriptionsIds - An array of prescription IDs.
     * @returns {Promise<Blob>} - A Promise that resolves to the merged Blob object.
     */
    const mergeDocumentsToBlob = async (prescriptionsIds) => {
      const promises = prescriptionsIds.map(async (id) => {
        const { data } = await dispatchAPI('GET', {
          url: `/files/${id}`,
          responseType: 'blob'
        });
        return data;
      });

      const blobs = await Promise.all(promises);
      const mergedBlobs = await mergePDFs(blobs);
      return mergedBlobs;
    };

    try {
      const mergedBlob = await mergeDocumentsToBlob(documentsIds);
      const url = window.URL.createObjectURL(mergedBlob);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;

      document.body.appendChild(iframe);

      iframe.contentWindow.print();
    } catch (e) {
      message(e);
    }
  };

  return { downloadDocument, printDocument, printMultipleDocuments };
};
