import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col, Typography } from 'antd';

const { Text } = Typography;

const CommentInfoCards = ({ t, isLoading, pensioner }) => (
  <Card title={t('pensioners.show.comment_info')} className="form-card">
    <Row gutter={[0, 24]}>
      <Col xs={24} xxl={24}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <Text>{pensioner.comment || ''}</Text>
        </Skeleton>
      </Col>
    </Row>
  </Card>
);

export default CommentInfoCards;

CommentInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pensioner: PropTypes.shape({
    comment: PropTypes.string
  }).isRequired
};
