import { Card, List, Spin, Badge } from 'antd';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { handleNotifMenuItemLink } from '../../../../../components/Notifications/handleNotificationMenuLink';
import { useDeleteNotification } from '../../../../../utils/deleteNotification';

/**
 * SupervisionAlerts component for displaying supervision alerts.
 * @component
 * @param {Object} props - The properties of the SupervisionAlerts component.
 * @param {Array} props.notifications - An array of notifications.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Function} props.dispatchAPI - Function for dispatching API calls.
 * @param {Object} props.user - User object containing user information.
 * @param {string} props.user._id - User ID.
 * @param {boolean} props.forceRefresh - Boolean flag for forcing a data refresh.
 * @param {Function} props.setForceRefresh - Function to set the forceRefresh state.
 * @param {Function} props.message - Function for displaying messages.
 * @param {boolean} props.loading - Boolean flag indicating if data is loading.
 * @returns {JSX.Element} - JSX element representing the SupervisionAlerts component.
 */
export const SupervisionAlerts = ({
  notifications,
  t,
  dispatchAPI,
  user,
  forceRefresh,
  setForceRefresh,
  message,
  loading
}) => {
  const [supervisionsNotifications, setSupervisionsNotifications] = useState(
    []
  );

  const { deleteNotification } = useDeleteNotification({
    dispatchAPI,
    forceRefresh,
    setForceRefresh,
    message
  });

  useEffect(() => {
    setSupervisionsNotifications(
      notifications.filter(
        (notification) =>
          notification.item.ref === 'Supervision' &&
          notification.item.type !== null
      )
    );
  }, [notifications]);

  const filteredNotif = supervisionsNotifications.map(
    (notif) => notif.consulted.filter((id) => id === user._id).length
  );
  const numberOfNotifNotConsulted = filteredNotif.filter(
    (item) => item === 0
  ).length;

  return (
    <Card
      title={t('notifications.supervisions_alerts')}
      extra={<Badge count={numberOfNotifNotConsulted} />}
      className="dashboard-card"
    >
      <Spin spinning={loading}>
        <List
          dataSource={supervisionsNotifications}
          renderItem={(item) =>
            handleNotifMenuItemLink(
              item._id,
              item.item,
              deleteNotification,
              t,
              item.created_at,
              item.consulted
            )
          }
          className="alert-list"
        />
      </Spin>
    </Card>
  );
};

SupervisionAlerts.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string
  }).isRequired
};
