import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Collapse, Row, Col, Card } from 'antd';
import ListRatings from '../ListRatings';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const RatingInfoCards = ({
  t,
  owner,
  setReviewActiveKey,
  reviewActiveKey,
  setReviewDrawerOpen,
  questionnaireType,
  form,
  setSupervisionRating,
  setShowReviewDrawer,
  setRefreshData,
  refreshData,
  setEditReviewDrawer,
  setReviewData,
  reviewData
}) => (
  <Collapse accordion className="ratings-collapse">
    <Panel header={t('ratings.header_panel')} key="1">
      <Row gutter={[12, 12]}>
        <Col xs={24} xxl={24}>
          <Card
            bordered={false}
            title={
              <Tabs
                defaultActiveKey={reviewActiveKey}
                onChange={(key) => setReviewActiveKey(key)}
              >
                <TabPane
                  tab={t('ratings.tabs.received_reviews')}
                  key="received_reviews"
                />
                <TabPane
                  tab={t('ratings.tabs.left_reviews')}
                  key="left_reviews"
                />
              </Tabs>
            }
            className="card-with-tabs"
          >
            <ListRatings
              reviewActiveKey={reviewActiveKey}
              owner={owner}
              setReviewDrawerOpen={setReviewDrawerOpen}
              questionnaireType={questionnaireType}
              form={form}
              setSupervisionRating={setSupervisionRating}
              setShowReviewDrawer={setShowReviewDrawer}
              setRefreshData={setRefreshData}
              refreshData={refreshData}
              setEditReviewDrawer={setEditReviewDrawer}
              setReviewData={setReviewData}
              reviewData={reviewData}
            />
          </Card>
        </Col>
      </Row>
    </Panel>
  </Collapse>
);

export default RatingInfoCards;

RatingInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  reviewActiveKey: PropTypes.string.isRequired,
  setReviewActiveKey: PropTypes.func.isRequired,
  owner: PropTypes.shape({
    animals: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  setReviewDrawerOpen: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func
  }).isRequired,
  setSupervisionRating: PropTypes.func.isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setEditReviewDrawer: PropTypes.func.isRequired,
  setReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({})
};

RatingInfoCards.defaultProps = {
  reviewData: {}
};
