import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';

export const useConfig = (
  animalsFileList,
  setAnimalsFileList,
  dispatchAPI,
  message,
  dataLoadedCallback
) => ({
  onCreateResource: {
    setBody: (data) => {
      const formData = new FormData();
      if (Object.keys(animalsFileList).length) {
        const animalPhotoMetadata = {};

        animalsFileList.forEach((photo, index) => {
          formData.append('animal_photo', photo.file);
          animalPhotoMetadata[photo.file.name] = {
            index,
            originalName: photo.file.name
          };
        });
        formData.append(
          'animal_photo_metadata',
          JSON.stringify(animalPhotoMetadata)
        );
      }

      formData.append('values', JSON.stringify(data));

      return formData;
    }
  },
  onUpdateResource: {
    setBody: (data) => {
      const formData = new FormData();

      if (animalsFileList.length) {
        const animalPhotoMetadata = {};

        animalsFileList.forEach((photo, index) => {
          formData.append('animal_photo', photo.file);
          animalPhotoMetadata[photo.file.name] = {
            index,
            originalName: photo.file.name
          };
        });
        formData.append(
          'animal_photo_metadata',
          JSON.stringify(animalPhotoMetadata)
        );
      }

      formData.append('values', JSON.stringify(data));

      return formData;
    }
  },
  onGetResource: {
    setFields: async (data) => {
      if (data?.animal_photo.length) {
        try {
          const animalPhotosPromises = (data.animal_photo || []).map((photo) =>
            createFileFromJSON(photo, dispatchAPI, message)
          );

          const results = await Promise.all(animalPhotosPromises);
          setAnimalsFileList(results);
        } catch (error) {
          message(message(error));
        }
      }

      dataLoadedCallback();
      return { animals: data };
    }
  }
});
