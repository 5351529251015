import { useTranslation } from 'react-i18next';
import { Typography, Modal, Button, Switch, Space, Divider } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useCallsAPI } from '../utils/callAPI';

const { Title, Text } = Typography;
const { confirm } = Modal;

export const ShowSettings = () => {
  const [profileID, setProfileID] = useState('');

  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { deleteProfile, getProfile } = useCallsAPI({
    setFunction: (data) => setProfileID(data[0]._id),
    id: profileID
  });

  useEffect(async () => {
    await getProfile();
  }, []);

  const showDeleteConfirm = () => {
    confirm({
      title: t('settings.messages.delete_account'),
      icon: <ExclamationCircleFilled />,
      okText: t('settings.messages.yes'),
      okType: 'danger',
      cancelText: t('settings.messages.no'),
      onOk() {
        deleteProfile();
      }
    });
  };

  return (
    <>
      <PageHeaderCustom title={t('settings.title')} />
      <ContentCustom>
        {user.role === 'users:PENSIONER' && (
          <>
            <Title level={3}>{t('settings.manage_notifs')}</Title>
            <Space>
              <Text>{t('settings.newlestter')}</Text>
              <Switch defaultChecked />
            </Space>
            <Divider />
          </>
        )}
        <Title level={3}>{t('settings.manage_profile')}</Title>
        <Button type="link" onClick={showDeleteConfirm}>
          {t('settings.delete_account')}
        </Button>
      </ContentCustom>
    </>
  );
};
