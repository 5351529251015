import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';

export const onFinishForm = async ({
  values,
  purpose,
  generalConditions,
  dispatchAPI,
  generalConditionToEdit,
  setVisible,
  setRefreshData,
  refreshData,
  message
}) => {
  let isPeriodTaken = false;
  try {
    if (purpose === 'create') {
      generalConditions.forEach((el) => {
        if (
          dayjs(values.application_date).startOf('day') <=
            dayjs(el.application_date) &&
          dayjs(values.end_date).endOf('day') >= dayjs(el.end_date)
        ) {
          if (isPeriodTaken) return;
          antdMessage.warning('La période est déjà prise en compte');
          isPeriodTaken = true;
        }
      });

      if (isPeriodTaken) {
        return;
      }

      await dispatchAPI('POST', { url: '/general-conditions', body: values });
    } else {
      generalConditions
        .filter((el) => el._id !== generalConditionToEdit._id)
        .forEach((el) => {
          if (
            dayjs(values.application_date).startOf('day') <=
              dayjs(el.application_date) &&
            dayjs(values.end_date).endOf('day') >= dayjs(el.end_date)
          ) {
            if (isPeriodTaken) return;
            antdMessage.warning('La période est déjà prise en compte');
            isPeriodTaken = true;
          }
        });

      if (isPeriodTaken) {
        return;
      }

      await dispatchAPI('PATCH', {
        url: `/general-conditions/${generalConditionToEdit._id}`,
        body: values
      });
    }
    setVisible(false);
    setRefreshData(!refreshData);
  } catch (e) {
    message(e);
  }
};
