import { Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SupervisionsPieChart } from './cards/SupervisionsPieChart';
import { MonthlySupervisionsChart } from './cards/MonthlySupervisionsChart';

/**
 * Component to display supervisions charts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.supervisionsStatus - Array of supervisions status data.
 * @param {Array} props.supervisions - Array of supervisions data.
 * @param {function} props.setStatusDate - Function to set status date.
 * @param {function} props.setSupDate - Function to set supervisions date.
 *
 * @returns {React.ReactNode} - The rendered component.
 */
export const SupervisionsCharts = ({
  supervisions,
  supervisionsStatus,
  setStatusDate,
  setSupDate
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[32, 24]}>
      <SupervisionsPieChart
        t={t}
        supervisionsStatus={supervisionsStatus}
        setStatusDate={setStatusDate}
      />
      <MonthlySupervisionsChart
        t={t}
        supervisions={supervisions}
        setSupDate={setSupDate}
      />
    </Row>
  );
};

SupervisionsCharts.propTypes = {
  supervisionsStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supervisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setStatusDate: PropTypes.func.isRequired,
  setSupDate: PropTypes.func.isRequired
};
