import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Drawer, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { onFinishForm } from '../utils/onFinishForm';

export const CreateUpdateDrawer = ({
  visible,
  setVisible,
  setRefreshData,
  refreshData,
  subscriptionPrices,
  purpose,
  subscriptionPriceToEdit
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const { fields } = useFields({
    subscriptionPrices,
    purpose,
    subscriptionPriceToEdit
  });

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue({
        ...subscriptionPriceToEdit,
        application_date: dayjs(subscriptionPriceToEdit.application_date),
        end_date: dayjs(subscriptionPriceToEdit.end_date)
      });
    }
  }, [purpose]);

  return (
    <Drawer
      destroyOnClose
      title={t(`subscription-prices.form.title.drawer.${purpose}`)}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={(values) =>
          onFinishForm({
            values,
            purpose,
            subscriptionPrices,
            dispatchAPI,
            subscriptionPriceToEdit,
            setVisible,
            setRefreshData,
            refreshData,
            message
          })
        }
      >
        {fields.map((field) => generateFormItem('subscription-prices', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={onClose}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

CreateUpdateDrawer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool,
  supervisionPrice: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  subscriptionPriceToEdit: PropTypes.shape({
    application_date: PropTypes.string,
    end_date: PropTypes.string,
    _id: PropTypes.string
  }),
  subscriptionPrices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

CreateUpdateDrawer.defaultProps = {
  visible: false,
  setVisible: null,
  setRefreshData: null,
  refreshData: false,
  subscriptionPriceToEdit: null
};
