import moment from 'moment';

export const supervisionInfos = (supervision) => {
  const { summary, main_informations, payment_amount, franc } =
    supervision || {};
  const { start_date, end_date } = main_informations || {};
  const { price_category } = summary || {};
  const { duration, total_ttc, advance_payment } = price_category || {};

  const setPriceCategory = (element) => {
    if (element?.duration?.extra_week) return element?.duration?.extra_week;

    if (element?.duration)
      return `${element?.duration?.start_day} à ${element?.duration?.end_day} jours`;

    return '-';
  };

  return [
    {
      label: 'supervisions.show.start_date',
      span: 1,
      content: (start_date && moment(start_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'supervisions.show.end_date',
      span: 1,
      content: (end_date && moment(end_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'supervisions.show.price_category',
      span: 1,
      content: setPriceCategory(duration)
    },
    {
      label: 'supervisions.show.rates.total_ttc',
      span: 1,
      content: (total_ttc && `${total_ttc} ${franc ? 'Fr' : '€'}`) || '-'
    },
    {
      label: 'supervisions.show.rates.advance_payment',
      span: 1,
      content:
        (advance_payment && `${advance_payment} ${franc ? 'Fr' : '€'}`) || '-'
    },
    {
      label: 'supervisions.show.rates.payment_amount',
      span: 1,
      content:
        (payment_amount && `${payment_amount} ${franc ? 'Fr' : '€'}`) || '-'
    }
  ];
};

export const urgencyInfos = (supervision) => {
  const { summary } = supervision || {};
  const { urgency } = summary || {};
  const { last_name, first_name, address, phone_number } = urgency || {};

  return [
    {
      label: 'supervisions.show.urgency.contact_person',
      span: 1.5,
      content: (last_name && first_name && `${last_name} ${first_name}`) || '-'
    },
    {
      label: 'supervisions.show.urgency.address',
      span: 1.5,
      content: (address && address) || '-'
    },
    {
      label: 'supervisions.show.urgency.phone_number',
      span: 1,
      content: (phone_number && phone_number.number) || '-'
    }
  ];
};
