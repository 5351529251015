import { Form, Input, Select, Tag, Upload, Checkbox, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons/lib';
import { useTranslation } from 'react-i18next';
import { useCountryArray } from '../../../utils/countryArray';
import { useCountryCodeInfos } from '../../owners/form/utils/useCountryCodeInfos';

const { Option } = Select;
const { Dragger } = Upload;

/**
 * Additional Informations Fields
 * @hook
 *
 * @typedef {Object} AdditionalInformationsField
 * @property {string} label - The label of the field.
 * @property {string[]} name - The name of the field.
 * @property {Object} [input] - The input component for the field.
 * @property {Object[]} [rules] - The validation rules for the field.
 * @property {string} [initialValue] - The initial value for the field.
 * @property {string} [valuePropName] - The name of the prop that holds the value of the field.
 *
 * @typedef {Object} AdditionalInformationsFields
 * @property {AdditionalInformationsField[]} additionalInformationsFields - The list of additional information fields.
 *
 * @typedef {Object} AdditionalInformationsProps
 * @property {Function} setFilesList - The function to set the files list.
 * @property {Object} filesList - The list of files.
 * @property {boolean} isFieldsLoading - Indicates if the fields are loading.
 * @property {Object} form - The form object.
 * @property {Function} setInternetAccess - The function to set the internet access.
 * @property {boolean} internetAccess - Indicates if internet access is enabled.
 * @property {Function} setRemovedFiles - The function to set the removed files.
 * @property {string} country - The selected country.
 * @property {Function} setCountry - The function to set the selected country.
 *
 * @param {AdditionalInformationsProps} props - The component props.
 * @returns {AdditionalInformationsFields} The additional information fields.
 */
export const additionalInformations = ({
  setFilesList,
  filesList,
  isFieldsLoading,
  form,
  setInternetAccess,
  internetAccess,
  setRemovedFiles,
  country,
  setCountry
}) => {
  const { t } = useTranslation();

  const countries = useCountryArray();
  const { handleCountryCodeInfos } = useCountryCodeInfos(country);

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const draggerProps = (name) => ({
    multiple: name === 'other_documents',
    onRemove: (file) => {
      if (name === 'other_documents') {
        const updatedFileList = { ...filesList };
        updatedFileList[name] = updatedFileList[name].filter(
          (item) => item.uid !== file.uid
        );
        setFilesList(updatedFileList);
        setRemovedFiles((prevRemovedFiles) => [...prevRemovedFiles, file.id]);
      } else {
        const updatedFileList = { ...filesList };
        setRemovedFiles((prevRemovedFiles) => [
          ...prevRemovedFiles,
          updatedFileList[name][0].id
        ]);
        delete updatedFileList[name];
        setFilesList(updatedFileList);
      }
    },
    beforeUpload: (file) => {
      if (name === 'other_documents') {
        setFilesList((prevFileList) => ({
          ...prevFileList,
          [name]: [...(prevFileList[name] || []), file]
        }));
      } else {
        setFilesList((prevFileList) => ({
          ...prevFileList,
          [name]: [file]
        }));
      }

      return false;
    },
    fileList: filesList[name]
  });

  const getRadioGroup = () => (
    <Radio.Group
      options={[
        { label: t('pensioners.form.yes'), value: true },
        { label: t('pensioners.form.no'), value: false }
      ]}
    />
  );

  const additionalInformationsFields = [
    {
      label: 'address.number',
      name: ['address', 'number'],
      rules: [{ required: true }]
    },
    {
      label: 'address.street',
      name: ['address', 'street'],
      rules: [{ required: true }]
    },
    {
      label: 'address.additional',
      name: ['address', 'additional']
    },
    {
      label: 'address.country',
      name: ['address', 'country'],
      input: (
        <Select
          showSearch
          filterOption={filterOption}
          isFieldsLoading={isFieldsLoading}
        >
          {(countries || []).map((value) => (
            <Option key={value} value={value}>
              <Tag>{value}</Tag>
            </Option>
          ))}
        </Select>
      ),
      rules: [
        { required: true },
        ({ getFieldValue }) => {
          setCountry(getFieldValue(['address', 'country']));
        }
      ]
    },
    {
      label: 'address.postal_code',
      name: ['address', 'postal_code'],
      input: (
        <Input
          onChange={(e) =>
            handleCountryCodeInfos(e.target.value).then((values) => {
              form.setFieldValue(['address', 'city'], values?.city);
              form.setFieldValue(['address', 'state'], values?.state);
            })
          }
        />
      ),
      rules: [{ required: true }]
    },
    {
      label: 'address.state',
      name: ['address', 'state'],
      rules: [{ required: true }]
    },
    {
      label: 'address.city',
      name: ['address', 'city'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number'],
      label: 'phone_number.number',
      input: (
        <Input.Group compact>
          <Form.Item
            noStyle
            name={['phone_number', 'country_code']}
            initialValue="+33"
          >
            <Select style={{ width: '30%' }}>
              <Option value="+33">+33</Option>
            </Select>
          </Form.Item>
          <Form.Item noStyle name={['phone_number', 'number']}>
            <Input type="number" style={{ width: '70%' }} />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['internet_access'],
      initialValue: internetAccess,
      input: (
        <Checkbox
          defaultChecked={internetAccess}
          onChange={() => setInternetAccess(!internetAccess)}
        />
      ),
      valuePropName: 'checked'
    },
    ...(internetAccess
      ? [
          {
            name: ['email'],
            rules: [{ required: true }, { type: 'email' }]
          }
        ]
      : []),
    {
      name: ['is_animals'],
      input: getRadioGroup()
    },
    {
      name: ['moving_with_animal'],
      input: getRadioGroup()
    },
    {
      name: ['is_smoker'],
      input: getRadioGroup()
    },
    {
      name: ['is_newsletters'],
      input: getRadioGroup()
    },
    {
      label: 'early_retirement_certificate',
      input: (
        <Dragger {...draggerProps('early_retirement_certificate')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'insurance_certificate',
      input: (
        <Dragger {...draggerProps('insurance_certificate')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'residence_proof',
      input: (
        <Dragger {...draggerProps('residence_proof')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      label: 'other_documents',
      input: (
        <Dragger {...draggerProps('other_documents')}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { additionalInformationsFields };
};
