/**
 * Adds supervision address data to the state.
 * @function
 * @async
 *
 * @param {Object} options - The options object.
 * @param {Object} options.owner - The owner object.
 * @param {Function} options.setSupervisionAddressData - The function to set the supervision address data.
 * @param {Function} options.setPetsittingAddressFileList - The function to set the petsitting address file list.
 * @param {Function} options.message - The function to display a message.
 * @param {Function} options.dispatchAPI - The function to dispatch an API request.
 * @param {Function} options.setTypeOfDwelling - The function to set the type of dwelling.
 * @returns {Promise<void>} - A promise that resolves when the supervision address data is added.
 */
export const addSupervisionAddressData = ({
  owner,
  setSupervisionAddressData,
  setPetsittingAddressFileList,
  setTypeOfDwelling
}) => {
  if (owner?.petsitting_address) {
    setSupervisionAddressData({
      petsitting_address: owner.petsitting_address
    });

    setTypeOfDwelling(owner.petsitting_address.type_of_dwelling);

    if (owner?.petsitting_address?.dwelling_photos?.length) {
      setPetsittingAddressFileList(owner.petsitting_address.dwelling_photos);
    }
  }
};
