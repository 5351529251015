import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Tag, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { WFLStatus } from '../../utils/constants/tagColors';
import { ListResource } from '../../components';

const ListWorkflow = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const headers = [
    {
      label: 'title',
      key: 'title'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'description',
      key: 'description'
    },
    {
      label: 'created_at',
      key: 'created_at'
    },
    {
      label: 'updated_at',
      key: 'updated_at'
    },
    {
      label: 'last_execution',
      key: 'last_execution'
    }
  ];

  const columns = [
    {
      title: t('workflows.form.last_execution'),
      key: 'last_execution',
      dataIndex: 'last_execution',
      render: (last_execution) => {
        if (last_execution) {
          if (last_execution.status === 'SUCCESS') {
            return (
              <Tooltip
                title={`Heure début : ${moment(
                  last_execution.startTimestamp
                ).format('DD-MM-YYYY à HH:mm:ss')}\n 
                Heure fin : ${moment(last_execution.endTimestamp).format(
                  'DD-MM-YYYY à HH:mm:ss'
                )}
                Résultat : ${JSON.stringify(last_execution.result)}`}
              >
                <span style={{ color: 'green' }}>
                  &#x2705; {moment(last_execution.endTimestamp).fromNow()}
                </span>
              </Tooltip>
            );
          }
          return (
            <Tooltip
              title={`Heure début : ${moment(
                last_execution.startTimestamp
              ).format('DD-MM-YYYY à HH:mm:ss')} \n Heure fin : ${moment(
                last_execution.endTimestamp
              ).format('DD-MM-YYYY à HH:mm:ss')} \n Erreur : ${JSON.stringify(
                last_execution.error
              )}`}
            >
              <span style={{ color: 'red' }}>
                &#x274C; {moment(last_execution.endTimestamp).fromNow()}
              </span>
            </Tooltip>
          );
        }
        return t('workflows.form.no_execution');
      }
    },
    {
      title: t('workflows.form.title'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('workflows.form.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => <Tag color={WFLStatus[status]}>{t(status)}</Tag>,
      sorter: true
    },
    {
      title: t('workflows.form.description'),
      key: 'description',
      dataIndex: 'description',
      sorter: true
    },
    {
      title: t('workflows.form.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        `le ${moment(created_at).format('DD-MM-YYYY à HH:mm')}`,
      sorter: true
    },
    {
      title: t('workflows.form.updated_at'),
      key: 'updated_at',
      dataIndex: 'updated_at',
      render: (updated_at) =>
        `le ${moment(updated_at).format('DD-MM-YYYY à HH:mm')}`,
      sorter: true
    }
  ];

  return (
    <ListResource
      resourceName="workflows"
      // withSubRoutes
      extraButtons={
        <Button
          type="primary"
          style={{ marginRight: 16 }}
          onClick={() => {
            navigate('/workflows/executions');
          }}
        >
          {t('workflows.list-executions')}
        </Button>
      } // Button to go to the workflow execution list
      columns={columns}
      headers={headers}
      resourceModelName="Workflow"
      onDoubleClickAction={{
        action: (record) => {
          navigate(`/workflows/edit/${record._id}`);
        }
      }}
      showAction={false}
      duplicateAction
      backIcon={false}
    />
  );
};

export default ListWorkflow;
