import { useEffect, useState } from 'react';
import { ListResource } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { useColumns } from './columns';
import { ExtraFilters } from './ExtraFilters';
import { useFormatter } from '../../utils/formatter';
import { headers } from './headers';

/**
 * Renders a list of pensioners with filter options.
 * @component
 *
 * @returns {JSX.Element} The rendered ListPensioners component.
 */
export const ListPensioners = () => {
  const [cityOptions, setCityOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [cityFilter, setCityFilter] = useState();
  const [departmentFilter, setDepartmentFilter] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { formattedData } = useFormatter();

  const { columns } = useColumns(setForceRefresh);
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const extraQuery = `${cityFilter ? `address.city=${cityFilter}` : ''}${
    departmentFilter
      ? `${cityFilter ? `&` : ''}address.state=${departmentFilter}`
      : ''
  }`;

  const fetchOptions = async () => {
    try {
      setIsFieldsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `pensioners?fields=address`
      });

      const uniqueStates = Array.from(
        new Set(data.map((item) => item?.address?.state).filter(Boolean))
      );
      const uniqueCities = Array.from(
        new Set(data.map((item) => item?.address?.city).filter(Boolean))
      );

      setDepartmentOptions(uniqueStates);
      setCityOptions(uniqueCities);
      setIsFieldsLoading(false);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchOptions();
    })();
  }, []);

  useEffect(() => {
    if (forceRefresh) {
      setForceRefresh(false);
    }
  }, [forceRefresh]);

  return (
    <ListResource
      resourceName="pensioners"
      columns={columns}
      headers={headers}
      resourceModelName="Pensioner"
      withArchiveButton
      extraQuery={extraQuery}
      customActionColumn
      forceRefresh={forceRefresh}
      formatter={formattedData}
      withoutImportButton
      scroll={{ x: 1400 }}
      extraFilters={
        <ExtraFilters
          isFieldsLoading={isFieldsLoading}
          cityOptions={cityOptions}
          departmentOptions={departmentOptions}
          setCityFilter={setCityFilter}
          setDepartmentFilter={setDepartmentFilter}
        />
      }
      backIcon={false}
    />
  );
};
