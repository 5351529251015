import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import OwnerPersonalInfoCards from './cards/OwnerPersonalInfoCards';
import PetsittingAddressInfoCards from './cards/PetsittingAddressInfoCards';
import AnimalInfoCards from './cards/AnimalInfoCards';
import RatingInfoCards from './cards/RatingInfoCards';
import HistoryInfoCards from './cards/HistoryInfoCards';
import SupervisionAndInvoiceInfoCards from './cards/SupervisionAndInvoiceInfoCards';
import { VeterinarianGroomerCard } from './cards/VeterinarianGroomerCard';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { getVeterinarian } from '../utils/getVeterinarian';

/**
 * The `DetailsCards` component renders a collection of info cards presenting detailed
 * information about a pet owner including personal details, pet sitting address, animals,
 * ratings, history, and supervision & invoice details. The information is displayed
 * within respective child components and arranged in a grid layout.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.owner - The owner object which contains details about the pet owner including petsitting address and animals.
 * @param {boolean} props.isLoading - A boolean indicating the loading state of the component.
 *
 * @example
 * <DetailsCards owner={ownerData} isLoading={false} />
 *
 * @returns {React.Element} The React component rendered as a collection of detail cards.
 */
const DetailsCards = ({
  owner,
  isLoading,
  setReviewDrawerOpen,
  setQuestionnaireType,
  questionnaireType,
  form,
  setSupervisionRating,
  setShowReviewDrawer,
  setRefreshData,
  refreshData,
  setReviewData,
  reviewData,
  setEditReviewDrawer
}) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, token } = useAuthContext();

  const [reviewActiveKey, setReviewActiveKey] = useState('received_reviews');
  const [veterinarian, setVeterinarian] = useState();
  const [groomer, setGroomer] = useState();

  const getColWidth = (count) => {
    if (count === 1) {
      return 24;
    }
    return 12;
  };

  useEffect(async () => {
    if (owner._id)
      await getVeterinarian({
        dispatchAPI,
        owner,
        setVeterinarian,
        setGroomer,
        message
      });
  }, [owner]);

  useEffect(() => {
    if (reviewActiveKey === 'received_reviews') {
      setQuestionnaireType('users:PENSIONER');
    }
    if (reviewActiveKey === 'left_reviews') {
      setQuestionnaireType('users:OWNER');
    }
  }, [reviewActiveKey]);

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24}>
        <OwnerPersonalInfoCards
          t={t}
          isLoading={isLoading}
          owner={owner}
          token={token}
        />
      </Col>
      {owner?.petsitting_address && (
        <Col xs={24}>
          <PetsittingAddressInfoCards
            t={t}
            isLoading={isLoading}
            owner={owner}
          />
        </Col>
      )}
      {owner?.animals?.length > 0 && (
        <Col xs={24} xxl={24} style={{ height: 420, overflow: 'auto' }}>
          <AnimalInfoCards
            t={t}
            isLoading={isLoading}
            owner={owner}
            getColWidth={getColWidth}
          />
        </Col>
      )}
      {(veterinarian || groomer) && (
        <Col xs={24} xxl={24}>
          <VeterinarianGroomerCard
            veterinarian={veterinarian}
            groomer={groomer}
          />
        </Col>
      )}
      <Col xs={24} xxl={24}>
        <RatingInfoCards
          t={t}
          owner={owner}
          setReviewActiveKey={setReviewActiveKey}
          reviewActiveKey={reviewActiveKey}
          setReviewDrawerOpen={setReviewDrawerOpen}
          questionnaireType={questionnaireType}
          form={form}
          setSupervisionRating={setSupervisionRating}
          setShowReviewDrawer={setShowReviewDrawer}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setReviewData={setReviewData}
          reviewData={reviewData}
          setEditReviewDrawer={setEditReviewDrawer}
        />
      </Col>
      <Col xs={24} lg={8}>
        <HistoryInfoCards t={t} owner={owner} />
      </Col>
      <Col xs={24} lg={16}>
        {Object.keys(owner).length ? (
          <SupervisionAndInvoiceInfoCards t={t} owner={owner} />
        ) : null}
      </Col>
    </Row>
  );
};

export default DetailsCards;

DetailsCards.propTypes = {
  owner: PropTypes.shape({
    _id: PropTypes.string,
    petsitting_address: PropTypes.shape({}),
    animals: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setReviewDrawerOpen: PropTypes.func.isRequired,
  setQuestionnaireType: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  form: PropTypes.shape({}).isRequired,
  setSupervisionRating: PropTypes.func.isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({}),
  setEditReviewDrawer: PropTypes.func.isRequired
};

DetailsCards.defaultProps = {
  reviewData: {}
};
