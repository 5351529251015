import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components';
import { useVeterinariansFields } from './fields/veterinariansFields';
import { useCustomSubmit } from './utils/customSubmit';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';
import { editingLocked } from '../../../utils/editingLocked';

export const CreateUpdateVeterinarian = ({ purpose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { profileLock } = useSocketContext();
  const { id, vetId } = useParams();

  const { veterinarianFields } = useVeterinariansFields();

  const { customSubmit } = useCustomSubmit({
    baseUrl: 'veterinarians',
    purpose
  });

  return (
    <>
      {editingLocked(profileLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'veterinarian_groomer',
            text: 'profile'
          }}
          navigate={navigate}
        />
      )}
      <CreateUpdateContainer
        purpose={purpose}
        fields={veterinarianFields}
        resource="veterinarians"
        baseUrl="veterinarians"
        customSubmit={customSubmit}
        customId={vetId}
        disabled={editingLocked(profileLock, id)}
      />
    </>
  );
};

CreateUpdateVeterinarian.propTypes = {
  purpose: PropTypes.string.isRequired
};
