import PropTypes from 'prop-types';
import { Button, Modal, Row } from 'antd';
import { useState } from 'react';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { cancelReservation } from '../../../../reservation/utils/cancelReservation';

/**
 * Modal component for canceling a pensioner reservation.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isCancelModalOpen - Flag indicating whether the cancel modal is open.
 * @param {function} props.setIsCancelModalOpen - Function to set the flag indicating whether the cancel modal is open.
 * @param {function} props.t - Translation function for internationalization.
 * @param {string} props.pensionerId - The ID of the pensioner.
 * @param {string} props.supervisionId - The ID of the supervision.
 * @param {string} props.reservationId - The ID of the reservation.
 * @param {function} props.setRefreshData - Function to set the flag indicating whether the data should be refreshed.
 * @param {boolean} props.refreshData - Flag indicating whether the data should be refreshed.
 * @param {number} props.pensionerCount - The count of pensioners.
 * @returns {JSX.Element} The cancel pensioner modal component.
 */
export const CancelPensionerModal = ({
  isCancelModalOpen,
  setIsCancelModalOpen,
  t,
  pensionerId,
  supervision,
  reservationId,
  setRefreshData,
  refreshData,
  pensionerCount
}) => {
  const { dispatchAPI, user: { _id } = {} } = useAuthContext();
  const { message } = useErrorMessage();
  const [isSubmiting, setIsSubmiting] = useState(false);

  return (
    <Modal
      title={t('supervisions.show.modal.title.edit_supervision')}
      open={isCancelModalOpen}
      onOk={() => setIsCancelModalOpen(false)}
      onCancel={() => setIsCancelModalOpen(false)}
      footer={
        <Row>
          <Button onClick={() => setIsCancelModalOpen(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button
            loading={isSubmiting}
            onClick={() =>
              cancelReservation({
                pensionerId,
                supervision,
                reservationId,
                dispatchAPI,
                message,
                refreshData,
                setRefreshData,
                setIsCancelModalOpen,
                setIsSubmiting,
                _id
              })
            }
          >
            {t('buttons.confirm')}
          </Button>
        </Row>
      }
    >
      {pensionerCount === 1 ? (
        <div>{t('supervisions.show.modal.content.anounce')}</div>
      ) : (
        <div>{t('supervisions.show.modal.content.cancel_reservation')}</div>
      )}
    </Modal>
  );
};

CancelPensionerModal.propTypes = {
  isCancelModalOpen: PropTypes.bool,
  setIsCancelModalOpen: PropTypes.func,
  t: PropTypes.func.isRequired,
  supervision: PropTypes.shape({
    reference: PropTypes.string,
    main_informations: PropTypes.shape({
      start_date: PropTypes.string,
      end_date: PropTypes.string
    }),
    address: PropTypes.shape({
      petsitting_address: PropTypes.shape({
        address: PropTypes.shape({
          state: PropTypes.string
        })
      })
    })
  }).isRequired,
  anounce: PropTypes.shape({
    description: PropTypes.string
  }).isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  pensionerId: PropTypes.string.isRequired,
  reservationId: PropTypes.string.isRequired,
  pensionerCount: PropTypes.number.isRequired
};

CancelPensionerModal.defaultProps = {
  isCancelModalOpen: false,
  setIsCancelModalOpen: null
};
