import dayjs from 'dayjs';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';

/**
 * Custom hook for making API calls related to various data sets.
 * @param {Object} params - Parameters for the hook.
 * @param {Function} params.setNotifications - Function to set notifications data.
 * @param {Function} params.setSupervisions - Function to set supervisions data.
 * @param {Function} params.setCustomerInvoices - Function to set customer invoices data.
 * @param {Function} params.setInternInvoices - Function to set intern invoices data.
 * @param {Function} params.setRegistrations - Function to set registrations data.
 * @param {Function} params.setLoading - Function to set loading state.
 * @param {string} params.statusDate - Date for filtering supervisions status data.
 * @param {Function} params.setSupervisionsStatus - Function to set supervisions status data.
 * @param {string} params.supDate - Date for filtering supervisions data.
 * @param {string} params.financeDate - Date for filtering customer invoices data.
 * @param {string} params.statisticsDate - Date for filtering statistics data.
 * @param {Function} params.setSubscriptions - Function to set subscriptions data.
 * @returns {Object} - Object containing functions for making API calls.
 */
export const useCallsAPI = ({
  setNotifications,
  setSupervisions,
  setCustomerInvoices,
  setInternInvoices,
  setRegistrations,
  setLoading,
  statusDate,
  setSupervisionsStatus,
  supDate,
  financeDate,
  statisticsDate,
  setSubscriptions
}) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();

  const getNotifications = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `notifications?recipients=${user?._id}&populate=item.type`
      });
      setNotifications(data);
      setLoading(false);
    } catch (e) {
      message(e);
    }
  };

  const getSupervisionsStatus = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `supervisions/statistics/status${
          statusDate
            ? `?created_at>=${dayjs(statusDate).format(
                'YYYY/MM/DD'
              )}&created_at<=${dayjs(statusDate)
                .endOf('day')
                .format('YYYY/MM/DD HH:mm:ss')}`
            : ''
        }`
      });
      setSupervisionsStatus(data.data);
    } catch (e) {
      message(e);
    }
  };

  const getSupervisionsData = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `supervisions/statistics/supervisions${
          supDate
            ? `?created_at>=${dayjs(supDate)
                .startOf('year')
                .format('YYYY/MM/DD')}&created_at<=${dayjs(supDate)
                .endOf('year')
                .format('YYYY/MM/DD HH:mm:ss')}`
            : ''
        }`
      });
      setSupervisions(data.data);
    } catch (e) {
      message(e);
    }
  };

  const getCustomerInvoicesData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `customerinvoices/statistics/invoices${
          financeDate
            ? `?payment_date>=${dayjs(financeDate)
                .startOf('year')
                .format('YYYY/MM/DD')}&payment_date<=${dayjs(financeDate)
                .endOf('year')
                .format('YYYY/MM/DD HH:mm:ss')}`
            : ''
        }`
      });
      setCustomerInvoices(data);
    } catch (e) {
      message(e);
    }
  };

  const getInternInvoicesData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `interninvoices/statistics${
          statisticsDate
            ? `?payment_date>=${dayjs(financeDate)
                .subtract(1, 'year')
                .startOf('year')
                .format('YYYY/MM/DD')}&payment_date<=${dayjs(financeDate)
                .endOf('year')
                .format('YYYY/MM/DD HH:mm:ss')}&finance_date=${dayjs(
                financeDate
              ).format('YYYY/MM/DD')}`
            : ''
        }`
      });
      setInternInvoices(data);
    } catch (e) {
      message(e);
    }
  };

  const getSubscriptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `customerinvoices/statistics/subscriptions${
          statisticsDate
            ? `?payment_date>=${dayjs(statisticsDate)
                .subtract(1, 'year')
                .startOf('year')
                .format('YYYY/MM/DD')}&payment_date<=${dayjs(statisticsDate)
                .endOf('year')
                .format('YYYY/MM/DD HH:mm:ss')}`
            : ''
        }`
      });
      setSubscriptions(data);
    } catch (e) {
      message(e);
    }
  };

  const getRegistrationsData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `users/statistics${
          statisticsDate
            ? `?created_at>=${dayjs(statisticsDate)
                .startOf('year')
                .format('YYYY/MM/DD')}&created_at<=${dayjs(statisticsDate)
                .endOf('year')
                .format('YYYY/MM/DD HH:mm:ss')}`
            : ''
        }`
      });
      setRegistrations(data);
    } catch (e) {
      message(e);
    }
  };

  return {
    getNotifications,
    getSupervisionsData,
    getCustomerInvoicesData,
    getInternInvoicesData,
    getRegistrationsData,
    getSupervisionsStatus,
    getSubscriptions
  };
};
