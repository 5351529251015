import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { principalInfo } from '../listContent/principalInfo';
import { DescriptionList } from '../../../../../components';

const PrincipalInfoCards = ({ t, isLoading, supervisionPrice }) => (
  <Card
    title={t('supervisionprices.show.principal_info')}
    className="form-card"
  >
    <Row gutter={[0, 24]}>
      <Col xs={24} xxl={24}>
        <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
          <DescriptionList
            data={principalInfo(supervisionPrice || {}, t)}
            translate
          />
        </Skeleton>
      </Col>
    </Row>
  </Card>
);

export default PrincipalInfoCards;

PrincipalInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  supervisionPrice: PropTypes.shape({}).isRequired
};
