export const reviewDrawerQuestions = {
  'users:OWNER': {
    aniService: 'questions.ani_seniors_phone_service',
    documents: 'questions.sufficient_documents',
    conviviality: 'questions.conviviality',
    arrivalContact: 'questions.contact_with_pensioners',
    animalsSatisfaction:
      'questions.satisfied_of_pensioners_services_for_animals',
    houseSatisfaction: 'questions.satisfied__of_pensioners_services_for_home',
    returnContact: 'questions.contact_with_pensioners_to_return'
  },
  'users:PENSIONER': {
    aniService: 'questions.ani_seniors_phone_service',
    documents: 'questions.sufficient_documents',
    conviviality: 'questions.conviviality',
    arrivalContact: 'questions.contact_with_owner',
    animalsSatisfaction: 'questions.satisfied_of_owner_animals',
    houseSatisfaction: 'questions.satisfied_of_owner_house',
    returnContact: 'questions.contact_with_owner_to_return'
  }
};
