import React, { useEffect, useState } from 'react';
import { Drawer, Form, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { getSubscription } from '../../utils/getSubscription';
import { handleSubmitSubscriptionDrawer } from '../../utils/handleSubmitSubscriptionDrawer';

/**
 * Renders a drawer component for managing subscriptions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.setSubscriptionDrawerOpen - The function to set the subscription drawer open state.
 * @param {boolean} props.subscriptionDrawerOpen - The subscription drawer open state.
 * @param {Function} props.setRefreshData - The function to set the refresh data state.
 * @param {boolean} props.refreshData - The refresh data state.
 * @param {Object} props.form - The form object.
 * @param {Object} props.pensioner - The pensioner object.
 * @param {Object} props.subscription - The subscription object.
 * @param {Function} props.setSubscriptionInvoice - The function to set the subscription invoice.
 * @param {Function} props.setSubscription - The function to set the subscription.
 * @param {Function} props.setRecordID - The function to set the record ID.
 * @returns {JSX.Element} The JSX element representing the subscription drawer.
 */
export const SubscriptionDrawer = ({
  t,
  setSubscriptionDrawerOpen,
  subscriptionDrawerOpen,
  setRefreshData,
  refreshData,
  form,
  pensioner,
  subscription,
  setSubscriptionInvoice,
  setSubscription,
  setRecordID
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const [reduction, setReduction] = useState(0);
  const { fields } = useFields({ subscription, setReduction, form });

  useEffect(() => {
    (async () => {
      await getSubscription({
        dispatchAPI,
        pensioner,
        setSubscription,
        setSubscriptionInvoice,
        setRecordID,
        message
      });
    })();
  }, []);

  const setTotalTTCWithReduction = () => {
    const reductionAmount = (20 * reduction) / 100;
    const newTotalTTC = 20 - reductionAmount;
    form.setFieldsValue({ total_ttc: newTotalTTC });
  };

  useEffect(() => {
    setTotalTTCWithReduction();
  }, [reduction]);

  return (
    <Drawer
      title={t('pensioners.show.subscription_info')}
      width={620}
      onClose={() => setSubscriptionDrawerOpen(false)}
      open={subscriptionDrawerOpen}
      bodyStyle={{
        paddingBottom: 80
      }}
    >
      <Form
        form={form}
        onFinish={(values) =>
          handleSubmitSubscriptionDrawer({
            values,
            pensioner,
            dispatchAPI,
            setRefreshData,
            setSubscriptionDrawerOpen,
            refreshData,
            message,
            t
          })
        }
      >
        {fields.map((field) => generateFields('subscriptions', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={() => setSubscriptionDrawerOpen(false)}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

SubscriptionDrawer.propTypes = {
  t: PropTypes.func.isRequired,
  setSubscriptionDrawerOpen: PropTypes.func.isRequired,
  subscriptionDrawerOpen: PropTypes.bool.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  form: PropTypes.shape({
    setFieldsValue: PropTypes.func.isRequired
  }).isRequired,
  pensioner: PropTypes.shape({
    phone_interview: PropTypes.shape({})
  }).isRequired,
  subscription: PropTypes.shape({}).isRequired,
  setSubscription: PropTypes.func.isRequired,
  setSubscriptionInvoice: PropTypes.func.isRequired,
  setRecordID: PropTypes.func.isRequired
};
