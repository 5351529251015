import { Card, Col, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text, Title } = Typography;

/**
 * Renders a card component for displaying a review.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.review - The review object.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.setShowReviewDrawer - The function to set the visibility of the review drawer.
 * @param {Function} props.setOpen - The function to set the open state.
 * @param {string} props.title - The title of the card.
 * @param {Function} props.setEditReviewDrawer - The function to set the visibility of the edit review drawer.
 * @returns {JSX.Element} The JSX element representing the review card.
 */
export const ReviewsCards = ({
  review,
  t,
  setShowReviewDrawer,
  setOpen,
  title,
  setEditReviewDrawer
}) => {
  const receiverName = review?.receiver?.id?.map((item) => ({
    first_name: item.first_name,
    last_name: item.last_name
  }));

  return (
    <Col xs={24} lg={16}>
      <Card
        title={title}
        hoverable
        key={review._id}
        onClick={() => {
          setEditReviewDrawer(true);
          setShowReviewDrawer(true);
          setOpen(true);
        }}
      >
        <Title level={5}>
          {t('owners.users_shows.reviews.given_to', ...receiverName)}
        </Title>
        <Text>{review?.comment_or_suggestion}</Text>
      </Card>
    </Col>
  );
};

ReviewsCards.propTypes = {
  review: PropTypes.shape({
    _id: PropTypes.string,
    receiver: PropTypes.shape({
      id: PropTypes.arrayOf(
        PropTypes.shape({
          first_name: PropTypes.string,
          last_name: PropTypes.string
        })
      )
    }),
    comment_or_suggestion: PropTypes.string
  }).isRequired,
  t: PropTypes.func.isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setEditReviewDrawer: PropTypes.func.isRequired
};
