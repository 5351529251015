export const getEnums = async ({ dispatchAPI, setEnums, message }) => {
  try {
    const { data: ownerEnums } = await dispatchAPI('GET', {
      url: '/owners/enums'
    });
    setEnums((prevState) => ({ ...prevState, ...ownerEnums }));
    const { data: supervisionEnums } = await dispatchAPI('GET', {
      url: '/supervisions/enums'
    });
    setEnums((prevState) => ({ ...prevState, ...supervisionEnums }));
    const { data: petsittingAddressEnums } = await dispatchAPI('GET', {
      url: '/petsittingaddresses/enums'
    });
    setEnums((prevState) => ({ ...prevState, ...petsittingAddressEnums }));
  } catch (e) {
    if (e.response) message(e.response.status);
  }
};
