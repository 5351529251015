import moment from 'moment';

export const useCancelInfo = ({ supervision }) => {
  const { decline, interrupt } = supervision || {};
  const cancelInfo = [
    {
      label: 'supervisions.form.interrupt.reason',
      span: 1,
      content: (decline && decline.reason) || '-'
    },
    {
      label: 'supervisions.form.interrupt.comment',
      span: 1,
      content: (decline && decline.comment) || '-'
    }
  ];

  const interruptInfo = [
    {
      label: 'supervisions.form.interrupt.reason',
      span: 1,
      content: (interrupt && interrupt.reason) || '-'
    },
    {
      label: 'supervisions.form.interrupt.comment',
      span: 1,
      content: (interrupt && interrupt.comment) || '-'
    },
    {
      label: 'supervisions.form.interrupt.application_date',
      span: 1,
      content:
        (interrupt &&
          interrupt.application_date &&
          moment(interrupt.application_date).format('DD/MM/YYYY')) ||
        '-'
    },
    {
      label: 'supervisions.form.interrupt.duplicate_supervision',
      span: 1,
      content:
        (interrupt && interrupt.duplicate_supervision ? 'Oui' : 'Non') || '-'
    },
    {
      label: 'supervisions.form.interrupt.remaining_amount',
      span: 1,
      content:
        (interrupt &&
          interrupt.remaining_amount &&
          `${interrupt.remaining_amount} €`) ||
        '-'
    },
    {
      label: 'supervisions.form.interrupt.duplicate_supervision_reference',
      span: 1,
      content: (interrupt && interrupt.duplicate_supervision_reference) || '-'
    }
  ];

  return { cancelInfo, interruptInfo };
};
