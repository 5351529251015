import axios from 'axios';
import europeanCountries from './europeanCountries';

/**
 * Custom hook to fetch country code information based on the provided postal code.
 * @hook
 *
 * @param {string} country - The country code.
 * @returns {Object} - An object containing the handleCountryCodeInfos function.
 */
export const useCountryCodeInfos = (country) => {
  /**
   * Fetches city and state information based on the provided postal code.
   * @param {string} postalCode - The postal code.
   * @returns {Promise<Object>} - A promise that resolves to an object containing the city and state information.
   */
  const handleCountryCodeInfos = (postalCode) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&components=country:${europeanCountries[country]}&key=AIzaSyAdqmd-qMAmtq2h5QCqR5HxAZprEXRvT_M`;

    return axios
      .get(url)
      .then((response) => {
        const city = response.data.results[0].address_components.find(
          (component) => component.types.includes('locality')
        )?.long_name;
        const state = response.data.results[0].address_components.find(
          (component) => component.types.includes('administrative_area_level_2')
        )?.long_name;
        return { city, state };
      })
      .catch(() => ({ city: '', state: '' }));
  };

  return { handleCountryCodeInfos };
};
