import { Route, Routes } from 'react-router-dom';
import { ShowVeterinarianGroomer } from './ShowVeterinarianGroomer';
import { CreateUpdateVeterinarian } from './CreateUpdateVeterinarian';
import { CreateUpdateGroomer } from './CreateupdateGroomer';

export const VeterinarianGroomerRouter = () => (
  <Routes>
    <Route
      path="/create/veterinarian/:id"
      element={<CreateUpdateVeterinarian purpose="create" />}
    />
    <Route
      path="/create/groomer/:id"
      element={<CreateUpdateGroomer purpose="create" />}
    />
    <Route
      path="/edit/veterinarian/:id"
      element={<CreateUpdateVeterinarian purpose="edit" />}
    />
    <Route
      path="/edit/groomer/:id"
      element={<CreateUpdateGroomer purpose="edit" />}
    />
    <Route path="/" element={<ShowVeterinarianGroomer />} />
  </Routes>
);
