import { Select, Row, Col, Tag, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CustomerType } from '../../utils/constants/tagColors';

const { Option } = Select;
const { Dragger } = Upload;

export const useMailingFields = ({
  recipients,
  isFieldsLoading,
  t,
  collection,
  filesList,
  setFilesList
}) => {
  const draggerProps = () => ({
    multiple: true,
    onRemove: (file) => {
      setFilesList(filesList.filter((f) => f.uid !== file.uid));
    },
    beforeUpload: (file) => {
      setFilesList((prevFileList) => [...prevFileList, file]);

      return false;
    },
    filesList
  });

  const mailingFields = [
    {
      name: ['recipients'],
      input: (
        <Select mode="multiple" loading={isFieldsLoading}>
          {(recipients || []).map((recipient) => (
            <Option key={recipient?.user?._id} value={recipient?.user?._id}>
              <Row justify="space-around" align="center">
                <Col>{`${recipient.first_name} ${recipient.last_name}`}</Col>
                <Col>
                  <Tag color={CustomerType[recipient.customer_type]}>
                    {t(`${collection}.tags.${recipient.customer_type}`)}
                  </Tag>
                </Col>
              </Row>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['models'],
      rules: [{ required: true }]
    },
    {
      name: ['object'],
      rules: [{ required: true }]
    },
    {
      noLabel: true,
      input: (
        <Dragger {...draggerProps('early_retirement_certificate')}>
          <p className="ant-upload-drag-icon">
            <DownloadOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    }
  ];

  return { mailingFields };
};
