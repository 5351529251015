export const dateFormat = (start_date, end_date) => {
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  const options = { month: 'long' };

  const formattedStartDate = `${startDate.getDate()} ${startDate.toLocaleDateString(
    'default',
    options
  )} ${startDate.getFullYear()}`;
  const formattedEndDate = `${endDate.getDate()} ${endDate.toLocaleDateString(
    'default',
    options
  )} ${endDate.getFullYear()}`;

  return { start_date: formattedStartDate, end_date: formattedEndDate };
};
