/**
 * Configuration function for monthly supervisions chart.
 * @param {Object} params - The parameters for the monthlySupervisionsChartConfig function.
 * @param {Array} params.monthlySupervisionsChartData - Data for the monthly supervisions chart.
 * @param {Function} params.t - Translation function for internationalization.
 * @returns {Object} - Configuration object for the monthly supervisions chart.
 */
export const monthlySupervisionsChartConfig = ({
  monthlySupervisionsChartData,
  t
}) => ({
  data: monthlySupervisionsChartData,
  xField: 'month',
  yField: 'number',
  columnWidthRatio: 0.8,
  color: '#7EDB50',
  tooltip: {
    /**
     * Formatter function for the tooltip.
     * @type {Function}
     * @param {Object} item - Data item for the tooltip.
     * @returns {Object} - Formatted tooltip content.
     */
    formatter: (item) => ({
      name: t('dashboard.tabs.supervisions.number'),
      value: item.number
    })
  },
  xAxis: {
    label: {
      autoHide: true,
      autoRotate: false
    }
  },
  meta: {
    type: {
      alias: 'type'
    },
    sales: {
      alias: t('dashboard.tabs.supervisions.number')
    }
  }
});
