export const onFinishInfoForm = async ({
  form,
  setInformationData,
  informationsData,
  setCurrent,
  Message,
  t
}) => {
  try {
    form.setFieldsValue(informationsData);
    const values = await form.validateFields();
    setInformationData(values);
    setCurrent(1);
    return null;
  } catch (errorInfo) {
    return Message.error(t('supervisions.messages.required_fields'));
  }
};
