import PropTypes from 'prop-types';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

export const DescriptionList = ({
  title,
  data,
  skipEmpty,
  translate,
  layout,
  comment
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Descriptions title={title} layout={layout}>
        {data.map(
          (item) =>
            (item.content !== '-' || !skipEmpty || !item.hidden) && (
              <Descriptions.Item
                label={translate ? t(`${item.label}`) : item.label}
                span={item.span || 1}
                key={item.label}
              >
                <Typography style={{ fontWeight: 700 }}>
                  {item.content || '-'}
                </Typography>
                {item.extra && item.extra}
              </Descriptions.Item>
            )
        )}
      </Descriptions>
      {comment && (
        <Typography style={{ fontWeight: 700 }}>{comment || '-'}</Typography>
      )}
    </div>
  );
};

DescriptionList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  skipEmpty: PropTypes.bool,
  translate: PropTypes.bool,
  layout: PropTypes.string,
  comment: PropTypes.string
};

DescriptionList.defaultProps = {
  title: null,
  skipEmpty: false,
  translate: false,
  layout: 'vertical',
  comment: null
};
