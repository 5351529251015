import React, { useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useColumns } from './columns/createUpdateColumns';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { handleSubmit } from './utils/handleSubmit';
import { setCreateUpdateParameters } from './utils/setCreateUpdateParameters';
import { getResources } from './utils/getResources';
import { getEnums } from './utils/getEnums';
import { CreateUpdateExtraFilters } from './extraFilters/CreateUpdateExtraFilters';
import { generateDocuments } from './utils/generateDocuments';

/**
 * Component for creating or updating a mail sender.
 * Manages form submission and displays a list of mail senders.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the create/update operation.
 * @returns {JSX.Element} React element representing the component.
 */

export const MailSenderCreateUpdate = ({ purpose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [enums, setEnums] = useState({});
  const { type, sendingType, id } = useParams();
  const { pensionerColumns, ownerColumns, veterinarianAndGroomerColumns } =
    useColumns({ enums, type });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (purpose === 'edit') {
      (async () => {
        await getResources({
          dispatchAPI,
          type,
          id,
          setSelectedRowKeys,
          form,
          message
        });
      })();
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      if (type !== 'GROOMER_VETERINARIAN_ADVERT')
        await getEnums({
          dispatchAPI,
          type,
          pensionerColumns,
          ownerColumns,
          veterinarianAndGroomerColumns,
          setEnums,
          message,
          purpose
        });
    })();
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (rowKeys) => {
      setSelectedRowKeys(rowKeys);
    }
  };

  return (
    <ListResource
      resourceName={
        setCreateUpdateParameters({
          type,
          pensionerColumns,
          sendingType,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).resourceName
      }
      columns={
        setCreateUpdateParameters({
          type,
          pensionerColumns,
          sendingType,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).columns
      }
      resourceModelName={
        setCreateUpdateParameters({
          type,
          pensionerColumns,
          sendingType,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).resourceModelName
      }
      customActionColumn
      withCreateButton={false}
      extraQuery={
        setCreateUpdateParameters({
          type,
          pensionerColumns,
          sendingType,
          ownerColumns,
          veterinarianAndGroomerColumns
        }).extraQuery
      }
      customPathName={`mail-sender-${sendingType.toLowerCase()}/${purpose}/${id}`}
      customPageHeaderTitle={t('mail-sender.form-title', {
        sending_type: sendingType === 'POST' ? 'courriers' : 'mails',
        purpose: purpose === 'edit' ? 'modifier' : 'créer'
      })}
      extraFilters={
        <>
          {sendingType === 'EMAIL' ? (
            <CreateUpdateExtraFilters form={form} />
          ) : null}{' '}
          {type === 'OWNER_EXPLANATORY_DOCUMENT' ||
          type === 'PENSIONER_EXPLANATORY_DOCUMENT' ||
          type === 'ANOUNCEMENT' ? (
            <Button
              type="primary"
              onClick={() =>
                generateDocuments(
                  { document_type: `${type}_WITHOUT_NAME` },
                  dispatchAPI,
                  message,
                  t
                )
              }
            >
              {t('mail-sender.buttons.dl_neutral_template')}
            </Button>
          ) : null}
        </>
      }
      rowSelection={{
        type: 'checkbox',
        ...rowSelection
      }}
      bottom_children={
        <>
          <Button type="link" danger onClick={() => navigate(-1)}>
            {t('buttons.cancel')}
          </Button>
          <Button
            type="primary"
            onClick={() =>
              handleSubmit({
                form,
                sendingType,
                purpose,
                dispatchAPI,
                id,
                type,
                selectedRowKeys,
                user,
                navigate,
                message,
                t
              })
            }
          >
            {t('buttons.confirm')}
          </Button>
        </>
      }
    />
  );
};

MailSenderCreateUpdate.propTypes = {
  purpose: PropTypes.string.isRequired
};
