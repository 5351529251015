import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

/**
 * Generates phone interview information related to animals.
 * @function
 * @param {object} phoneInterview - Phone interview data
 * @param {function} t - Translation function
 *
 * @returns {Array} An array of description list items for animal-related information
 */
const phoneInterviewAnimalsInfo = (phoneInterview, t) => {
  const { animals } = phoneInterview ?? {};
  const {
    is_animals,
    animals_type,
    walking_leach,
    animals_treatments,
    additional_informations
  } = animals ?? {};

  return [
    {
      label: 'pensioners.form.phone_interviews.labels.is_animals',
      span: 1,
      content: is_animals
        ? t('pensioners.show.phone_interviews.card.yes')
        : t('pensioners.show.phone_interviews.card.no')
    },
    {
      label: 'pensioners.form.phone_interviews.labels.had_animals',
      span: 2,
      content:
        (animals_type &&
          (animals_type ?? []).map((type, index) => (
            <>
              <Text>
                {`${type?.type ? t(`animals.tags.${type.type}`) : '-'}, ${
                  type?.breed ?? '-'
                }, ${
                  type?.age
                    ? t('pensioners.show.phone_interviews.card.age', {
                        age: type.age
                      })
                    : '-'
                }`}
              </Text>
              {index !== animals_type.length - 1 && ' / '}
            </>
          ))) ||
        'Non'
    },
    {
      label: 'pensioners.form.phone_interviews.labels.walk_with_leash',
      span: 1,
      content:
        (walking_leach &&
          (walking_leach ?? []).map((type) => (
            <Text>
              {t(`pensioners.show.phone_interviews.checkbox_options.${type}`)}
              {!(type === walking_leach[walking_leach.length - 1]) && ', '}
            </Text>
          ))) ||
        'Non'
    },
    {
      label: 'pensioners.form.phone_interviews.labels.animals_treatments',
      span: 2,
      content:
        animals_treatments &&
        (animals_treatments ?? []).map(
          (type) =>
            (
              <Text>
                {t(`pensioners.show.phone_interviews.checkbox_options.${type}`)}
                {!(
                  type === animals_treatments[animals_treatments.length - 1]
                ) && ', '}
              </Text>
            ) || 'Non'
        )
    },
    {
      label: 'pensioners.form.phone_interviews.labels.additional_informations',
      span: 2,
      content: (additional_informations && additional_informations) || '-'
    }
  ];
};

/**
 * Generates phone interview information related to dwelling.
 * @function
 * @param {object} data - Phone interview data
 * @param {function} t - Translation function
 *
 * @returns {Array} An array of description list items for dwelling-related information
 */
const phoneInterviewDwellingInfo = (data, t) => {
  const { dwelling } = data;
  const { already_live_in, is_stair_problem, wont } = dwelling ?? {};

  return [
    {
      label: 'pensioners.form.phone_interviews.labels.already_live_in',
      span: 3,
      content:
        (already_live_in ?? []).map((type) => (
          <Text>
            {t(`pensioners.show.phone_interviews.checkbox_options.${type}`)}
            {!(type === already_live_in[already_live_in.length - 1]) && ', '}
          </Text>
        )) || 'Non'
    },
    {
      label: 'pensioners.form.phone_interviews.labels.is_stair_problem',
      span: 3,
      content: is_stair_problem
        ? t('pensioners.show.phone_interviews.card.yes')
        : t('pensioners.show.phone_interviews.card.no')
    },
    {
      label: 'pensioners.form.phone_interviews.labels.dwelling_wont',
      span: 3,
      content:
        (wont ?? []).map((type) => (
          <Text>
            {t(`pensioners.show.phone_interviews.checkbox_options.${type}`)}
            {!(type === wont[wont.length - 1]) && ', '}
          </Text>
        )) || 'Non'
    }
  ];
};

/**
 * Generates phone interview personal information.
 * @function
 * @param {object} data - Phone interview data
 * @param {function} t - Translation function
 *
 * @returns {Array} An array of description list items for personal information
 */
const phoneInterviewPersonalInfo = (data, t) => {
  const { pensioner } = data;

  return [
    {
      label: 'pensioners.form.phone_interviews.labels.pensioner_wont',
      span: 3,
      content:
        (pensioner?.wont ?? []).map(
          (type) =>
            `${t(`pensioners.show.phone_interviews.checkbox_options.${type}`)}`
        ) || 'Non'
    },
    {
      label:
        'pensioners.form.phone_interviews.labels.send_photo_with_cell_phone',
      span: 3,
      content: pensioner?.send_photo_with_cell_phone
        ? t('pensioners.show.phone_interviews.card.yes')
        : t('pensioners.show.phone_interviews.card.no')
    },
    {
      label: 'pensioners.form.phone_interviews.labels.additional_informations',
      span: 2,
      content:
        (pensioner?.additional_informations &&
          pensioner.additional_informations) ||
        '-'
    }
  ];
};

export {
  phoneInterviewAnimalsInfo,
  phoneInterviewDwellingInfo,
  phoneInterviewPersonalInfo
};
