import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { SubscriptionsChart } from './cards/SubscriptionsChart';
import { RegistrationsChart } from './cards/RegistrationsChart';

/**
 * Component to display statistics using subscription and registration charts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.customerInvoices - Customer invoices data.
 * @param {Object} props.registrations - Registrations data.
 *
 * @returns {React.ReactNode} - The rendered component.
 */
export const ShowStatistics = ({
  customerInvoices,
  registrations,
  setStatisticsDate,
  subscriptions,
  statisticsDate
}) => {
  const { t } = useTranslation();

  const handleChange = (date) => {
    if (date) {
      setStatisticsDate(date);
    } else {
      setStatisticsDate(dayjs().format('YYYY-MM-DD'));
    }
  };

  return (
    <>
      <DatePicker
        picker="year"
        onChange={handleChange}
        style={{ marginBottom: '20px' }}
      />
      <SubscriptionsChart
        t={t}
        customerInvoices={customerInvoices}
        subscriptions={subscriptions}
        statisticsDate={statisticsDate}
      />
      <RegistrationsChart t={t} registrations={registrations} />
    </>
  );
};

ShowStatistics.propTypes = {
  customerInvoices: PropTypes.shape({}).isRequired,
  subscriptions: PropTypes.shape({}).isRequired,
  registrations: PropTypes.shape({}).isRequired,
  setStatisticsDate: PropTypes.func.isRequired,
  statisticsDate: PropTypes.string
};

ShowStatistics.defaultProps = {
  statisticsDate: null
};
