export const deletePrices = async ({
  record,
  dispatchAPI,
  message,
  setRefreshData,
  refreshData,
  supervisionPrice
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/supervisionprices/${supervisionPrice._id}?type=remove_price&price_to_remove=${record._id}`
    });

    setRefreshData(!refreshData);
  } catch (error) {
    message(error);
  }
};
