import dayjs from 'dayjs';
import { message as antdMessage } from 'antd';

export const reserveSupervision = async ({
  supervisionId,
  dispatchAPI,
  user,
  message,
  navigate,
  t,
  setIsReservationModalOpen,
  anounceId
}) => {
  try {
    const { data: pensionerData } = await dispatchAPI('GET', {
      url: `/pensioners?user=${user._id}`
    });

    const { data } = await dispatchAPI('POST', {
      url: '/reservations',
      body: {
        supervision: supervisionId,
        pensioner: pensionerData[0]._id,
        status: 'WAITING',
        date: dayjs()
      }
    });

    if (data) {
      if (navigate) {
        setIsReservationModalOpen(false);
        antdMessage.success(t('view-ads.messages.success.reservation'));
        navigate(`/show/${supervisionId}/${anounceId}`);
      } else {
        setIsReservationModalOpen(false);
        antdMessage.success(t('view-ads.messages.success.reservation'));
      }
    }
  } catch (e) {
    message(e);
  }
};
