import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../../../components';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { ListReviews } from '../reviews/ListReviews';
import { createFileFromJSON } from '../../../../utils/filesManagement/createFileFromJSON';
import { useDownloadDocument } from '../../../../utils/downloadDoc';
import { onPreview } from '../../../../utils/filesManagement/onPreview';
import { ListDocuments } from '../../../../components/ListDocuments/ListDocuments';
import { GeneralInfos } from './detailList/GeneralInfos';
import { Otherinfos } from './detailList/OtherInfos';
import { ExtraInfos } from './detailList/ExtraInfos';
import { useSocketContext } from '../../../../contexts/SocketContext';
import { profileLockMessage } from '../../utils/profileLockMessage';

/**
 * React component for displaying a user profile.
 * @component
 * @returns {JSX.Element} - React component.
 */
export const ShowProfile = () => {
  const [profile, setProfile] = useState({});
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { userProfile, user, dispatchAPI, token } = useAuthContext();
  const { message } = useErrorMessage();
  const { downloadDocument } = useDownloadDocument();
  const navigate = useNavigate();
  const { onProfileLock, profileLock } = useSocketContext();

  const personalDocumentsKeys = [
    'criminal_record_extract',
    'identification_photocopy'
  ];
  const secondaryPersonalDocumentsKeys = [
    'secondary_profile_identification_photocopy',
    'secondary_profile_criminal_record_extract'
  ];
  const role = `${user?.role?.split(':')[1].toLowerCase()}s`;
  const personalDocuments = profile?.documents?.filter((doc) =>
    personalDocumentsKeys.includes(doc?.type)
  );
  const secondaryPersonalDocuments =
    profile?.secondary_profile?.documents?.filter((doc) =>
      secondaryPersonalDocumentsKeys.includes(doc?.type)
    );
  const otherInfosDocumentsKeys = [
    'early_retirement_certificate',
    'insurance_certificate',
    'residence_proof'
  ];
  const otherInfosDocuments = profile?.documents?.filter((doc) =>
    otherInfosDocumentsKeys.includes(doc?.type)
  );
  const pensionerRole = user?.role === 'users:PENSIONER';
  const checkProfileLock = profileLock.some(
    (item) => item.type === profile._id
  );

  const documentsTypesArray = [
    'identification_photo',
    'secondary_profile_identification_photo'
  ];
  const photoId = (profileData) => {
    const documentType = profileData?.documents?.find((doc) =>
      documentsTypesArray.includes(doc.type)
    );

    return documentType?._id?._id;
  };

  const getProfile = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/${role}/profile-by-user?email=${user?.email}&populate=photo${
          pensionerRole
            ? ',documents._id,secondary_profile.documents._id,secondary_profile.photo'
            : ''
        }`
      });

      setProfile(data);

      if (data.secondary_profile?.photo) {
        const secondaryProfilePhoto = await createFileFromJSON(
          data.secondary_profile.photo,
          dispatchAPI,
          message
        );
        setProfile((prevState) => ({
          ...prevState,
          secondary_profile: {
            ...data.secondary_profile,
            photo: secondaryProfilePhoto
          }
        }));
      }
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getProfile();
      setIsLoading(false);
    })();
  }, [userProfile]);

  const getRatings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/ratings?author.id=${user._id}&populate=author.id,receiver.id`
      });
      setReviews(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getRatings();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await onProfileLock();
    })();
  }, []);

  return (
    <>
      <PageHeaderCustom
        withoutBreadCrumb
        backIcon={<LeftOutlined />}
        title={t('dashboard.cards_titles.my_profile')}
      />
      <ContentCustom>
        <GeneralInfos
          token={token}
          profile={profile}
          t={t}
          photoId={pensionerRole ? photoId(profile) : profile?.photo?._id}
          user={user}
          isLoading={isLoading}
          navigate={navigate}
          profileID={profile?._id}
          onPreview={onPreview}
          personalDocuments={personalDocuments}
          downloadDocument={downloadDocument}
          checkProfileLock={checkProfileLock}
          profileLockMessage={profileLockMessage}
        />
        {['first_name', 'last_name'].every((item) =>
          Object.keys(profile?.secondary_profile ?? []).includes(item)
        ) &&
          pensionerRole && (
            <>
              <Divider />
              <GeneralInfos
                token={token}
                photoId={photoId(profile?.secondary_profile)}
                profile={profile?.secondary_profile || {}}
                t={t}
                user={user}
                navigate={navigate}
                profileID={profile?._id}
                onPreview={onPreview}
                isLoading={isLoading}
                personalDocuments={secondaryPersonalDocuments}
                downloadDocument={downloadDocument}
                checkProfileLock={checkProfileLock}
                profileLockMessage={profileLockMessage}
              />
            </>
          )}
        {pensionerRole && (
          <>
            <Otherinfos
              isLoading={isLoading}
              profile={profile}
              t={t}
              navigate={navigate}
              checkProfileLock={checkProfileLock}
              profileLockMessage={profileLockMessage}
            />
            <Divider />
            <ListDocuments
              onPreview={onPreview}
              isLoading={isLoading}
              documents={otherInfosDocuments}
              t={t}
              downloadDocument={downloadDocument}
              withTitle
            />
            <Divider />
            <ExtraInfos
              profile={profile}
              t={t}
              navigate={navigate}
              isLoading={isLoading}
              checkProfileLock={checkProfileLock}
              profileLockMessage={profileLockMessage}
            />
          </>
        )}
        {Object.keys(reviews).length > 0 && (
          <>
            <Divider />
            <ListReviews user={user} t={t} reviews={reviews} />
          </>
        )}
      </ContentCustom>
    </>
  );
};
