/**
 * Sets consulting announcement authorization based on pensioner information.
 * @param {Object} options - Options for setting consulting announcement authorization.
 * @param {Object} options.pensionerInfo - Information about the pensioner.
 * @param {Function} options.setMissingConditionInfo - Function to set missing condition information.
 * @param {Function} options.setAuthorizationValid - Function to set authorization validity.
 * @returns {void}
 */
export const setConsultingAnounceAuthorization = ({
  pensionerInfo,
  setMissingConditionInfo,
  setAuthorizationValid
}) => {
  /**
   * Flag indicating whether the authorization is valid.
   * @type {boolean}
   */
  let isValid = pensionerInfo?.status === ('ACTIVE' || 'FREE');

  /**
   * Object to store the necessary documents for the principal profile.
   * @type {Object}
   */
  const principalProfileNecessaryDocuments = {};

  /**
   * Object to store the necessary documents for the secondary profile.
   * @type {Object}
   */
  const secondaryProfileNecessaryDocuments = {};

  /**
   * Array of necessary documents.
   * @type {string[]}
   */
  const necessaryDocuments = [
    'criminal_record_extract',
    'identification_photocopy',
    'identification_photo',
    'insurance_certificate',
    'residence_proof',
    'early_retirement_certificate'
  ];

  /**
   * Iterates over necessary documents to check their presence in the pensioner's profiles.
   */
  // Check if the document is present in the principal profile
  necessaryDocuments.forEach((document) => {
    principalProfileNecessaryDocuments[document] =
      pensionerInfo?.documents?.some((doc) => doc.type === document) || false;
    if (!principalProfileNecessaryDocuments[document]) {
      isValid = false;
    }
  });

  const secondaryDocumentsRequired = necessaryDocuments.slice(0, 2);
  // Check if the document is present in the secondary profile
  if (Object.keys(pensionerInfo?.secondary_profile || {}).length > 3) {
    secondaryDocumentsRequired.forEach((document) => {
      secondaryProfileNecessaryDocuments[document] =
        pensionerInfo?.secondary_profile?.documents?.some(
          (doc) => doc.type === `secondary_profile_${document}`
        ) || false;
      if (!secondaryProfileNecessaryDocuments[document]) {
        isValid = false;
      }
    });
  }

  /**
   * Sets missing condition information and authorization validity.
   */
  setMissingConditionInfo({
    status: pensionerInfo?.status,
    principalProfileNecessaryDocuments,
    secondaryProfileNecessaryDocuments
  });
  setAuthorizationValid(isValid);
};
