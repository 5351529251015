import { useNavigate, useParams } from 'react-router-dom';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';

export const useCustomSubmit = ({
  onCreateResource,
  onUpdateResource,
  baseUrl,
  customID,
  purpose
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const { id } = useParams();

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `${baseUrl}/${customID || id}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? onUpdateResource.setBody(body)
            : body
      });

      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const createResource = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `${baseUrl}${customID || id ? `/${customID || id}` : ''}`,
        body:
          onCreateResource && onCreateResource.setBody
            ? await onCreateResource.setBody(body)
            : body
      });
      navigate(-1);
    } catch (e) {
      message(e);
    }
  };

  const customSubmit = async (body) => {
    if (purpose === 'create') {
      await createResource(body);
    } else {
      await updateResource(body);
    }
  };

  return { customSubmit };
};
