import { useEffect } from 'react';
import { InputNumber, DatePicker } from 'antd';
import dayjs from 'dayjs';

export const useFields = ({ subscription, setReduction, form }) => {
  const disabledDate = (current) => {
    let startDate = dayjs(subscription?.start_date);
    startDate = startDate.add(1, 'year');
    return current && current <= startDate.endOf('day');
  };

  useEffect(() => {
    if (Object.keys(subscription).length) {
      form.setFieldsValue({
        start_date: dayjs(subscription.start_date).add(1, 'year').add(1, 'day')
      });
    }
  }, [subscription]);

  const fields = [
    {
      label: 'start_date',
      name: 'start_date',
      rules: [{ required: true }],
      input: (
        <DatePicker
          format="DD/MM/YYYY"
          disabledDate={Object.keys(subscription) ? disabledDate : null}
          dateRender={(current) => {
            const isDisabled = disabledDate(current);
            return isDisabled ? (
              <div style={{ background: 'rgba(0, 0, 0, 0.04)' }}>
                {current.date()}
              </div>
            ) : (
              current.date()
            );
          }}
        />
      )
    },
    {
      label: 'reduction',
      name: 'reduction',
      input: <InputNumber addonAfter="%" onChange={(e) => setReduction(e)} />
    },
    {
      label: 'reduction_type',
      name: 'reduction_type'
    },
    {
      label: 'payment_method',
      name: 'payment_method'
    },
    {
      label: 'total_ttc',
      name: 'total_ttc',
      input: <InputNumber disabled defaultValue={20} addonAfter="€" />
    }
  ];

  return { fields };
};
