import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useConfig } from './utils/animalsConfig';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { useFields } from '../../owners/form/animalForm/fields';
import { useCustomSubmit } from './utils/customSubmit';
import { useSocketContext } from '../../../contexts/SocketContext';
import { EditingLocked } from '../../../components/EditingLocked/EditingLocked';
import { editingLocked } from '../../../utils/editingLocked';

export const CreateUpdateAnimals = ({ purpose }) => {
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [dataFetched, setDataFetched] = useState(false);
  const { petId: animalID, id } = useParams();
  const { profileLock } = useSocketContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [animalsFileList, setAnimalsFileList] = useState([]);
  const [ownerID, setOwnerID] = useState('');
  const config = useConfig(
    animalsFileList,
    setAnimalsFileList,
    dispatchAPI,
    message,
    () => {
      if (!dataFetched) {
        setDataFetched(true);
      }
    }
  );
  const { onCreateResource, onUpdateResource } = config;

  const { handleSubmit } = useCustomSubmit(
    onCreateResource,
    onUpdateResource,
    ownerID,
    animalID,
    purpose
  );

  const { fields } = useFields({
    fileList: animalsFileList,
    setFileList: setAnimalsFileList,
    name: 'animals',
    form,
    dataFetched,
    ownerUser: true
  });

  const getOwner = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/owners?email=${user?.email}&population=animals,animals.animal_photos`
      });
      setOwnerID(data[0]?._id);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getOwner();
    })();
  }, []);

  return (
    <>
      {editingLocked(profileLock, id) && (
        <EditingLocked
          t={t}
          resources={{
            button: 'my_pets',
            text: 'profile'
          }}
          navigate={navigate}
        />
      )}
      <CreateUpdateContainer
        purpose={purpose}
        fields={fields}
        populate="animal_photo"
        baseUrl="animals/form"
        resource="animals"
        config={config}
        customFormInstance={form}
        customSubmit={handleSubmit}
        customId={animalID}
        disabled={editingLocked(profileLock, id)}
      />
    </>
  );
};

CreateUpdateAnimals.propTypes = {
  purpose: PropTypes.string.isRequired
};
