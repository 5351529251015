import React from 'react';
import PropTypes from 'prop-types';
import { Row, Card, Col, Tabs } from 'antd';
import ListInvoices from '../ListInvoices';
import ListSupervisions from '../ListSupervisions';

/**
 * Component for displaying supervision and invoice information cards within a tabbed interface.
 * @component
 * @example
 * const ExampleComponent = () => {
 *   return <SupervisionAndInvoiceInfoCards t={translateFunction} owner={ownerData} />;
 * };
 *
 * @param {Object} props - The component props.
 * @param {function} props.t - The translation function for internationalization.
 * @param {Object} props.owner - The owner data.
 * @returns {JSX.Element} - The rendered component.
 */
const SupervisionAndInvoiceInfoCards = ({ owner, t }) => {
  /**
   * Tab items for supervisions and invoices.
   * @type {Object[]}
   * @property {string} label - The display label for the tab.
   * @property {string} key - The unique key for the tab.
   * @property {JSX.Element} children - The content to be displayed under the tab.
   */
  const items = [
    {
      label: t('supervision_and_invoice.tabs.supervisions'),
      key: 'supervisions',
      children: <ListSupervisions owner={owner} />
    },
    {
      label: t('supervision_and_invoice.tabs.invoices'),
      key: 'invoices',
      children: <ListInvoices owner={owner} />
    }
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xxl={24}>
        <Card className="card-with-tabs">
          <Tabs defaultActiveKey="supervisions" items={items} />
        </Card>
      </Col>
    </Row>
  );
};

export default SupervisionAndInvoiceInfoCards;

SupervisionAndInvoiceInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  owner: PropTypes.shape({}).isRequired
};
