export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta',
  DEVELOPER: 'purple'
};

export const pendingTasksCat = {
  POST: 'green',
  PATCH: 'blue',
  DELETE: 'red'
};

export const WFLStatus = {
  INITIATED: 'grey',
  PENDING: 'orange',
  VALIDATED: 'green'
};

export const OwnerStatus = {
  ACTIVE: 'green',
  REQUEST: 'orange',
  ARCHIVED: 'grey'
};

export const PensionerStatus = {
  ACTIVE: 'green',
  REQUEST: 'orange',
  INACTIVE: 'red',
  OUTDATED: 'red',
  FREE: 'green'
};

export const SupervisionStatus = {
  WAITING: 'blue',
  REQUEST: 'orange',
  BOOKED: 'green',
  PUBLISHED: 'geekblue',
  IN_PROGRESS: 'purple',
  FINISHED: 'magenta',
  CANCEL: 'red',
  INTERRUPT: 'red',
  OUTDATED: 'red'
};

export const InvoiceStatus = {
  PAID: 'green',
  FREE: 'green',
  UNPAID: 'red',
  WAITING: 'orange',
  SUBSCRIPTION: 'green',
  CREDIT_NOTE: 'red',
  ADVANCE_PAYMENT: 'orange',
  BALANCE: 'blue'
};

export const InvoiceType = {
  SUBSCRIPTION: 'green',
  CREDIT_NOTE: 'red',
  ADVANCED_PAYMENT: 'orange',
  BALANCE: 'blue'
};

export const ReservationStatus = {
  YES: 'green',
  NO: 'red',
  WAITING: 'orange'
};

export const CustomerType = {
  pensioner: 'purple',
  owner: 'PINK'
};

export const templateStatus = {
  'CREATING-PROCESS': '#FADB14',
  VALIDATED: '#2DE180'
};

export const mailSenderStatus = {
  SENT: 'green',
  NOT_SENT: 'red'
};
