import { Route, Routes } from 'react-router-dom';
import { ListTemplateMails } from './ListTemplateMails';
import { CreateUpdateTemplateMails } from './CreateUpdateTemplateMails';
import { Exception } from '../../components';

/**
 * `TemplateMailRouter` is a component responsible for defining the routing structure for TemplateMailRouter related pages.
 *
 * @component
 * @returns {JSX.Element} JSX elements representing the TemplateMailRouter router.
 */

export const TemplateMailRouter = () => (
  <Routes>
    <Route
      path="/create"
      element={<CreateUpdateTemplateMails purpose="create" />}
    />
    <Route
      path="/edit/:id"
      element={<CreateUpdateTemplateMails purpose="edit" />}
    />
    <Route index element={<ListTemplateMails />} />
    <Route path="*" element={<Exception />} />
  </Routes>
);
