export const getContracts = async ({
  user,
  dispatchAPI,
  setFilterID,
  setContracts,
  message
}) => {
  const key =
    user.role === 'users:OWNER' ? 'main_informations.owner' : 'pensioners';
  const customer = user.role === 'users:OWNER' ? 'owners' : 'pensioners';

  const { data: customerData } = await dispatchAPI('GET', {
    url: `${customer}?user=${user._id}`
  });

  try {
    const { data } = await dispatchAPI('GET', {
      url: `supervisions?${key}=${customerData[0]._id}&populate=documents._id`
    });

    const updatedContracts = [];

    if (data.length) {
      data.map((supervision) =>
        supervision.documents.map((doc) => {
          if (doc.user === user._id && doc.type.includes('CONTRACT')) {
            updatedContracts.push({
              ...doc,
              supervision_id: supervision._id,
              supervision_reference: supervision.reference,
              document_type: doc.type.includes('OWNER')
                ? 'OWNER_CONTRACT'
                : 'PENSIONER_CONTRACT'
            });
          }
          return null;
        })
      );
    }

    setFilterID(customerData[0]._id);
    setContracts(updatedContracts);
  } catch (e) {
    message(e);
  }
};
