/**
 * Retrieves mail sender information for a specific ID.
 *
 * @function
 * @param {Object} params - The parameters for retrieving mail sender information.
 * @param {function} params.dispatchAPI - A function for dispatching API requests.
 * @param {string} params.id - The ID of the mail sender to retrieve.
 * @param {function} params.setMailSender - A function to set the mail sender state.
 */

export const getMailSender = async ({ dispatchAPI, id, setMailSender }) => {
  const { data } = await dispatchAPI('GET', {
    url: `/mail-sender/${id}`
  });

  if (data) {
    setMailSender(data);
  }
};
