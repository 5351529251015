import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button, Drawer, Form } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../utils/errorMessage';
import { useFields } from './fields';
import { useGenerateFormItem } from '../../../../utils/generateFormItem';
import { addTotalTTC } from '../../utils/addTotalTTC';
import { onFinishForm } from '../../utils/onFinishForm';

const PriceManagementDrawer = ({
  visible,
  setVisible,
  setRefreshData,
  refreshData,
  supervisionPrice,
  purpose,
  priceToEdit
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const [totalHT, setTotalHT] = useState(null);
  const [TVA, setTVA] = useState(null);
  const { fields } = useFields({
    setTotalHT,
    setTVA,
    supervisionPrice,
    purpose,
    priceToEdit
  });

  useEffect(() => {
    addTotalTTC({ totalHT, TVA, form });
  }, [totalHT, TVA]);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    if (purpose === 'edit') {
      form.setFieldsValue({
        rate: {
          ...priceToEdit,
          application_date: dayjs(priceToEdit.application_date),
          end_date: dayjs(priceToEdit.end_date)
        }
      });
    }
  }, [purpose]);

  return (
    <Drawer
      destroyOnClose
      title={t('supervisionprices.form.title.drawer')}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Form
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
        form={form}
        onFinish={(values) =>
          onFinishForm({
            values,
            purpose,
            supervisionPrice,
            dispatchAPI,
            priceToEdit,
            onClose,
            setTVA,
            setTotalHT,
            setRefreshData,
            refreshData,
            message
          })
        }
      >
        {fields.map((field) => generateFormItem('supervisionprices', field))}
        <Form.Item style={{ marginTop: 16 }}>
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={onClose}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button type="add" htmlType="submit">
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default PriceManagementDrawer;

PriceManagementDrawer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setRefreshData: PropTypes.func,
  refreshData: PropTypes.bool,
  supervisionPrice: PropTypes.shape({}).isRequired,
  purpose: PropTypes.string.isRequired,
  priceToEdit: PropTypes.string
};

PriceManagementDrawer.defaultProps = {
  visible: false,
  setVisible: null,
  setRefreshData: null,
  refreshData: false,
  priceToEdit: null
};
