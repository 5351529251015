import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Typography, List, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { routes } from '../../../../utils/constants/ownerPensionerRoutes';
import { useSocketContext } from '../../../../contexts/SocketContext';
import { profileLockMessage } from '../../utils/profileLockMessage';
import { editingLocked } from '../../../../utils/editingLocked';

const { Text } = Typography;

/**
 * Renders a card component for displaying groomer information.
 *@component
 *
 * @param {Object} props - The component props.
 * @param {Array} props.groomers - The array of groomer objects.
 * @param {function} props.navigate - The function to navigate to a different page.
 * @param {Object} props.owner - The owner object.
 * @param {boolean} props.isFieldsLoading - Indicates if the fields are loading.
 * @returns {JSX.Element} The groomer card component.
 */
export const GroomerCard = ({ groomers, navigate, owner, isFieldsLoading }) => {
  const { t } = useTranslation();
  const { profileLock } = useSocketContext();

  const createPath = `${routes.VETERINARIAN_GROOMER}/create/groomer/${owner?._id}`;
  const editPath = `${routes.VETERINARIAN_GROOMER}/edit/groomer/${groomers[0]?._id}`;

  const items = groomers?.map((groomer) => [
    {
      label: t('groomers.form.name'),
      content: groomer.name || '-'
    },
    {
      label: t('groomers.postal_address'),
      content: groomer.address
        ? `${groomer?.address?.number || ''} ${groomer?.address?.street || ''}`
        : '-'
    },
    {
      label: t('groomers.form.address.city'),
      content: groomer.address ? groomer?.address?.city : '-'
    },
    {
      label: t('groomers.form.address.country'),
      content: groomer.address ? groomer?.address?.country : '-'
    },
    {
      label: t('groomers.form.phone_number.number'),
      content: groomer.phone_number ? groomer.phone_number.number : '-'
    }
  ]);

  return (
    <Card
      className="vet-card"
      title={t('groomers.header_panel')}
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (editingLocked(profileLock, owner?._id)) {
              profileLockMessage(t, 'profile');
              return;
            }
            navigate(`${groomers?.length ? editPath : createPath}`);
          }}
        >
          {groomers?.length ? <EditOutlined /> : <PlusOutlined />}
          {groomers?.length ? t('buttons.edit') : t('buttons.create')}
        </Button>
      }
    >
      {!groomers?.length && <Text>{t('groomers.no_groomer')}</Text>}
      {groomers?.length > 0 && (
        <Skeleton loading={isFieldsLoading} active>
          <List
            itemLayout="vertical"
            dataSource={items.flat(1)}
            renderItem={(item) => (
              <List.Item>
                <Text>{item.label} : </Text>
                <Text strong>{item.content}</Text>
              </List.Item>
            )}
          />
        </Skeleton>
      )}
    </Card>
  );
};

GroomerCard.propTypes = {
  groomers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired
    })
  ).isRequired,
  navigate: PropTypes.func.isRequired,
  owner: PropTypes.shape({
    _id: PropTypes.string.isRequired
  }).isRequired,
  isFieldsLoading: PropTypes.bool.isRequired
};
