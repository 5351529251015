import { Input } from 'antd';

const { TextArea } = Input;

export const useCancelSupervisionInfoFields = () => {
  const cancelSupervisionFields = [
    {
      name: ['decline', 'reason'],
      rules: [{ required: true }]
    },
    {
      name: ['decline', 'comment'],
      input: <TextArea />
    }
  ];

  return { cancelSupervisionFields };
};
