import { createContext, useCallback, useContext, useState } from 'react';
import { useAuthContext } from '../AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';

const OwnerContext = createContext();

/**
 * Provides the OwnerContext to its children components.
 * @context OwnerContext
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The OwnerContextProvider component.
 */
export const OwnerContextProvider = ({ children }) => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [owner, setOwner] = useState({});
  const [supervisions, setSupervisions] = useState([]);

  /**
   * Retrieves the owner data from the API.
   * @param {string} id - The owner ID.
   * @returns {Promise<void>} A promise that resolves when the owner data is retrieved.
   */
  const getOwner = useCallback(async (id) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/owners/${id}?populate=petsitting_address,animals,history.by,user`
      });

      setOwner(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getSupervisions = useCallback(async (id) => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/supervisions?status=FINISHED`,
        body: {},
        params: {
          fields: 'reference',
          'main_informations.owner': id
        }
      });
      setSupervisions(data);
    } catch (e) {
      message(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <OwnerContext.Provider
      value={{ owner, getOwner, supervisions, getSupervisions, isLoading }}
    >
      {children}
    </OwnerContext.Provider>
  );
};

export const useOwnerContext = () => useContext(OwnerContext);
