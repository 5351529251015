import dayjs from 'dayjs';

/**
 * Updates the status of a mail sender record to 'SENT'.
 *
 * @function
 * @param {Object} params - The parameters for updating the status.
 * @param {Function} params.dispatchAPI - The function for making an API request.
 * @param {string} params.id - The ID of the mail sender record.
 * @param {Function} params.setRefreshData - The function for updating the data refresh state.
 * @param {boolean} params.refreshData - The current data refresh state.
 * @param {Function} params.message - The function for displaying a message.
 */

export const updateStatus = async ({
  dispatchAPI,
  id,
  message,
  navigate,
  type,
  sendingType,
  mailSender
}) => {
  try {
    await dispatchAPI('PATCH', {
      url: `/mail-sender/${id}`,
      body: { status: 'SENT', sending_date: dayjs() }
    });

    switch (type) {
      case 'PENSIONER_WISH':
        mailSender.pensioners.map(async (pensionerId) => {
          await dispatchAPI('PATCH', {
            url: `/pensioners/${pensionerId}`,
            body: { wish_mail_sending_date: dayjs() }
          });
        });
        break;
      case 'OWNER_WISH':
        mailSender.owners.map(async (ownerId) => {
          await dispatchAPI('PATCH', {
            url: `/owners/${ownerId}`,
            body: { owner_wish_sending_date: dayjs() }
          });
        });
        break;
      case 'OTHER_PENSIONER':
        mailSender.owners.map(async (pensionerId) => {
          await dispatchAPI('PATCH', {
            url: `/pensioners/${pensionerId}`,
            body: { other_mail_sending_date: dayjs() }
          });
        });
        break;
      case 'OTHER_OWNER':
        mailSender.owners.map(async (ownerId) => {
          await dispatchAPI('PATCH', {
            url: `/owners/${ownerId}`,
            body: { other_mail_sending_date: dayjs() }
          });
        });
        break;
      case 'ANOUNCEMENT':
        mailSender.pensioners.map(async (pensionerId) => {
          await dispatchAPI('PATCH', {
            url: `/pensioners/${pensionerId}`,
            body: { anouncement_mail_sending_date: dayjs() }
          });
        });
        break;
      case 'OWNER_EXPLANATORY_DOCUMENT':
        mailSender.owners.map(async (ownerId) => {
          await dispatchAPI('PATCH', {
            url: `/owners/${ownerId}`,
            body: { explanatory_document_sending_date: dayjs() }
          });
        });
        break;
      case 'PENSIONER_EXPLANATORY_DOCUMENT':
        mailSender.pensioners.map(async (pensionerId) => {
          await dispatchAPI('PATCH', {
            url: `/pensioners/${pensionerId}`,
            body: { explanatory_document_sending_date: dayjs() }
          });
        });
        break;
      case 'GROOMER_VETERINARIAN_ADVERT':
        if (mailSender.veterinarians.length) {
          mailSender.veterinarians.map(async (veterinarianId) => {
            await dispatchAPI('PATCH', {
              url: `/veterinarians/${veterinarianId}`,
              body: { advert_sending_date: dayjs() }
            });
          });
        }
        if (mailSender.groomers.length) {
          mailSender.groomers.map(async (groomerId) => {
            await dispatchAPI('PATCH', {
              url: `/groomers/${groomerId}`,
              body: { advert_sending_date: dayjs() }
            });
          });
        }
        break;
      default:
        return null;
    }

    navigate(`/mail-sender/show/${type}/${id}/SENT/${sendingType}`);
  } catch (e) {
    message(e);
  }
  return null;
};
