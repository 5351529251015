import moment from 'moment';
import { convertToHTML } from '../../../../../utils/convertToHTML';

export const supervisionAnnouncementInfo = ({ anounce }) => {
  const { supervision, description } = anounce[0] || {};
  const { reference, main_informations, address } = supervision || {};
  const { start_date, end_date } = main_informations || {};
  const { petsitting_address } = address || {};

  return [
    {
      label: 'supervisions.show.anounce.reference',
      span: 0,
      content: (reference && reference) || '-'
    },
    {
      label: 'supervisions.show.anounce.start_date',
      span: 1,
      content: (start_date && moment(start_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'supervisions.show.anounce.end_date',
      span: 1,
      content: (end_date && moment(end_date).format('DD/MM/YYYY')) || '-'
    },
    {
      label: 'supervisions.show.anounce.state',
      span: 1,
      content: (petsitting_address && petsitting_address?.address?.state) || '-'
    },
    {
      label: 'supervisions.show.anounce.description',
      span: 10,
      content:
        (description &&
          convertToHTML({
            htmlContent: description
          })) ||
        '-'
    }
  ];
};
