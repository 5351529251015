export const getAnounce = async ({
  dispatchAPI,
  anounceId,
  setAnounce,
  message
}) => {
  try {
    const { data } = await dispatchAPI('GET', {
      url: `/anounces/${anounceId}?populate=supervision.address.petsitting_address`
    });
    setAnounce(data);
  } catch (e) {
    message(e);
  }
};
