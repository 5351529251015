import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Collapse } from 'antd';
import ListRatings from '../ListRatings';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const RatingInfoCards = ({
  t,
  pensioner,
  setReviewActiveKey,
  reviewActiveKey,
  setReviewDrawerOpen,
  questionnaireType,
  setSupervisionRating,
  form,
  setShowReviewDrawer,
  setRefreshData,
  refreshData,
  setReviewData,
  reviewData,
  setEditReviewDrawer
}) => (
  <Collapse accordion>
    <Panel header={t('ratings.header_panel')} key="1">
      <Tabs
        defaultActiveKey={reviewActiveKey}
        onChange={(key) => setReviewActiveKey(key)}
      >
        <TabPane
          tab={t('ratings.tabs.received_reviews')}
          key="received_reviews"
        />
        <TabPane tab={t('ratings.tabs.left_reviews')} key="left_reviews" />
      </Tabs>
      <ListRatings
        reviewActiveKey={reviewActiveKey}
        pensioner={pensioner}
        setReviewDrawerOpen={setReviewDrawerOpen}
        questionnaireType={questionnaireType}
        setSupervisionRating={setSupervisionRating}
        form={form}
        setShowReviewDrawer={setShowReviewDrawer}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
        setReviewData={setReviewData}
        reviewData={reviewData}
        setEditReviewDrawer={setEditReviewDrawer}
      />
    </Panel>
  </Collapse>
);

export default RatingInfoCards;

RatingInfoCards.propTypes = {
  t: PropTypes.func.isRequired,
  reviewActiveKey: PropTypes.bool.isRequired,
  setReviewActiveKey: PropTypes.func.isRequired,
  pensioner: PropTypes.shape({
    animals: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  setReviewDrawerOpen: PropTypes.func.isRequired,
  questionnaireType: PropTypes.string.isRequired,
  setSupervisionRating: PropTypes.func.isRequired,
  form: PropTypes.shape({}).isRequired,
  setShowReviewDrawer: PropTypes.func.isRequired,
  setRefreshData: PropTypes.func.isRequired,
  refreshData: PropTypes.bool.isRequired,
  setReviewData: PropTypes.func.isRequired,
  reviewData: PropTypes.shape({}),
  setEditReviewDrawer: PropTypes.func.isRequired
};

RatingInfoCards.defaultProps = {
  reviewData: {}
};
