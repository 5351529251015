export const handleSelectChangeDoc = ({
  value,
  file,
  filesList,
  setFilesList
}) => {
  const newFile = file;
  newFile.documentType = value;
  newFile.status = 'done';
  const findIndex = filesList.findIndex(
    (f) => f?.newFile?.uid === newFile?.uid
  );
  if (findIndex !== -1) {
    const updatedFileList = [...filesList];
    updatedFileList[findIndex] = { newFile };
    setFilesList(updatedFileList);
  } else {
    setFilesList((prevFileList) => [
      ...prevFileList,
      {
        newFile
      }
    ]);
  }
};
