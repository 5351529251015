import dayjs from 'dayjs';
import { monthArray } from '../monthsArray';

/**
 * Set data for the subscription chart based on subscriptions.
 * @param {Object} params - Parameters for setSubscriptionChart function.
 * @param {Array} params.subscriptions - Subscription data.
 * @param {Function} params.setItems - State setting function for items data.
 * @param {Function} params.t - Translation function for internationalization.
 * @returns {void}
 */
export const setSubscriptionChart = ({
  subscriptions,
  setItems,
  t,
  statisticsDate
}) => {
  /**
   * Object to store statistics for N-1 and N years.
   * @type {Object}
   */

  const statistics = {
    'N-1': subscriptions?.filter(
      ({ payment_date, status }) =>
        dayjs(payment_date).isSame(
          dayjs(statisticsDate).subtract(1, 'year'),
          'year'
        ) && status === 'PAID'
    ),
    N: subscriptions?.filter(
      ({ payment_date, status }) =>
        dayjs(payment_date).isSame(dayjs(statisticsDate), 'year') &&
        status === 'PAID'
    )
  };

  /**
   * Object to store calculated data.
   * @type {Object}
   */
  const calculatedData = {};

  // Iterate over statistics and calculate data.
  Object.entries(statistics).forEach(([key, invoices]) => {
    invoices?.forEach((invoice) => {
      // Calculate month key using dayjs.
      const monthKey = dayjs(invoice.payment_date).format('MMMM');

      // Initialize calculatedData object for the key.
      calculatedData[key] = calculatedData[key] || {};

      // Update calculated data for the month and key.
      calculatedData[key][monthKey] =
        (calculatedData[key]?.[monthKey] || 0) + (invoice.total_ttc || 0);
    });
  });

  // Map over monthsArray and set items data.
  monthArray(t).map((month) =>
    setItems((prev) => [
      ...prev,
      {
        month,
        salesNMinus1: calculatedData['N-1']?.[month.toLowerCase()] || 0,
        salesN: calculatedData.N?.[month.toLowerCase()] || 0
      }
    ])
  );
};
