import React from 'react';
import { Row, Col, Typography, Form, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * Component for displaying extra filters in the MailSender create/update form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.form - Ant Design form object.
 * @returns {JSX.Element} React element representing the component.
 */

const { Text } = Typography;

export const CreateUpdateExtraFilters = ({ form }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} align="middle">
      <Col>
        <Text>{`${t('mail-sender.form.previsional_sending_date_label')}`}</Text>
      </Col>
      <Col>
        <Form form={form}>
          <Form.Item name="previsional_sending_date">
            <DatePicker format="DD/MM/YYYY" />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

CreateUpdateExtraFilters.propTypes = {
  form: PropTypes.shape({}).isRequired
};
