/**
 * Sets parameters for showing a mail sender record based on the type.
 *
 * @function
 * @param {Object} params - The parameters for setting show parameters.
 * @param {string} params.type - The type of mail sender record.
 * @param {Array} params.pensionerColumns - Columns specific to pensioners.
 * @param {Array} params.ownerColumns - Columns specific to owners.
 * @param {Array} params.veterinarianAndGroomerColumns - Columns specific to veterinarians and groomers.
 * @returns {Object|string} - Returns an object with parameters or a string indicating 'other'.
 */

export const setShowParameters = ({
  type,
  pensionerColumns,
  ownerColumns,
  veterinarianAndGroomerColumns
}) => {
  switch (type) {
    case 'ANOUNCEMENT':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        populate: 'pensioners'
      };
    case 'PENSIONER_WISH':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        populate: 'pensioners'
      };
    case 'OWNER_WISH':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        populate: 'owners'
      };
    case 'OWNER_EXPLANATORY_DOCUMENT':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        populate: 'owners'
      };
    case 'PENSIONER_EXPLANATORY_DOCUMENT':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        populate: 'pensioners'
      };
    case 'GROOMER_VETERINARIAN_ADVERT':
      return {
        resourceName: 'veterinarians/groomers_and_veterinarians',
        resourceModelName: 'Veterinarian',
        columns: veterinarianAndGroomerColumns,
        populate: 'veterinarians,groomers'
      };
    case 'OTHER_PENSIONER':
      return {
        resourceName: 'pensioners',
        resourceModelName: 'Pensioner',
        columns: pensionerColumns,
        populate: 'pensioners'
      };
    case 'OTHER_OWNER':
      return {
        resourceName: 'owners',
        resourceModelName: 'Owner',
        columns: ownerColumns,
        populate: 'owners'
      };
    default:
      return null;
  }
};
