import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PrincipalInfoCards from './cards/PrincipalInfoCards';
import DetailsInfoCards from './cards/DetailsInfoCards';
import DocumentsInfoCards from './cards/DocumentsInfoCards';
import { CreditNoteCards } from './cards/creditNote/CreditNoteCards';

/**
 * Composant qui affiche les détails d'une facture.
 * @component
 * @param {string} type - Le type de facture (par exemple, "interninvoices" ou "customerinvoices").
 * @param {Object} invoice - Les détails de la facture.
 * @param {boolean} isLoading - Indique si les données sont en cours de chargement.
 * @returns {JSX.Element}
 */

const DetailsCards = ({
  type,
  invoice,
  isLoading,
  setIsCreditNoteCardVisible,
  isCreditNoteCardVisible
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[12, 12]}>
      <Col xs={24} xxl={24}>
        <PrincipalInfoCards
          t={t}
          isLoading={isLoading}
          invoice={invoice}
          type={type}
        />
      </Col>
      <Col xs={24} xxl={24}>
        {Object.keys(invoice).length ? (
          <DetailsInfoCards
            t={t}
            isLoading={isLoading}
            invoice={invoice}
            type={type}
          />
        ) : null}
      </Col>
      {isCreditNoteCardVisible && (
        <Col xs={24} xxl={24}>
          <CreditNoteCards
            t={t}
            setIsCreditNoteCardVisible={setIsCreditNoteCardVisible}
            isCreditNoteCardVisible={isCreditNoteCardVisible}
            invoice={invoice}
          />
        </Col>
      )}
      <Col xs={24} xxl={24}>
        <DocumentsInfoCards
          t={t}
          isLoading={isLoading}
          invoice={invoice}
          type={type}
        />
      </Col>
    </Row>
  );
};

export default DetailsCards;

DetailsCards.propTypes = {
  type: PropTypes.string.isRequired,
  invoice: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsCreditNoteCardVisible: PropTypes.func,
  isCreditNoteCardVisible: PropTypes.bool
};

DetailsCards.defaultProps = {
  setIsCreditNoteCardVisible: () => {},
  isCreditNoteCardVisible: false
};
