import { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useFields } from '../fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { addCustomerTotalTTC } from './utils/addCustomerTotalTTC';
import { setCustomerFields } from './utils/setCustomerFields';
import { setSupervisionFields } from './utils/setSupervisionFields';
import { useConfig } from './utils/createUpdateConfig';
import { useInvoicesContext } from '../../../contexts/InvoicesContext/InvoicesContext';

/**
 * Composant de création/mise à jour de facture client.
 * @component
 * @param {Object} props - Les propriétés du composant.
 * @param {string} props.purpose - Le but de l'opération (création ou édition).
 * @returns {JSX.Element} Le composant de création/mise à jour de facture client.
 */
export const CreateUpdateCustomerInvoice = ({ purpose }) => {
  const [form] = Form.useForm();
  const [filesList, setFilesList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [updateWordingFields, setUpdateWordingFields] = useState(true);
  const [updatedWordingType, setUpdatedWordingType] = useState('HT');

  const {
    customers,
    customer,
    supervision,
    supervisions,
    selectedSupervisions,
    supervisionPrices,
    getOwnerCustomer,
    getSupervision,
    dispatch
  } = useInvoicesContext();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const OwnerId = searchParams.get('ownerId');
  const OwnerUserId = searchParams.get('ownerUserId');
  const SupervisionId = searchParams.get('supervisionId');
  const { invoiceFields, isFieldsLoading } = useFields({
    filesList,
    setFilesList,
    customers,
    setCustomer: (value) => dispatch({ type: 'SET_CUSTOMER', payload: value }),
    selectedSupervisions,
    setSupervision: (value) =>
      dispatch({ type: 'SET_SUPERVISION', payload: value }),
    invoiceType: 'customer',
    setPaymentMethod,
    paymentMethod,
    setUpdatedWordingType,
    form,
    setUpdateWordingFields,
    updateWordingFields
  });

  const { config } = useConfig({
    filesList,
    setFilesList,
    setSupervisionPrices: (value) =>
      dispatch({ type: 'SET_SUPERVISION_PRICES', payload: value })
  });

  useEffect(() => {
    (async () => {
      if (OwnerId) await getOwnerCustomer(OwnerId);
      if (SupervisionId) await getSupervision(SupervisionId);
    })();

    form.setFieldsValue({
      customer: OwnerUserId,
      supervision: SupervisionId
    });
  }, [OwnerId, SupervisionId]);

  useEffect(() => {
    addCustomerTotalTTC({
      updatedWordingType,
      form,
      supervisionPrices
    });
  }, [updateWordingFields]);

  useEffect(() => {
    setCustomerFields({
      customer,
      form,
      supervisions,
      setSelectedSupervisions: (value) =>
        dispatch({ type: 'SET_SELECTED_SUPERVISIONS', payload: value })
    });
  }, [customer]);

  useEffect(() => {
    setSupervisionFields({ supervision, form });
  }, [supervision]);

  return (
    <CreateUpdateContainer
      fields={invoiceFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="customerinvoices/form"
      resource="customerinvoices"
      populate="files"
      config={config}
      customFormInstance={form}
    />
  );
};

CreateUpdateCustomerInvoice.propTypes = {
  purpose: PropTypes.string.isRequired
};
