import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-components';
import { LeftOutlined } from '@ant-design/icons';

/**
 * Custom page header component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.title - The title of the page.
 * @param {ReactNode} props.extra - Additional content to be displayed on the right side of the header.
 * @param {string} props.customBreadcrumbs - The type of custom breadcrumbs to be displayed.
 * @param {boolean} props.withoutBreadCrumb - Determines whether to display the breadcrumb or not.
 * @param {string} props.customPathName - The custom pathname to be used for generating breadcrumbs.
 * @param {Object} props.supervision - The supervision object used for generating custom breadcrumbs.
 * @param {ReactNode} props.backIcon - The custom back icon to be displayed.
 * @param {Function} props.onBack - The function to be called when the back button is clicked.
 * @returns {JSX.Element} The rendered page header component.
 */
export const PageHeaderCustom = ({
  title,
  extra,
  customBreadcrumbs,
  withoutBreadCrumb,
  customPathName,
  supervision,
  backIcon,
  onBack,
  withoutBackIcon
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitPathname = customPathName
    ? customPathName.split('/').filter((p) => p !== '')
    : pathname.split('/').filter((p) => p !== '');
  let tmpPath;
  const breadCrumbs = splitPathname
    .slice(0, splitPathname.length - 1)
    .map((p) => {
      tmpPath = `/${p}`;
      return {
        path: tmpPath,
        breadcrumbName:
          p === 'show' &&
          customBreadcrumbs === 'supervision' &&
          supervision?.reference
            ? t(`breadcrumbs.supervision`, supervision)
            : t(`breadcrumbs.${p}`)
      };
    });
  const dynamicRoutes = [...breadCrumbs];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <PageHeader
      breadcrumb={
        withoutBreadCrumb ? null : { items: dynamicRoutes, itemRender }
      }
      title={title}
      onBack={() => (onBack ? navigate(onBack) : navigate(-1))}
      ghost={false}
      extra={extra}
      backIcon={!withoutBackIcon ? backIcon || <LeftOutlined /> : false}
    />
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  extra: PropTypes.element,
  customBreadcrumbs: PropTypes.string,
  supervision: PropTypes.shape({
    reference: PropTypes.string
  }),
  withoutBreadCrumb: PropTypes.bool,
  customPathName: PropTypes.string,
  backIcon: PropTypes.bool,
  onBack: PropTypes.string,
  withoutBackIcon: PropTypes.bool
};

PageHeaderCustom.defaultProps = {
  extra: null,
  customBreadcrumbs: null,
  supervision: null,
  title: null,
  withoutBreadCrumb: false,
  customPathName: null,
  backIcon: false,
  onBack: null,
  withoutBackIcon: false
};
