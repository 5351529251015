import { Tag, Divider, Button, DatePicker, Spin } from 'antd';
import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  ExportOutlined,
  CheckOutlined,
  CloseOutlined,
  MailOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { ReservationStatus } from '../../utils/constants/tagColors';
import { useErrorMessage } from '../../utils/errorMessage';
import { cancelReservation } from './utils/cancelReservation';

/**
 * Hook pour obtenir les colonnes de la liste des réservations.
 * @param {Object} options - Les options de configuration.
 * @param {function} options.setPurpose - Fonction pour définir le but de la création/mise à jour de réservation.
 * @param {function} options.setIsCreateUpdateModalOpen - Fonction pour définir l'état de la modal de création/mise à jour de réservation.
 * @param {function} options.setCustomId - Fonction pour définir un identifiant personnalisé.
 * @param {function} options.setIsModalOpen - Fonction pour définir l'état de la modal.
 * @param {function} options.setPensionerId - Fonction pour définir l'identifiant du pensionnaire.
 * @param {function} options.setReservationId - Fonction pour définir l'identifiant de la réservation.
 * @param {function} options.setSupervision - Fonction pour définir les informations de supervision.
 * @param {Array} options.reservations - La liste des réservations.
 * @returns {Array} Les colonnes de la liste des réservations.
 */

export const useColumns = ({
  setPurpose,
  setIsCreateUpdateModalOpen,
  setCustomId,
  setIsModalOpen,
  setPensionerId,
  setReservationId,
  setSupervision,
  reservations,
  setRecipient,
  setIsEmailModalOpen,
  setRecipients
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [enums, setEnums] = useState();
  const dateInput = useRef(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/reservations/enums' });
      setEnums(data);
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return [
    {
      title: t('reservations.form.status'),
      key: 'status',
      dataIndex: ['status'],
      render: (status) =>
        status ? (
          <Tag color={ReservationStatus[status]}>
            {t(`reservations.tags.${status}`, {
              defaultValue: status
            })}
          </Tag>
        ) : (
          'N/R'
        ),
      sorter: true,
      filters: enums?.status?.map((r) => ({
        text: t(`reservations.tags.${r}`),
        value: r
      }))
    },
    {
      title: t('reservations.form.pensioner.reference'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => pensioner?.reference,
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.name'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => pensioner?.last_name,
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.address.city'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => pensioner?.address?.city,
      sorter: true
    },
    {
      title: t('reservations.form.supervision.reference'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (supervision) => supervision?.reference,
      sorter: true
    },
    {
      title: t('reservations.form.supervision.address.city'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (supervision) =>
        supervision?.address?.petsitting_address?.address?.city,
      sorter: true
    },
    {
      title: t('reservations.form.supervision.address.postal_code'),
      key: 'supervision',
      dataIndex: 'supervision',
      render: (supervision) =>
        supervision?.address?.petsitting_address?.address?.postal_code,
      sorter: true
    },
    {
      title: t('reservations.form.pensioner.email'),
      key: 'pensioner',
      dataIndex: 'pensioner',
      render: (pensioner) => pensioner?.email,
      sorter: true
    },
    {
      title: t('reservations.form.date'),
      key: 'date',
      dataIndex: 'date',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <div>
          <DatePicker
            ref={dateInput}
            value={selectedKeys.length ? dayjs(selectedKeys[0]) : null}
            onChange={(e) => {
              setSelectedKeys(e ? [dayjs(e).format('YYYY-MM-DD')] : []);
              confirm({ closeDropdown: true });
              clearFilters();
            }}
            onPressEnter={() => confirm()}
          />
          <Button onClick={() => confirm()}>{t('buttons.filter')}</Button>
        </div>
      ),
      onfilter: (value, record) =>
        record.date === dayjs(value).format('YYYY/MM/DD'),
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      key: 'action',
      align: 'right',
      fixed: 'right',
      width: 200,
      render: (record) => {
        const pensionerAlreadyHasReservation = reservations.some(
          (reservation) =>
            reservation.status === 'RESERVE' &&
            reservation?.supervision?._id !== record?.supervision?._id &&
            reservation?.pensioner?._id === record?.pensioner?._id &&
            !(
              dayjs(
                reservation?.supervision?.main_informations?.end_date
              ).isBefore(record?.supervision?.main_informations?.start_date) ||
              dayjs(
                reservation?.supervision?.main_informations?.start_date
              ).isAfter(record?.supervision?.main_informations?.end_date)
            )
        );
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setRecipients([
                  { ...record.pensioner, customer_type: 'PENSIONER' }
                ]);
                setRecipient(record.pensioner.user._id);
                setIsEmailModalOpen(true);
              }}
              style={{ padding: 0 }}
            >
              <MailOutlined style={{ fontSize: 18 }} />
            </Button>
            {record.status === 'WAITING' &&
            !reservations
              .filter(
                (reservation) =>
                  reservation?.supervision?._id === record?.supervision?._id
              )
              .some((element) => element.status === 'RESERVE') &&
            !pensionerAlreadyHasReservation ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() => {
                    setIsModalOpen(true);
                    setPensionerId(record?.pensioner?._id);
                    setReservationId(record._id);
                    setSupervision(record?.supervision);
                  }}
                  style={{ padding: 0 }}
                >
                  <CheckOutlined style={{ fontSize: 18, color: 'green' }} />
                </Button>
              </>
            ) : null}
            {record.status === 'RESERVE' ? (
              <>
                <Divider type="vertical" />
                <Button
                  type="link"
                  onClick={() =>
                    cancelReservation({
                      reservationId: record._id,
                      supervisionId: record?.supervision?._id,
                      pensionerId: record?.pensioner?._id,
                      dispatchAPI,
                      navigate,
                      message,
                      setIsLoading
                    })
                  }
                  style={{ padding: 0 }}
                >
                  {isLoading[record._id] ? (
                    <Spin size="small" />
                  ) : (
                    <CloseOutlined style={{ fontSize: 18, color: 'red' }} />
                  )}
                </Button>
              </>
            ) : null}
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `../supervisions/show/${record?.supervision?._id}`
              }}
            >
              <ExportOutlined style={{ fontSize: 18 }} />
            </Link>
            <Divider type="vertical" />
            <Button
              type="link"
              onClick={() => {
                setPurpose('edit');
                setIsCreateUpdateModalOpen(true);
                setCustomId(record._id);
              }}
              style={{ padding: 0 }}
            >
              <EditOutlined style={{ fontSize: 18 }} />
            </Button>
          </>
        );
      }
    }
  ];
};
