import { useCallback, useEffect, useState, forwardRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin, Card, message as antdMessage } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/errorMessage';
import { ContentCustom } from '../ContentCustom/ContentCustom';
import { PageHeaderCustom } from '../PageHeader/PageHeader';
import { useGenerateFormItem } from '../../utils/generateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../utils/constants/formLayout';

/**
 * The `CreateUpdateContainer` component is a higher-order component responsible for the Create and Update functionalities.
 * It displays a form with input fields defined in the `fields` or `formCards` props, potentially grouped in cards.
 * It handles the form submission, where it either creates or updates a resource based on the `purpose` prop.
 * It also handles loading states during resource fetching and submission.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.purpose - The purpose of this component, which can either be "create" or "edit".
 * @param {Object[]} props.fields - An array of objects representing the fields in the form.
 * @param {string} props.baseUrl - The base URL for the resource API endpoint.
 * @param {string} props.resource - The name of the resource being created or updated.
 * @param {boolean} [props.loadingFields=false] - Indicates whether the fields are loading.
 * @param {Object} [props.config={}] - Configuration object for fetching and submitting data.
 * @param {React.Element} [props.formExtra=null] - Additional React element to be displayed at the end of the form.
 * @param {string} [props.tradKey=null] - The translation key prefix for field labels and messages.
 * @param {string} [props.submitLabel=null] - The label for the submit button.
 * @param {function} [props.customSubmit=null] - A custom submit function to be called on form submission.
 * @param {boolean} [props.isParentLoading=false] - Indicates whether the parent component is loading.
 * @param {Object[]} [props.formCards=null] - An array of objects representing groups of fields in the form, to be displayed inside cards.
 * @param {string[]} [props.formCardsTitles=null] - Titles for the form cards.
 * @param {Object} [props.wordingFields=null] - Additional field properties for customization.
 * @param {string} [props.populate=null] - The population parameter for the GET request when fetching data for editing.
 * @param {Object} [props.customFormInstance=null] - The custom form instance passed down from the parent component.
 *
 * @example
 *
 * @returns {JSX.Element} The CreateUpdateContainer component.
 * return (
 *   <CreateUpdateContainer purpose="create" fields={fields} baseUrl="/api/resource" resource="resource" />
 * );
 */
export const CreateUpdateContainer = forwardRef(
  (
    {
      customFormInstance,
      purpose,
      fields,
      formCards,
      formCardsTitles,
      loadingFields,
      resource,
      baseUrl,
      config,
      formExtra,
      tradKey,
      submitLabel,
      customSubmit,
      isParentLoading,
      populate,
      withPageHeaderCustom,
      customNavigate,
      customId,
      extraQuery,
      customIsSubmiting,
      disabled
    },
    ref
  ) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { message } = useErrorMessage();
    const { dispatchAPI } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const generateFields = useGenerateFormItem();
    const [form] = Form.useForm();
    const { onGetResource, onCreateResource, onUpdateResource } = config;

    const updateResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('PATCH', {
          url: `${baseUrl}/${customId || id}${
            extraQuery ? `?${extraQuery}` : ''
          }`,
          body:
            onUpdateResource && onUpdateResource.setBody
              ? onUpdateResource.setBody(body)
              : body
        });
        if (customNavigate) {
          customNavigate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        if (e?.response?.data?.message?.includes('E11000')) {
          antdMessage.warning(t('errors.message.E11000'));
        } else {
          message(e);
        }
        setIsSubmitting(false);
      }
    };

    const createResource = async (body) => {
      setIsSubmitting(true);
      try {
        await dispatchAPI('POST', {
          url: `${baseUrl}`,
          body:
            onCreateResource && onCreateResource.setBody
              ? await onCreateResource.setBody(body)
              : body
        });
        if (customNavigate) {
          customNavigate();
        } else {
          navigate(-1);
        }
      } catch (e) {
        if (e?.response?.data?.message.includes('E11000')) {
          antdMessage.warning(t('errors.message.E11000'));
        } else {
          message(e);
        }
        setIsSubmitting(false);
      }
    };

    const getResource = useCallback(async () => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `${baseUrl}/${customId || id}${
            populate ? `?populate=${populate}` : ''
          }`
        });
        (customFormInstance || form).setFieldsValue(
          onGetResource && onGetResource.setFields
            ? await onGetResource.setFields(data)
            : data
        );
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    }, [purpose, id, loadingFields, baseUrl, customId]);

    useEffect(() => {
      if (purpose === 'edit' && (id || customId)) {
        setIsLoading(true);
        if (!loadingFields)
          (async () => {
            await getResource();
          })();
      }
    }, [getResource]);

    const handleSubmit = async (values) => {
      if (customSubmit) customSubmit(values);
      else {
        if (purpose === 'edit') await updateResource(values);
        if (purpose === 'create') await createResource(values);
      }
    };

    return (
      <>
        {withPageHeaderCustom && (
          <PageHeaderCustom title={t(`${resource}.form.title.${purpose}`)} />
        )}
        <ContentCustom>
          <Spin spinning={isLoading || isParentLoading}>
            <Form
              ref={ref}
              {...formItemLayout}
              onFinish={handleSubmit}
              form={customFormInstance || form}
              disabled={disabled}
            >
              {formCards &&
                formCards.map((formFields, index) => (
                  <Card
                    className="form-card"
                    key={formCardsTitles[index]}
                    title={t(
                      `card_titles.${resource}.${formCardsTitles[index]}`
                    )}
                  >
                    {formFields.map((field) =>
                      generateFields(tradKey || resource, field)
                    )}
                  </Card>
                ))}
              {fields &&
                fields.map((field) =>
                  generateFields(tradKey || resource, field)
                )}
              {formExtra}
              <Form.Item {...tailFormItemLayout}>
                <Row justify="end">
                  <Button
                    style={{ margin: '0 10px' }}
                    type="link"
                    danger
                    onClick={() => navigate(-1)}
                  >
                    {`${t('buttons.cancel')} `}
                    <CloseOutlined />
                  </Button>
                  <Button
                    type="add"
                    htmlType="submit"
                    loading={isSubmitting || customIsSubmiting}
                  >
                    {`${t(submitLabel || 'buttons.save')} `}
                    <CheckOutlined />
                  </Button>
                </Row>
              </Form.Item>
            </Form>
          </Spin>
        </ContentCustom>
      </>
    );
  }
);

CreateUpdateContainer.propTypes = {
  purpose: PropTypes.string.isRequired,
  customIsSubmiting: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseUrl: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  loadingFields: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  formExtra: PropTypes.element,
  tradKey: PropTypes.string,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool,
  formCards: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  formCardsTitles: PropTypes.arrayOf(PropTypes.string),
  populate: PropTypes.string,
  customFormInstance: PropTypes.shape({}),
  withPageHeaderCustom: PropTypes.bool,
  customNavigate: PropTypes.func,
  customId: PropTypes.string,
  extraQuery: PropTypes.string,
  disabled: PropTypes.bool
};

CreateUpdateContainer.defaultProps = {
  config: {},
  loadingFields: false,
  formExtra: null,
  tradKey: null,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false,
  formCards: null,
  formCardsTitles: null,
  populate: null,
  customFormInstance: null,
  withPageHeaderCustom: false,
  customNavigate: null,
  customId: null,
  extraQuery: null,
  customIsSubmiting: false,
  disabled: false
};
