import { useState, useEffect } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import { useFields } from '../fields';
import { CreateUpdateContainer } from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/errorMessage';
import { addInternTotalTTC } from './utils/addInternTotalTTC';
import { useConfig } from './utils/createUpdateConfig';

/**
 * Composant de création/mise à jour de factures internes.
 * @component
 * @param {Object} props - Les propriétés du composant.
 * @param {string} props.purpose - Le but du composant (création ou mise à jour).
 * @returns {JSX.Element} Le composant de création/mise à jour de factures internes.
 */

export const CreateUpdateInternInvoice = ({ purpose }) => {
  const [form] = Form.useForm();
  const [filesList, setFilesList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [updateWordingFields, setUpdateWordingFields] = useState(true);
  const { invoiceFields, isFieldsLoading } = useFields({
    filesList,
    setFilesList,
    paymentMethod,
    setPaymentMethod,
    invoiceType: 'intern',
    setUpdateWordingFields,
    updateWordingFields,
    form
  });
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();

  useEffect(() => {
    addInternTotalTTC({ form });
  }, [updateWordingFields]);

  const config = useConfig({ filesList, setFilesList, dispatchAPI, message });

  return (
    <CreateUpdateContainer
      fields={invoiceFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="interninvoices/form"
      resource="interninvoices"
      config={config}
      populate="files"
      customFormInstance={form}
    />
  );
};

CreateUpdateInternInvoice.propTypes = {
  purpose: PropTypes.string.isRequired
};
