import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Row, Card, Col } from 'antd';
import { principalInfo } from '../listContent/principalInfo';
import { DescriptionList } from '../../../../../components';

/**
 * PrincipalInfoCards component for displaying principal information cards.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.type - Type of the principal info cards (e.g., 'customerinvoices', 'interninvoices').
 * @param {function} props.t - Translation function.
 * @param {boolean} props.isLoading - Flag indicating whether the data is loading.
 * @param {Object} props.invoice - The invoice object.
 * @returns {JSX.Element} PrincipalInfoCards component.
 */
const PrincipalInfoCards = ({ type, t, isLoading, invoice }) => {
  const { customerInfo, internInfo } = principalInfo(invoice, t);

  return (
    <Card title={t(`${type}.show.principal_info`)} className="form-card">
      <Row gutter={[0, 24]}>
        <Col xs={24} xxl={24}>
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <DescriptionList
              data={type === 'customerinvoices' ? customerInfo : internInfo}
              translate
            />
          </Skeleton>
        </Col>
      </Row>
    </Card>
  );
};

export default PrincipalInfoCards;

PrincipalInfoCards.propTypes = {
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({}).isRequired
};
