export const useDocumentsManagement = (supervision, t, user) => {
  const selectOptions = [
    ...(user.role === 'users:OWNER'
      ? [
          {
            label: t('supervisions.show.documents.OWNER_CONTRACT'),
            value: 'OWNER_CONTRACT__'
          },
          {
            label: t('supervisions.show.documents.OWNER_RATING_SHEET'),
            value: 'OWNER_RATING_SHEET__'
          }
        ]
      : [
          {
            label: t('supervisions.show.documents.PENSIONER_CONTRACT_1', {
              reference: supervision.pensioners?.[0]?.reference
            }),
            value: 'PENSIONER_CONTRACT_1'
          },
          {
            label: t('supervisions.show.documents.PENSIONER_RATING_SHEET_1'),
            value: 'PENSIONER_RATING_SHEET_1'
          }
        ]),
    {
      label: t('supervisions.show.documents.OTHER_DOCUMENT'),
      value: 'OTHER_DOCUMENT__'
    }
  ];

  return { selectOptions };
};
