import { Flex, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ExtraFilters = ({ setDateFilter }) => {
  const { t } = useTranslation();

  return (
    <Flex gap="middle" align="center">
      <DatePicker
        placeholder={t('supervisions.place_holders.start_date')}
        style={{ width: 200 }}
        format="DD/MM/YYYY"
        onChange={(e) => setDateFilter(e)}
      />
    </Flex>
  );
};

ExtraFilters.propTypes = {
  setDateFilter: PropTypes.func
};

ExtraFilters.defaultProps = {
  setDateFilter: () => {}
};
