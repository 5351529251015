import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { SupervisionAlerts } from './cards/SupervisionAlerts';
import { GeneralAlerts } from './cards/GeneralAlerts';

/**
 * ShowAlerts component for displaying supervision and general alerts.
 * @component
 * @param {Object} props - The properties of the ShowAlerts component.
 * @param {Array} props.notifications - An array of notifications.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {boolean} props.forceRefresh - Boolean flag for forcing a data refresh.
 * @param {Function} props.setForceRefresh - Function to set the forceRefresh state.
 * @param {Function} props.message - Function for displaying messages.
 * @param {Function} props.dispatchAPI - Function for dispatching API calls.
 * @param {Object} props.user - User object containing user information.
 * @param {string} props.user._id - User ID.
 * @param {boolean} props.loading - Boolean flag indicating if data is loading.
 * @returns {JSX.Element} - JSX element representing the ShowAlerts component.
 */
export const ShowAlerts = ({
  notifications,
  t,
  forceRefresh,
  setForceRefresh,
  message,
  dispatchAPI,
  user,
  loading
}) => (
  <Row gutter={[32, 24]}>
    <Col xs={24} lg={12}>
      <SupervisionAlerts
        notifications={notifications}
        t={t}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        message={message}
        dispatchAPI={dispatchAPI}
        user={user}
        loading={loading}
      />
    </Col>
    <Col xs={24} lg={12}>
      <GeneralAlerts
        notifications={notifications}
        t={t}
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        message={message}
        dispatchAPI={dispatchAPI}
        user={user}
      />
    </Col>
  </Row>
);

ShowAlerts.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  dispatchAPI: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired
};
