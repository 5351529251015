/**
 * Generate data for a supervisions pie chart based on status counts.
 * @param {Object} params - Parameters for supervisionsPieChartData function.
 * @param {number} params.request - Count of supervisions with status REQUEST.
 * @param {number} params.waiting - Count of supervisions with status WAITING.
 * @param {number} params.published - Count of supervisions with status PUBLISHED.
 * @param {number} params.waiting_booking - Count of supervisions with status WAITING_BOOKING.
 * @param {number} params.booked - Count of supervisions with status BOOKED.
 * @param {number} params.in_progress - Count of supervisions with status IN_PROGRESS.
 * @param {Function} params.t - Translation function for internationalization.
 * @returns {Array} - Array of objects representing supervisions pie chart data.
 */
export const supervisionsPieChartData = ({
  request,
  waiting,
  published,
  waiting_booking,
  booked,
  in_progress,
  t
}) => [
  {
    status: t('supervisions.tags.REQUEST'),
    value: request || 0
  },
  {
    status: t('supervisions.tags.WAITING'),
    value: waiting || 0
  },
  {
    status: t('supervisions.tags.PUBLISHED'),
    value: published || 0
  },
  {
    status: t('supervisions.tags.WAITING_BOOKING'),
    value: waiting_booking || 0
  },
  {
    status: t('supervisions.tags.BOOKED'),
    value: booked || 0
  },
  {
    status: t('supervisions.tags.IN_PROGRESS'),
    value: in_progress || 0
  }
];
