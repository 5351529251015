// eslint-disable-next-line import/no-extraneous-dependencies
import { Pie } from '@ant-design/plots';
import { Card, Col, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { supervisionsPieChartData } from '../../../utils/setDataChart/supervisionsPieChart';
import { supervisionsPieChartConfig } from '../../../utils/setChartConfig/supervisionsPieChartConfig';

/**
 * SupervisionsPieChart component displays a pie chart representing the status of supervisions using Ant Design components and @ant-design/plots.
 * It utilizes custom hooks for data fetching and chart configuration.
 *
 * @component
 * @param {Object} props - Component properties.
 * @param {Function} props.t - Translation function for internationalization.
 * @param {Object} props.supervisions - Object containing the counts for different supervision statuses.
 * @param {number} props.supervisions.request - Count of supervisions in the 'request' status.
 * @param {number} props.supervisions.waiting - Count of supervisions in the 'waiting' status.
 * @param {number} props.supervisions.published - Count of supervisions in the 'published' status.
 * @param {number} props.supervisions.waitingBooking - Count of supervisions in the 'waitingBooking' status.
 * @param {number} props.supervisions.booked - Count of supervisions in the 'booked' status.
 * @param {number} props.supervisions.inProgress - Count of supervisions in the 'inProgress' status.
 * @returns {JSX.Element} SupervisionsPieChart component.
 */
export const SupervisionsPieChart = ({
  t,
  supervisionsStatus,
  setStatusDate
}) => {
  const { request, waiting, published, waiting_booking, booked, in_progress } =
    supervisionsStatus || {};

  return (
    <Col xs={24}>
      <DatePicker
        onChange={(date) => setStatusDate(date)}
        format="DD/MM/YYYY"
        style={{ marginBottom: '20px' }}
      />
      <Card
        title={t('dashboard.tabs.supervisions.status')}
        className="dashboard-card"
      >
        <Pie
          {...supervisionsPieChartConfig(
            supervisionsPieChartData({
              request,
              waiting,
              published,
              waiting_booking,
              booked,
              in_progress,
              t
            })
          )}
        />
      </Card>
    </Col>
  );
};

SupervisionsPieChart.propTypes = {
  t: PropTypes.func.isRequired,
  supervisionsStatus: PropTypes.shape({}).isRequired,
  setStatusDate: PropTypes.func.isRequired
};
