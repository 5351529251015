import { useCallback, useEffect, useState } from 'react';
import { Form, Input, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../../../contexts/AuthContext';
import { useErrorMessage } from '../../../../../utils/errorMessage';
import { useCountryArray } from '../../../../../utils/countryArray';
import { useCountryCodeInfos } from '../../utils/useCountryCodeInfos';

const { Option } = Select;

/**
 * Custom hook to manage the fields of a form in your React application. It fetches enum values from the API and prepares an array of form field configurations.
 * The hook also maintains a loading state for async operations and has an error handling mechanism.
 *
 * @param {Function} setTypeOfDwelling - A callback function to set the type of dwelling.
 * @param {Object} form - Instance of form.
 * @param {string} country - The country data.
 * @param {Function} setCountry - A function to set the country data.
 *
 * @returns {Object} Object containing the following properties:
 *
 * - `formFields`: An array of objects representing each field in the form. Each object has properties like label, name, input, and optionally rules which are used to construct the form fields in the component where this hook is used.
 *
 * @hook
 * @function
 */
export const useFields = ({ setTypeOfDwelling, form, country, setCountry }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const countries = useCountryArray();
  const { handleCountryCodeInfos } = useCountryCodeInfos(country);

  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});

  const filterOption = (input, option) =>
    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const formFields = [
    [
      {
        label: 'type_of_dwelling',
        name: ['petsitting_address', 'type_of_dwelling'],
        rules: [{ required: true }],
        input: (
          <Select
            loading={isFieldsLoading}
            onChange={(e) => setTypeOfDwelling(e)}
          >
            {(enums?.type_of_dwelling || []).map((type_of_dwelling) => (
              <Option key={type_of_dwelling} value={type_of_dwelling}>
                <Tag>{t(`petsittingaddress.tags.${type_of_dwelling}`)}</Tag>
              </Option>
            ))}
          </Select>
        )
      },
      {
        name: ['petsitting_address', 'supervision_phone'],
        label: 'supervision_phone.number',
        input: (
          <Input.Group compact>
            <Form.Item
              noStyle
              name={['petsitting_address', 'supervision_phone', 'country_code']}
              initialValue="+33"
            >
              <Select style={{ width: 70 }}>
                <Option value="+33">+33</Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              name={['petsitting_address', 'supervision_phone', 'number']}
            >
              <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
            </Form.Item>
          </Input.Group>
        )
      },
      {
        label: 'address.number',
        name: ['petsitting_address', 'address', 'number'],
        rules: [{ required: true }]
      },
      {
        label: 'address.street',
        name: ['petsitting_address', 'address', 'street'],
        rules: [{ required: true }]
      },
      {
        label: 'address.additional',
        name: ['petsitting_address', 'address', 'additional']
      },
      {
        label: 'address.country',
        name: ['petsitting_address', 'address', 'country'],
        input: (
          <Select
            showSearch
            loading={isFieldsLoading}
            filterOption={filterOption}
          >
            {(countries || []).map((value) => (
              <Option key={value} value={value}>
                <Tag>{value}</Tag>
              </Option>
            ))}
          </Select>
        ),
        rules: [
          { required: true },
          ({ getFieldValue }) => {
            setCountry(
              getFieldValue(['petsitting_address', 'address', 'country'])
            );
          }
        ]
      },
      {
        label: 'address.postal_code',
        name: ['petsitting_address', 'address', 'postal_code'],
        rules: [{ required: true }],
        input: (
          <Input
            type="number"
            onChange={(e) =>
              handleCountryCodeInfos(e.target.value).then((values) => {
                form.setFieldValue(
                  ['petsitting_address', 'address', 'city'],
                  values?.city
                );
                form.setFieldValue(
                  ['petsitting_address', 'address', 'state'],
                  values?.state
                );
              })
            }
          />
        )
      },
      {
        label: 'address.state',
        name: ['petsitting_address', 'address', 'state'],
        rules: [{ required: true }]
      },
      {
        label: 'address.city',
        name: ['petsitting_address', 'address', 'city'],
        rules: [{ required: true }]
      },
      {
        label: 'insurance_company.consulting_room_name',
        name: [
          'petsitting_address',
          'insurance_company',
          'consulting_room_name'
        ]
      },
      {
        label: 'insurance_company.address',
        name: ['petsitting_address', 'insurance_company', 'address']
      },
      {
        name: ['petsitting_address', 'insurance_company', 'phone_number'],
        label: 'insurance_company.phone_number.number',
        input: (
          <Input.Group compact>
            <Form.Item
              noStyle
              name={[
                'petsitting_address',
                'insurance_company',
                'phone_number',
                'country_code'
              ]}
              initialValue="+33"
            >
              <Select style={{ width: 70 }}>
                <Option value="+33">+33</Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              name={[
                'petsitting_address',
                'insurance_company',
                'phone_number',
                'number'
              ]}
            >
              <Input type="number" style={{ width: 'calc(100% - 70px)' }} />
            </Form.Item>
          </Input.Group>
        )
      },
      {
        label: 'police_number',
        name: ['petsitting_address', 'police_number']
      }
    ]
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: 'owners/petsittingaddress/enums'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    formFields
  };
};
